import { createContext, useContext, useEffect } from 'react';
import type { CapacityManagementRootResponse } from 'src/apis/capacity-management/types';
import { useAxios } from 'src/hooks/use-axios';
import { handleError } from 'src/utils/handle-error';
import { reportToSentry } from 'src/utils/report-to-sentry';

// this hook will be used while the parellel prod deployment of capacity management.
// we safely try to access the root resource to figure out if the user should see
// capacity management features or not.
export function useCapacityManagementRequest() {
  const capacityManagement = useAxios(
    (axios, baseConfig) =>
      axios.request<CapacityManagementRootResponse>({
        ...baseConfig,
        url: PRISMA_CONFIG.capacityManagement,
        timeout: 5000,
      }),
    {
      neededOnPageLoad: true,
      onError(error) {
        const [_, report] = handleError(error, () => {});
        if (report) reportToSentry(error);
        return error;
      },
    }
  );

  useEffect(() => {
    capacityManagement.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return capacityManagement;
}

export type CapacityManagementRootRequest = ReturnType<
  typeof useCapacityManagementRequest
>;

const CapacityManagementContext = createContext<
  CapacityManagementRootResponse | undefined
>(undefined);

export const CapacityManagementProvider = CapacityManagementContext.Provider;

export function useCapacityManagement() {
  return useContext(CapacityManagementContext);
}
