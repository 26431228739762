// types for the capacity management service (*only* backend)
import type {
  Links,
  NeverLinks,
  OptionalLinks,
  SortDirection,
  Embedded,
} from 'src/apis/api-utilities';
import type {
  DirectionType,
  FlowDirection,
} from 'src/components/data-display/direction';
import type { PeriodTypeValue } from 'src/pages/reporting/auctions/components/period-type';

export type CapacityManagementRootResponse = {
  // if capacity management is hidden via a feature toggle
  // the whole `_links` object will be `undefined`
  // for non-TAG users
  // see https://prisma.atlassian.net/browse/TAG-4217
  _links?: Links<
    | 'self'
    | 'marketAreas'
    | 'capacityCategories'
    | 'balancingGroups'
    | 'deal'
    | 'trade'
    | 'conversionRequest'
    | 'affiliateTransactions'
  > &
    OptionalLinks<
      | 'networkpoint'
      | 'draftedProducts'
      | 'uploadedProduct'
      | 'uploadedProductByOfferId'
      | 'myTransactionsV3'
      | 'myTsoTransactionsV3'
      | 'myTsoTransactionsShipperFilter'
      | 'myPortfolioGraph'
      | 'capacityGraph'
      | 'viewAvailabilityAndPriceData'
      | 'monthlyStatements'
      | 'monthlyStatementsWithCommodityFeeSupport'
      | 'shipperContracts'
      | 'corrections'
      | 'invoicesV2'
      | 'myInvoicesV2'
      | 'publishInvoices'
      | 'shipper'
      | 'operator'
      | 'curtailments'
      | 'multiYearTransactionTransportAllocation'
      | 'balancingGroupAllocations'
      | 'settingsInvoicing'
    >;
};

export type CmNetworkPointResponse = {
  _links: Links<
    | 'self'
    | 'viewInternalBookings'
    | 'viewMaintenancePlans'
    | 'viewTechnicalCapacity'
    | 'viewTariff'
    | 'getCustomPriceSteps'
    | 'getAvailabilitySheet'
    | 'getBalancingGroups'
    | 'getBalancingGroupsAllocationsSheet'
  > &
    OptionalLinks<
      | 'manageInternalBookings'
      | 'manageMaintenancePlans'
      | 'manageTechnicalCapacity'
      | 'manageTariff'
      | 'updateCustomPriceSteps'
    >;
  id: string;
  name: string;
  flowUnit: FlowUnit;
  capacityCategories: CapacityCategory[];
};

export type CapacityCategory = { id: string; name: string };

export type CapacityCategoriesResponse = {
  capacityCategories: CapacityCategory[];
};

export type MarketArea = { id: string; name: string };

export type MarketAreasResponse = {
  marketAreas: MarketArea[];
};

export type FlowUnit = { id: string; name: string };

export type InternalBookingWrite = {
  validFrom: string;
  amount: number;
  capacityCategoryId: string;
};

export type InternalBookingResponse = {
  _links: Links<'self'> & OptionalLinks<'update' | 'delete'>;
  id: string;
  validFrom: string;
  amount: number;
  capacityCategory: CapacityCategory;
  flowUnit: FlowUnit;
};

export type InternalBookingsParams = {
  sortBy?: InternalBookingsSortBy;
  sortDirection?: SortDirection;
  offset?: number;
  limit?: number;
};

export type InternalBookingsResponse = {
  _links: Links<'self' | 'create' | 'instance'>;
  total: number;
  limit: number;
  offset: number;
} & Embedded<InternalBookingResponse, 'optional'>; // _embedded === undefined // no items

export type MaintenancePlanResponse = {
  // _links: Links<'self'>;
  validFrom: string;
  validTo: string;
  amount: number;
  flowUnit: FlowUnit;
  planned: boolean;
};

export type InternalBookingsSortBy = 'validFrom';

export type MaintenancePlansSortBy = 'validFrom' | 'validTo' | 'planned';

export type MaintenancePlansParams = {
  sortBy?: MaintenancePlansSortBy;
  sortDirection?: SortDirection;
  offset: number;
  limit?: number;
};

export type MaintenancePlansResponse = {
  _links: Links<'self' | 'update'>;
  // _embedded === undefined // no items
  items: MaintenancePlanResponse[];
  total: number;
  limit: number;
  offset: number;
};

type TechnicalCapacity = {
  value: number;
  flowUnit: FlowUnit;
};

type TechnicalCapacityWrite = {
  value: number;
  flowUnitId: string;
};

export type TechnicalCapacityItem = {
  validFrom: string;
  firm: TechnicalCapacity;
  interruptible: TechnicalCapacity;
};

export type TechnicalCapacityResponse = {
  _links: Links<'self' | 'update'>;
  // undefined, if empty
} & Embedded<TechnicalCapacityItem, 'optional'>;

export type TechnicalCapacityItemWrite = {
  validFrom: string;
  firm: TechnicalCapacityWrite;
  interruptible: TechnicalCapacityWrite;
};

export type TechnicalCapacityWriteData = {
  slices: TechnicalCapacityItemWrite[];
};

export type MonetaryValue = {
  value: number;
  currency: string;
};

export type TimeUnit = 'YEAR' | 'DAY' | 'HOUR';

export type TariffItem = {
  validFrom: string;
  capacityCategory: CapacityCategory;
  regulatedTariff: MonetaryValue;
  flowUnit: FlowUnit;
  timeUnit: TimeUnit;
  itemId: string;
  multipliers: {
    year: number;
    quarter: number;
    month: number;
    day: number;
    withinday: number;
  };
};

export type TariffResponse = {
  _links: Links<'self' | 'update'>;
  slices: TariffItem[];
};

export type TariffItemWrite = {
  validFrom: string;
  capacityCategoryId: string;
  regulatedTariff: MonetaryValue;
  flowUnitId: string;
  timeUnit: TimeUnit;
  itemId: string;
  multipliers: {
    year: number;
    quarter: number;
    month: number;
    day: number;
    withinday: number;
  };
};

export type TariffWriteData = {
  slices: TariffItemWrite[];
};

export type PriceSteps = {
  year: number;
  quarter: number;
  month: number;
};

export type PriceStepsResponse = {
  _links: Links<'self'> & OptionalLinks<'update' | 'delete'>;
} & PriceSteps;

export type DraftedProduct = {
  _links: Links<
    | 'self'
    | 'technicalCapacityUnderlyingData'
    | 'productComparisonGraph'
    | 'draftedProductUnderlyingDataSheet'
    | 'availableTechnicalCapacityUnderlyingData'
  > &
    OptionalLinks<
      | 'nominatedCapacityUnderlyingData'
      | 'requestedSurrenderCapacityUnderlyingData'
      | 'fdaUioliCapacityUnderlyingData'
      | 'bookedCapacityUnderlyingData'
    >;
  id: string;
  networkpointName: string;
  networkPointId: string;
  direction: FlowDirection;
  productType: PeriodTypeValue;
  runtimeStart: string;
  runtimeEnd: string;
  amount: number;
  capacityCategory: CapacityCategory;
  flowUnit: FlowUnit;
  regulatedTariff: MonetaryValue;
  additionalCharges: Charge[];
  calculation: ProductCalculation;
  scheduleInformation: ScheduleInformation;
};

export type Charge = {
  id: string;
  name: string;
  monetaryValue: MonetaryValue;
  flowUnit: FlowUnit;
};

export type ScheduleInformation = {
  uploadTime: string;
  publicationTime: string;
  auctionStartTime: string;
  isEstimate: boolean;
};
export type UnderlyingDataParams = {
  offset?: number;
  type: UnderlyingDataType;
  limit?: number;
};

export type UnderlyingDataType =
  | 'TECHNICAL_CAPACITY'
  | 'AVAILABLE_TECHNICAL_CAPACITY'
  | 'NOMINATED_CAPACITY'
  | 'REQUESTED_SURRENDER_CAPACITY'
  | 'FDAUIOLI_CAPACITY'
  | 'BOOKED_CAPACITY';

export type UnderlyingDataResponse = {
  offset: number;
  limit: number;
  total: number;
} & Embedded<UnderlyingData, 'optional'>;

export type UnderlyingData = {
  intervalStart: string;
  intervalEnd: string;
  selected: boolean;
  amount: Amount;
};

export type ProductCalculation =
  | ProductCalculationFirmYear
  | ProductCalculationFirmQuarterOrMonth
  | ProductCalculationFirmShortTerm
  | ProductCalculationInterruptibleLongTerm
  | ProductCalculationInterruptibleDay;

export type ProductCalculationFirmYear = {
  type: 'FIRM_YEAR';
  technicalCapacity: Amount;
  camRuleCapacity: Amount;
  internallyBookedCapacity: Amount;
  bookedCapacityFromAllocations: Amount;
  bookedCapacity: Amount;
  convertedCapacity: Amount;
  requestedSurrenderCapacity: Amount;
  productCapacity: Amount;
};

export type ProductCalculationFirmQuarterOrMonth = {
  type: 'FIRM_QUARTER' | 'FIRM_MONTH';
  technicalCapacity: Amount;
  internallyBookedCapacity: Amount;
  bookedCapacityFromAllocations: Amount;
  bookedCapacity: Amount;
  convertedCapacity: Amount;
  requestedSurrenderCapacity: Amount;
  productCapacity: Amount;
};

export type ProductCalculationFirmShortTerm = {
  type: 'FIRM_DAY' | 'FIRM_WITHINDAY';
  technicalCapacity: Amount;
  availableTechnicalCapacity: Amount;
  internallyBookedCapacity: Amount;
  bookedCapacityFromAllocations: Amount;
  bookedCapacity: Amount;
  convertedCapacity: Amount;
  requestedSurrenderCapacity: Amount;
  fdaUioliCapacity?: Amount;
  productCapacity: Amount;
};

export type ProductCalculationInterruptibleLongTerm = {
  type: 'INTERRUPTIBLE_YEAR' | 'INTERRUPTIBLE_QUARTER' | 'INTERRUPTIBLE_MONTH';
  technicalCapacity: Amount;
  internallyBookedCapacity: Amount;
  convertedCapacity: Amount;
  bookedCapacityFromAllocations: Amount;
  bookedCapacity: Amount;
  productCapacity: Amount;
};

export type ProductCalculationInterruptibleDay = {
  type: 'INTERRUPTIBLE_DAY';
  technicalCapacity: Amount;
  availableTechnicalCapacity: Amount;
  nominatedCapacity?: Amount;
  productCapacity: Amount;
};

export type Amount = { value: number; flowUnit: FlowUnit };

export type DraftedProductsParams = {
  offset?: number;
  limit?: number;
  networkPointId?: string[];
  capacityCategoryId?: string[];
  productType?: PeriodTypeValue[];
  runtimeStartFrom?: string | null;
  runtimeStartTo?: string | null;
};

export type DraftedProductsResponse = {
  _links: Links<'self' | 'instance'>;
  total: number;
  limit: number;
  offset: number;
  // _embedded === undefined // no items
} & Embedded<DraftedProduct, 'optional'>;

export type MixedProductType = PeriodTypeValue | 'CUSTOM';

export type TransactionType =
  | 'AUCTION'
  | 'SURRENDER'
  | 'CONVERSION' // never bundled!
  | 'ASSIGNMENT_BUY'
  | 'TRANSFER_OF_USE_BUY'
  | 'ASSIGNMENT_SELL'
  | 'TRANSFER_OF_USE_SELL'
  | 'MULTI_YEAR_TRANSPORT_ALLOCATION' // TAG only
  | 'DIRECT_FCFS'
  | 'FCFS'; // since v3

export type TransactionStatus =
  | 'PENDING'
  | 'SUCCESSFUL'
  | 'UNSUCCESSFUL'
  | 'INVALIDATED';

export type TransactionsSortBy = 'CREATED_AT';

export type Transaction = {
  _links?: OptionalLinks<
    | 'self' // indicates whether the user has access to the details page
    | 'createTradeProposal' // _can_ be available for auctions, trades or multi year allocations, only for shippers
    | 'trade'
    | 'multiYearAllocation' // if multiyear and shipper
  >;
  createdAt: string;
  networkpointName: string;
  networkPointId?: string | null; // null or undefined until auction events are fully available
  productType?: MixedProductType | null; // null or undefined until auction events are fully available
  runtimeStart: string;
  runtimeEnd: string;
  transactionTypeSpecificId: string;
  status: TransactionStatus;
  surcharge?: MonetaryValue;
  price?: MonetaryValue; // only for secondary trades
  amount: number;
  flowUnit: FlowUnit;
  dealId?: string; // only for type === 'AUCTION'
  tradeId?: string; // only for type === 'ASSIGNMENT_BUY' | 'TRANSFER_OF_USE_BUY' | 'ASSIGNMENT_SELL' | 'TRANSFER_OF_USE_SELL'
  conversionRequestId?: string; // only for type === 'CONVERSION'
  surrenderRequestId?: string; // only for type === 'SURRENDER'
  surrenderId?: string; // only for type === 'SURRENDER' (if successful)
  fcfsBookingProcessId?: string; // only for type === 'FCFS'; **NOTE** null for legacy bookings
  transportFcfsAllocationId?: string; // only for type === 'FCFS', only considered if the fcfsBookingProcessId is null
  directFcfsRequestId?: string; // only for type === 'DIRECT_FCFS'
  transactionType: TransactionType;
  shipperName: string;
  shipperId: string;
} & (
  | { exit: TransactionSide; entry?: undefined; direction: 'EXIT' }
  | { exit?: undefined; entry: TransactionSide; direction: 'ENTRY' }
  | {
      exit: TransactionSide;
      entry: TransactionSide;
      direction: 'BUNDLE';
    }
);

type TransactionSide = {
  _links?: OptionalLinks<
    'balancingGroupAllocations' | 'createConversionRequest'
  >;
  tsoShortName: string;
  tsoId: string;
  capacityCategory: CapacityCategory;
  regulatedTariff?: MonetaryValue;
  marketAreaName: string;
  marketAreaId: string;
  surcharge?: MonetaryValue;
  auctionAllocationId?: string;
  multiYearTransportAllocationId?: string; // only for multi year
};

export type TransactionsParams = {
  limit?: number;
  offset?: number;
  createdAtStart?: string | null;
  createdAtEnd?: string | null;
  tsoId?: string[];
  marketAreaId?: string[];
  networkPointId?: string[];
  direction?: DirectionType[];
  capacityCategoryId?: string[];
  productType?: MixedProductType[];
  runtimeIntersectionFilterStart?: string | null;
  runtimeIntersectionFilterEnd?: string | null;
  transactionType?: TransactionType[];
  transactionTypeSpecificId?: string[];
  status?: TransactionStatus[];
  regulatedTariffMin?: number | null;
  regulatedTariffMax?: number | null;
  surchargeMin?: number | null;
  surchargeMax?: number | null;
  onlyWithSurcharge?: boolean;
  amountMin?: number | null;
  amountMax?: number | null;
  sortDirection?: SortDirection;
  sortBy?: TransactionsSortBy;
};

export type TransactionsResponse = {
  _links: Links<'self' | 'first'> &
    OptionalLinks<
      'next' | 'prev' | 'myTsoTransactionsSheet' | 'myTransactionsSheet'
    >;
  total: number;
  limit: number;
  offset: number;
} & Embedded<Transaction, 'optional'>;

export type DealResponse = {
  _links: Links<'self'>;
  dealId: string;
} & (
  | { exit: TransportAuctionAllocationResponse; entry?: undefined }
  | { exit?: undefined; entry: TransportAuctionAllocationResponse }
  | {
      exit: TransportAuctionAllocationResponse;
      entry: TransportAuctionAllocationResponse;
    }
);

export type ReportingBalancingGroupAllocationsSortBy =
  | 'CREATION_DATE'
  | 'BALANCING_GROUP'
  | 'SHIPPER_NAME';

export type ReportingBalancingGroupAllocationsParams = {
  limit?: number;
  offset?: number;
  creationDateStart?: string | null;
  creationDateEnd?: string | null;
  balancingGroupId?: string[];
  networkPointId?: string[];
  allocationIntervalIntersectionFilterStart?: string | null;
  allocationIntervalIntersectionFilterEnd?: string | null;
  sortDirection?: SortDirection;
  sortBy?: ReportingBalancingGroupAllocationsSortBy;
};

export type ReportingBalancingGroupAllocationsResponse = {
  _links: Links<'self' | 'first'> &
    OptionalLinks<'balancingGroupAllocationsSheet' | 'next' | 'prev'>;
  total: number;
  limit: number;
  offset: number;
} & Embedded<ReportingBalancingGroupAllocation, 'optional'>;

export type ReportingBalancingGroupAllocation = {
  id: string;
  createdAt: string;
  networkPointName: string;
  networkPointId: string;
  direction: FlowDirection;
  marketAreaName: string;
  shipperName: string;
  shipperId: string;
  balancingGroup: BalancingGroup;
  allocationStart: string;
  allocationEnd: string;
  capacityCategory: CapacityCategory;
  productType: MixedProductType;
  amount: number;
  flowUnit: FlowUnit;
  transactionTypeSpecificId: string;
  transactionTypeSpecificUuid: string;
  transactionSourceSpecificId?: string;
  transactionType: TransactionType;
};

export type TransportAuctionAllocationResponse = {
  _links: Links<'self'> &
    OptionalLinks<
      'balancingGroupAllocations' | 'capacityGraph' | 'createConversionRequest'
    >;
  id: string;
  dealId: string;
  auctionId: string;
  assignedContractId?: string;
  assignedAuctionId: string;
  networkpointName: string;
  networkPointId: string;
  direction: FlowDirection;
  capacityCategory: CapacityCategory;
  tsoShortName: string;
  tsoId: string;
  // in case of bundles the following props are the same for each direction:
  runtimeStart: string;
  runtimeEnd: string;
  amount: number;
  flowUnit: FlowUnit;
};

export type MultiYearTransportAllocationResponse = {
  _links: Links<'self'> &
    OptionalLinks<'balancingGroupAllocations' | 'capacityGraph'>;
  id: string;
  dealId: string;
  assignedContractId?: string; // should be set everywhere on prod, before that only after cleanup
  networkPointName: string;
  networkPointId: string; // needed for fetching nwp info on multi year tx detail page
  direction: FlowDirection;
  capacityCategory: CapacityCategory;
  tsoShortName: string;
  tsoId: string;
  shipperName: string;
  shipperId: string;
  runtimeStart: string;
  runtimeEnd: string;
  amounts: MultiYearAmount[];
  flowUnit: FlowUnit;
};

export type TradeResponse = {
  _links: Links<'self'>;
  id: string;
  assignedTradeId: string;
  flowDirection: DirectionType;
  runtimeStart: string;
  runtimeEnd: string;
  amount: number;
  flowUnit: FlowUnit;
} & (
  | { exit: TradeDetailsResponse; entry?: undefined }
  | { exit?: undefined; entry: TradeDetailsResponse }
  | {
      exit: TradeDetailsResponse;
      entry: TradeDetailsResponse;
    }
);

export type TradeDetailsResponse = {
  _links?: OptionalLinks<'balancingGroupAllocations' | 'capacityGraph'>;
  capacityCategory: CapacityCategory;
  tsoShortName: string;
  tsoId: string;
  networkpointName: string;
  networkPointId: string;
};

export type BalancingGroup = {
  id: string;
  name: string;
};

export type BalancingGroupsParams = {
  nwpId: string;
  capacityCategoryId: string;
};

export type BalancingGroupsResponse = {
  balancingGroups: BalancingGroup[];
};

export type BalancingGroupsDownloadResponse = {
  _links: Links<'self'> & OptionalLinks<'download'>;
  balancingGroups: BalancingGroup[];
};

export type BalancingGroupAllocationsResponse = {
  _links: Links<'self'> & OptionalLinks<'create'>;
} & Embedded<BalancingGroupAllocationResponse, 'optional'>;

export type BalancingGroupAllocationResponse = {
  _links?: //  Links<'self'> &
  OptionalLinks<
    | 'changeRuntimeEnd' // available if runtime has started, but not ended
    | 'update' // available if runtime has not started
    | 'delete' // available if runtime has not started
  >;
  id: string;
  runtimeStart: string;
  runtimeEnd: string;
  amount: number;
  flowUnit: FlowUnit;
  balancingGroup: BalancingGroup;
};

export type BalancingGroupAllocationWrite = {
  runtimeStart: string;
  runtimeEnd: string;
  amount: number;
  balancingGroupId: string;
};

export type BalancingGroupAllocationPatch = {
  runtimeEnd: string;
};

type CapacityGraphSlice = {
  date: string;
  allocatedAmount: number;
  freeAmount: number;
};

export type CapacityGraphResponse = {
  _links: Links<'self'>;
  slices: CapacityGraphSlice[];
  flowUnit: FlowUnit;
  runtimeStart: string;
  runtimeEnd: string;
};

export type ShipperTransactionsParams = {
  limit?: number;
  offset?: number;
  createdAtStart?: string | null;
  createdAtEnd?: string | null;
  shipperId?: string[];
  marketAreaId?: string[];
  networkPointId?: string[];
  direction?: DirectionType[];
  capacityCategoryId?: string[];
  productType?: MixedProductType[];
  runtimeIntersectionFilterStart?: string | null;
  runtimeIntersectionFilterEnd?: string | null;
  transactionType?: TransactionType[];
  transactionTypeSpecificId?: string[];
  status?: TransactionStatus[];
  regulatedTariffMin?: number | null;
  regulatedTariffMax?: number | null;
  surchargeMin?: number | null;
  surchargeMax?: number | null;
  onlyWithSurcharge?: boolean;
  amountMin?: number | null;
  amountMax?: number | null;
  sortDirection?: SortDirection;
  sortBy?: TransactionsSortBy;
};

export type Shipper = {
  id: string;
  eic: string;
  companyName: string;
};

export type Shippers = {
  total: number;
} & Embedded<Shipper>;

export type Tso = {
  tsoId: string;
  name: string;
  tsoShortName: string;
};

export type Tsos = {
  total: number;
} & Embedded<Tso>;

type MyPortfolioAmount = {
  capacityCategory: CapacityCategory;
  value: number;
};

type MyPortfolioGraphSlice = {
  date: string;
  bookedAmounts: MyPortfolioAmount[];
  maintenance: boolean;
};

export type MyPortfolioGraphResponse = {
  flowUnit: FlowUnit;
  runtimeIntersectionStart: string;
  runtimeIntersectionEnd: string;
  slices: MyPortfolioGraphSlice[];
};

export type Granularity = 'HOURLY' | 'DAILY';

export type MyPortfolioParams = {
  nwpId: string;
  runtimeIntersectionFilterStart: string;
  runtimeIntersectionFilterEnd: string;
  granularity: Granularity;
};

export type ConversionRequestResponse = {
  _links?: OptionalLinks<'reject' | 'approve'>;
  id: string;
  assignedId: string;
  amount: number;
  runtimeStart: string;
  runtimeEnd: string;
  bundledAllocation: TransportAuctionAllocationResponse;
  unbundledAllocation?: TransportAuctionAllocationResponse;
  unbundledMultiYearAllocation?: MultiYearTransportAllocationResponse;
} & (
  | {
      status: 'SUCCESSFUL';
      approvalDate: string;
      rejectionDate?: undefined;
      expirationDate?: undefined;
    }
  | {
      status: 'REJECTED_BY_TSO' | 'AUTOMATICALLY_REJECTED';
      rejectionDate: string;
      approvalDate?: undefined;
      expirationDate?: undefined;
    }
  | {
      status: 'PENDING';
      expirationDate: string;
      approvalDate?: undefined;
      rejectionDate?: undefined;
    }
);

export type ConversionRequestStatus = ConversionRequestResponse['status'];

export type ConversionRequestWrite = {
  bundledAllocationId: string;
  unbundledDealId: string;
  amount: number;
  runtimeStart: string;
  runtimeEnd: string;
};

export type NwpCapacityGraphParams = {
  nwpId: string;
  runtimeIntersectionFilterStart: string;
  runtimeIntersectionFilterEnd: string;
  granularity: Granularity;
};

export type NwpCapacityGraphResponse = {
  _links?: OptionalLinks<'capacitySheet'>;
  flowUnit: FlowUnit;
  runtimeIntersectionStart: string;
  runtimeIntersectionEnd: string;
  slices: NwpCapacityGraphSlice[];
};

export type NwpCapacityGraphSlice = {
  date: string;
  amounts: NwpCapacityGraphAmount[];
};

export type NwpCapacityGraphAmount = {
  value: number;
} & (
  | {
      type: 'MARKETED_CAPACITY' | 'INTERNAL_BOOKING';
      capacityCategory: CapacityCategory;
    }
  | {
      type:
        | 'TECHNICAL_CAPACITY_FIRM'
        | 'TECHNICAL_CAPACITY_INTERRUPTIBLE'
        | 'AVAILABLE_TECHNICAL_CAPACITY_PLANNED'
        | 'AVAILABLE_TECHNICAL_CAPACITY_UNPLANNED';
      capacityCategory?: undefined;
    }
);

// TSOs without capacity management can only see 'MARKETED_CAPACITY'
export type NwpCapacityGraphAmountType = NwpCapacityGraphAmount['type'];

export type DownloadInformationResponse = {
  _links: Links<'self' | 'download'>;
  fileName: string;
};

// if the download is not ready, poll it until it's available
export type DownloadJobResultResponse = {
  _links: Links<'self'> & OptionalLinks<'download'>;
  fileName?: string; // only available, if download is available
};

export type ProductComparisonGraphResponse = Embedded<ProductComparisonGraph>;

export type ProductComparisonGraph = {
  runtimeStart: string;
  runtimeEnd: string;
  auctionStart: string;
  currentProduct: boolean;
  productCapacity: Amount;
  marketedCapacity?: Amount;
};

export type UploadedProductResponse = {
  _links: Links<'self'> &
    OptionalLinks<
      | 'technicalCapacityUnderlyingData'
      | 'availableTechnicalCapacityUnderlyingData'
      | 'nominatedCapacityUnderlyingData'
      | 'requestedSurrenderCapacityUnderlyingData'
      | 'fdaUioliCapacityUnderlyingData'
      | 'bookedCapacityUnderlyingData'
      | 'uploadedProductUnderlyingDataSheet'
      | 'productComparisonGraph'
    >;
  id?: string; // id is nullable on backend and only there if it is a CaMa Product
  offerId: string;
  calculation?: ProductCalculation;
};

export type AffiliateTransactionsParams = {
  limit?: number;
  offset?: number;
} & (
  | { assignedAuctionId: string; assignedTradeId?: undefined }
  | { assignedAuctionId?: undefined; assignedTradeId: string }
);

// eslint-disable-next-line no-restricted-syntax
export enum MonthlyStatementStatus {
  DRAFT = 'DRAFT',
  APPROVED = 'APPROVED',
}

export type MonthlyStatementType = 'CAPACITY_FEE' | 'COMMODITY_FEE';

export type MonthlyStatement = {
  _links: Links<'self' | 'monthlyStatementOverviewSheetAsync'> &
    OptionalLinks<
      | 'approvalPrerequisites'
      | 'update'
      | 'invoiceDocumentsV2'
      | 'updateCommodityStatement'
    >;
  id: string;
  assignedId: string;
  status: MonthlyStatementStatus;
  servicePeriodStart: string;
  servicePeriodEnd: string;
  shipperCount: number;
  contractCount: number;
  itemCount: number;
  invoiceCreated: boolean;
  approvalTimestamp?: string;
  // undefined can only happen on edge cases
  // (e.g. price is 0 or the currency is missing or more than one currency is found)
  monetaryValue?: MonetaryValue;
  feeCalculationType?: FeeCalculationType; // undefined as long as it's not deployed everywhere
  monthlyStatementType?: MonthlyStatementType; // undefined as long as it's not deployed everywhere
};

export type ApprovalPrerequisites = {
  _links: Links<'self'> & OptionalLinks<'approve'>;
  itemCount?: number;
  checkedVersion?: number;
  checkedDate?: string;
  thresholdInformation?: {
    skippedItemsCount?: number; // If item count is equal to skippedItemsCount, all invoices are below the threshold // TODO: This field is optional as long as CARMA-408 isn't fully merged yet. After that, it should be required
    skippedContractsByShippers: Embedded<ShipperContracts>; // If array is empty, all invoices are above the threshold
    thresholdValue: MonetaryValue;
  };
};

type ContractsByShipper = {
  _links: Links<'self'>;
} & Embedded<ShipperContracts>;

export type MonthlyStatementDetails = MonthlyStatement & {
  contractsByShippers: ContractsByShipper;
};

export type FeeCalculationType = 'HOUR_BASED' | 'MONTH_BASED';

export type WriteMonthlyStatement = {
  servicePeriodStart: string;
  servicePeriodEnd: string;
  contractIds: string[];
  feeCalculationType: FeeCalculationType;
};

export type WriteCommodityStatement = {
  servicePeriodStart: string;
  servicePeriodEnd: string;
  shipperIds: string[];
};

export type MonthlyStatementsSortBy =
  | 'SERVICE_PERIOD_START'
  | 'MONTHLY_STATEMENT_ID'
  | 'SHIPPER_COUNT'
  | 'CONTRACT_COUNT'
  | 'ITEM_COUNT'
  | 'MONETARY_VALUE'
  | 'STATUS';

export const monthlyStatementsValues = [
  'SERVICE_PERIOD_START',
  'MONTHLY_STATEMENT_ID',
  'SHIPPER_COUNT',
  'CONTRACT_COUNT',
  'ITEM_COUNT',
  'MONETARY_VALUE',
  'STATUS',
] as const satisfies readonly MonthlyStatementsSortBy[];

export type MonthlyStatementsParams = {
  servicePeriodStart: string | null;
  servicePeriodEnd: string | null;
  status?: MonthlyStatementStatus[];
  sortBy?: MonthlyStatementsSortBy;
  sortDirection?: SortDirection;
  limit?: number;
  offset?: number;
  onlyWithInvoiceCreated?: boolean;
  monthlyStatementType?: MonthlyStatementType[];
  monthlyStatementId: string | null;
};

export type ShipperContractsParams = {
  servicePeriodStart: string;
  servicePeriodEnd: string;
};

export type ShipperContractsResponse = {
  _links: Links<'self'>;
  limit: number;
  offset: number;
  total: number;
} & Embedded<ShipperContracts, 'optional'>;

export type ShipperContracts = {
  shipperName: string;
  shipperId: string;
  contracts: Contract[];
};

export type Contract = {
  id: string;
  assignedId: string;
};

export type MonthlyStatementsResponse = {
  _links: Links<'self' | 'instance'> &
    OptionalLinks<'create' | 'createCommodityStatement'>;
  limit: number;
  offset: number;
  total: number;
} & Embedded<MonthlyStatement, 'optional'>;

export type AssociatedMonthlyStatement = {
  id: string;
  assignedId: string;
};

export type CorrectionType =
  | 'REGULATED_TARIFF'
  | 'SURCHARGE'
  | 'REGULATED_TARIFF_REDUCTION'
  | 'SURCHARGE_REDUCTION';

export type CorrectionTypeWithCommodityFee =
  | 'COMMODITY_FEE'
  | 'COMMODITY_FEE_REDUCTION'
  | CorrectionType;

export type Correction = {
  _links?: OptionalLinks<'delete'>;
  id: string;
  associatedMonthlyStatements: AssociatedMonthlyStatement[];
  type: CorrectionTypeWithCommodityFee;
  creationDate: string;
  billingPeriodStart: string;
  billingPeriodEnd: string;
  shipperName: string;
  shipperId: string;
  assignedContractId: string;
  fee: MonetaryValue;
};

export type CorrectionsSortBy = 'CREATION_DATE' | 'BILLING_PERIOD_START';

export type CorrectionsParams = {
  creationDateStart: string | null;
  creationDateEnd: string | null;
  billingPeriodStart: string | null;
  billingPeriodEnd: string | null;
  type?: CorrectionType[];
  sortBy?: CorrectionsSortBy;
  sortDirection?: SortDirection;
  limit?: number;
  offset?: number;
  shipperId?: string[];
  contractId?: string[];
};

export type CorrectionsResponse = {
  _links: Links<'self' | 'shipperFilter' | 'contractFilter'> &
    OptionalLinks<'uploadWithCommodityFeeSupport'>;
  limit: number;
  offset: number;
  total: number;
} & Embedded<Correction, 'optional'>;

export type ContractFilterForTso = {
  id: string;
  assignedId: string;
  shipperName: string;
  shipperId: string;
};

export type ContractsFilterForTso = {
  total: number;
} & Embedded<ContractFilterForTso>;

export type MonthlyStatementFilterForTso = {
  id: string;
  assignedId: string;
};

export type MonthlyStatementsFilterForTso = {
  total: number;
} & Embedded<MonthlyStatementFilterForTso>;

export type ContractFilterForShipper = {
  id: string;
  assignedId: string;
  tsoShortName: string;
  tsoId: string;
};

export type ContractsFilterForShipper = {
  total: number;
} & Embedded<ContractFilterForShipper>;

export type InvoicesForTsoResponse = {
  _links: Links<
    | 'self'
    | 'instance'
    | 'contractFilter'
    | 'shipperFilter'
    | 'monthlyStatementFilter'
  > &
    OptionalLinks<'publishInvoices'>;
  total: number;
  limit: number;
  offset: number;
} & Embedded<InvoiceSummary>;

export type InvoicesForShipperResponse = {
  _links: Links<'self' | 'instance' | 'contractFilter' | 'tsoFilter'>;
  total: number;
  limit: number;
  offset: number;
} & Embedded<InvoiceSummary>;

export type ManualAttachmentStatus = 'UPLOADED' | 'WITHDRAWN';

export type ManualAttachment = {
  version: number;
  status: ManualAttachmentStatus;
  // this field will always be set in the upcoming release
  downloadedByShipper?: boolean;
};

export type DunningDocument = {
  _links: Links<'self'>;
  level: DunningLevel;
  downloadedByShipper: boolean;
  publicationDate: string;
};

// links which are optional for all invoice types
type SharedOptionalInvoiceTypeLinks =
  | 'dunningDocument' // only exists for API V1. V2 has no dunningDocument link and dunningDocument is an array
  | 'manualAttachment'
  | 'uploadManualAttachment'
  | 'withdrawManualAttachment'
  | 'confirmManualAttachmentUpload'
  | 'cancelInvoice'
  | 'invoiceCancellationDocument'
  | 'invoiceDocument'
  | 'monthlyStatement';

export type DocumentInfo = { downloadedByShipper: boolean };

export type CommonInvoiceFields = {
  id: string;
  invoiceNumber: string;
  creationDate: string;
  isOverdue: boolean;
  invoice: DocumentInfo;
  isPublished: boolean;
  invoiceValue: MonetaryValue;
  status: InvoicesStatus;
  shipperId: string;
  shipperName: string;
  contractId: string;
  assignedContractId: string;
  tsoId: string;
  tsoShortName: string;
  tsoName: string;
  invoiceCancellation?: DocumentInfo;
  underlyingMonthlyStatementType: MonthlyStatementType;
  monthlyStatementAssignedId: string;
  monthlyStatementId: string;
} & (
  | {
      type: InvoiceType.CREDIT_NOTE | InvoiceType.DEBIT_NOTE;
      dueDate?: string;
    }
  | {
      type: InvoiceType.INVOICE | InvoiceType.ARREARS;
      dueDate: string;
    }
) &
  (
    | {
        type: InvoiceType.ARREARS;
        _links?: Links<'affiliateInvoices'> &
          OptionalLinks<SharedOptionalInvoiceTypeLinks> &
          NeverLinks<
            | 'invoicingSourceDataDocument'
            | 'positionsDocument'
            | 'publishInvoice'
          >;
      }
    | {
        type:
          | InvoiceType.INVOICE
          | InvoiceType.CREDIT_NOTE
          | InvoiceType.DEBIT_NOTE;
        _links?: OptionalLinks<
          | SharedOptionalInvoiceTypeLinks
          | 'publishInvoice'
          // 'invoicingSourceDataDocument' and 'positionsDocument' will be created within milliseconds after we receive an invoice,
          // but they are not available immediately (and creation could theoretically fail):
          | 'invoicingSourceDataDocument'
          | 'positionsDocument'
        > &
          NeverLinks<'affiliateInvoices'>; // only for arrears
      }
  );

export type DunningLevel = 1 | 2 | 3;

export type InvoiceDetailShipper = CommonInvoiceFields & {
  dunningDocument?: DunningDocument | DunningDocument[]; // for V2, all dunning documents are shown. For version 2 dunnings show an empty array.
  manualAttachment?: ManualAttachment;
  generatedAttachmentPdf?: DocumentInfo; // can technically be null, is however necessary for invoice publishing and therefore practically always there for shippers
  generatedAttachmentXlsx?: DocumentInfo; // can technically be null, is however necessary for invoice publishing and therefore practically always there for shippers
};

export type InvoiceDetailTso = CommonInvoiceFields &
  InvoiceDetailShipper & {
    pendingCancellationRequest: boolean;
  };

// on overview
export type InvoiceSummary = CommonInvoiceFields & {
  dunningLevel?: DunningLevel;
};

// eslint-disable-next-line no-restricted-syntax
export enum InvoicesStatus {
  OPEN = 'OPEN',
  CANCELLED = 'CANCELLED',
  PAID = 'PAID',
  WITHDRAWN = 'WITHDRAWN',
}

// eslint-disable-next-line no-restricted-syntax
export enum PublishStatus {
  SHOW_ALL = 'SHOW_ALL',
  ON_HOLD = 'ON_HOLD',
  PUBLISHED = 'PUBLISHED',
}

// eslint-disable-next-line no-restricted-syntax
export enum InvoiceType {
  INVOICE = 'INVOICE',
  DEBIT_NOTE = 'DEBIT_NOTE',
  CREDIT_NOTE = 'CREDIT_NOTE',
  ARREARS = 'ARREARS',
}

export type InvoicesSortBy =
  | 'CREATION_DATE'
  | 'INVOICE_NUMBER'
  | 'DUE_DATE'
  | 'AMOUNT'
  | 'STATUS';

export type TsoInvoicesSortBy = InvoicesSortBy | 'TSO_NAME';
export type ShipperInvoicesSortBy =
  | InvoicesSortBy
  | 'SHIPPER_NAME'
  | 'MONTHLY_STATEMENT_ID';

export type InvoicesParamsForTso = {
  invoiceNumber?: string | null;
  dueDateStart?: string | null;
  dueDateEnd?: string | null;
  creationDateStart?: string | null;
  creationDateEnd?: string | null;
  status?: InvoicesStatus[];
  monthlyStatementType?: MonthlyStatementType[];
  invoiceType?: InvoiceType[];
  sortBy?: ShipperInvoicesSortBy;
  sortDirection?: SortDirection;
  publishStatus?: PublishStatus | null;
  limit?: number;
  offset?: number;
  shipperId?: string[];
  contractId?: string[];
  monthlyStatementId?: string[];
  onlyOverdue?: boolean;
};

export type InvoicesParamsForShipper = {
  invoiceNumber?: string | null;
  dueDateStart?: string | null;
  dueDateEnd?: string | null;
  creationDateStart?: string | null;
  creationDateEnd?: string | null;
  status?: InvoicesStatus[];
  monthlyStatementType?: MonthlyStatementType[];
  invoiceType?: InvoiceType[];
  sortBy?: TsoInvoicesSortBy;
  sortDirection?: SortDirection;
  limit?: number;
  offset?: number;
  contractId?: string[];
  tsoId?: string[];
  onlyOverdue?: boolean;
};

export type ConfirmUpload = { id: string; fileName: string };

export type DownloadJob = {
  _links: Links<'downloadInformation'>;
  jobId: string;
};

export type AffiliateInvoices = {
  _links: Links<'self'>;
  items: AffiliateInvoice[];
};

export type AffiliateInvoice = {
  _links: Links<'affiliateInvoice' | 'underlyingInvoiceDetail'>;
  affiliateInvoiceId: string;
  affiliateInvoiceNumber: string;
  underlyingInvoiceId: string;
  underlyingInvoiceNumber: string;
  underlyingMonthlyStatementType: MonthlyStatementType;
};

export type ShipperResponse = {
  id: string;
  name: string;
  eic: string;
  nominationAllowed: boolean;
  revokeCapacityRightsAt: string | null;
  _links: Links<'self'> &
    OptionalLinks<'revokeCapacityRights'> &
    (
      | (OptionalLinks<'suspendNomination'> & NeverLinks<'resumeNomination'>)
      | (OptionalLinks<'resumeNomination'> & NeverLinks<'suspendNomination'>)
    );
};

export type OperatorResponse = {
  _links: Links<'self'>;
  id: string;
  name: string;
  shortName: string;
  allowsNomination: boolean;
};

export type RevokeCapacityRightsWrite = {
  revokeCapacityRightsAt: string;
};

export type CurtailmentsResponse = {
  _links: Links<'self' | 'shipperFilter' | 'first'> &
    OptionalLinks<'next' | 'prev' | 'curtailmentsSheet'>;
  limit: number;
  offset: number;
  total: number;
} & Embedded<Curtailment, 'optional'>;

export type NetworkPoint = {
  id: string;
  name: string;
  direction: FlowDirection;
};

export type Curtailment = {
  id: string;
  associatedMonthlyStatements: AssociatedMonthlyStatement[];
  creationDate: string;
  curtailmentPeriodStart: string;
  curtailmentPeriodEnd: string;
  shipperName: string;
  shipperId: string;
  balancingGroup: {
    id: string;
    name: string;
  };
  networkPoint: NetworkPoint;
  monetaryValue?: MonetaryValue; // optional in case a curtailed period is not associated to any ms
};

export type ShipperFilter = {
  links: Links<'self'>;
  total: number;
} & Embedded<Shipper>;

export type CurtailmentsSortBy = 'CURTAILMENT_PERIOD_START';

export type CurtailmentsParams = {
  curtailmentPeriodStart: string | null;
  curtailmentPeriodEnd: string | null;
  shipperId: string[];
  sortBy: CurtailmentsSortBy;
  sortDirection?: SortDirection;
  limit?: number;
  offset?: number;
};

export type MultiYearAmount = {
  value: number;
  runtimeStart: string;
  runtimeEnd: string;
};

export type MyMultiYearTransactionsParams = {
  limit?: number;
  offset?: number;
  networkPointId?: string[];
  direction?: DirectionType[];
  capacityCategoryId?: string[];
  runtimeIntersectionFilterStart?: string | null;
  runtimeIntersectionFilterEnd?: string | null;
  transactionId?: string | null;
  sortBy?: 'RUNTIME_START';
  sortDirection?: SortDirection;
};

export type MultiYearTransactionsParams = MyMultiYearTransactionsParams & {
  shipperId?: string[];
};

export type MultiYearTransportAllocation = {};

export type InvoicesThresholdData = {
  invoicingThreshold: MonetaryValue;
};
