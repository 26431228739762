import type { FC, ReactNode } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import { useMeasure } from 'src/hooks/use-measure';

type ContextValue = {
  height: number;
  setHeight: (height: number) => void;
};

const context = createContext<ContextValue>({ height: 0, setHeight: () => {} });

export const useHeaderHeight = () => useContext(context);

export const useHeaderHeightMeasure = () => {
  const { setHeight } = useHeaderHeight();
  const [ref, bounds] = useMeasure();

  useEffect(() => {
    setHeight(bounds.height);
  }, [setHeight, bounds.height]);

  return ref;
};

export const HeaderHeightProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [height, setHeight] = useState(0);

  return (
    <context.Provider value={{ height, setHeight }}>
      {children}
    </context.Provider>
  );
};
