import type { FC } from 'react';
import { lazy } from 'react';
import { useParams } from 'react-router-dom';
import { useAuthorizationService } from 'src/apis/authorization-service/use-authorization-service';
import { usePermissions } from 'src/apis/authorization-service/use-permissions';
import { useUserPermissionGroups } from 'src/apis/authorization-service/use-user-authorization-data';
import type { OrganisationServiceRequest } from 'src/apis/organisation-service/use-organisation-service';
import { PageSpinner } from 'src/components/spinner-container';
import type { Successful } from 'src/hooks/use-axios';
import { useUserDetails } from 'src/pages/settings/organisation/users/details/use-user-details';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(
  () => import('src/pages/settings/organisation/users/details/page')
);

type Props = {
  organisationService: Successful<OrganisationServiceRequest>;
};

export const UserDetails: FC<Props> = ({ organisationService }) => {
  if (!organisationService.response.data._links.getUserDetails)
    throw new Forbidden();

  const { userId } = useParams<{ userId: string }>();
  const userDetails = useUserDetails({
    url: organisationService.response.data._links.getUserDetails.href,
    userId,
  });

  const authorizationService = useAuthorizationService();

  const authorizationData = useUserPermissionGroups({
    authorizationService,
    userId,
  });
  const permissions = usePermissions({ authorizationService });

  if (
    !userDetails.response ||
    !authorizationService.response ||
    !authorizationData?.response ||
    !permissions?.response
  )
    return <PageSpinner />;

  return (
    <Page
      userDetails={userDetails}
      organisationService={organisationService}
      permissions={permissions}
      authorizationData={authorizationData}
    />
  );
};
