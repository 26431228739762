import { useEffect } from 'react';
import urlTemplate from 'url-template';
import type {
  RelationsParams,
  RelationsResponse,
} from 'src/apis/contract-management/types';
import { useAxios } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';

type RequestOptions = {
  url?: string;
  params?: RelationsParams;
};

export function useOperatorRelations({ url, params }: RequestOptions) {
  const relations = useAxios(
    (axios, baseConfig, { url, params }: RequestOptions) => {
      if (!url) throw new Forbidden();

      return axios.request<RelationsResponse>({
        ...baseConfig,
        url: urlTemplate.parse(url).expand({}),
        params,
      });
    },
    {
      neededOnPageLoad: true,
      onError(error) {
        if (error instanceof Forbidden) {
          return error;
        } else {
          throw error;
        }
      },
    }
  );
  useEffect(() => {
    relations.execute({ url, params });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, params]);

  return relations;
}

export type RelationsRequest = ReturnType<typeof useOperatorRelations>;
