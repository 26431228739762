import type { FC } from 'react';
import { lazy } from 'react';
import { useMemoOne } from 'use-memo-one';
import type { CapacityManagementRootRequest } from 'src/apis/capacity-management/use-capacity-management-root';
import type { ProductsParams } from 'src/apis/monolith/types';
import { useProducts } from 'src/apis/monolith/use-products';
import { useUploadedProductsOverviewParams } from 'src/pages/reporting/products/uploaded/overview/use-page-params';

const Page = lazy(() => import('./page'));

type Props = { capacityManagementRoot?: CapacityManagementRootRequest };

export const ReportingUploadsUploadedOverview: FC<Props> = ({
  capacityManagementRoot,
}) => {
  const pageParams = useUploadedProductsOverviewParams();

  const productsParams = useMemoOne(
    () =>
      ({
        ...pageParams.value,
        sortAscending: pageParams.value.sortAscending === 'asc',
        networkPointName: pageParams.value.networkPointName.map(
          (item) => item.value
        ),
      }) satisfies ProductsParams,
    [pageParams.value]
  );

  const uploads = useProducts({ params: productsParams });

  return (
    <Page
      pageParams={pageParams}
      productsParams={productsParams}
      uploads={uploads}
      capacityManagementRoot={capacityManagementRoot}
    />
  );
};
