import { useFormikContext } from 'formik';
import type { FC } from 'react';
import { useEffect } from 'react';
import { z } from 'zod';
import type { ColumnFilterResponse } from 'src/apis/monolith/types';
import { EmptyValue } from 'src/components/data-display/empty-value';
import { Alert } from 'src/components/feedback/alert';
import type {
  FormOption,
  BaseFormOption,
} from 'src/components/form/form-option';
import type { SearchableMultiSelectProps } from 'src/components/form/select/searchable-multi-select';
import {
  SearchableMultiSelect,
  SearchableMultiSelectContent,
} from 'src/components/form/select/searchable-multi-select';
import { formOptionSchema } from 'src/components/form/zod-schemas';
import {
  preprocessAsArray,
  ZOD_EXPLICIT_EMPTY,
} from 'src/components/form/zod-utilities';
import { useAxios } from 'src/hooks/use-axios';
import { useSearch } from 'src/hooks/use-search';
import type { StrictOmit } from 'src/utils/utility-types';

type AuctionReportFilterTableProps = {
  filterTable: 'auctionReport';
  filterColumn: 'tso' | 'networkPointName';
};

type StorageOffersReportFilterTableProps = {
  filterTable: 'storageOffers';
  filterColumn: 'offerLabel' | 'storageLocationName' | 'tsoShortName';
};

type SurrenderReportFilterTableProps = {
  filterTable: 'surrenderReport';
  filterColumn: 'networkPointName';
};

type TradeProposalReportFilterTableProps = {
  filterTable: 'secondaryProposalReport';
  filterColumn: 'tso' | 'networkPointName';
};

type TradeReportFilterTableProps = {
  filterTable: 'secondaryTradeReport';
  filterColumn: 'tso' | 'networkPointName';
};

type UploadReportFilterTableProps = {
  filterTable: 'uploadReport';
  filterColumn: 'networkPointName';
};

type AllFilterTableProps =
  | AuctionReportFilterTableProps
  | StorageOffersReportFilterTableProps
  | SurrenderReportFilterTableProps
  | TradeProposalReportFilterTableProps
  | TradeReportFilterTableProps
  | UploadReportFilterTableProps;

type SearchableMultiSelectMonolithProps = AllFilterTableProps &
  StrictOmit<
    SearchableMultiSelectProps<string, FormOption<string>[]>,
    | 'name'
    | 'options'
    | 'searchValueForCurrentOptions'
    | 'searchValue'
    | 'setSearchValue'
    | 'pending'
  >;

/**
 * This a wrapper around our regular `SearchableMultiSelect` component that makes use
 * of a very specific endpoint in the monolith called `columnFilter`.
 */
export const SearchableMultiSelectMonolith: FC<
  SearchableMultiSelectMonolithProps
> = ({ filterColumn, filterTable, ...props }) => {
  const { values } = useFormikContext();

  const minLength = 3;

  const [searchValue, setSearchValue, searchQuery] = useSearch('', {
    minLength,
  });

  const search = useAxios(
    async (
      axios,
      baseConfig,
      { searchQuery, values }: { searchQuery: string; values: unknown }
    ) =>
      axios.request<ColumnFilterResponse>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/columnFilter`,
        params: {
          filterColumn,
          filterTable,
          infix: searchQuery,
          ...transformValues(values),
        },
      }),
    { neededOnPageLoad: false }
  );

  useEffect(() => {
    if (searchQuery) search.execute({ searchQuery, values });
    else search.setResponse(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  return (
    <SearchableMultiSelect
      {...props}
      name={filterColumn}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      options={search.response?.data.options}
      pending={search.pending}
      searchValueForCurrentOptions={search.response?.config.params.search}
      renderOptions={({ children }) => (
        <>
          {!search.pending && search.response?.data.truncated && (
            <Alert type="warning">
              There are more than 100 results. Please refine your search
              criteria.
            </Alert>
          )}

          {!search.response && !search.pending ? (
            <SearchableMultiSelectContent>
              <EmptyValue
                label={`Type at least ${minLength} characters to search...`}
              />
            </SearchableMultiSelectContent>
          ) : (
            children
          )}
        </>
      )}
    />
  );
};

/**
 * The monolith has several quirks and one of them is that it has _one_ search endpoint called `/columnFilter`
 * for multiple different use cases and that this endpoint needs - besides the actual search term -
 * other currently entered search values (e.g. for performance reasons).
 * We just appened all form values as params and transform some well known fields.
 *
 * This is far from ideal, but we slowly refactor everything.
 */
function transformValues(values: any) {
  const copiedValues = { ...values };
  if ('tso' in values) {
    copiedValues.tso = values.tso.map(
      (item: BaseFormOption<string>) => item.value
    );
  }
  if ('networkPointName' in values) {
    copiedValues.networkPointName = values.networkPointName.map(
      (item: BaseFormOption<string>) => item.value
    );
  }
  if ('offerLabel' in values) {
    copiedValues.offerLabel = values.offerLabel.map(
      (item: BaseFormOption<string>) => item.value
    );
  }
  if ('storageLocationName' in values) {
    copiedValues.storageLocationName = values.storageLocationName.map(
      (item: BaseFormOption<string>) => item.value
    );
  }
  if ('tsoShortName' in values) {
    copiedValues.tsoShortName = values.tsoShortName.map(
      (item: BaseFormOption<string>) => item.value
    );
  }
  return copiedValues;
}

/**
 * This is only needed for **old** pages to support old deep links
 * and bookmarks. Besides our regular handling of form options
 * this schema will also support the old single string format.
 *
 * For new pages - even if they use `<SearchableMultiSelectMonolith />` -
 * this is **not** needed.
 *
 * This can also be mixed (e.g. start with an existing filter and then
 * add more filters via the search).
 * Example: http://localhost:4200/reporting/auctions/short-and-long-term-auctions?tso%5B0%5D%5Blabel%5D=GTP&tso%5B0%5D%5Bvalue%5D=GTP&tso=CTP
 */
export const monolithFilterOptionsSchema = z.preprocess(
  preprocessAsArray,
  z
    .array(
      z.preprocess((value) => {
        if (value === ZOD_EXPLICIT_EMPTY) {
          return value;
        } else if (typeof value === 'string') {
          return { label: value, value };
        } else return value;
      }, formOptionSchema)
    )
    .default([])
);
