import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import {
  getOperatorContactDetailsSchema,
  getUserAssignmentsForOperatorSchema,
} from 'src/pages/operators/assignments/details/schema';

export function useAssignmentsAndContactsParams() {
  const userAssignmentsByOperator = usePageParamsFromSchema(
    getUserAssignmentsForOperatorSchema(),
    {
      groupPrefix: 'assignmentsByOperator',
      filters: {
        userName: { label: 'Name' },
        userEmail: { label: 'Email' },
        status: { label: 'Assignment Status' },
      },
    }
  );

  const contactDetails = usePageParamsFromSchema(
    getOperatorContactDetailsSchema(),
    {
      groupPrefix: 'contactDetails',
    }
  );

  return { userAssignmentsByOperator, contactDetails };
}

export type AssignmentsAndContactsParams = ReturnType<
  typeof useAssignmentsAndContactsParams
>;
