import type { FC } from 'react';
import styled from 'styled-components';
import { PillButton } from 'src/components/form/datepicker/pill-button';

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const MonthSelectorContainer = styled.div`
  background: #fff;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
`;

type MonthSelectorProps = {
  month: number;
  onSelect: (month: number) => void;
};

export const MonthSelector: FC<MonthSelectorProps> = (props) => {
  const { month: selectedMonth, onSelect } = props;

  return (
    <MonthSelectorContainer>
      {months.map((month, index) => (
        <div key={month}>
          <PillButton
            active={index === selectedMonth}
            onClick={() => onSelect(index)}
          >
            {month}
          </PillButton>
        </div>
      ))}
    </MonthSelectorContainer>
  );
};
