import type { FC } from 'react';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { useCapacityManagementRoot } from 'src/apis/capacity-management/use-capacity-management-root';
import { useDeal } from 'src/apis/capacity-management/use-deal';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { RootRoutes } from 'src/components/root-routes';
import { PageSpinner } from 'src/components/spinner-container';
import { useReferrer } from 'src/hooks/use-referrer';
import { ParamsGuard } from 'src/utils/params-guard';

const Page = lazy(() => import('./page'));

export const CreateConversion: FC = () => {
  const capacityManagementRoot = useCapacityManagementRoot();
  const deal = useDeal({ capacityManagementRoot });
  const referrer = useReferrer({
    label: 'My Transactions',
    location: '/reporting/my-transactions',
  });

  // avoid pop in of page titles in case of 401s
  if (!capacityManagementRoot.response) return <PageSpinner />;

  return (
    <RootRoutes>
      <Route
        path="/reporting/my-transactions/deals/:dealId/conversions/create/:direction/*"
        element={
          <ParamsGuard name="direction" regExp={/^(exit|entry)$/}>
            <Page deal={deal} />
          </ParamsGuard>
        }
      />

      <Route
        path="/reporting/my-transactions/deals/:dealId/conversions/create"
        element={
          !deal.response ? (
            <PageSpinner />
          ) : deal.response.data.exit?._links.createConversionRequest ? (
            <Navigate
              replace
              {...referrer.getLinkProps(
                `/reporting/my-transactions/deals/${deal.response.data.dealId}/conversions/create/exit`
              )}
            />
          ) : (
            <Navigate
              replace
              {...referrer.getLinkProps({
                pathname: `/reporting/my-transactions/deals/${deal.response.data.dealId}/conversions/create/entry`,
              })}
            />
          )
        }
      />

      <Route path="*" element={<ThrowNotFound />} />
    </RootRoutes>
  );
};
