import type { ButtonHTMLAttributes, FC } from 'react';
import styled from 'styled-components';
import { Tooltip } from 'src/components/overlay/tooltip';

type IsChecked = { isChecked: boolean };

const Container = styled.button<IsChecked>`
  position: relative;

  width: 5.4rem;
  height: 2.6rem;
  border-radius: 2rem;
  padding: 0;

  cursor: pointer;
  transition:
    background 0.2s ease-in-out,
    border 0.2s ease-in-out;
  background: #efefef;
  border: 0.1rem solid #dfdfdf;
`;

const Label = styled.div<IsChecked>`
  position: absolute;
  top: 0.7rem;
  left: ${({ isChecked }) => (isChecked ? '0.6rem' : '3.4rem')};

  color: #999;
  font-size: 1rem;
  user-select: none;
`;

const Switcher = styled.div<IsChecked>`
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 100%;
  background: #fff;
  box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.4);
  transition: transform 0.2s ease-in-out;
  transform: translateX(${({ isChecked }) => (isChecked ? '2.8rem' : '0')});

  display: flex;
  justify-content: center;
  align-items: center;
`;

export type ToggleProps = {
  isChecked: boolean;
  tooltip?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const CustomizedToggle: FC<ToggleProps> = ({
  isChecked,
  tooltip,
  ...rest
}) => {
  return (
    <Tooltip content={tooltip}>
      {(targetProps) => (
        <Container
          isChecked={isChecked}
          type="button"
          role="switch"
          aria-checked={isChecked ? 'true' : 'false'}
          {...rest}
          {...targetProps}
        >
          <Label isChecked={isChecked}>{isChecked ? 'Yes' : 'No'}</Label>
          <Switcher isChecked={isChecked} />
        </Container>
      )}
    </Tooltip>
  );
};
