import type { FC } from 'react';
import { lazy } from 'react';
import { useParams } from 'react-router-dom';
import type {
  ContractManagementResponse,
  FinancialSecurityType,
} from 'src/apis/contract-management/types';
import { useContractManagement } from 'src/apis/contract-management/use-contract-management';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { PageSpinner } from 'src/components/spinner-container';

const Page = lazy(() => import('./page'));

const InjectLinks: FC<{
  type: FinancialSecurityType;
  financialSecurityId: string;
  contractManagementRoot: ContractManagementResponse;
}> = ({ contractManagementRoot, type, financialSecurityId }) => {
  switch (type) {
    case 'CASH_DEPOSIT':
      if (!contractManagementRoot._links.getCashDeposit?.href) {
        return <ThrowNotFound />;
      }
      return (
        <Page
          getLectLink={contractManagementRoot._links.getLect?.href}
          type="CASH_DEPOSIT"
          financialSecurityId={financialSecurityId}
          getCashDepositUrl={contractManagementRoot._links.getCashDeposit.href}
        />
      );
    case 'RATING':
      if (!contractManagementRoot._links.getRating?.href) {
        return <ThrowNotFound />;
      }
      return (
        <Page
          getLectLink={contractManagementRoot._links.getLect?.href}
          type="RATING"
          financialSecurityId={financialSecurityId}
          getRatingUrl={contractManagementRoot._links.getRating.href}
        />
      );
    case 'BANK_GUARANTEE':
      if (!contractManagementRoot._links.getBankGuarantee?.href) {
        return <ThrowNotFound />;
      }
      return (
        <Page
          getLectLink={contractManagementRoot._links.getLect?.href}
          type="BANK_GUARANTEE"
          financialSecurityId={financialSecurityId}
          getBankGuaranteeUrl={
            contractManagementRoot._links.getBankGuarantee.href
          }
          uploadFileUrl={contractManagementRoot._links.uploadFile?.href}
        />
      );
    case 'PARENT_COMPANY_GUARANTEE':
      if (!contractManagementRoot._links.getParentCompanyGuarantee?.href) {
        return <ThrowNotFound />;
      }
      return (
        <Page
          getLectLink={contractManagementRoot._links.getLect?.href}
          type="PARENT_COMPANY_GUARANTEE"
          financialSecurityId={financialSecurityId}
          getParentCompanyGuaranteeUrl={
            contractManagementRoot._links.getParentCompanyGuarantee.href
          }
          uploadFileUrl={contractManagementRoot._links.uploadFile?.href}
        />
      );
  }

  return null;
};

export const FinancialSecurityDetails: FC<{
  type: FinancialSecurityType;
}> = ({ type }) => {
  const { financialSecurityId } = useParams<{ financialSecurityId: string }>();
  const contractManagementRoot = useContractManagement();

  if (!contractManagementRoot.response) return <PageSpinner />;

  return (
    <InjectLinks
      type={type}
      financialSecurityId={financialSecurityId}
      contractManagementRoot={contractManagementRoot.response.data}
    />
  );
};
