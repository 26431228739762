import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FC } from 'react';
import type { QuickFilter } from 'src/apis/monolith/types';
import { ActionGroup } from 'src/components/buttons-and-actions/action-group';
import { Link } from 'src/components/navigation/link';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { useSearchParams } from 'src/hooks/use-search-params';
import type { PageParams } from 'src/pages/transport/network-points/overview/use-page-params';

export const specialQuickFilters = {
  favoritesOnly: '__showFavoritesOnly',
  ownTsoOnly: '__showOwnTsoOnly',
  inactiveOnly: '__showInactivePointsOnly',
  showAll: '__all',
  custom: undefined,
};

const specialQuickFilterLabels: Record<string, string | null> = {
  __showFavoritesOnly: 'Favourites Only',
  __showOwnTsoOnly: 'Your Network Points Only',
  __showInactivePointsOnly: 'Your Inactive Network Points Only',
  __all: 'All',
};

export const getQuickFilterLabel = (name: string) =>
  specialQuickFilterLabels[name] ?? name;

export const QuickFiltersBar: FC<{
  pageParams: PageParams;
  quickFilters: QuickFilter[];
}> = ({ pageParams, quickFilters }) => {
  const monolithUser = useOptionalAuthenticatedMonolithUser();
  const searchParams = useSearchParams();

  if (!monolithUser) return null;

  const updateSearchParams = (
    type: keyof typeof specialQuickFilters,
    customName?: string
  ) => {
    const newSearchParams = new URLSearchParams(searchParams);

    const customQuickFilter = specialQuickFilters[type] ?? customName;
    if (customQuickFilter != null) {
      newSearchParams.set('customQuickFilter', customQuickFilter);
    } else {
      newSearchParams.delete('customQuickFilter');
    }
    if (monolithUser.isTso && type !== 'showAll') {
      newSearchParams.delete('tso');
    }
    newSearchParams.delete('start');
    newSearchParams.delete('sortColumn');
    newSearchParams.delete('sortAscending');

    return `?${newSearchParams}`;
  };

  return (
    <div>
      {monolithUser.isShipper && (
        <ActionGroup mode="quick-filter">
          <Link
            data-testid="quickFilter-showAll"
            isActive={
              pageParams.value.customQuickFilter === specialQuickFilters.showAll
            }
            to={updateSearchParams('showAll')}
          >
            All
          </Link>
          <Link
            data-testid="quickFilter-favoritesOnly"
            isActive={
              pageParams.value.customQuickFilter ===
              specialQuickFilters.favoritesOnly
            }
            to={updateSearchParams('favoritesOnly')}
          >
            <FontAwesomeIcon icon={faStar} /> Favourites Only
          </Link>
          {quickFilters.map((quickFilter, index) => (
            <Link
              key={`${quickFilter.name}-${index}`}
              data-testid={`quickFilter-${quickFilter.name}`}
              isActive={pageParams.value.customQuickFilter === quickFilter.name}
              to={updateSearchParams('custom', quickFilter.name)}
            >
              {quickFilter.name}
            </Link>
          ))}
        </ActionGroup>
      )}

      {monolithUser.isTso && (
        <ActionGroup mode="quick-filter">
          <Link
            data-testid="quickFilter-showAll"
            isActive={
              pageParams.value.customQuickFilter === specialQuickFilters.showAll
            }
            to={updateSearchParams('showAll')}
          >
            All
          </Link>
          <Link
            data-testid="quickFilter-ownTsoOnly"
            isActive={
              pageParams.value.customQuickFilter ===
              specialQuickFilters.ownTsoOnly
            }
            to={updateSearchParams('ownTsoOnly')}
          >
            Your TSO's Only
          </Link>

          <Link
            data-testid="quickFilter-inactiveOnly"
            isActive={
              pageParams.value.customQuickFilter ===
              specialQuickFilters.inactiveOnly
            }
            to={updateSearchParams('inactiveOnly')}
          >
            Your TSO's Inactive Points Only
          </Link>
        </ActionGroup>
      )}
    </div>
  );
};
