import type { FC } from 'react';
import type { PhoneNumber } from 'src/apis/organisation-registration/types';
import { ExternalLink } from 'src/components/navigation/link';

export const PhoneLink: FC<{ value: PhoneNumber }> = ({ value }) => {
  return (
    <ExternalLink
      mode="default-underlined"
      href={`tel:${value.countryCallingCode}${value.subscriber}`}
    >
      {value.countryCallingCode}
      {value.subscriber}
    </ExternalLink>
  );
};
