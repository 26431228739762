import type { FC, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';
import { ActionModeProvider } from 'src/components/action-mode';
import { Breakpoints } from 'src/styles';

export const LayoutContainer = styled.div`
  display: grid;

  grid-gap: 3rem;
  grid-template-areas:
    'sidebar'
    'title'
    'content';

  @media (${Breakpoints.minTablet}) {
    grid-template-columns: 1fr 5fr;
    grid-template-areas:
      '.       title'
      'sidebar content';
  }
`;

export const LayoutTitle = styled.div`
  grid-area: title;
`;

export const LayoutContent = styled.div`
  grid-area: content;
`;

export const LayoutSidebar = styled.div`
  grid-area: sidebar;
`;

const WrappedSidebar: FC<
  HTMLAttributes<HTMLDivElement> & { children: ReactNode }
> = ({ children, ...rest }) => (
  <div {...rest}>
    <ActionModeProvider value={{ mode: 'sidebar' }}>
      {children}
    </ActionModeProvider>
  </div>
);

export const Sidebar = styled(WrappedSidebar)`
  background-color: #fff;
  border-radius: 0;
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.05);
`;
