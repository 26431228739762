import type { FC, ReactNode } from 'react';
import { SplitActions } from 'src/components/buttons-and-actions/split-actions';
import { EmptyValue } from 'src/components/data-display/empty-value';

type TableActionsProps = {
  children: ReactNode;
  initialActive?: boolean;
  empty?: string;
  /**
   * Please never use numbers. Treat this as deprecated. This is only here for
   * backwards compatibility. (It usually refers to use an index, but a proper
   * ID will make tests more resilient.)
   */
  testId?: string | number;
};

export const TableActions: FC<TableActionsProps> = ({
  children,
  initialActive = false,
  empty,
  testId,
}) => {
  return (
    <SplitActions
      mode="button-secondary"
      size="small"
      initialActive={initialActive}
      empty={<EmptyValue label={empty} />}
      testId={testId?.toString()}
    >
      {children}
    </SplitActions>
  );
};
