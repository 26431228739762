import type { FC } from 'react';
import { lazy } from 'react';
import { useParams } from 'react-router-dom';
import { useMemoOne } from 'use-memo-one';
import type { AffiliateTransactionsParams } from 'src/apis/capacity-management/types';
import type { CapacityManagementRootRequest } from 'src/apis/capacity-management/use-capacity-management-root';
import { useContractManagement } from 'src/apis/contract-management/use-contract-management';
import type {
  FmctRequest,
  OptionalFmctRequest,
} from 'src/apis/contract-management/use-fmct';
import { useOptionalFmct } from 'src/apis/contract-management/use-fmct';
import type { TradeRequest } from 'src/apis/monolith/use-trade';
import { useTrade } from 'src/apis/monolith/use-trade';
import { PageSpinner } from 'src/components/spinner-container';
import type { Successful } from 'src/hooks/use-axios';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { useOnChange } from 'src/hooks/use-on-change';
import { useTitle } from 'src/hooks/use-title';
import type { AffiliateTransactionsRequest } from 'src/pages/reporting/auctions/details/use-affiliate-transactions';
import { useAffiliateTransactions } from 'src/pages/reporting/auctions/details/use-affiliate-transactions';
import { CapacityManagement } from 'src/pages/transport/network-points/details/availability-and-price-data/overview/capacity-management';
import type { ContractManagementTradeRequest } from 'src/pages/transport/secondary-tradings/trade/use-contract-management-trade';
import { useContractManagementTrade } from 'src/pages/transport/secondary-tradings/trade/use-contract-management-trade';
import type {
  DectOfferRequest,
  OptionalDectOfferRequest,
} from 'src/pages/transport/secondary-tradings/trade/use-dect-offer';
import { useOptionalDectOffer } from 'src/pages/transport/secondary-tradings/trade/use-dect-offer';
import { usePageParams } from 'src/pages/transport/secondary-tradings/trade/use-page-params';

const Page = lazy(() => import('./page'));

export const TransportSecondaryTradingsTrade: FC = () => {
  const { tradeId } = useParams<{ tradeId: string }>();
  useTitle(`Trade ID ${tradeId}`);
  const trade = useTrade({ tradeId });
  const monolithUser = useOptionalAuthenticatedMonolithUser();

  if (!monolithUser) return <LoadingStates trade={trade} />;

  return (
    <CapacityManagement>
      {(capacityManagementRoot) =>
        capacityManagementRoot ? (
          <LoadCapacityManagementData
            trade={trade}
            capacityManagementRoot={capacityManagementRoot}
          />
        ) : (
          <ContractManagement trade={trade} />
        )
      }
    </CapacityManagement>
  );
};

type LoadCapacityManagementDataProps = {
  trade: TradeRequest;
  capacityManagementRoot: CapacityManagementRootRequest;
};

const LoadCapacityManagementData: FC<LoadCapacityManagementDataProps> = ({
  trade,
  capacityManagementRoot,
}) => {
  const { tradeId } = useParams<{ tradeId: string }>();
  const pageParams = usePageParams();

  const params = useMemoOne<AffiliateTransactionsParams>(
    () => ({
      assignedTradeId: tradeId,
      limit: pageParams.value.pageSize,
      offset: pageParams.value.start,
    }),
    [tradeId, pageParams.value]
  );
  const affiliateTransactions = useAffiliateTransactions({
    capacityManagementRoot,
    params,
  });

  return (
    <ContractManagement
      trade={trade}
      affiliateTransactions={affiliateTransactions}
      usesAffiliateTransactions={Boolean(
        capacityManagementRoot.response?.data._links?.affiliateTransactions
      )}
    />
  );
};

type ContractManagementProps = {
  trade: TradeRequest;
  affiliateTransactions?: AffiliateTransactionsRequest;
  usesAffiliateTransactions?: boolean;
};

const ContractManagement: FC<ContractManagementProps> = ({
  trade,
  affiliateTransactions,
  usesAffiliateTransactions,
}) => {
  const contractManagement = useContractManagement();

  if (
    (!contractManagement.response && !contractManagement.error) ||
    !trade.response
  )
    return <PageSpinner />;

  return contractManagement.response?.data._links.trade?.href ? (
    <LoadContractManagementData
      trade={trade}
      affiliateTransactions={affiliateTransactions}
      usesAffiliateTransactions={usesAffiliateTransactions}
      tradeUrl={contractManagement.response.data._links.trade.href}
    />
  ) : (
    <LoadingStates
      trade={trade}
      affiliateTransactions={affiliateTransactions}
      usesAffiliateTransactions={usesAffiliateTransactions}
    />
  );
};

type LoadContractManagementDataProps = {
  trade: Successful<TradeRequest>;
  affiliateTransactions?: AffiliateTransactionsRequest;
  usesAffiliateTransactions?: boolean;
  tradeUrl: string;
};

const LoadContractManagementData: FC<LoadContractManagementDataProps> = ({
  trade,
  affiliateTransactions,
  usesAffiliateTransactions,
  tradeUrl,
}) => {
  const tradeUuid = trade.response.data.uuid;
  const tradeStatus = trade.response.data.response.state;

  const contractManagementTrade = useContractManagementTrade({
    tradeUrl,
    tradeUuid,
  });

  useOnChange(() => {
    contractManagementTrade.execute({ tradeUuid });
  }, tradeStatus);

  const exitFmct = useOptionalFmct({
    url: contractManagementTrade.response?.data._links.exitFmct?.href,
  });
  const entryFmct = useOptionalFmct({
    url: contractManagementTrade.response?.data._links.entryFmct?.href,
  });
  const exitDectOffer = useOptionalDectOffer({
    url: contractManagementTrade.response?.data._links.exitDectOffer?.href,
  });
  const entryDectOffer = useOptionalDectOffer({
    url: contractManagementTrade.response?.data._links.entryDectOffer?.href,
  });

  return (
    <LoadingStates
      trade={trade}
      affiliateTransactions={affiliateTransactions}
      usesAffiliateTransactions={usesAffiliateTransactions}
      contractManagementTrade={contractManagementTrade}
      exitFmct={exitFmct}
      entryFmct={entryFmct}
      exitDectOffer={exitDectOffer}
      entryDectOffer={entryDectOffer}
    />
  );
};

type LoadingStatesProps = {
  trade: TradeRequest;
  affiliateTransactions?: AffiliateTransactionsRequest;
  usesAffiliateTransactions?: boolean;
  contractManagementTrade?: ContractManagementTradeRequest;
  exitFmct?: OptionalFmctRequest;
  entryFmct?: OptionalFmctRequest;
  exitDectOffer?: OptionalDectOfferRequest;
  entryDectOffer?: OptionalDectOfferRequest;
};

const LoadingStates: FC<LoadingStatesProps> = ({
  trade,
  contractManagementTrade,
  affiliateTransactions,
  usesAffiliateTransactions,
  exitFmct,
  entryFmct,
  exitDectOffer,
  entryDectOffer,
}) => {
  if (
    !trade.response ||
    (usesAffiliateTransactions &&
      affiliateTransactions?.response === null &&
      affiliateTransactions.error === null) ||
    // we need to await the contract management trade to properly fetch the fmcts/dect offers
    (contractManagementTrade?.response === null &&
      contractManagementTrade.error === null) ||
    (exitFmct?.response === null && exitFmct.error === null) ||
    (entryFmct?.response === null && entryFmct.error === null) ||
    (exitDectOffer?.response === null && exitDectOffer.error === null) ||
    (entryDectOffer?.response === null && entryDectOffer.error === null)
  )
    return <PageSpinner />;

  return (
    <Page
      trade={trade}
      affiliateTransactions={affiliateTransactions}
      exitFmct={
        exitFmct?.response ? (exitFmct as Successful<FmctRequest>) : undefined
      }
      entryFmct={
        entryFmct?.response ? (entryFmct as Successful<FmctRequest>) : undefined
      }
      exitDectOffer={
        exitDectOffer?.response
          ? (exitDectOffer as Successful<DectOfferRequest>)
          : undefined
      }
      entryDectOffer={
        entryDectOffer?.response
          ? (entryDectOffer as Successful<DectOfferRequest>)
          : undefined
      }
    />
  );
};
