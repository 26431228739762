import type { ReactNode } from 'react';
import { Children, Fragment, isValidElement } from 'react';

export function flattenChildren(children: ReactNode) {
  const flattened: ReactNode[] = [];

  function flatten(child: ReactNode) {
    Children.forEach(child, (c) => {
      if (!isValidElement(c)) return;

      if (c.type === Fragment) {
        flatten(c.props.children);
      } else {
        flattened.push(c);
      }
    });
  }

  flatten(children);

  return flattened;
}
