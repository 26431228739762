import { useEffect } from 'react';
import type { BidCollectorRootResponse } from 'src/apis/snam-bid-collector/types';
import { useAxios } from 'src/hooks/use-axios';

export function useIsSnamAdmin() {
  const bidCollectorRoot = useAxios(
    (axios, baseConfig) =>
      axios.request<BidCollectorRootResponse>({
        ...baseConfig,
        url: PRISMA_CONFIG.snamBidCollector,
      }),
    { neededOnPageLoad: true, onError: (error) => error }
  );

  useEffect(() => {
    bidCollectorRoot.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (bidCollectorRoot.error) return false;

  if (!bidCollectorRoot.response) return;

  return Boolean(bidCollectorRoot.response.data._links.toggleFeature);
}
