import { useEffect } from 'react';
import type { CurrentShipperUser } from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';

export function useCurrentShipperUser() {
  const shipper = useAxios(
    (axios, baseConfig) =>
      axios.request<CurrentShipperUser>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/shipperUsers`,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    shipper.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return shipper;
}

export type CurrentShipperUserRequest = ReturnType<
  typeof useCurrentShipperUser
>;
