import type { FC } from 'react';
import { lazy, useEffect } from 'react';
import { useTrFlags } from 'src/hooks/use-tr-flags';

const Routes = lazy(() => import('./transparency-routes'));

export const Transparency: FC = () => {
  const trFlags = useTrFlags();
  useEffect(() => {
    if (!trFlags.userCanAcces) throw trFlags.error;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <>{trFlags.userCanAcces && <Routes />}</>;
};
