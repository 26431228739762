import { useEffect } from 'react';
import type { BookingProcessDetails } from 'src/apis/regular-fcfs/types';
import { useAxios } from 'src/hooks/use-axios';

export function useBookingProcessDetails(bookingProcessId: string) {
  const request = useAxios(
    (axios, baseConfig, bookingProcessId: string) =>
      axios.request<BookingProcessDetails>({
        url: `${PRISMA_CONFIG.regularFcfs}/api/fcfs-process-details/${bookingProcessId}`,
        ...baseConfig,
      }),
    {
      neededOnPageLoad: true,
    }
  );

  useEffect(() => {
    request.execute(bookingProcessId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingProcessId]);

  return request;
}

export type BookingProcessDetailsRequest = ReturnType<
  typeof useBookingProcessDetails
>;

export function useGetBookingProcessId(bookingId: number) {
  const request = useAxios(
    (axios, baseConfig, bookingId: number) =>
      axios.request<string>({
        url: `${PRISMA_CONFIG.regularFcfs}/api/fcfs-process-id/${bookingId}`,
        ...baseConfig,
      }),
    {
      neededOnPageLoad: true,
    }
  );

  useEffect(() => {
    request.execute(bookingId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingId]);

  return request;
}
