import { faFileCsv } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FC } from 'react';
import type { CreditLimitAssignment } from 'src/apis/monolith/types';
import { Card } from 'src/components/data-display/card';
import { FormatDate } from 'src/components/data-display/format-date';
import { FormatNumber } from 'src/components/data-display/format-number';
import { Table } from 'src/components/data-display/smart-table';
import { Td, Th } from 'src/components/data-display/table';
import { DownloadLimit } from 'src/components/download-limit';
import { Stack } from 'src/components/layout/stack';
import { MonolithDownload } from 'src/components/monolith-download';
import { Heading } from 'src/components/text/heading';

export const CreditLimits: FC<{
  creditLimits: Readonly<CreditLimitAssignment[]>;
  operatorOldId: number;
}> = ({ creditLimits, operatorOldId }) => {
  return (
    <Card>
      <Stack gap={2} alignItems="center">
        <Stack flow="column" justifyContent="space-between" gap={2}>
          <Heading mode="card">Credit Limits</Heading>

          <DownloadLimit totalItems={creditLimits.length}>
            <MonolithDownload
              mode="secondary"
              size="small"
              href={`${PRISMA_CONFIG.monolithApiUrl}/shipperUsers/assignments/${operatorOldId}/csv/limits`}
            >
              <FontAwesomeIcon icon={faFileCsv} aria-hidden /> CSV
            </MonolithDownload>
          </DownloadLimit>
        </Stack>

        <Table
          data={creditLimits}
          setId={(limit) => limit.identifier}
          cols={[
            {
              width: 1,
              key: 'Identifier',
              head: <Th>Identifier</Th>,
              body: (limit) => <Td>{limit.identifier}</Td>,
            },
            {
              width: 1,
              key: 'Static',
              head: <Th align="right">Static</Th>,
              body: (limit) => (
                <Td align="right">
                  <strong>
                    <FormatNumber
                      value={limit.staticLimit.amount.valueAsEuros.value}
                    />
                  </strong>{' '}
                  <small>{limit.staticLimit.currency}</small>
                </Td>
              ),
            },
            {
              width: 1,
              key: 'Used',
              head: <Th align="right">Used</Th>,
              body: (limit) => (
                <Td align="right">
                  <strong>
                    <FormatNumber
                      value={limit.usedLimit.amount.valueAsEuros.value}
                    />
                  </strong>{' '}
                  <small>{limit.usedLimit.currency}</small>
                </Td>
              ),
            },
            {
              width: 3,
              key: 'Application',
              head: <Th>Application</Th>,
              body: (limit) => <Td>{limit.application}</Td>,
            },
            {
              width: 3,
              key: 'Interval',
              head: <Th>Interval</Th>,
              body: (limit) => (
                <Td>
                  <FormatDate value={limit.interval.start} type="date-time" />{' '}
                  {' - '}{' '}
                  <FormatDate value={limit.interval.end} type="date-time" />
                </Td>
              ),
            },
            {
              width: 3,
              key: 'Framework',
              head: <Th>Framework</Th>,
              body: (limit) => <Td>{limit.framework}</Td>,
            },
            {
              width: 2,
              key: 'Type',
              head: <Th>Type</Th>,
              body: (limit) => <Td>{limit.stacked ? 'Stacked' : 'Regular'}</Td>,
            },
          ]}
        />
      </Stack>
    </Card>
  );
};
