import type { FC } from 'react';
import { lazy } from 'react';
import { useOrganisationDetails } from 'src/apis/organisation-service/use-organisation-details';
import type { OrganisationServiceRequest } from 'src/apis/organisation-service/use-organisation-service';
import { PageSpinner } from 'src/components/spinner-container';
import type { Successful } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';

type Props = {
  organisationService: Successful<OrganisationServiceRequest>;
};

const Page = lazy(
  () =>
    import(
      'src/pages/settings/organisation/company-information/shipper-role-wizard/page'
    )
);

export const ShipperRoleWizard: FC<Props> = ({ organisationService }) => {
  if (!organisationService.response.data._links.getMyOrganisationDetails) {
    throw new Forbidden();
  }

  const organisationDetails = useOrganisationDetails({
    url: organisationService.response.data._links.getMyOrganisationDetails.href,
  });

  if (!organisationDetails.response) {
    return <PageSpinner />;
  }

  return (
    <Page
      organisationData={organisationDetails.response.data}
      organisationService={organisationService}
    />
  );
};
