import { useEffect } from 'react';
import type { TariffResponse } from 'src/apis/capacity-management/types';
import type { CmNetworkPointRequest } from 'src/apis/capacity-management/use-cm-network-point';
import { useAxios } from 'src/hooks/use-axios';

type HookConfig = {
  cmNetworkPoint: CmNetworkPointRequest;
};

type RequestOptions = {
  url: string;
};

export function useTariff({ cmNetworkPoint }: HookConfig) {
  const tariff = useAxios(
    (axios, baseConfig, { url }: RequestOptions) =>
      axios.request<TariffResponse>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!cmNetworkPoint.response) return;

    const url = cmNetworkPoint.response.data._links.viewTariff.href;
    const options = { url };

    tariff.execute(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmNetworkPoint.response]);

  return tariff;
}

export type TariffRequest = ReturnType<typeof useTariff>;
