import { useEffect } from 'react';
import type { OrganisationServicePublicRoot } from 'src/apis/organisation-service/types';
import { useAxios } from 'src/hooks/use-axios';

export function useOrganisationServicePublic() {
  const organisationServicePublic = useAxios(
    (axios, baseConfig) =>
      axios.request<OrganisationServicePublicRoot>({
        ...baseConfig,
        url: PRISMA_CONFIG.organisationService + '/public',
      }),
    { neededOnPageLoad: true, cacheKey: 'OrganisationServicePublic' }
  );

  useEffect(() => {
    organisationServicePublic.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return organisationServicePublic;
}

export type OrganisationServicePublicRequest = ReturnType<
  typeof useOrganisationServicePublic
>;
