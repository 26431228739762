import escapedString from 'escape-string-regexp';
import type { FC } from 'react';
import { useEffect, useMemo } from 'react';
import { z } from 'zod';
import type { NetworkPoint } from 'src/apis/monolith/types';
import { Direction } from 'src/components/data-display/direction';
import { SearchableMultiSelect } from 'src/components/form/select/searchable-multi-select';
import { formOptionSchema } from 'src/components/form/zod-schemas';
import { useAxios } from 'src/hooks/use-axios';
import { useSearch } from 'src/hooks/use-search';

const url = `${PRISMA_CONFIG.monolithApiUrl}/networkPoints`;

type Props = {
  label: string;
};

const networkPointDirectionMetaSchema = z.object({
  marketArea: z.string(),
  tsoShortName: z.string(),
});

const networkPointMetaSchema = z.union([
  z.object({
    exit: networkPointDirectionMetaSchema,
    entry: networkPointDirectionMetaSchema,
  }),
  z.object({
    exit: networkPointDirectionMetaSchema,
    entry: z.undefined(),
  }),
  z.object({
    exit: z.undefined(),
    entry: networkPointDirectionMetaSchema,
  }),
]);

export const networkPointSchema = formOptionSchema.and(networkPointMetaSchema);

export type NetworkPointOption = z.input<typeof networkPointSchema>;

export const NetworkPointSearchableMultiSelect: FC<Props> = ({ label }) => {
  const [searchValue, setSearchValue, searchQuery] = useSearch('', {
    minLength: 1,
  });

  const searchRequest = useAxios(
    (axios, baseConfig, searchQuery: string) =>
      axios.request<NetworkPoint[]>({
        ...baseConfig,
        url,
        params: {
          infix: searchQuery,
          // params settings taken from https://platform.prisma-capacity.eu/#/network-point
          allowFutureForTso: true,
          detailLevel: 'MINIMAL',
          mode: 'filtered',
          bundled: true,
        },
      }),
    { neededOnPageLoad: false }
  );

  useEffect(() => {
    if (!searchQuery) return;
    searchRequest.execute(searchQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const searchResult = useMemo(() => {
    if (!searchQuery || !searchRequest.response) return;

    const escapedSearchQuery = new RegExp(
      escapedString(searchQuery.toLowerCase())
    );

    return searchRequest.response.data
      .filter((networkPoint) =>
        networkPoint.name.toLowerCase().match(escapedSearchQuery)
      )
      .map((networkPoint) => ({
        value: networkPoint.networkPointUuid,
        label: networkPoint.name,
        ...(networkPoint.bundled
          ? {
              exit: {
                marketArea: networkPoint.marketArea0,
                tsoShortName: networkPoint.tso0ShortName,
              },
              entry: {
                marketArea: networkPoint.marketArea1,
                tsoShortName: networkPoint.tso1ShortName,
              },
            }
          : networkPoint.direction === 'ENTRY'
            ? {
                entry: {
                  marketArea: networkPoint.marketArea0,
                  tsoShortName: networkPoint.tso0ShortName,
                },
              }
            : {
                exit: {
                  marketArea: networkPoint.marketArea0,
                  tsoShortName: networkPoint.tso0ShortName,
                },
              }),
      })) satisfies NetworkPointOption[];
  }, [searchQuery, searchRequest.response]);

  return (
    <SearchableMultiSelect
      label={label}
      name="networkPointId"
      searchValue={searchValue}
      searchValueForCurrentOptions={searchValue}
      setSearchValue={setSearchValue}
      pending={searchRequest.pending}
      stacked
      placeholder="E.g. Arnoldstein"
      options={searchResult}
      renderOptionHint={(option) => <OptionHint option={option} />}
      renderSelectedOptionTooltip={(option) => (
        <SelectedValueTooltip option={option} />
      )}
    />
  );
};
const OptionHint: FC<{
  option: NetworkPointOption;
}> = ({ option }) => {
  if (option.entry && option.exit) {
    return (
      <>
        {option.exit.tsoShortName} / {option.entry.tsoShortName}{' '}
        <Direction networkPoint={option} />
      </>
    );
  } else if (option.entry) {
    return (
      <>
        {option.entry.tsoShortName} <Direction networkPoint={option} />
      </>
    );
  } else {
    return (
      <>
        {option.exit.tsoShortName} <Direction networkPoint={option} />
      </>
    );
  }
};

const SelectedValueTooltip: FC<{
  option: NetworkPointOption;
}> = ({ option }) => {
  if (option.entry && option.exit) {
    return (
      <>
        <strong>Exit</strong>: {option.exit.tsoShortName}
        <br />
        <strong>Entry</strong>: {option.entry.tsoShortName}
      </>
    );
  } else if (option.entry) {
    return (
      <>
        <strong>Entry</strong>: {option.entry.tsoShortName}
      </>
    );
  } else {
    return (
      <>
        <strong>Exit</strong>: {option.exit.tsoShortName}
      </>
    );
  }
};
