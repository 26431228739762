import type { FC } from 'react';
import { Stack } from 'src/components/layout/stack';
import { Link } from 'src/components/navigation/link';

export const DemandsTabNavigation: FC = () => {
  return (
    <div>
      <Stack flow="column" gap={2.5} inline>
        <Link to="/aggregate-eu/demands/my-demands" mode="pill">
          My Demands
        </Link>
        {/* <Link to={`/aggregate-eu/demands/aggregation-of-demands`} mode="pill">
          Aggregation of Demands
        </Link> */}
        <Link to="/aggregate-eu/demands/my-information-sheets" mode="pill">
          My Information Sheets
        </Link>
      </Stack>
    </div>
  );
};
