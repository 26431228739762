import { useEffect } from 'react';
import type { RemitReportingSubscriptionInfo } from 'src/apis/remit-reporting/types';
import { useAxios } from 'src/hooks/use-axios';
import { handleError } from 'src/utils/handle-error';
import { reportToSentry } from 'src/utils/report-to-sentry';

export function useRemitSecondaryReportingSubscriptionInfo() {
  const subscriptionInfo = useAxios(
    (axios, baseConfig) =>
      axios.request<RemitReportingSubscriptionInfo>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.remitReporting}/api/platform/remit-reporting-settings/secondary`,
        method: 'GET',
        timeout: 5000,
      }),
    {
      neededOnPageLoad: false,
      cacheKey: `RemitSecondaryReportingSubscriptionInfo`,
      onError(error) {
        const [_, report] = handleError(error, () => {});
        if (report) reportToSentry(error);
        return error;
      },
    }
  );

  useEffect(() => {
    subscriptionInfo.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return subscriptionInfo;
}

export type RemitSecondaryReportingSubscriptionInfoRequest = ReturnType<
  typeof useRemitSecondaryReportingSubscriptionInfo
>;
