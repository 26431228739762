import { useAxios } from 'src/hooks/use-axios';

export function useWithdrawUserAssignment() {
  return useAxios(
    (axios, baseConfig, url: string) =>
      axios.request({
        ...baseConfig,
        method: 'DELETE',
        url,
      }),
    {
      neededOnPageLoad: false,
    }
  );
}

export type WithdrawUserAssignmentsRequest = ReturnType<
  typeof useWithdrawUserAssignment
>;
