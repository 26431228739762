import type { FC } from 'react';
import { lazy, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import type { ComfortBid } from 'src/apis/monolith/types';
import { useComfortBidConfig } from 'src/apis/monolith/use-comfort-bid-config';
import { useDayAheadComfortBid } from 'src/apis/monolith/use-day-ahead-comfort-bid';
import { PageSpinner } from 'src/components/spinner-container';
import type { ComfortBidValues } from 'src/pages/transport/auctions/comfort-bids/create/bids-schema';
import { createFakeAuctionForComfortBid } from 'src/pages/transport/auctions/comfort-bids/create/bids-schema';
import type { BaseOrder } from 'src/pages/transport/auctions/details/shared-form-logic';
import { getPrefilledShortTermBidFormValues } from 'src/pages/transport/auctions/details/short-term-bidding-form';
import { createId } from 'src/utils/create-id';

const Page = lazy(() => import('./page'));

export const EditComfortBids: FC = () => {
  const { bidId } = useParams<{ bidId: string }>();
  const comfortBid = useDayAheadComfortBid(bidId);
  if (!comfortBid.response) return <PageSpinner />;

  return <LoadConfig comfortBid={comfortBid.response.data} />;
};

const LoadConfig: FC<{
  comfortBid: ComfortBid;
}> = ({ comfortBid }) => {
  const [initialValues, setInitialValues] = useState<ComfortBidValues | null>(
    null
  );
  const comfortBidConfig = useComfortBidConfig();

  useEffect(() => {
    comfortBidConfig.execute({
      params: {
        networkPointId: comfortBid.networkPoint.id,
        runtimeStart: comfortBid.orderRuntime.start,
        runtimeEnd: comfortBid.orderRuntime.end,
        capacityCategory0: comfortBid.capacityCategory0,
        capacityCategory1: comfortBid.capacityCategory1,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialValues) return;
    if (!comfortBidConfig.response) return;

    const networkPoint = comfortBid.networkPoint;

    const defaultBid: ComfortBidValues = networkPoint.bundled
      ? {
          id: createId(),
          bundled: networkPoint.bundled,
          networkPointId: networkPoint.id,
          runtimeStart: comfortBid.orderRuntime.start,
          runtimeEnd: comfortBid.orderRuntime.end,
          capacityCategory0: comfortBid.capacityCategory0,
          capacityCategory1: comfortBid.capacityCategory1!,
          bid: null,
          config: null,
          fakeAuction: null,
        }
      : {
          id: createId(),
          bundled: networkPoint.bundled,
          networkPointId: networkPoint.id,
          runtimeStart: comfortBid.orderRuntime.start,
          runtimeEnd: comfortBid.orderRuntime.end,
          capacityCategory0: comfortBid.capacityCategory0,
          bid: null,
          config: null,
          fakeAuction: null,
        };

    const fakeAuction = createFakeAuctionForComfortBid({
      comfortBidConfig,
      networkPoint: comfortBid.networkPoint,
    });
    const fakeOrder: BaseOrder = {
      minQuantity: comfortBid.limitBids[0].min,
      maxQuantity: comfortBid.limitBids[0].max,
      price: comfortBid.limitBids[0].highestBid,
      withinDayRollover: comfortBid.rollover,
      balancingGroup0: comfortBid.balancingGroup0,
      balancingGroup1: comfortBid.balancingGroup1,
      portfolioCode0: comfortBid.portfolioCode0,
      portfolioCode1: comfortBid.portfolioCode1,
      upgrade0: comfortBid.upgrade0,
      upgrade1: comfortBid.upgrade1,
      conversions: comfortBid.conversions,
      undiscounted: comfortBid.undiscounted,
    };
    const bid = getPrefilledShortTermBidFormValues(fakeAuction, fakeOrder);
    const values: ComfortBidValues = {
      ...defaultBid,
      config: {
        ...comfortBidConfig.response.data,
        // despite having the field in the comfort bid config type, it doesn't seem to be set by the backend
        // and the old frontend always assumed that it is true
        withinDayRolloverAllowed: true,
      },
      fakeAuction,
      bid,
    };

    setInitialValues(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comfortBidConfig.response]);

  if (!initialValues) return <PageSpinner />;

  return (
    <Page
      comfortBid={comfortBid}
      comfortBidConfig={comfortBidConfig}
      initialValues={initialValues}
    />
  );
};
