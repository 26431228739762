// note: even thought this is called "dect offer" this refers to the "dect document" which might be available
// for dect offers _or_ dects
import type { FC } from 'react';
import { useEffect } from 'react';
import type {
  DectOfferResponse,
  Relation,
} from 'src/apis/contract-management/types';
import { Button } from 'src/components/buttons-and-actions/button';
import { Tooltip } from 'src/components/overlay/tooltip';
import { Spinner } from 'src/components/spinner-container';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { useTimeout } from 'src/hooks/use-timeout';
import { useToast } from 'src/hooks/use-toasts';
import { useDectOffer } from 'src/pages/transport/secondary-tradings/trade/use-dect-offer';
import { useDectOfferDocument } from 'src/pages/transport/secondary-tradings/trade/use-dect-offer-document';
import { setLocationHref } from 'src/utils/location-usage';

// DECT Offer is downloadable until it is send to the Operator for review.
// Shipper must download the Derived Contract offer, sign it and send to TAG.
// Once DECT Offer is approved and DECT activated the download button should disappear.
// There is no DECT Offer download later on in the process. Both parties should keep signed paper documents.
export const DownloadDectOfferDocument: FC<
  {
    label: string;
    /**
     * This happens if the dect offer status changes from SUBMITTED to WITHDRAWN
     * shortly before the shipper requests the download.
     * It's not relevant for the TSO.
     */
    onError: () => void;
  } & (
    | { dectData: Relation; type?: undefined }
    | { dectData: DectOfferResponse; type: 'button' }
  )
> = ({ dectData, type, label, onError }) => {
  const monolithUser = useOptionalAuthenticatedMonolithUser();
  const documentLink = dectData._links.document;

  if (
    // TSOs should always be able to download the DECT Offer, no matter which status
    // it currently has. sadly this document is not available as soon as the
    // dect offer is created. it might take ~20 seconds until it is generated.
    // we need to poll and wait for this to happen.
    monolithUser?.isTso &&
    !documentLink
  )
    return (
      <PollDocument dectOfferHref={dectData._links.self.href} label={label} />
    );

  if (!documentLink) return null;

  return (
    <WithDectOfferDocument
      dectOfferDocumentUrl={documentLink.href}
      type={type}
      label={label}
      onError={onError}
    />
  );
};

const PollDocument: FC<{ dectOfferHref: string; label: string }> = ({
  dectOfferHref,
  label,
}) => {
  const increasingTimeout = useTimeout({ delay: 1000, multiplier: 2 });
  const dectOffer = useDectOffer({ url: dectOfferHref });

  useEffect(() => {
    if (!dectOffer.response) return;
    // as long as the document is not available schedule a refresh
    if (dectOffer.response.data._links.document) {
      increasingTimeout.reset();
    } else {
      increasingTimeout.next(() => dectOffer.refresh?.());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dectOffer.response]);

  // as the polling is only relevant for TSOs we don't really care about the status
  // which could have changed in between. they should always have the option to get
  // the document.
  if (dectOffer.response?.data._links.document)
    return (
      <WithDectOfferDocument
        dectOfferDocumentUrl={dectOffer.response.data._links.document.href}
        type="button"
        label={label}
      />
    );

  return (
    <Tooltip content="The offer document will be available in a couple of seconds.">
      {(targetProps) => (
        <Button
          data-testid="pending-dect-offer-document"
          mode="secondary"
          size="small"
          disabled
          {...targetProps}
        >
          {label} <Spinner />
        </Button>
      )}
    </Tooltip>
  );
};

const WithDectOfferDocument: FC<{
  dectOfferDocumentUrl: string;
  type?: 'button';
  label: string;
  onError?: () => void;
}> = ({ onError, dectOfferDocumentUrl, type, label }) => {
  const dectOfferDocument = useDectOfferDocument();
  const notify = useToast();

  useEffect(() => {
    if (!dectOfferDocument.response) return;
    setLocationHref(dectOfferDocument.response.data.downloadUrl);
  }, [dectOfferDocument.response]);

  useEffect(() => {
    if (!dectOfferDocument.error) return;
    notify({
      type: 'error',
      children: `The contract status has changed and the document can't be downloaded any longer.`,
    });
    onError?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dectOfferDocument.error]);

  return type === 'button' ? (
    <Button
      mode="secondary"
      size="small"
      onClick={() => dectOfferDocument.execute({ dectOfferDocumentUrl })}
    >
      {label}
    </Button>
  ) : (
    <Button
      mode="dropdown"
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation(); // TODO: see TAG-9571
        dectOfferDocument.execute({ dectOfferDocumentUrl });
        notify({ type: 'success', children: 'Download started.' });
      }}
    >
      Download
    </Button>
  );
};
