import { z } from 'zod';
import {
  ascSortDirectionSchema,
  getPaginationSchema,
} from 'src/components/form/zod-schemas';
import {
  preprocessAsArray,
  preprocessAsBoolean,
} from 'src/components/form/zod-utilities';

export type AssignmentsSortBy =
  | 'OPERATOR_NAME'
  | 'ORGANISATION_ASSIGNMENT_STATUS'
  | 'TRADING_MARKET'
  | 'USER_NAME'
  | 'USER_EMAIL'
  | 'USER_ASSIGNMENT_STATUS';

export const sortByValues = [
  'OPERATOR_NAME',
  'ORGANISATION_ASSIGNMENT_STATUS',
  'TRADING_MARKET',
  'USER_NAME',
  'USER_EMAIL',
  'USER_ASSIGNMENT_STATUS',
] as const satisfies readonly AssignmentsSortBy[];

export function getOperatorsOverviewPageParamsForOperatorsListSchema() {
  return z
    .object({
      sortBy: z.enum(sortByValues).default('OPERATOR_NAME'),
      sortDirection: ascSortDirectionSchema,
      operatorName: z.string().nullable().default(null),
      organisationAssignmentStatus: z.preprocess(
        preprocessAsArray,
        z
          .array(
            z.enum([
              'WAITING_FOR_APPROVAL',
              'APPROVED',
              'TEMPORARILY_DISABLED',
              'CANCELLED',
            ])
          )
          .default([])
      ),
      tradingMarket: z.preprocess(
        preprocessAsArray,
        z.array(z.enum(['PRIMARY', 'SECONDARY'])).default([])
      ),
      userName: z.string().nullable().default(null),
      userEmail: z.string().nullable().default(null),
      userAssignmentStatus: z.preprocess(
        preprocessAsArray,
        z
          .array(
            z.enum([
              'WAITING_FOR_APPROVAL',
              'APPROVED',
              'TEMPORARILY_DISABLED',
              'CANCELLED',
            ])
          )
          .default([])
      ),
      allActive: z.preprocess(preprocessAsBoolean, z.boolean().default(false)),
      ownAssigned: z.preprocess(
        preprocessAsBoolean,
        z.boolean().default(false)
      ),
    })
    .merge(getPaginationSchema());
}

export function getAssignmentOverviewPageParamsSchema() {
  return z.object({
    assignments: z.enum(['own', 'all']).default('own'),
    groupBy: z.enum(['operator', 'user']).default('operator'),
  });
}
