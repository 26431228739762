import { useEffect } from 'react';
import urlTemplate from 'url-template';
import type {
  LngOffers,
  LngOffersOverviewParams,
  LngOffersReportingParams,
} from 'src/apis/lng-marketing/types';
import type { LngMarketingRootRequest } from 'src/apis/lng-marketing/use-lng-marketing-root';
import { useAxios } from 'src/hooks/use-axios';

type HookOptions = {
  lngMarketingRoot: LngMarketingRootRequest;
  params: LngOffersOverviewParams | LngOffersReportingParams;
};

type ExecuteOptions = {
  url: string;
  params: LngOffersOverviewParams | LngOffersReportingParams;
};

export function useLngOffers({ lngMarketingRoot, params }: HookOptions) {
  const lngOffers = useAxios(
    (axios, baseConfig, { url, params }: ExecuteOptions) =>
      axios.request<LngOffers>({
        ...baseConfig,
        url,
        params,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!lngMarketingRoot?.response) return;

    // prefer new over old link
    const offersPublicLink =
      lngMarketingRoot.response.data._links.getOffersPublic;

    if (!offersPublicLink) return;

    const offersLink = lngMarketingRoot.response.data._links.getOffers;

    // prefer private offers if available
    const url = urlTemplate
      .parse(offersLink ? offersLink.href : offersPublicLink.href)
      .expand({});

    lngOffers.execute({
      url,
      params,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lngMarketingRoot?.response, params]);

  // not available on stage
  if (!lngMarketingRoot) return;

  // feature flag not active
  if (
    lngMarketingRoot.response &&
    !lngMarketingRoot.response.data._links.getOffersPublic
  )
    return;

  return lngOffers;
}

export type LngOffersRequest = ReturnType<typeof useLngOffers>;
