import type { FC } from 'react';
import { lazy } from 'react';
import { useUmmRoot } from 'src/apis/iip-service/use-umm-root';

const Page = lazy(() => import('./page'));

export const UrgentMarketMessages: FC = () => {
  const ummRoot = useUmmRoot();
  return <Page ummRoot={ummRoot} />;
};
