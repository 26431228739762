import { useEffect } from 'react';
import type { ShipperUserData } from 'src/apis/monolith/types';
import { useAxios, isServerError } from 'src/hooks/use-axios';

export function useShipperUserData(id: string | number) {
  const shipperUserData = useAxios(
    (axios, baseConfig) =>
      axios.request<ShipperUserData>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/shipperUsers/user/${id}`,
      }),
    {
      neededOnPageLoad: true,
      onError(error) {
        // returns 404 for:
        // - unknown IDs
        // - deactivated users
        // - outdated time slices (our id is NOT a userRefId and therefor refers to
        //   a time slice and not the user itself)
        if (isServerError(error, 404)) {
          return error;
        } else {
          throw error;
        }
      },
    }
  );

  useEffect(() => {
    shipperUserData.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return shipperUserData;
}

export type ShipperUserDataRequest = ReturnType<typeof useShipperUserData>;
