import type { FC } from 'react';
import { useEffect } from 'react';
import { isLocalStorageSupported } from 'src/hooks/use-storage';
import { useToast } from 'src/hooks/use-toasts';

export const CookieCheck: FC = () => {
  const notify = useToast();

  useEffect(() => {
    if (isLocalStorageSupported) return;
    notify({
      type: 'error',
      children:
        "The local storage can't be used. You will not be able to fully use the platform without this. This can happen for example, if all cookies are blocked.",
    });
  }, [notify]);

  return null;
};
