import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import urlTemplate from 'url-template';
import type {
  ConversionRequestResponse,
  CapacityManagementRootResponse,
} from 'src/apis/capacity-management/types';
import type { CapacityManagementRootRequest } from 'src/apis/capacity-management/use-capacity-management-root';
import { useAxios } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';

type HookConfig = {
  capacityManagementRoot: CapacityManagementRootRequest;
};

type RequestOptions = {
  rootData: CapacityManagementRootResponse;
  conversionRequestId: string;
};

export function useConversionRequest({ capacityManagementRoot }: HookConfig) {
  const conversion = useAxios(
    (axios, baseConfig, { rootData, conversionRequestId }: RequestOptions) => {
      if (!rootData._links?.conversionRequest) throw new Forbidden();

      const url = urlTemplate
        .parse(rootData._links.conversionRequest.href)
        .expand({ conversionRequestId });

      return axios.request<ConversionRequestResponse>({
        ...baseConfig,
        url,
      });
    },
    { neededOnPageLoad: true }
  );

  const { conversionRequestId } = useParams<{ conversionRequestId: string }>();

  useEffect(() => {
    if (!capacityManagementRoot.response) return;

    const rootData = capacityManagementRoot.response.data;
    const options = { rootData, conversionRequestId };

    conversion.execute(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capacityManagementRoot.response, conversionRequestId]);

  return conversion;
}

export type ConversionRequestRequest = ReturnType<typeof useConversionRequest>;
