import type { FC } from 'react';
import { lazy, useMemo } from 'react';
import type { ContactsParams } from 'src/apis/organisation-service/types';
import { useContacts } from 'src/apis/organisation-service/use-contacts';
import type { OrganisationDetailsRequest } from 'src/apis/organisation-service/use-organisation-details';
import { useOrganisationDetails } from 'src/apis/organisation-service/use-organisation-details';
import type { OrganisationServiceRequest } from 'src/apis/organisation-service/use-organisation-service';
import { PageSpinner } from 'src/components/spinner-container';
import type { Successful } from 'src/hooks/use-axios';
import { usePageParams } from 'src/pages/settings/organisation/contacts/use-page-params';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(() => import('./page'));

type Props = {
  organisationService: Successful<OrganisationServiceRequest>;
};

export const ContactsOverview: FC<Props> = ({ organisationService }) => {
  if (!organisationService.response.data._links.getMyOrganisationDetails)
    throw new Forbidden();

  const organisationDetails = useOrganisationDetails({
    url: organisationService.response.data._links.getMyOrganisationDetails.href,
  });

  if (!organisationDetails.response) return <PageSpinner />;

  return <Contacts organisationDetails={organisationDetails} />;
};

type ContactProps = {
  organisationDetails: Successful<OrganisationDetailsRequest>;
};

const Contacts: FC<ContactProps> = ({ organisationDetails }) => {
  if (!organisationDetails.response.data._links.contacts) throw new Forbidden();

  const pageParams = usePageParams();

  const params = useMemo(
    () =>
      ({
        limit: pageParams.value.pageSize,
        offset: pageParams.value.start,
      }) satisfies ContactsParams,
    [pageParams.value]
  );

  const contacts = useContacts({
    url: organisationDetails.response.data._links.contacts.href,
    params,
  });

  if (!contacts.response) return <PageSpinner />;

  return <Page contacts={contacts} pageParams={pageParams} />;
};
