import type { FC } from 'react';
import { lazy, useMemo } from 'react';
import type {
  ComfortBidsParams,
  ReverseAuctionsReportParams,
} from 'src/apis/monolith/types';
import { useAuctionTiles } from 'src/apis/monolith/use-auction-tiles';
import { useCalendars } from 'src/apis/monolith/use-calendars';
import { useOptionalDayAheadComfortBids } from 'src/apis/monolith/use-day-ahead-comfort-bids';
import { useReverseAuctions } from 'src/apis/monolith/use-reverse-auctions';
import { PageSpinner } from 'src/components/spinner-container';
import { useBidCollectorRoot } from 'src/pages/snam-bid-collector/use-bid-collector';
import { useTransportAuctionsPageParams } from 'src/pages/transport/auctions/use-page-params';

const Page = lazy(() => import('./page'));

export const AuctionsStart: FC = () => {
  const pageParams = useTransportAuctionsPageParams();

  const auctionTiles = useAuctionTiles();
  const snamBidCollector = useBidCollectorRoot();
  const calendars = useCalendars();

  const comfortBidsParams = useMemo(
    () =>
      ({
        pageSize: pageParams.comfortBids.value.pageSize,
        start: pageParams.comfortBids.value.start,
      }) satisfies ComfortBidsParams,
    [pageParams.comfortBids.value.pageSize, pageParams.comfortBids.value.start]
  );
  const comfortBids = useOptionalDayAheadComfortBids({
    params: comfortBidsParams,
  });

  const reverseAuctionsParams = useMemo<ReverseAuctionsReportParams>(
    () => ({
      pageSize: pageParams.reverseAuctions.value.pageSize,
      start: pageParams.reverseAuctions.value.start,
      sortColumn: pageParams.reverseAuctions.value.sortColumn,
      sortAscending: pageParams.reverseAuctions.value.sortDirection === 'asc',
    }),
    [
      pageParams.reverseAuctions.value.pageSize,
      pageParams.reverseAuctions.value.start,
      pageParams.reverseAuctions.value.sortColumn,
      pageParams.reverseAuctions.value.sortDirection,
    ]
  );
  const reverseAuctions = useReverseAuctions({ params: reverseAuctionsParams });

  if (
    !auctionTiles.response ||
    !snamBidCollector.response ||
    !calendars.response ||
    !reverseAuctions.response ||
    comfortBids?.response === null
  )
    return <PageSpinner />;

  return (
    <Page
      auctionTiles={auctionTiles}
      snamBidCollector={snamBidCollector}
      calendars={calendars}
      pageParams={pageParams}
      reverseAuctions={reverseAuctions}
      comfortBids={comfortBids}
    />
  );
};
