import { useEffect } from 'react';
import urlTemplate from 'url-template';
import type { InternalBookingResponse } from 'src/apis/capacity-management/types';
import type { InternalBookingsRequest } from 'src/apis/capacity-management/use-internal-bookings';
import { useAxios } from 'src/hooks/use-axios';

type HookConfig = {
  internalBookings: InternalBookingsRequest;
  oldNetworkPointId: string;
  bookingId: string;
};

type RequestOptions = {
  url: string;
};

export function useInternalBooking({
  internalBookings,
  oldNetworkPointId,
  bookingId,
}: HookConfig) {
  const internalBooking = useAxios(
    (axios, baseConfig, { url }: RequestOptions) =>
      axios.request<InternalBookingResponse>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!internalBookings.response) return;

    const url = urlTemplate
      .parse(internalBookings.response.data._links.instance.href)
      .expand({ oldNetworkPointId, bookingId });
    const options = { url };

    internalBooking.execute(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalBookings.response, bookingId, oldNetworkPointId]);

  return internalBooking;
}

export type InternalBookingRequest = ReturnType<typeof useInternalBooking>;
