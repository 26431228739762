import { useMemo } from 'react';
import type { AuthAccount } from 'src/hooks/use-accounts';
import { useSafeLocalStorage } from 'src/hooks/use-storage';
import { backendStage } from 'src/utils/backend-stage';

const cognitoStoragePrefix = `CognitoIdentityServiceProvider.${PRISMA_CONFIG.authentication.clientId}`;
const lastUserKey = `${cognitoStoragePrefix}.LastAuthUser`;

/**
 * Curious fact about the way Cognito controls the tokens in the local storage:
 * on stages that requite MFA the Cognito username (which is a UUID) is used as part of
 * the key while on the other stages the email is used. Sadly there is now way to automatically identify this.
 */
function chooseAccountProperty() {
  if (
    PRISMA_CONFIG.stage === 'prod' ||
    PRISMA_CONFIG.stage === 'int' ||
    (PRISMA_CONFIG.stage === 'local' && backendStage === 'int')
  )
    return 'cognitoUserName' as const;
  return 'email' as const;
}

const accountProperty = chooseAccountProperty();

/**
 * This hook manipulates Cognitos local storage. Usually Cognito does it on their own and it should be treated
 * as an implementation detail. But we need to do it manually in order to support a "switch accounts" feature which
 * is not supported by Cognito out of the box.
 *
 * It is highly likely that you don't need this hook at all and rather look for `useCognito` or `useAccounts`!
 */
export function useCognitoLastAuthUser() {
  const safeLocalStorage = useSafeLocalStorage();
  return useMemo(
    () => ({
      equals(account: AuthAccount | null) {
        const item = safeLocalStorage.getItem(lastUserKey);
        if (account) return account[accountProperty] === item;
        return item === account;
      },
      set(account: AuthAccount) {
        safeLocalStorage.setItem(lastUserKey, account[accountProperty]);
      },
      remove() {
        safeLocalStorage.removeItem(lastUserKey);
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
}

export type CognitoLastAuthUser = ReturnType<typeof useCognitoLastAuthUser>;
