import { z } from 'zod';
import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';

const auctionDetailsSchema = z.object({
  identifier: z.string().nullable().default(null),
});

export function useAuctionDetailsPageParams() {
  return usePageParamsFromSchema(auctionDetailsSchema);
}
export type AuctionDetailsPageParams = ReturnType<
  typeof useAuctionDetailsPageParams
>;
