import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import type { FC, InputHTMLAttributes, ReactNode, RefObject } from 'react';
import { useEffect } from 'react';
import type {
  BeforeMaskedStateChangeStates,
  InputState,
} from 'react-input-mask';
import InputMask from 'react-input-mask';
import styled from 'styled-components';
import type { InputTime } from 'src/components/form/datepicker/utils';
import {
  dateRegExp,
  dateTimeRegExp,
  extractDateFromString,
  getMask,
  getPlaceholder,
  toISOString,
} from 'src/components/form/datepicker/utils';
import { useFieldGroup } from 'src/components/form/field-group';
import { InnerAddon } from 'src/components/form/inner-addon';
import { SimpleInput } from 'src/components/form/input';
import { useFocusManager } from 'src/components/form/use-focus-manager';
import { Tooltip } from 'src/components/overlay/tooltip';

type DateInputProps = {
  time: InputTime;
  name: string;
  value: string;
  setUserValue: (value: string) => void;
  setActive: (active: boolean) => void;
  fieldId: string;
  showError: boolean;
  disabled?: boolean;
  disabledMessage?: ReactNode;
  referenceRef?: (value: HTMLElement | null) => void;
  validate: (value: string | null) => string | undefined;
  onTab?: () => void;
  isoValue: string | null;
} & InputHTMLAttributes<HTMLInputElement>;

type InputStateWithEnteredString = InputState & {
  enteredString: string;
};

function stateHasEnteredString(
  inputMask: InputState
): inputMask is InputStateWithEnteredString {
  return (inputMask as any)?.enteredString;
}

export const DateInputField: FC<DateInputProps> = ({
  time,
  name,
  value,
  setUserValue: setValue,
  setActive,
  fieldId,
  showError,
  disabled,
  disabledMessage,
  referenceRef,
  validate,
  onTab,
  isoValue,
  ...inputProps
}) => {
  const { setFieldValue, setFieldError, setFieldTouched } =
    useFormikContext<unknown>();

  const formGroup = useFieldGroup();
  useEffect(() => {
    if (formGroup) {
      formGroup.onError({ name, showError });
    }
  }, [showError, name, formGroup]);

  const focusRef = useFocusManager();

  // a fix for when users try to enter dates that are not first of month via e.g. copy & paste
  // in that case, we extract the date from the input and mask it manually
  // otherwise, entering e.g. 15.09.2020 would turn mask into 01.50.9202 due to mask shifting text
  // right in a desperate attempt to match it.
  const beforeMaskStateChangedHandler =
    time === 'gas-month'
      ? (evstates: BeforeMaskedStateChangeStates) => {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (evstates.nextState && stateHasEnteredString(evstates.nextState)) {
            const input = evstates.nextState.enteredString;
            const datePart = extractDateFromString(input);
            if (datePart) {
              const firstDayOfMonth = `01${datePart.substring(2)} | 06:00`;
              evstates.nextState.value = firstDayOfMonth;
            }
          }
          return evstates.nextState;
        }
      : undefined;

  return (
    <InnerAddon
      showError={showError}
      disabled={disabled}
      right={
        <FontAwesomeIcon
          icon={faCalendarAlt}
          aria-hidden="true"
          style={{ fontSize: 12 }}
        />
      }
    >
      {({ rightBounds }) => (
        <div ref={referenceRef}>
          <Tooltip
            content={disabled && disabledMessage ? disabledMessage : undefined}
          >
            {(targetProps) => (
              <InputMask
                mask={getMask(time)}
                maskPlaceholder={getPlaceholder(time)}
                value={value}
                beforeMaskedStateChange={beforeMaskStateChangedHandler}
                onChange={(event) => {
                  // note: InputMask also triggers change events on focus and blur
                  if (event.type === 'focus' || event.type === 'blur') return;

                  setValue(event.currentTarget.value);
                  // flag field as touched even though we haven't changed the _real_
                  // value (behind `setFieldValue`), but the the mask value (behind `setValue`)
                  setFieldTouched(name, true, false);

                  const enteredPlaceholder =
                    event.currentTarget.value === getPlaceholder(time);

                  if (
                    !validate(event.currentTarget.value) &&
                    !enteredPlaceholder
                  ) {
                    // user entered a valid date
                    setFieldError(name, undefined);
                    setFieldValue(
                      name,
                      toISOString(event.currentTarget.value),
                      true
                    );
                  } else {
                    // validate in case it is the placeholder, to trigger potential required validation
                    setFieldValue(name, null, enteredPlaceholder);
                    const fullInput = event.currentTarget.value.match(
                      time ? dateTimeRegExp : dateRegExp
                    );
                    if (fullInput) {
                      // user entered a "full", but invalid date
                      setFieldError(
                        name,
                        'Please enter a valid date for {label}.'
                      );
                    } else {
                      // user entered a partial date or nothing
                      setFieldError(name, undefined);
                    }
                  }
                }}
                onFocus={() => setActive(true)}
                onKeyDown={(e) => {
                  if (e.key === 'Tab' && onTab) {
                    onTab();
                  }
                }}
                {...inputProps}
                onBlur={(event) => {
                  inputProps.onBlur?.(event);
                  // for partially filled out inputs (e.g. time === "gas-month") we remove
                  // the value completely on blur when it matches the placeholder
                  if (getPlaceholder(time) === value) {
                    setValue('');
                  }
                }}
                disabled={disabled}
                {...targetProps}
              >
                <SimpleInput
                  ref={focusRef as RefObject<HTMLInputElement>}
                  id={fieldId}
                  error={showError}
                  name={name}
                  placeholder={getPlaceholder(time)}
                  data-test-error={showError}
                  data-testid={name}
                  rightBounds={rightBounds}
                  data-test-iso-value={isoValue}
                />
              </InputMask>
            )}
          </Tooltip>
        </div>
      )}
    </InnerAddon>
  );
};

export const ErrorWrapper = styled.div`
  margin-top: 1rem;
`;
