import { useEffect } from 'react';
import type { TraderList } from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';

export function useTraderLists() {
  const traderLists = useAxios(
    (axios, baseConfig) =>
      axios.request<TraderList[]>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/shippers/traderLists`,
      }),
    {
      neededOnPageLoad: true,
    }
  );

  useEffect(() => {
    traderLists.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return traderLists;
}

export type TsoConfigRequest = ReturnType<typeof useTraderLists>;
