import { useEffect } from 'react';
import type { AssignmentsResponse } from 'src/apis/snam-bid-collector/types';
import { useAxios } from 'src/hooks/use-axios';
import type { BidCollectorRootRequest } from 'src/pages/snam-bid-collector/use-bid-collector';

type RequestOptions = {
  url: string;
};

export function useAssignments(bidCollectorRoot: BidCollectorRootRequest) {
  const getAssignmentsRequest = useAxios(
    (axios, baseConfig, { url }: RequestOptions) =>
      axios.request<AssignmentsResponse>({
        url,
        ...baseConfig,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (
      !bidCollectorRoot.response ||
      !bidCollectorRoot.response.data._links.getAssignments
    ) {
      return;
    }

    getAssignmentsRequest.execute({
      url: bidCollectorRoot.response.data._links.getAssignments.href,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bidCollectorRoot.response]);

  return getAssignmentsRequest;
}

export type AssignmentsRequest = ReturnType<typeof useAssignments>;
