import { useEffect } from 'react';
import type { NetworkPointDetail } from 'src/apis/monolith/types';
import { isServerError, useAxios } from 'src/hooks/use-axios';

export type NetworkPointParams = {
  'include-links'?: boolean;
};

type Options = {
  uuid: string;
  params?: NetworkPointParams;
};

export function useNetworkPointByUuid({ uuid, params }: Options) {
  const networkPoint = useAxios(
    (axios, baseConfig, id: string, params?: NetworkPointParams) => {
      return axios.request<NetworkPointDetail>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/networkPoints/by-uuid/${id}`,
        method: 'GET',
        params,
      });
    },
    {
      neededOnPageLoad: true,
      onError(error) {
        if (isServerError(error, 404)) {
          return error;
        }
      },
    }
  );

  useEffect(() => {
    if (!uuid) return;

    networkPoint.execute(uuid, params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid, params]);

  return networkPoint;
}

export type NetworkPointDetailRequest = ReturnType<
  typeof useNetworkPointByUuid
>;
