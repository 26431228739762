import { useEffect } from 'react';
import urlTemplate from 'url-template';
import type { OperatorResponse } from 'src/apis/contract-management/types';
import { isServerError, useAxios } from 'src/hooks/use-axios';

type HookConfig = {
  operatorUrl: string;
  operatorUuid: string;
};

type RequestOptions = {
  operatorUuid: string;
};

export function useOperator({ operatorUrl, operatorUuid }: HookConfig) {
  const operator = useAxios(
    (axios, baseConfig, { operatorUuid }: RequestOptions) => {
      const url = urlTemplate
        .parse(operatorUrl)
        .expand({ operatorId: operatorUuid });

      return axios.request<OperatorResponse>({
        ...baseConfig,
        url,
      });
    },
    {
      neededOnPageLoad: true,
      onError: (err) => {
        if (isServerError(err, 404)) {
          return err;
        } else {
          throw err;
        }
      },
    }
  );

  useEffect(() => {
    operator.execute({ operatorUuid });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return operator;
}

export type OperatorRequest = ReturnType<typeof useOperator>;
