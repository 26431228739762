import { useEffect } from 'react';
import type { Address } from 'src/apis/contract-management/types';
import { useAxios } from 'src/hooks/use-axios';

export function useShipperAddress({ url }: { url?: string }) {
  const shipperAddress = useAxios(
    (axios, baseConfig) =>
      axios.request<Address>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!url) return;
    shipperAddress.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  if (!url) return;

  return shipperAddress;
}

export type ShipperAddressRequest = ReturnType<typeof useShipperAddress>;
