import type { DialogProps } from '@reach/dialog';
import type { FC, ReactNode } from 'react';
import { useCallback, useState } from 'react';
import type { StrictOmit } from 'src/utils/utility-types';

type ModalProps = DialogProps & {
  close: () => void;
};

/**
 * @deprecated Please use `useModal2` instead.
 */
export function useModal<T>(
  Component: FC<T & ModalProps>,
  props: StrictOmit<T & ModalProps, 'close'>
) {
  const [isOpen, setIsOpen] = useState(false);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  const modalProps = { ...props, close } as T & ModalProps;

  return {
    modal: isOpen ? <Component {...modalProps} /> : null,
    open,
    close,
  };
}

type ModalApi = {
  close: () => void;
};

type TriggerProps = {
  onClick: () => void;
};

type ModalProps2 = {
  modal: (props: ModalApi) => ReactNode;
  trigger: (props: TriggerProps) => ReactNode;
};

export function useModal2<T extends ModalProps2 | null>(
  props: T
): T extends null ? null : ModalHookReturn {
  const [isOpen, setIsOpen] = useState(false);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  if (props === null) return null as ModalHookOptionalReturn<T>;

  return {
    modal: isOpen ? props.modal({ close }) : null,
    open,
    close,
    trigger: props.trigger({ onClick: open }),
  } as ModalHookOptionalReturn<T>;
}

type ModalHookOptionalReturn<T> = T extends null ? null : ModalHookReturn;

type ModalHookReturn = {
  modal: JSX.Element;
  open: () => void;
  close: () => void;
  trigger: JSX.Element;
};
