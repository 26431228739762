import type { FC } from 'react';
import { lazy } from 'react';
import { useParams } from 'react-router-dom';
import type { OrganisationServicePublicRequest } from 'src/apis/organisation-service/use-organisation-service-public';
import { useOrganisationServicePublic } from 'src/apis/organisation-service/use-organisation-service-public';
import { usePublicOperatorDetails } from 'src/apis/organisation-service/use-public-operator-details';
import type {
  ExternalSecondaryTradeDetails,
  RemitReport,
  RemitReportingEntrypoint,
} from 'src/apis/remit-reporting/types';
import { useRemitReportingEntrypoint } from 'src/apis/remit-reporting/use-remit-reporting-entrypoint';
import { PageSpinner } from 'src/components/spinner-container';
import type { Successful } from 'src/hooks/use-axios';
import { useExternalTrade } from 'src/pages/reporting/secondary-tradings/external-trades/details/use-external-trade';
import { useRemitReportHistory } from 'src/pages/reporting/secondary-tradings/overview/remit-reports/use-remit-report-history';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(() => import('./page'));

export const ExternalTradeDetails: FC = () => {
  const remitReportingEntrypoint = useRemitReportingEntrypoint();

  if (!remitReportingEntrypoint.response) {
    return <PageSpinner />;
  }

  if (!remitReportingEntrypoint.response.data._links?.['get-external-trade']) {
    throw new Forbidden();
  }

  return (
    <ExternalTradeDetailsWithTradeDetails
      remitReportingEntrypoint={remitReportingEntrypoint.response.data}
    />
  );
};

const ExternalTradeDetailsWithTradeDetails: FC<{
  remitReportingEntrypoint: RemitReportingEntrypoint;
}> = ({ remitReportingEntrypoint }) => {
  const { externalTradeId } = useParams<{
    externalTradeId: string;
  }>();
  const externalTradeRequest = useExternalTrade(externalTradeId);
  const organisationService = useOrganisationServicePublic();

  if (!externalTradeRequest.response || !organisationService.response) {
    return <PageSpinner />;
  }

  return remitReportingEntrypoint._links?.['secondary-report-history'] ? (
    <ExternalTradeDetailsWithRemitHistory
      externalTradeId={externalTradeId}
      externalTrade={externalTradeRequest.response.data}
      organisationService={organisationService}
      remitReportingEntrypoint={remitReportingEntrypoint}
    />
  ) : (
    <ExternalTradeDetailsWithOperatorDetails
      externalTradeId={externalTradeId}
      externalTrade={externalTradeRequest.response.data}
      organisationService={organisationService}
      remitReportingEntrypoint={remitReportingEntrypoint}
    />
  );
};

const ExternalTradeDetailsWithRemitHistory: FC<{
  externalTradeId: string;
  externalTrade: ExternalSecondaryTradeDetails;
  organisationService: Successful<OrganisationServicePublicRequest>;
  remitReportingEntrypoint: RemitReportingEntrypoint;
}> = ({
  externalTradeId,
  externalTrade,
  organisationService,
  remitReportingEntrypoint,
}) => {
  const remitReportHistoryRequest = useRemitReportHistory(externalTradeId);

  if (!remitReportHistoryRequest.response) {
    return <PageSpinner />;
  }

  return (
    <ExternalTradeDetailsWithOperatorDetails
      remitReportingEntrypoint={remitReportingEntrypoint}
      externalTradeId={externalTradeId}
      externalTrade={externalTrade}
      organisationService={organisationService}
      remitReportHistory={remitReportHistoryRequest.response.data}
    />
  );
};

const ExternalTradeDetailsWithOperatorDetails: FC<{
  externalTradeId: string;
  remitReportHistory?: RemitReport[];
  externalTrade: ExternalSecondaryTradeDetails;
  organisationService: Successful<OrganisationServicePublicRequest>;
  remitReportingEntrypoint: RemitReportingEntrypoint;
}> = ({
  externalTradeId,
  remitReportHistory,
  externalTrade,
  organisationService,
  remitReportingEntrypoint,
}) => {
  const exitOrganisationId = externalTrade.exitTradeInfo?.tsoOrganisationId;
  const exitDetail = usePublicOperatorDetails({
    organisationServicePublic: organisationService,
    operatorUuid: exitOrganisationId,
  });

  const entryOrganisationId = externalTrade.entryTradeInfo?.tsoOrganisationId;
  const entryDetail = usePublicOperatorDetails({
    organisationServicePublic: organisationService,
    operatorUuid: entryOrganisationId,
  });

  if (
    (exitOrganisationId && !exitDetail.response) ||
    (entryOrganisationId && !entryDetail.response)
  ) {
    return <PageSpinner />;
  }

  return (
    <Page
      externalTradeId={externalTradeId}
      externalTrade={externalTrade}
      remitReportHistory={remitReportHistory}
      exitDetail={exitDetail.response?.data}
      entryDetail={entryDetail.response?.data}
      remitReportingEntrypoint={remitReportingEntrypoint}
    />
  );
};
