import { useEffect } from 'react';
import type { AuctionDetail } from 'src/apis/monolith/types';
import { isServerError, useAxios } from 'src/hooks/use-axios';
import { NotFound } from 'src/utils/errors';

type HookConfig = {
  auctionId: number | string;
};

type RequestOptions = {
  auctionId: number | string;
};

export function useAuction({ auctionId }: HookConfig) {
  const auction = useAxios(
    (axios, baseConfig, { auctionId }: RequestOptions) => {
      return axios.request<AuctionDetail>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/auctions/${auctionId}`,
        validateStatus(status) {
          // if we get 204 the auction is in a PREOPEN state
          // and does not belong to the current user
          // this can be treated like a "404 Not Found" error
          if (status === 204) return false;

          return status >= 200 && status < 300; // default
        },
      });
    },
    {
      neededOnPageLoad: true,
      onError(error) {
        if (
          isServerError(error, 204) || // see `validateStatus`
          isServerError(error, 500) // sadly we get a 500 in case of an unknown id
        ) {
          throw new NotFound();
        } else {
          throw error;
        }
      },
    }
  );

  useEffect(() => {
    const options = { auctionId };
    auction.execute(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auctionId]);

  return auction;
}

export type AuctionRequest = ReturnType<typeof useAuction>;
