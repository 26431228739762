import { useEffect } from 'react';
import type { AuctionConfig } from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';

type Options = {
  auctionId: string;
  orderId?: string;
};

type Params = {
  auctionId: string;
  orderId?: string;
};

export function useAuctionConfig({ auctionId, orderId }: Options) {
  const auctionConfig = useAxios(
    (axios, baseConfig, { auctionId, orderId }: Params) =>
      axios.request<AuctionConfig>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/auctions/${auctionId}/config`,
        params: { orderId },
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    auctionConfig.execute({ auctionId, orderId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auctionId, orderId]);

  return auctionConfig;
}

export type AuctionConfigRequest = ReturnType<typeof useAuctionConfig>;
