import { useEffect } from 'react';
import type {
  OrganisationAssignmentResponse,
  OrganisationAssignmentsResponse,
  AssignmentsOrganisationItem,
  MarketType,
} from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';
import type { DeepReadonly } from 'src/utils/deep-readonly';

export const mapAssignedOperatorsResponseToOrganisationAssignmentItems = (
  assignedOperators: DeepReadonly<OrganisationAssignmentResponse[]>
): AssignmentsOrganisationItem[] => {
  return assignedOperators.map((assignedOperator) => ({
    organisationId: assignedOperator.tso.id,
    organisationName: assignedOperator.tso.name,
    status: assignedOperator.state,
    logoUrl: `${PRISMA_CONFIG.monolithUrl}${assignedOperator.tso.logoSmallUrl}`,
    marketAccess:
      assignedOperator.state !== 'APPROVED'
        ? undefined
        : ([
            ...(assignedOperator.primaryAccess ? ['PRIMARY'] : []),
            ...(assignedOperator.secondaryAccess ? ['SECONDARY'] : []),
          ] as MarketType),
  }));
};

/**
 * Requests assignment data between a single shipper organisation and all operators.
 */
export function useOrganisationAssignments() {
  const assignments = useAxios(
    (axios, baseConfig) =>
      axios.request<OrganisationAssignmentsResponse>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/shippers/assigned-operators`,
        method: 'GET',
      }),
    { neededOnPageLoad: false }
  );

  useEffect(() => {
    assignments.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return assignments;
}

export type OrganisationAssignmentsRequest = ReturnType<
  typeof useOrganisationAssignments
>;
