import type { FC } from 'react';
import type { Relation } from 'src/apis/contract-management/types';
import type { RelationsRequest } from 'src/apis/contract-management/use-operator-relations';
import { TableActions } from 'src/components/buttons-and-actions/table-actions';
import { EmptyValue } from 'src/components/data-display/empty-value';
import { FormatDate } from 'src/components/data-display/format-date';
import { Table } from 'src/components/data-display/smart-table';
import { Td, Th } from 'src/components/data-display/table';
import { Link } from 'src/components/navigation/link';
import type { Successful } from 'src/hooks/use-axios';
import { DownloadDectOfferDocument } from 'src/pages/operators/assignments/details/components/contract-buttons/dect-offer-download';
import { DownloadFmctOfferDocument } from 'src/pages/operators/assignments/details/components/contract-buttons/fmct-offer-download';
import { contractTypeLabels } from 'src/pages/operators/assignments/details/components/contract-type-labels';
import { ContractStatusDisplay } from 'src/pages/operators/assignments/details/components/contracts-status-badge';

const ContractActions: FC<{
  relation: Relation;
  tsoId: string;
  relations: Successful<RelationsRequest>;
}> = ({ relation, tsoId, relations }) => {
  const isDectOffer =
    relation.contractType === 'DECT' &&
    (relation.status === 'CREATED' ||
      relation.status === 'SUBMITTED' ||
      relation.status === 'WITHDRAWN');
  const showDectOffer = isDectOffer && relation.status !== 'WITHDRAWN';

  const isFmctOffer =
    relation.contractType === 'FMCT' &&
    (relation.status === 'PENDING' || relation.status === 'REJECTED');
  const showFmctOffer = isFmctOffer && relation.status !== 'REJECTED';

  return (
    <TableActions testId={relation.id}>
      <Link
        data-testid={`actions-link-${relation.id}`}
        to={`/operators/assignments/operators/${tsoId}/contract/${relation.contractType.toLowerCase()}${
          isDectOffer || isFmctOffer ? '-offer' : ''
        }/${relation.id}/details`}
      >
        Details
      </Link>

      {showFmctOffer ? (
        <DownloadFmctOfferDocument
          relation={relation}
          // fmct offer status changed -> refresh
          onError={() => relations.refresh?.()}
        />
      ) : null}

      {showDectOffer ? (
        <DownloadDectOfferDocument
          dectData={relation}
          label="Download"
          // dect offer status changed -> refresh
          onError={() => relations.refresh?.()}
        />
      ) : null}
    </TableActions>
  );
};

type Props = {
  relations: Successful<RelationsRequest>;
  tsoId: string;
};

export const ContractsTable: FC<Props> = ({ relations, tsoId }) => {
  return (
    <Table
      pending={relations.pending}
      paginated={{
        ...relations.response.data,
        paramPrefix: 'relations',
      }}
      data={relations.response.data._embedded.items ?? []}
      cols={[
        {
          key: 'assignedId',
          head: <Th>Contract ID</Th>,
          body: (data) => (
            <Td data-testid={`assignedId-${data.id}`}>{data.assignedId}</Td>
          ),
        },
        {
          key: 'startDate',
          head: <Th>Start</Th>,
          body: (data) => (
            <Td data-testid={`startDate-${data.id}`}>
              <FormatDate value={data.startDate} type="date-time" />
            </Td>
          ),
        },
        {
          key: 'endDate',
          head: <Th>End</Th>,
          body: (data) => (
            <Td data-testid={`endDate-${data.id}`}>
              {data.endDate ? (
                <FormatDate value={data.endDate} type="date-time" />
              ) : (
                <EmptyValue label="Indefinite" />
              )}
            </Td>
          ),
        },
        {
          key: 'contractType',
          head: <Th>Contract Type</Th>,
          body: (data) => (
            <Td data-testid={`contractType-${data.id}`}>
              {contractTypeLabels[data.contractType]}
            </Td>
          ),
        },
        {
          key: 'status',
          head: <Th>Status</Th>,
          body: (data) => (
            <Td data-testid={`status-${data.id}`}>
              <ContractStatusDisplay status={data.status} />
            </Td>
          ),
        },
        {
          key: 'actions',
          width: '10rem',
          head: <Th align="center">Actions</Th>,
          body: (data) => (
            <Td data-testid={`actions-${data.id}`} align="center">
              <ContractActions
                relation={data}
                tsoId={tsoId}
                relations={relations}
              />
            </Td>
          ),
        },
      ]}
      empty={{
        label: 'No contracts have been found.',
      }}
    />
  );
};
