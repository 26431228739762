import { useEffect, useMemo } from 'react';
import urlTemplate from 'url-template';
import type { MyTransactionsRequest } from 'src/apis/capacity-management/use-my-transactions';
import type {
  DealResponse,
  TradeResponse,
} from 'src/apis/surrender-service/types';
import type { SurrenderRootRequest } from 'src/apis/surrender-service/use-surrender-root';
import { useAxios } from 'src/hooks/use-axios';

export function useGetSurrenderActions(
  myTransactionsRequest: MyTransactionsRequest,
  surrenderRootRequest: SurrenderRootRequest
) {
  const trades = useAxios(
    (axios, baseConfig, { url }: { url: string }) => {
      return axios.request<TradeResponse>({
        url,
        ...baseConfig,
      });
    },
    {
      neededOnPageLoad: false,
    }
  );

  const deals = useAxios(
    (axios, baseConfig, { url }: { url: string }) => {
      return axios.request<DealResponse>({
        url,
        ...baseConfig,
      });
    },
    {
      neededOnPageLoad: false,
    }
  );

  // Secondary
  const tradesUrl = useMemo(() => {
    if (
      !myTransactionsRequest.response ||
      !surrenderRootRequest.response ||
      !myTransactionsRequest.response.data._embedded ||
      !surrenderRootRequest.response.data._links.getTrades
    ) {
      return;
    }

    const trades = myTransactionsRequest.response.data._embedded.items.filter(
      (transaction) =>
        transaction.transactionType === 'ASSIGNMENT_BUY' ||
        transaction.transactionType === 'TRANSFER_OF_USE_BUY' ||
        transaction.transactionType === 'ASSIGNMENT_SELL' ||
        transaction.transactionType === 'TRANSFER_OF_USE_SELL'
    );

    const tradeIds = trades.map((trade) => trade.tradeId);

    if (tradeIds.length === 0) {
      return;
    }

    return urlTemplate
      .parse(surrenderRootRequest.response.data._links.getTrades.href)
      .expand({ tradeId: tradeIds, limit: tradeIds.length });
  }, [myTransactionsRequest.response, surrenderRootRequest.response]);

  useEffect(() => {
    if (!tradesUrl) {
      return;
    }

    trades.execute({ url: tradesUrl });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradesUrl]);

  // Primary
  const dealsUrl = useMemo(() => {
    if (
      !myTransactionsRequest.response ||
      !surrenderRootRequest.response ||
      !myTransactionsRequest.response.data._embedded ||
      !surrenderRootRequest.response.data._links.getDeals
    ) {
      return;
    }

    const deals = myTransactionsRequest.response.data._embedded.items.filter(
      (transaction) =>
        transaction.transactionType === 'AUCTION' ||
        transaction.transactionType === 'MULTI_YEAR_TRANSPORT_ALLOCATION'
    );

    const dealIds = deals.map((deal) =>
      // bundles are not relevant for surrenders, so it's okay to use on or the other here
      // https://prisma.atlassian.net/browse/TS-318?focusedCommentId=74896
      deal.transactionType === 'AUCTION'
        ? deal.entry?.auctionAllocationId || deal.exit?.auctionAllocationId
        : deal.exit?.multiYearTransportAllocationId ||
          deal.entry?.multiYearTransportAllocationId
    );

    if (dealIds.length === 0) {
      return;
    }

    return urlTemplate
      .parse(surrenderRootRequest.response.data._links.getDeals.href)
      .expand({ auctionAllocationId: dealIds, limit: dealIds.length });
  }, [myTransactionsRequest.response, surrenderRootRequest.response]);

  useEffect(() => {
    if (!dealsUrl) {
      return;
    }

    deals.execute({ url: dealsUrl });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealsUrl]);

  return { trades, deals };
}

export type SurrenderActionsRequests = ReturnType<
  typeof useGetSurrenderActions
>;

export const isTradeSurrenderable = (
  surrenderActionsRequest: SurrenderActionsRequests,
  tradeId: string
) => {
  const trade =
    surrenderActionsRequest.trades.response?.data._embedded.items.find(
      (item) => item.tradeId === tradeId
    );
  return Boolean(trade?._links.createSurrenderRequest);
};

export const isDealSurrenderable = (
  surrenderActionsRequest: SurrenderActionsRequests,
  dealId: string
) => {
  const deal =
    surrenderActionsRequest.deals.response?.data._embedded.items.find(
      (item) => item.dealId === dealId
    );
  return Boolean(deal?._links.createSurrenderRequest);
};
