import { useEffect } from 'react';
import urlTemplate from 'url-template';
import type { Uuid } from 'src/apis/api-utilities';
import type { PermissionGroupDetailsResponse } from 'src/apis/authorization-service/types';
import type { PermissionGroupsRequest } from 'src/apis/authorization-service/use-permission-groups';
import type { Successful } from 'src/hooks/use-axios';
import { useAxios } from 'src/hooks/use-axios';

type HookProps = {
  permissionGroups?: PermissionGroupsRequest;
  permissionGroupId: Uuid;
};

type ExecuteProps = {
  permissionGroups: Successful<PermissionGroupsRequest>;
  permissionGroupId: Uuid;
};

export function usePermissionGroup({
  permissionGroups,
  permissionGroupId,
}: HookProps) {
  const request = useAxios(
    (
      axios,
      baseConfig,
      { permissionGroups, permissionGroupId }: ExecuteProps
    ) => {
      const url = urlTemplate
        .parse(permissionGroups.response.data._links.instance.href)
        .expand({ permissionGroupId });

      return axios.request<PermissionGroupDetailsResponse>({
        ...baseConfig,
        url,
      });
    },
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!permissionGroups?.response) return;
    request.execute({ permissionGroups, permissionGroupId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionGroups?.response, permissionGroupId]);

  if (!permissionGroups) return;

  return request;
}

export type PermissionGroupRequest = ReturnType<typeof usePermissionGroup>;
