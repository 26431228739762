import { memo } from 'react';
import type { FinancialSecurityStatusValue } from 'src/apis/contract-management/types';
import type { StatusProps } from 'src/components/data-display/status-display';
import { StatusDisplay } from 'src/components/data-display/status-display';
import { Colors } from 'src/styles';

const financialSecurityStatusMap: Record<
  FinancialSecurityStatusValue,
  Pick<StatusProps, 'iconColor' | 'status'>
> = {
  APPROVED: {
    iconColor: Colors.success,
    status: 'Approved',
  },
  SUBMITTED: {
    iconColor: Colors.warning,
    status: 'Submitted',
  },
  WITHDRAWN: {
    iconColor: Colors.error,
    status: 'Withdrawn',
  },
  REJECTED: {
    iconColor: Colors.error,
    status: 'Rejected',
  },
  RELEASE_PENDING: {
    iconColor: Colors.error,
    status: 'Release Pending',
  },
  RELEASED: {
    iconColor: Colors.error,
    status: 'Released',
  },
  REVOKED: {
    iconColor: Colors.error,
    status: 'Revoked',
  },
  ENFORCED: {
    iconColor: Colors.error,
    status: 'Enforced',
  },
};

export const FinancialSecurityStatusDisplay = memo<{
  value: FinancialSecurityStatusValue;
}>(({ value }) => {
  return (
    <StatusDisplay
      {...financialSecurityStatusMap[value]}
      data-testid="financial-security-status"
    />
  );
});
