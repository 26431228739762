import type { ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  gap?: number | [row: number, column: number];
  alignItems?: 'center' | 'baseline' | 'stretch';
  justifyContent?: 'normal' | 'space-between' | 'end';
  flexDirection?: 'column' | 'row';
  children: ReactNode;
};

export const GroupWrap = styled.div<Props>`
  display: flex;
  flex-direction: ${({ flexDirection = 'row' }) => flexDirection};
  flex-wrap: wrap;
  gap: ${({ gap = 0 }) =>
    Array.isArray(gap) ? `${gap[0]}rem ${gap[1]}rem` : `${gap}rem`};
  align-items: ${({ alignItems = 'center' }) => alignItems};
  justify-content: ${({ justifyContent = 'normal' }) => justifyContent};
`;
