import type { FC } from 'react';
import { FormatDate } from 'src/components/data-display/format-date';
import { StatusDisplay } from 'src/components/data-display/status-display';
import { Alert } from 'src/components/feedback/alert';
import { Stack } from 'src/components/layout/stack';
import { Heading } from 'src/components/text/heading';
import { Colors } from 'src/styles';

export const Runtime: FC<{
  start: string;
  end: string;
}> = ({ start, end }) => {
  return (
    <>
      <FormatDate value={start} type="date-time" /> -{' '}
      <FormatDate value={end} type="date-time" />
    </>
  );
};

export const Disclaimer: FC<{}> = () => {
  return (
    <Alert
      type="info"
      title="When using this form to report external trades, please be aware that:"
      data-testid="external-trade-disclaimer"
    >
      <Stack gap={2}>
        <p>
          PRISMA examines all trade reports to ensure completeness and, provided
          that no omissions or obvious errors are detected, forwards the trade
          data on the form to the designated recipients.
        </p>
        <p>
          However, PRISMA does not exert any control over the trade data written
          on this form. So, the sole responsibility for the accuracy and
          truthfulness of the content of the trade data entered lies with the
          customer on whose behalf the report is to be sent.
        </p>
      </Stack>
    </Alert>
  );
};

export const DisclaimerInfo: FC<{}> = () => {
  return (
    <Stack gap={2} data-testid="external-trade-disclaimer-info">
      <Heading mode="card">
        When using this form to report external trades, please be aware that:
      </Heading>
      <p>
        PRISMA examines all trade reports to ensure completeness and, provided
        that no omissions or obvious errors are detected, forwards the trade
        data on the form to the designated recipients.
      </p>
      <p>
        However, PRISMA does not exert any control over the trade data written
        on this form. So, the sole responsibility for the accuracy and
        truthfulness of the content of the trade data entered lies with the
        customer on whose behalf the report is to be sent.
      </p>
    </Stack>
  );
};

export const ExternalTradeStatus: FC<{ cancelled: boolean }> = ({
  cancelled,
}) => (
  <StatusDisplay
    iconColor={cancelled ? Colors.error : Colors.success}
    status={cancelled ? 'Discarded' : 'Created'}
  />
);
