import type { ShipperAssignmentStatus } from 'src/apis/assignment-service/types';
import { labelsToOptions } from 'src/utils/labels-to-options';

export type TradingMarket = 'PRIMARY' | 'SECONDARY';

const tradingMarketsLabels: Record<TradingMarket, string> = {
  PRIMARY: 'Primary Market',
  SECONDARY: 'Secondary Market',
};

export const shipperAssignmentStatusLabels: Record<
  ShipperAssignmentStatus,
  string
> = {
  APPROVED: 'Approved',
  WAITING_FOR_APPROVAL: 'Waiting for Approval',
  TEMPORARILY_DISABLED: 'Temporarily Disabled',
  CANCELLED: 'Cancelled',
};

export const shipperAssignmentStatusOptions = labelsToOptions(
  shipperAssignmentStatusLabels
);

export const tradingMarketsOptions = labelsToOptions(tradingMarketsLabels);
