import type { FC } from 'react';
import { useMemo } from 'react';
import { countries } from 'src/pages/settings/registration/components/countries';

export const Country: FC<{ code: string }> = ({ code }) => {
  const country = useMemo(() => {
    const found = countries.find((country) => country.value === code);
    if (found) {
      return found.label;
    } else {
      return code;
    }
  }, [code]);
  return <>{country}</>;
};
