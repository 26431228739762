import { useEffect } from 'react';
import type { SecondaryConfig } from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';

export type SecondaryConfigParams = {
  networkPointId: number;
};

export function useSecondaryConfig({ networkPointId }: SecondaryConfigParams) {
  const secondaryConfig = useAxios(
    (axios, baseConfig, params: SecondaryConfigParams) =>
      axios.request<SecondaryConfig>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/proposal/tsoConfig`,
        params,
      }),
    {
      neededOnPageLoad: true,
    }
  );

  useEffect(() => {
    secondaryConfig.execute({ networkPointId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkPointId]);

  return secondaryConfig;
}

export type SecondaryConfigRequest = ReturnType<typeof useSecondaryConfig>;
