import { useEffect } from 'react';
import urlTemplate from 'url-template';
import type {
  ContractReportsParams,
  ContractReportsResponse,
} from 'src/apis/aggregate-eu/types';
import type { Link } from 'src/apis/api-utilities';
import { useAxios } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';

type HookConfig = {
  contractsLink?: Link;
  params?: ContractReportsParams;
};

export function useGetContractReports({ params, contractsLink }: HookConfig) {
  const contracts = useAxios(
    (
      axios,
      baseConfig,
      params: {
        params?: ContractReportsParams;
      }
    ) => {
      if (!contractsLink) throw new Forbidden();

      const url = urlTemplate.parse(contractsLink.href).expand({});
      return axios.request<ContractReportsResponse>({
        ...baseConfig,
        url,
        params: params.params,
      });
    },
    {
      neededOnPageLoad: true,
      onError(error) {
        if (error instanceof Forbidden) {
          return error;
        } else {
          throw error;
        }
      },
    }
  );

  useEffect(() => {
    const options = { params };

    contracts.execute(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return contracts;
}

export type ContractsRequest = ReturnType<typeof useGetContractReports>;
