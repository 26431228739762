import { useEffect } from 'react';
import urlTemplate from 'url-template';
import type {
  MyAssignmentsParams,
  MyAssignmentsResponse,
} from 'src/apis/assignment-service/types';
import type { AssignmentServiceRequest } from 'src/apis/assignment-service/use-assignment-service-root';
import type { Successful } from 'src/hooks/use-axios';
import { useAxios } from 'src/hooks/use-axios';

type Props = {
  assignmentService: Successful<AssignmentServiceRequest>;
  operatorId?: string | null;
};

type HookProps = {
  url: string;
} & MyAssignmentsParams;

export function useMyAssignmentsOptional({
  assignmentService,
  operatorId,
}: Props) {
  const myAssignments = useAxios(
    (axios, baseConfig, { url, ...params }: HookProps) =>
      axios.request<MyAssignmentsResponse>({
        ...baseConfig,
        url,
        params,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!assignmentService.response.data._links.getMyAssignments || !operatorId)
      return;

    const url = assignmentService.response.data._links.getMyAssignments.href;
    const templatedUrl = urlTemplate.parse(url).expand({});

    myAssignments.execute({
      url: templatedUrl,
      operatorId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentService.response, operatorId]);

  if (!assignmentService.response.data._links.getMyAssignments || !operatorId)
    return;

  return myAssignments;
}

export type MyAssignmentsOptionalRequest = ReturnType<
  typeof useMyAssignmentsOptional
>;

export function useManualMyAssignments() {
  const myAssignments = useAxios(
    (axios, baseConfig, { url, ...params }: HookProps) =>
      axios.request<MyAssignmentsResponse>({
        ...baseConfig,
        url,
        params,
      }),
    { neededOnPageLoad: false }
  );

  return myAssignments;
}

export type ManualMyAssignmentsRequest = ReturnType<
  typeof useManualMyAssignments
>;
