import { useEffect } from 'react';
import urlTemplate from 'url-template';
import type { OperatorResponse } from 'src/apis/capacity-management/types';
import { useCapacityManagement } from 'src/apis/capacity-management/use-capacity-management';
import type { TsoRequest } from 'src/apis/monolith/use-tso';
import { isServerError, useAxios } from 'src/hooks/use-axios';

type HookConfig = {
  // operatorNominationLink?: Link;
  // operatorId: string;
  tso: TsoRequest;
};

type RequestOptions = {
  url: string;
};

export function useOperatorByTso({ tso }: HookConfig) {
  const capacityManagement = useCapacityManagement();
  const operatorLink = capacityManagement?._links?.operator;

  const operator = useAxios(
    (axios, baseConfig, { url }: RequestOptions) =>
      axios.request<OperatorResponse>({
        ...baseConfig,
        url,
      }),
    {
      neededOnPageLoad: true,
      onError(error) {
        if (isServerError(error, 403)) {
          // operator is not subscribed to cama
          return error;
        } else {
          throw error;
        }
      },
    }
  );

  useEffect(() => {
    if (!operatorLink) return;
    if (!tso.response) return;

    const operatorId = tso.response.data.organizationId;
    const url = urlTemplate.parse(operatorLink.href).expand({ operatorId });

    operator.execute({ url });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tso.response]);

  // either carma itself or the operator link aren't rolled out on this stage
  // (or we're authenticated as an operator)
  // in any case, we aren't interested in the request
  if (!operatorLink) return;

  return operator;
}

export type OperatorRequest = ReturnType<typeof useOperatorByTso>;
