import type { FC } from 'react';
import { lazy } from 'react';
import { Route } from 'react-router-dom';
import type { CapacityManagementRootRequest } from 'src/apis/capacity-management/use-capacity-management-root';
import { useCmNetworkPoint } from 'src/apis/capacity-management/use-cm-network-point';
import type { NetworkPointRequest } from 'src/apis/monolith/use-network-point';
import { RootRoutes } from 'src/components/root-routes';
import AddInternalBooking from 'src/pages/transport/network-points/details/availability-and-price-data/internal-bookings/add';
import EditInternalBooking from 'src/pages/transport/network-points/details/availability-and-price-data/internal-bookings/edit';
import Overview from 'src/pages/transport/network-points/details/availability-and-price-data/overview';
import EditPriceSettings from 'src/pages/transport/network-points/details/availability-and-price-data/price-settings/edit';
import EditPriceSteps from 'src/pages/transport/network-points/details/availability-and-price-data/price-steps/edit';
import EditTechnicalCapacity from 'src/pages/transport/network-points/details/availability-and-price-data/technical-capacity/edit';

const NotFound = lazy(() => import('../../../../errors/not-found'));

type Props = {
  networkPoint: NetworkPointRequest;
  capacityManagementRoot: CapacityManagementRootRequest;
};

export const AvailabilityAndPriceData: FC<Props> = ({
  networkPoint,
  capacityManagementRoot,
}) => {
  const cmNetworkPoint = useCmNetworkPoint({
    capacityManagementRoot,
    networkPoint,
  });

  return (
    <RootRoutes>
      <Route
        path="/transport/network-points/:oldNetworkPointId/availability-and-price-data"
        element={
          <Overview
            networkPoint={networkPoint}
            capacityManagementRoot={capacityManagementRoot}
            cmNetworkPoint={cmNetworkPoint}
          />
        }
      />

      <Route
        path="/transport/network-points/:oldNetworkPointId/availability-and-price-data/technical-capacity/edit"
        element={<EditTechnicalCapacity cmNetworkPoint={cmNetworkPoint} />}
      />

      <Route
        path="/transport/network-points/:oldNetworkPointId/availability-and-price-data/internal-bookings/add"
        element={<AddInternalBooking cmNetworkPoint={cmNetworkPoint} />}
      />

      <Route
        path="/transport/network-points/:oldNetworkPointId/availability-and-price-data/internal-bookings/:bookingId/edit"
        element={<EditInternalBooking cmNetworkPoint={cmNetworkPoint} />}
      />

      <Route
        path="/transport/network-points/:oldNetworkPointId/availability-and-price-data/price-settings/edit"
        element={<EditPriceSettings cmNetworkPoint={cmNetworkPoint} />}
      />

      <Route
        path="/transport/network-points/:oldNetworkPointId/availability-and-price-data/price-steps/edit"
        element={<EditPriceSteps cmNetworkPoint={cmNetworkPoint} />}
      />

      <Route path="*" element={<NotFound />} />
    </RootRoutes>
  );
};
