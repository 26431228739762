import type { FC } from 'react';
import { Route, useParams } from 'react-router-dom';
import { useMemoOne } from 'use-memo-one';
import type { CapacityManagementRootRequest } from 'src/apis/capacity-management/use-capacity-management-root';
import type { NetworkPointParams } from 'src/apis/monolith/use-network-point';
import { useNetworkPoint } from 'src/apis/monolith/use-network-point';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { RootRoutes } from 'src/components/root-routes';
import { PageSpinner } from 'src/components/spinner-container';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { AvailabilityAndPriceData } from 'src/pages/transport/network-points/details/availability-and-price-data';
import { CapacityManagement } from 'src/pages/transport/network-points/details/availability-and-price-data/overview/capacity-management';
import { CapacityOverview } from 'src/pages/transport/network-points/details/capacity-overview';
import { MyPortfolioOverview } from 'src/pages/transport/network-points/details/my-portfolio-overview';
import { NetworkPointsDetails } from 'src/pages/transport/network-points/details/point-details';

export const NetworkPoint: FC = () => {
  const { oldNetworkPointId } = useParams<{ oldNetworkPointId: string }>();

  const networkPointParams = useMemoOne<NetworkPointParams>(
    () => ({ 'include-links': true }),
    []
  );
  const networkPoint = useNetworkPoint({
    oldNetworkPointId,
    params: networkPointParams,
  });

  const monolithUser = useOptionalAuthenticatedMonolithUser();

  const routes = (capacityManagementRoot?: CapacityManagementRootRequest) => (
    <RootRoutes>
      {capacityManagementRoot && (
        <Route
          path="/transport/network-points/:oldNetworkPointId/availability-and-price-data/*"
          element={
            <AvailabilityAndPriceData
              networkPoint={networkPoint}
              capacityManagementRoot={capacityManagementRoot}
            />
          }
        />
      )}

      {capacityManagementRoot && (
        <Route
          path="/transport/network-points/:oldNetworkPointId/my-portfolio-overview/*"
          element={
            <MyPortfolioOverview
              networkPoint={networkPoint}
              capacityManagementRoot={capacityManagementRoot}
            />
          }
        />
      )}

      {capacityManagementRoot && (
        <Route
          path="/transport/network-points/:oldNetworkPointId/capacity-overview/*"
          element={
            <CapacityOverview
              networkPoint={networkPoint}
              capacityManagementRoot={capacityManagementRoot}
            />
          }
        />
      )}

      <Route
        path="/transport/network-points/:oldNetworkPointId"
        element={
          <>
            {/* we must wait for the response to get proper 404/410 handling :( */}
            {networkPoint.response ? (
              <NetworkPointsDetails networkPoint={networkPoint} />
            ) : (
              <PageSpinner />
            )}
          </>
        }
      />

      <Route path="*" element={<ThrowNotFound />} />
    </RootRoutes>
  );

  if (monolithUser) {
    return <CapacityManagement>{routes}</CapacityManagement>;
  } else {
    return routes();
  }
};
