import { useEffect } from 'react';
import type { TsoPlatformIds } from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';

export function useOperatorPlatformId(uuid: string) {
  const tso = useAxios(
    (axios, baseConfig, uuid: string) =>
      axios.request<TsoPlatformIds>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/tso/platform-ids/${uuid}`,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    tso.execute(uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  return tso;
}

export type TsoPlatformIdsRequest = ReturnType<typeof useOperatorPlatformId>;
