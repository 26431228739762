import { flatten } from 'flatnest';
import type { FieldValidator } from 'formik';
import { useField, useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useFieldRegistration } from 'src/components/form/field-registry';
import type { OnShowError } from 'src/components/form/use-error-handler';
import { useErrorHandler } from 'src/components/form/use-error-handler';

type CustomFieldOptions = {
  validate?: FieldValidator;
  onShowError?: OnShowError;
};

export function useCustomField<T = string>(
  name: string,
  label: string,
  options: CustomFieldOptions = {}
) {
  const formik = useFormikContext();
  const { validate, onShowError } = options;

  const [field, meta, helper] = useField<T>({
    name,
    validate,
  });

  // note: formik allows us to control if we want validation
  // on blur and on change or not (via `validateOnBlur` and `validateOnChange`).
  // sadly those properties also influence how dirtiness of fields is handled.
  // by default if you just tab through required fields formik would show
  // form errors for those fields on blur, even if you haven't change a value.
  // we'd like to only show validation errors in case the user changed a value
  // (-> direct interaction, not just focusing an de-focusing a field) or after a
  // submit attempt.
  // therefor we overwrite the default behaviour.
  const originalOnChange = field.onChange;
  // keep the original functionality, but mark every changed field as dirty/touched
  field.onChange = (e: React.ChangeEvent<any>) => {
    originalOnChange(e);
    formik.setFieldTouched(field.name, true, false);
  };
  // don't mark fields as dirty/touched just by blurring (but keep it touched, if it
  // was changed earlier)
  field.onBlur = () => {
    if (!formik.validateOnBlur) return;
    const flattenedTouched = flatten(formik.touched);
    formik.setFieldTouched(
      field.name,
      flattenedTouched[field.name] ?? false,
      true
    );
  };

  // if a field gets added after the user already tried to submit once,
  // we treat it as dirty/touched immidiately
  useEffect(() => {
    if (!formik.submitCount) return;
    formik.setFieldTouched(field.name, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useFieldRegistration(name, {
    label,
  });

  const [showError, error] = useErrorHandler(field, meta, onShowError);
  return [field, showError, error, helper] as const;
}
