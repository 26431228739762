import type { FC } from 'react';
import { useState } from 'react';
import type { ShipperUser } from 'src/apis/monolith/types';
import { useCancelAssignment } from 'src/apis/monolith/use-cancel-assignment';
import { Button } from 'src/components/buttons-and-actions/button';
import { useOnChange } from 'src/hooks/use-on-change';
import { useToast } from 'src/hooks/use-toasts';
import { CancelAssignmentModal } from 'src/pages/operators/assignments/details/components/cancel-assignment-modal';

export const CancelAssignmentButton: FC<{
  userId: string;
  tsoId: string;
  user: Pick<ShipperUser, 'firstName' | 'lastName'>;
  operatorName: string;
  onCancelSuccess?: () => void;
}> = ({ userId, tsoId, user, operatorName, onCancelSuccess }) => {
  const [showCancellationModal, setShowCancellationModal] = useState(false);
  const cancelAssignment = useCancelAssignment({
    userId,
    tsoId,
  });

  const notify = useToast();

  useOnChange(() => {
    setShowCancellationModal(false);

    notify({
      type: 'success',
      children: 'Assignment cancelled successfully.',
    });

    onCancelSuccess?.();
  }, cancelAssignment.response);

  useOnChange((error) => {
    setShowCancellationModal(false);

    if (error) {
      notify({
        type: 'error',
        children: 'Server error. Assignment was not cancelled',
      });
    }
  }, cancelAssignment.error);

  return (
    <>
      <Button
        data-testid="cancel-assignment-btn"
        size="small"
        mode="primary"
        onClick={() => setShowCancellationModal(true)}
      >
        Cancel Assignment
      </Button>

      {showCancellationModal && (
        <CancelAssignmentModal
          onDismiss={() => setShowCancellationModal(false)}
          cancelAssignment={cancelAssignment}
          userName={user.firstName + ' ' + user.lastName}
          operatorName={operatorName}
        />
      )}
    </>
  );
};
