import { useEffect } from 'react';
import urlTemplate from 'url-template';
import type { Link } from 'src/apis/api-utilities';
import type { PermissionGroupAssignableUsersResponse } from 'src/apis/authorization-service/types';
import { useAxios } from 'src/hooks/use-axios';

type HookProps = {
  assignableUsersLink?: Link;
};
type ExecutableProps = {
  assignableUsersLink: Link;
} & HookProps;

export function usePermissionGroupAssignableUsers({
  assignableUsersLink,
}: HookProps) {
  const request = useAxios(
    (axios, baseConfig, { assignableUsersLink }: ExecutableProps) => {
      const url = urlTemplate.parse(assignableUsersLink.href).expand({});
      return axios.request<PermissionGroupAssignableUsersResponse>({
        ...baseConfig,
        url,
      });
    },
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!assignableUsersLink) return;
    request.execute({ assignableUsersLink });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignableUsersLink]);

  if (!assignableUsersLink) return;

  return request;
}

export type PermissionGroupAssignableUsersRequest = ReturnType<
  typeof usePermissionGroupAssignableUsers
>;
