import type { PlatformSettings } from 'src/apis/monolith/use-platform-settings';
import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import { getOverviewSurrenderPageParamsSchema } from 'src/pages/reporting/surrender/overview/schema';

type Options = {
  platformSettings: PlatformSettings | null;
};

export function useSurrenderPageParams({ platformSettings }: Options) {
  return usePageParamsFromSchema(
    getOverviewSurrenderPageParamsSchema(platformSettings),
    {
      filters: {
        amountSetting: { label: 'Capacity' },
        'bookedCapacity-max': { label: 'Booked Capacity' },
        'bookedCapacity-min': { label: 'Booked Capacity' },
        costSetting: { label: 'Currency' },
        dealId: { label: 'Deal ID' },
        direction: { label: 'Direction' },
        favoritesOnly: { label: 'Quick Filter' },
        networkPointName: { label: 'Network Point' },
        runtimeFrom: { label: 'Runtime' },
        runtimeTo: { label: 'Runtime' },
        tso: { label: 'TSO' },
      },
    }
  );
}

export type PageParams = ReturnType<typeof useSurrenderPageParams>;

export type SurrenderParams = PageParams['value'];
