import { z } from 'zod';
import { getPaginationSchema } from 'src/components/form/zod-schemas';
import {
  optionalDefault,
  preprocessAsArray,
} from 'src/components/form/zod-utilities';
import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';

const myInformationSheetsSchema = z
  .object({
    processType: z.preprocess(
      optionalDefault(['NBP_PROCUREMENT', 'LNG_PROCUREMENT']),
      z.preprocess(
        preprocessAsArray,
        z.array(z.enum(['NBP_PROCUREMENT', 'LNG_PROCUREMENT']))
      )
    ),
    locationId: z.array(z.string()).default([]),
    startOfMonthStart: z.string().nullable().default(null),
    startOfMonthEnd: z.string().nullable().default(null),
  })
  .merge(getPaginationSchema({ pageSize: 500 }));

export function usePageParams() {
  return usePageParamsFromSchema(myInformationSheetsSchema, {
    filters: {
      processType: { label: 'Process Type' },
      locationId: { label: 'Locations' },
      startOfMonthStart: { label: 'Period Start' },
      startOfMonthEnd: { label: 'End' },
    },
  });
}

export type PageParams = ReturnType<typeof usePageParams>;
