import type { FC } from 'react';
import { lazy } from 'react';
import { useMemo } from 'use-memo-one';
import type {
  CreditLimitsExportParams,
  CreditLimitsRequestParams,
} from 'src/apis/monolith/types';
import { useCreditLimits } from 'src/apis/monolith/use-credit-limits';
import { PageSpinner } from 'src/components/spinner-container';
import { useCreditLimitsPageParams } from 'src/pages/shippers/credit-limits/use-page-params';
import { useSimpleCreditFrameworks } from 'src/pages/shippers/credit-limits/use-simple-credit-frameworks';

const Page = lazy(() => import('./page'));

export const CreditLimitsOverview: FC = () => {
  const pageParams = useCreditLimitsPageParams();
  const requestParams = useMemo(
    () =>
      ({
        offset: pageParams.value.start,
        limit: pageParams.value.pageSize,
        sort: pageParams.value.sortBy,
        sortDescending: pageParams.value.sortDirection === 'desc',
        creditLimitIdentifier: pageParams.value.creditLimitIdentifier,
        shipperRefId: pageParams.value.shippers.map(
          (shipper) => shipper.shipperRefId
        ),
        frameworkLabel: pageParams.value.frameworkLabels,
        intervalStart: pageParams.value.intervalStart,
        intervalEnd: pageParams.value.intervalEnd,
        createdAtFrom: pageParams.value.createdAtFrom,
        createdAtTo: pageParams.value.createdAtTo,
        changedAtFrom: pageParams.value.changedAtFrom,
        changedAtTo: pageParams.value.changedAtTo,
      }) satisfies CreditLimitsRequestParams,
    [pageParams.value]
  );
  const exportParams = useMemo(
    () =>
      ({
        sort: pageParams.value.sortBy,
        sortDescending: pageParams.value.sortDirection === 'desc',
        creditLimitIdentifier: pageParams.value.creditLimitIdentifier,
        shipperRefId: pageParams.value.shippers.map(
          (shipper) => shipper.shipperRefId
        ),
        frameworkLabel: pageParams.value.frameworkLabels,
        intervalStart: pageParams.value.intervalStart,
        intervalEnd: pageParams.value.intervalEnd,
        createdAtFrom: pageParams.value.createdAtFrom,
        createdAtTo: pageParams.value.createdAtTo,
        changedAtFrom: pageParams.value.changedAtFrom,
        changedAtTo: pageParams.value.changedAtTo,
      }) satisfies CreditLimitsExportParams,
    [pageParams.value]
  );
  const creditLimits = useCreditLimits(requestParams);
  const creditFrameworks = useSimpleCreditFrameworks();

  if (creditLimits.response === null || creditFrameworks.response === null)
    return <PageSpinner />;

  return (
    <Page
      pageParams={pageParams}
      exportParams={exportParams}
      creditFrameworks={creditFrameworks}
      creditLimits={creditLimits}
    />
  );
};
