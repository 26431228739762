import type { AxiosResponse } from 'axios';
import type { PaginatedData, ValidityPeriod } from 'src/apis/direct-fcfs/types';
import {
  shiftBerlinZoneToTso,
  shiftTsoZoneToBerlin,
} from 'src/pages/direct-fcfs/support/date';

export function paginatedResponseDataConverter<T, R = T>(
  converter: (data: T) => R
) {
  return (response: AxiosResponse<PaginatedData<T>>) => ({
    ...response,
    data: {
      ...response.data,
      values: response.data.values.map((value) => converter(value)),
    },
  });
}

export function responseDataConverter<T, R = T>(converter: (data: T) => R) {
  return (response: AxiosResponse<T>) => ({
    ...response,
    data: converter(response.data),
  });
}

export function tsoZonedDateTimeSupport(date: string, tsoZone: string): string {
  return shiftTsoZoneToBerlin(date, tsoZone);
}

export function validityPeriodTsoZoneSupport(
  validity: ValidityPeriod,
  tsoZone: string
): ValidityPeriod {
  return {
    from: shiftTsoZoneToBerlin(validity.from, tsoZone),
    to: shiftTsoZoneToBerlin(validity.to, tsoZone),
  };
}

export function shiftValidityPeriodBerlinZoneToTso(
  validity: ValidityPeriod,
  tsoZone: string
): ValidityPeriod {
  return {
    from: shiftBerlinZoneToTso(validity.from, tsoZone),
    to: shiftBerlinZoneToTso(validity.to, tsoZone),
  };
}
