import type { CustomCheckboxProps } from '@reach/checkbox';
import { CustomCheckbox } from '@reach/checkbox';
import { useFormikContext } from 'formik';
import type { FC, InputHTMLAttributes, ReactNode, RefObject } from 'react';
import { forwardRef, memo, useEffect, useId } from 'react';
import mergeRefs from 'react-merge-refs';
import styled, { css } from 'styled-components';
import {
  CheckedIcon,
  MixedCheck,
  UncheckedIcon,
} from 'src/components/form/checkbox-icons';
import { ErrorMessage } from 'src/components/form/error-message';
import { useFieldGroup } from 'src/components/form/field-group';
import { FieldItem, FieldLayout } from 'src/components/form/field-layout';
import type { FormFieldProps } from 'src/components/form/form-field-props';
import { FieldLabel } from 'src/components/form/label';
import { radioSize } from 'src/components/form/radios';
import { useCustomField } from 'src/components/form/use-custom-field';
import { useFocusManager } from 'src/components/form/use-focus-manager';
import { Stack } from 'src/components/layout/stack';
import { Tooltip } from 'src/components/overlay/tooltip';
import { Hint } from 'src/components/text/hint';
import { useDefaultStacked } from 'src/hooks/use-default-stacked';

import { centeredIconOffset, Colors, focusStyle } from 'src/styles';
import type { StrictOmit } from 'src/utils/utility-types';

const StyledLabel = styled.span<{ showError?: boolean; disabled?: boolean }>`
  color: ${Colors.brand};
  user-select: none;
  width: fit-content; /* Fixes text wrapping issue in Safari */
  ${({ showError }) =>
    showError &&
    css`
      color: ${Colors.error};
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      color: ${Colors.inactive};
    `};
`;

const CheckboxContainer = styled.span<{
  disabled?: boolean;
}>`
  word-break: break-word;
  [data-reach-custom-checkbox] {
    display: inline-flex; /* focus style with inline-flex is nicer in Firefox then with default inline-block  */
    margin: 0;
    outline: 0;
    top: ${centeredIconOffset};
    width: ${radioSize}rem;
    height: ${radioSize}rem;
    ${({ disabled }) => disabled && 'cursor: not-allowed;'};
  }

  [data-reach-custom-checkbox]:focus-within {
    ${focusStyle}
  }
`;

export type SimpleCheckboxProps = {
  label: ReactNode;
  labelRef?: RefObject<HTMLLabelElement>;
  showError?: boolean;
  hideLabel?: boolean;
  disabled?: boolean;
  disabledMessage?: ReactNode;
  id?: string;
  'data-testid'?: string;
} & StrictOmit<InputHTMLAttributes<HTMLInputElement>, 'checked'> &
  CustomCheckboxProps;

export const SimpleCheckbox = memo(
  forwardRef<HTMLInputElement, SimpleCheckboxProps>(
    (
      {
        label,
        showError,
        checked,
        disabled,
        disabledMessage,
        labelRef,
        hideLabel,
        ...inputProps
      },
      ref
    ) => {
      const focusRef = useFocusManager();

      return (
        <Tooltip
          content={disabled && disabledMessage ? disabledMessage : undefined}
        >
          {(targetProps) => (
            <CheckboxContainer disabled={disabled} {...targetProps}>
              <Stack
                flow="column"
                as="label"
                gap={0.5}
                inline
                alignItems="baseline"
                ref={labelRef}
              >
                <CustomCheckbox
                  data-testid={inputProps.name}
                  checked={checked}
                  {...inputProps}
                  disabled={disabled}
                  // Here we need to map again because of CustomCheckbox requirements (it expects a string)
                  // otherways it throws a numerous console errors
                  value={Boolean(inputProps.value).toString()}
                  ref={mergeRefs([focusRef, ref])}
                >
                  {checked === 'mixed' ? (
                    <MixedCheck error={showError} disabled={disabled} />
                  ) : checked === true ? (
                    <CheckedIcon error={showError} disabled={disabled} />
                  ) : (
                    <UncheckedIcon error={showError} disabled={disabled} />
                  )}
                </CustomCheckbox>
                {!hideLabel && (
                  <StyledLabel showError={showError} disabled={disabled}>
                    {label}
                  </StyledLabel>
                )}
              </Stack>
            </CheckboxContainer>
          )}
        </Tooltip>
      );
    }
  )
);

type Props = {
  optionLabel: ReactNode;
  stacked?: boolean;
} & FormFieldProps &
  StrictOmit<InputHTMLAttributes<HTMLInputElement>, 'checked'> &
  CustomCheckboxProps;

/**
 * Use this for yes/no decisions from the user.
 */
export const Checkbox: FC<Props> = (props) => {
  const { isStacked, minTablet } = useDefaultStacked();

  const {
    label,
    optionLabel,
    name,
    checked,
    stacked = isStacked,
    hideLabel = false,
    info,
    hint,
    disabledMessage,
    ...inputProps
  } = props;
  const id = useId();
  const { setFieldValue } = useFormikContext<boolean>();
  const [field, showError, error] = useCustomField<boolean>(name, label);
  const formGroup = useFieldGroup();
  useEffect(() => {
    if (formGroup) {
      formGroup.onError({ name, showError });
    }
  }, [showError, name, formGroup]);
  const fieldId = formGroup?.id || id;

  const fieldItem = (
    <FieldItem>
      <div>
        <SimpleCheckbox
          label={optionLabel}
          id={fieldId}
          showError={showError}
          data-test-error={showError}
          aria-labelledby={field.name}
          {...field}
          {...inputProps}
          // if value is null/undefined, default to empty string
          value={Boolean(field.value).toString()}
          // if value is null/undefined, explicitely cast to boolean
          // (this avoids switching from uncontrolled to controlled input)
          checked={Boolean(field.value)}
          onChange={() => {
            if (field.value === true) {
              setFieldValue(field.name, false);
            } else {
              setFieldValue(field.name, true);
            }
          }}
          disabledMessage={disabledMessage}
        />
      </div>
      {hint && (
        <Hint
          disabled={props.disabled}
          mode="formInput"
          children={hint}
          data-testid={`${field.name}-hint`}
        />
      )}
      {showError && error && (
        <ErrorMessage
          data-testid={`${name}Error`}
          error={error}
          label={label}
        />
      )}
    </FieldItem>
  );

  return ((formGroup && !minTablet) || !formGroup) && !hideLabel ? (
    <FieldLayout stacked={stacked}>
      <FieldLabel
        name={name}
        displayLabel={label}
        fieldId={fieldId}
        info={info}
        showError={showError}
      />

      {fieldItem}
    </FieldLayout>
  ) : (
    fieldItem
  );
};
