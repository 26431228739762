import type { FC, ReactNode } from 'react';
import { useHeaderHeight } from 'src/hooks/use-header-height';

export const Sticky: FC<{ children: ReactNode }> = ({ children }) => {
  const { height } = useHeaderHeight();
  const padding = 20;
  const top = height + padding;
  return (
    <div style={{ height: '100%' }}>
      <div style={{ position: 'sticky', top }}>{children}</div>
    </div>
  );
};
