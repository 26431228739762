import type { FC, ReactNode } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useMemoOne } from 'use-memo-one';
import { useStorybook } from 'src/hooks/use-storybook';
import { assertContext } from 'src/utils/assert-context';

export const stickyHeaderId = 'sticky-header';

type ScrollManagerContextValue = {
  check: () => void;
  scrollTo: (hash: string, behaviour?: ScrollBehavior) => void;
};

// pass undefined as any, because we run assertContext at runtime
const ScrollManagerContext = createContext<ScrollManagerContextValue>(
  undefined as any
);

export function useScrollManager() {
  const context = useContext(ScrollManagerContext);
  assertContext(context, 'ScrollManager');
  return context;
}

export const ScrollManagerProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { pathname, hash } = useLocation();
  const { isDocsPage } = useStorybook();

  // note: checkedHash is an object instead of a string, because
  // we might want to scroll to the same area multiple times
  // without changing the hash in between
  const [checkedHash, setCheckedHash] = useState<{
    value: string;
    behaviour?: ScrollBehavior;
  }>({ value: '' });

  // useLayoutEffect, because resetting the checkedHash happened _after_
  // check was called (e.g. in Settings > Registration) with useEffect
  useLayoutEffect(() => {
    // forget checkedHash, because we switched to a new page
    // (which might uses the same hash)
    setCheckedHash({ value: '' });

    // disable scrolling in storybook docs views
    // (or you would jump to the start of the docs page, when you
    // interact with the examples)
    if (isDocsPage) return;

    window.scroll(0, 0);
  }, [pathname, isDocsPage]);

  useEffect(() => {
    if (!checkedHash.value) return;

    const targetId = checkedHash.value.substr(1);

    const target = document.getElementById(targetId);
    const header = document.getElementById(stickyHeaderId);

    if (target && header) {
      window.scrollTo({
        top:
          target.getBoundingClientRect().top +
          window.pageYOffset -
          header.clientHeight,
        left: 0,
        behavior: checkedHash.behaviour,
      });
    } else {
      // throw errors only during development to find typos in ids
      // it's not critical for production
      if (process.env.NODE_ENV !== 'production') {
        if (!target)
          throw `ScrollManagerProvider can't find element #${targetId}.`;
        if (!header)
          throw `ScrollManagerProvider can't find element #${stickyHeaderId}.`;
      }
    }
  }, [checkedHash]);

  const check = useCallback(() => setCheckedHash({ value: hash }), [hash]);

  const scrollTo = useCallback(
    (hash: string, behaviour?: ScrollBehavior) =>
      setCheckedHash({ value: hash, behaviour }),
    []
  );

  const value = useMemoOne(() => ({ check, scrollTo }), [check, scrollTo]);

  return (
    <ScrollManagerContext.Provider value={value}>
      {children}
    </ScrollManagerContext.Provider>
  );
};

/**
 * Use this component to indicate that the page has loaded and the anchor you want to use
 * is available. Use this for navigations within one page.
 *
 * ```
 * const { hash } = useLocation();
 * <ScrollCheck key={hash} />
 * ```
 */
export const ScrollCheck = () => {
  const { check } = useScrollManager();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(check, []);

  return null;
};
