import { useEffect } from 'react';
import type { Collection } from 'src/apis/api-utilities';
import type {
  NetworkPoint,
  NetworkPointsSearchParams,
} from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';

export function useNetworkPoints(params: NetworkPointsSearchParams) {
  const networkPoint = useAxios(
    (axios, baseConfig, params: NetworkPointsSearchParams) =>
      axios.request<Collection<NetworkPoint>>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/networkPoints/findNetworkPoints`,
        method: 'GET',
        params,
      }),
    {
      neededOnPageLoad: false,
    }
  );

  useEffect(() => {
    networkPoint.execute(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return networkPoint;
}

export type NetworkPointsRequest = ReturnType<typeof useNetworkPoints>;
