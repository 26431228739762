import type { FC } from 'react';
import { useEffect, useState } from 'react';
import type { OperatorDocument } from 'src/apis/document-exchange/types';
import type { DocumentWithdrawRequest } from 'src/apis/document-exchange/use-document-withdraw';
import { useDocumentWithdraw } from 'src/apis/document-exchange/use-document-withdraw';
import { Button } from 'src/components/buttons-and-actions/button';
import { TableActions } from 'src/components/buttons-and-actions/table-actions';
import { Link } from 'src/components/navigation/link';
import { useToast } from 'src/hooks/use-toasts';
import { WithdrawDocumentModal } from 'src/pages/shippers/documents/withdraw-document-modal';

export const Actions: FC<{
  refreshDocuments?: () => void;
  data: OperatorDocument;
  documentWithdraw?: DocumentWithdrawRequest;
  index: number;
}> = ({ refreshDocuments, documentWithdraw, index, data }) => {
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const notify = useToast();

  useEffect(() => {
    if (!documentWithdraw?.response) return;
    setShowWithdrawModal(false);

    notify({ type: 'success', children: 'Document withdrawn.' });
    if (refreshDocuments) {
      refreshDocuments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentWithdraw?.response]);

  if (!documentWithdraw) return null;

  return (
    <>
      <TableActions testId={data.operatorDocumentId}>
        {data.documentStatus === 'PUBLISHED' ? (
          <>
            {data._links.getOperatorDocumentVersionHistory ? (
              <Link to={`/shippers/documents/${data.operatorDocumentId}`}>
                Edit
              </Link>
            ) : null}

            <Button
              data-testid={`actions-dropdown-withdraw-${index}`}
              onClick={() => {
                setShowWithdrawModal(true);
              }}
            >
              Withdraw
            </Button>
          </>
        ) : null}
      </TableActions>

      {showWithdrawModal && (
        <WithdrawDocumentModal
          documentWithdraw={documentWithdraw}
          onDismiss={() => setShowWithdrawModal(false)}
        />
      )}
    </>
  );
};

const WithWithdraw: FC<{
  refreshDocuments?: () => void;
  data: OperatorDocument;
  documentWithdrawUrl: string;
  index: number;
}> = ({ refreshDocuments, data, documentWithdrawUrl, index }) => {
  const documentWithdraw = useDocumentWithdraw(documentWithdrawUrl);

  return (
    <Actions
      data={data}
      refreshDocuments={refreshDocuments}
      documentWithdraw={documentWithdraw}
      index={index}
    />
  );
};

export const ActionsWrapper: FC<{
  refreshDocuments?: () => void;
  data: OperatorDocument;
  index: number;
}> = ({ refreshDocuments, data, index }) => {
  return data._links.withdrawOperatorDocument ? (
    <WithWithdraw
      refreshDocuments={refreshDocuments}
      data={data}
      documentWithdrawUrl={data._links.withdrawOperatorDocument.href}
      index={index}
    />
  ) : (
    <Actions data={data} refreshDocuments={refreshDocuments} index={index} />
  );
};
