import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import urlTemplate from 'url-template';
import type {
  DraftedProduct,
  DraftedProductsResponse,
} from 'src/apis/capacity-management/types';
import type { DraftedProductsRequest } from 'src/apis/capacity-management/use-drafted-products';
import { useAxios, isServerError } from 'src/hooks/use-axios';

type HookConfig = {
  draftedProducts: DraftedProductsRequest;
};

type RequestOptions = {
  draftedProductsData: DraftedProductsResponse;
  draftedProductId: string;
};

export function useDraftedProduct({ draftedProducts }: HookConfig) {
  const { draftedProductId } = useParams<{ draftedProductId: string }>();

  const draftedProduct = useAxios(
    (
      axios,
      baseConfig,
      { draftedProductsData, draftedProductId }: RequestOptions
    ) => {
      const url = urlTemplate
        .parse(draftedProductsData._links.instance.href)
        .expand({ draftedProductId });
      return axios.request<DraftedProduct>({
        ...baseConfig,
        url,
      });
    },
    {
      neededOnPageLoad: true,
      onError(error) {
        if (isServerError(error, 410)) {
          return error;
        } else {
          throw error;
        }
      },
    }
  );

  useEffect(() => {
    if (!draftedProducts.response) return;

    const draftedProductsData = draftedProducts.response.data;
    const options = { draftedProductsData, draftedProductId };

    draftedProduct.execute(options);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftedProducts.response, draftedProductId]);

  return draftedProduct;
}

export type DraftedProductRequest = ReturnType<typeof useDraftedProduct>;
