import type { FC } from 'react';
import { lazy } from 'react';
import { PageHeader } from 'src/components/layout/page-header';
import { Stack } from 'src/components/layout/stack';

const Page = lazy(() => import('./page'));

export const PrimaryRemitReportsOverview: FC<{}> = ({}) => {
  return (
    <Stack gap={2}>
      <PageHeader title="REMIT Reports" />

      <Page />
    </Stack>
  );
};
