import { useEffect } from 'react';
import type { CapacityManagementRootResponse } from 'src/apis/capacity-management/types';
import { useAxios } from 'src/hooks/use-axios';

export function useCapacityManagementRoot() {
  const capacityManagementRoot = useAxios(
    (axios, baseConfig) =>
      axios.request<CapacityManagementRootResponse>({
        ...baseConfig,
        url: PRISMA_CONFIG.capacityManagement,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    capacityManagementRoot.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return capacityManagementRoot;
}

export type CapacityManagementRootRequest = ReturnType<
  typeof useCapacityManagementRoot
>;
