import { useEffect } from 'react';
import urlTemplate from 'url-template';
import type {
  UsersRequestParams,
  UsersResponse,
} from 'src/apis/organisation-service/types';
import { useAxios } from 'src/hooks/use-axios';

type Options = {
  url: string;
  params?: UsersRequestParams;
};

export function useUsers({ url, params }: Options) {
  const users = useAxios(
    (axios, baseConfig, { params }: { params?: UsersRequestParams }) =>
      axios.request<UsersResponse>({
        ...baseConfig,
        url: urlTemplate.parse(url).expand({}),
        params,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    users.execute({ params });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return users;
}

export type UsersRequest = ReturnType<typeof useUsers>;
