import { useEffect } from 'react';
import urlTemplate from 'url-template';
import type { OrganisationSubscriptionResponse } from 'src/apis/aggregate-eu/types';
import type { AggregateEuRootRequest } from 'src/apis/aggregate-eu/use-aggregate-eu-root';
import type { Successful } from 'src/hooks/use-axios';
import { useAxios } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';

type HookConfig = {
  aggregateEuRootRequest: Successful<AggregateEuRootRequest>;
};

export const useGetOrganisationSubscription = ({
  aggregateEuRootRequest,
}: HookConfig) => {
  const link =
    aggregateEuRootRequest.response.data._links?.manageOrganisationSubscription
      ?.href;

  const organisationSubscription = useAxios(
    (axios, baseConfig) => {
      if (!link) throw new Forbidden();

      const url = urlTemplate.parse(link).expand({});

      return axios.request<OrganisationSubscriptionResponse>({
        ...baseConfig,
        url,
      });
    },
    {
      neededOnPageLoad: true,
      onError(error) {
        if (error instanceof Forbidden) {
          return error;
        } else {
          throw error;
        }
      },
    }
  );

  useEffect(() => {
    if (!link) return;
    organisationSubscription.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link]);

  return organisationSubscription;
};
