import { useEffect } from 'react';
import type { AssignmentServiceRoot } from 'src/apis/assignment-service/types';
import { useAxios } from 'src/hooks/use-axios';

export function useAssignmentServicePublic() {
  const assignmentService = useAxios(
    (axios, baseConfig) =>
      axios.request<AssignmentServiceRoot>({
        ...baseConfig,
        url: PRISMA_CONFIG.assignmentService,
      }),
    { neededOnPageLoad: true, cacheKey: 'assignment-service-root' }
  );

  useEffect(() => {
    assignmentService.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return assignmentService;
}

export type AssignmentServiceRequest = ReturnType<
  typeof useAssignmentServicePublic
>;
