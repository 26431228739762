// note: check if we can generalize these components, so they can be used for other forms as well
import type { AxiosResponse } from 'axios';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Colors } from 'src/styles';

export const Warning = styled.p`
  color: ${Colors.warning};
`;

export const SuccessHandler: FC<{
  response: AxiosResponse | null | undefined;
  to: string;
}> = ({ response, to }) => {
  const [lastResponse] = useState(response);
  const navigate = useNavigate();

  useEffect(() => {
    if (response !== lastResponse) {
      navigate(to);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastResponse, response]);

  return null;
};
