import { useEffect } from 'react';
import { isServerError, useAxios } from 'src/hooks/use-axios';
import type { SimpleCreditFrameworksResponse } from 'src/pages/shippers/credit-limits/types';

export function useSimpleCreditFrameworks() {
  const simpleCreditFrameworksRequest = useAxios(
    (axios, baseConfig) =>
      axios.request<SimpleCreditFrameworksResponse>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/credit-framework`,
      }),
    {
      neededOnPageLoad: true,
      onError(error) {
        if (isServerError(error, 404)) {
          // this happens if we search for a non-existing response id
          return error;
        } else {
          throw error;
        }
      },
    }
  );

  useEffect(() => {
    simpleCreditFrameworksRequest.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return simpleCreditFrameworksRequest;
}

export type SimpleCreditFrameworksRequest = ReturnType<
  typeof useSimpleCreditFrameworks
>;
