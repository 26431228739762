import { useEffect } from 'react';
import type { LngOfferDetailsRequest } from 'src/apis/lng-marketing/use-lng-offer-details';
import { useAxios } from 'src/hooks/use-axios';

type HookConfig = {
  lngOfferDetails: LngOfferDetailsRequest;
};

type DownloadDocument = {
  downloadUrl: string;
  fileName: string;
};

export function useLngOfferDocumentFile({ lngOfferDetails }: HookConfig) {
  const lngOfferDocumentFile = useAxios(
    (axios, baseConfig, { url }: { url: string }) =>
      axios.request<DownloadDocument>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (
      !lngOfferDetails?.response ||
      !lngOfferDetails.response.data.pdfAttachment?._links.download
    )
      return;

    lngOfferDocumentFile.execute({
      url: lngOfferDetails.response.data.pdfAttachment._links.download.href,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lngOfferDetails?.response]);

  if (!lngOfferDetails?.response?.data.pdfAttachment?._links.download) return;
  return lngOfferDocumentFile;
}

export type LngOfferDocumentFileRequest = ReturnType<
  typeof useLngOfferDocumentFile
>;
