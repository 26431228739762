import type { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { DialogProps } from '@reach/dialog';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import { useFormikContext } from 'formik';
import type { FC, MouseEventHandler } from 'react';
import { useEffect, useState, useId } from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import styled, { createGlobalStyle, css } from 'styled-components';
import { ActionModeProvider } from 'src/components/action-mode';
import { ActionGroupModeProvider } from 'src/components/buttons-and-actions/action-group';
import type { ButtonProps } from 'src/components/buttons-and-actions/button';
import { Button } from 'src/components/buttons-and-actions/button';
import { Stack } from 'src/components/layout/stack';
import { Tooltip } from 'src/components/overlay/tooltip';
import { useMeasure } from 'src/hooks/use-measure';

import { ParentZIndexProvider } from 'src/hooks/use-parent-z-index';
import { Colors } from 'src/styles';

export type Size = 'default' | 'small' | 'middle' | 'large';

const borderRadius = '0.6rem';

const padding = '1.5rem';

const Header = styled.div<{ hasIcon: boolean }>`
  border-top-left-radius: ${borderRadius};
  border-top-right-radius: ${borderRadius};
  background-color: ${Colors.brand};
  color: white;

  display: grid;
  grid-auto-flow: column;
  grid-gap: ${padding};
  padding: ${padding};
  align-items: center;

  ${({ hasIcon }) => `
    grid-template-columns: auto ${hasIcon ? '1fr' : 'min-content'};
  `}

  font-size: 2rem;
`;

const ContentWrapper = styled.div<{ scrollable?: boolean; footer: boolean }>`
  ${({ scrollable }) =>
    scrollable &&
    css`
      max-height: 50vh;
      overflow-y: auto;
    `}

  ${({ footer }) =>
    !footer &&
    css`
      border-bottom-left-radius: ${borderRadius};
      border-bottom-right-radius: ${borderRadius};
    `}

  background-color: white;
  padding: ${padding};
`;
const Title = styled.p`
  font-weight: 900;
  margin: 0;
  word-break: break-all;
`;

export const Close = styled.button`
  align-self: start;
  color: ${Colors.brandLight1};
  background: none;
  border: none;
  padding: 0;
  font-size: 2.2rem;
  justify-items: space-around;

  cursor: pointer;
  user-select: none;

  transition:
    color 0.35s,
    background-color 0.35s;

  &:hover {
    color: ${Colors.brandLight2};
  }
`;

// see https://github.com/reach/reach-ui/issues/98#issuecomment-547897104
const layoutFix = 'prisma-layout-fix-for-react-dialog';

const LayoutFix = createGlobalStyle`
  html.${layoutFix} {
    overflow-x: visible;

    & body {
      margin-right: 0 !important;
    }
  }

  [data-reach-dialog-content] {
    padding: 0;
  }
`;

const sizeWidths: Record<Size, string> = {
  default: '60rem',
  small: '30rem',
  middle: '45rem',
  large: '90rem',
};

const ModalContent = styled(DialogContent)<{ size: Size }>`
  background: none;
  width: ${({ size }) => sizeWidths[size]};
  max-width: calc(100% - ${padding} * 2);
  padding: 0;
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.5);
  border-radius: ${borderRadius};
  transition: opacity 0.1s ease-in-out;
`;

const FooterWrapper = styled.div`
  border-bottom-left-radius: ${borderRadius};
  border-bottom-right-radius: ${borderRadius};
  background-color: white;
  padding: ${padding};
`;

type ActionButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  label: string;
  dataTestId?: string;
  disabled?: boolean;
  props?: ButtonProps;
};

type CloseButtonProps = {
  label?: string;
  dataTestId?: string;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  props?: ButtonProps;
};

type SubmitButtonProps = {
  label: string;
  dataTestId?: string;
  disabled?: boolean;
  props?: ButtonProps;
};

type ConfirmButtonProps = ActionButtonProps & {
  tooltip?: string | boolean;
};
export type Footer =
  | {
      submitButton: SubmitButtonProps;
      closeButton?: CloseButtonProps | null;
      confirmButton?: never;
    }
  | {
      confirmButton: ConfirmButtonProps | null;
      closeButton?: CloseButtonProps | null;
      submitButton?: never;
    };

type Props = {
  icon?: IconDefinition | undefined;
  title: string;
  size?: Size;
  /**
   * Be very careful with this one. Usually there is no good reason why you should
   * NOT be able to close a modal. At the end you cannot force people to do something
   * within the modal or keep it open for some time. They could simply refresh or navigate
   * away from the page.
   */
  hideClose?: boolean;
  /**
   * You can use the default footer by just passing in the confirmationButton specification.
   * * By default, closeButton is a button that closes the modal when clicked.
   * * If you set closeButton to null explicitly, you will have no close button.
   * * If your modal has a form, use submitButton instead of confirmButton. */
  footer: Footer | null;
} & DialogProps;

export const Modal: FC<Props> = ({
  icon,
  title,
  children,
  size = 'default',
  hideClose,
  footer,
  ...dialogProps
}) => {
  useEffect(() => {
    document.documentElement.classList.add(layoutFix);
    return () => document.documentElement.classList.remove(layoutFix);
  }, []);

  const [isScrollable, setIsScrollable] = useState<boolean>(false);
  const [innerRef, innerRefBounds] = useMeasure();
  const id = useId();

  useEffect(() => {
    if (innerRefBounds.height > 300) {
      setIsScrollable(true);
    }
  }, [innerRefBounds]);

  const zIndex = 3;

  return (
    // reset ActionModeProvider and ActionGroupModeProvider in case
    // a modal gets nested inside them
    <ActionModeProvider value={null}>
      <ActionGroupModeProvider value="single">
        <RemoveScroll
          // currently not used anymore, but I leave it here in case
          // we need to add shards again in the future
          shards={[]}
        >
          <DialogOverlay
            {...dialogProps}
            style={{
              zIndex,
            }}
            dangerouslyBypassScrollLock // we provide our own <RemoveScroll/> to make use of shards
          >
            <ParentZIndexProvider value={zIndex}>
              <LayoutFix />
              <ModalContent aria-labelledby={id} size={size}>
                {title && (
                  <Header hasIcon={Boolean(icon)}>
                    {icon && <FontAwesomeIcon icon={icon} />}
                    <Title id={id}>{title}</Title>
                    {!hideClose && (
                      <Close onClick={dialogProps.onDismiss}>
                        <FontAwesomeIcon
                          icon={faTimes}
                          aria-label="Close modal"
                        />
                      </Close>
                    )}
                  </Header>
                )}
                <ContentWrapper scrollable={isScrollable} footer={!!footer}>
                  {/* we need this div to measure the children's height and set
                  the parent div to scrollable */}
                  <div ref={innerRef}>{children}</div>
                </ContentWrapper>
                {!!footer && (
                  <FooterWrapper>
                    <Stack
                      flow="column"
                      justifyContent={
                        footer.closeButton === null ? 'end' : 'space-between'
                      }
                      gap={2}
                    >
                      {footer.closeButton === undefined ? (
                        <Button
                          mode="secondary"
                          data-testid="close-modal"
                          onClick={dialogProps.onDismiss}
                        >
                          Close
                        </Button>
                      ) : footer.closeButton ? (
                        <Button
                          mode="secondary"
                          data-testid={footer.closeButton.dataTestId}
                          onClick={
                            footer.closeButton.onClick ?? dialogProps.onDismiss
                          }
                          disabled={footer.confirmButton?.disabled}
                          {...footer.closeButton.props}
                        >
                          {footer.closeButton.label ?? 'Close'}
                        </Button>
                      ) : null}
                      {footer.submitButton && (
                        <SubmitButton submitButton={footer.submitButton} />
                      )}
                      {footer.confirmButton && (
                        <ConfirmButton confirmButton={footer.confirmButton} />
                      )}
                    </Stack>
                  </FooterWrapper>
                )}
              </ModalContent>
            </ParentZIndexProvider>
          </DialogOverlay>
        </RemoveScroll>
      </ActionGroupModeProvider>
    </ActionModeProvider>
  );
};

export const ModalDivider = styled.hr`
  position: relative;
  left: -${padding};
  width: calc(100% + ${padding} * 2);
  border: none;
  border-top: 0.1rem solid #e5e5e5;
`;

const SubmitButton: FC<{ submitButton: SubmitButtonProps }> = ({
  submitButton,
}) => {
  const { submitForm } = useFormikContext();

  return (
    <Button
      disabled={submitButton.disabled}
      type="submit"
      data-testid={submitButton.dataTestId ?? 'submit-form'}
      mode="primary"
      onClick={() => submitForm()}
      {...submitButton.props}
    >
      {submitButton.label}
    </Button>
  );
};

const ConfirmButton: FC<{ confirmButton: ConfirmButtonProps }> = ({
  confirmButton,
}) => {
  const button = (
    <Button
      disabled={confirmButton.disabled}
      data-testid={confirmButton.dataTestId ?? 'submit-form'}
      mode="primary"
      onClick={confirmButton.onClick}
      {...confirmButton.props}
    >
      {confirmButton.label}
    </Button>
  );

  return (
    <>
      {!!confirmButton.tooltip ? (
        <Tooltip content={confirmButton.tooltip}>{() => <>{button}</>}</Tooltip>
      ) : (
        <>{button}</>
      )}
    </>
  );
};
