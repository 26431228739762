import { useEffect } from 'react';
import type { ShipperBriefInfo } from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';

export function useShipperBriefInfo(referenceId: number) {
  const api = useAxios(
    (axios, baseConfig, referenceId: number) =>
      axios.request<ShipperBriefInfo>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/shippers/${referenceId}`,
        method: 'GET',
        timeout: 5000,
      }),
    {
      neededOnPageLoad: true,
      cacheKey: `ShipperBriefInfo-${referenceId}`,
    }
  );

  useEffect(() => {
    api.execute(referenceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referenceId]);

  return api;
}

export type ShipperBriefInfoRequest = ReturnType<typeof useShipperBriefInfo>;
