import type { FC } from 'react';
import { Route } from 'react-router-dom';
import { useOrganisationService } from 'src/apis/organisation-service/use-organisation-service';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { RootRoutes } from 'src/components/root-routes';
import { PageSpinner } from 'src/components/spinner-container';
import { InvoicesSettings } from 'src/pages/settings/invoices';
import { CompanyInformationOverview } from 'src/pages/settings/organisation/company-information';
import { EditGeneralInformation } from 'src/pages/settings/organisation/company-information/edit-general-information';
import { EditLsoInformation } from 'src/pages/settings/organisation/company-information/edit-lso-information';
import { EditShipperInformation } from 'src/pages/settings/organisation/company-information/edit-shipper-information';
import {
  EditSsoInformation,
  EditTsoInformation,
} from 'src/pages/settings/organisation/company-information/edit-tso-or-sso-information';
import { ShipperRoleWizard } from 'src/pages/settings/organisation/company-information/shipper-role-wizard';
import { ContactsOverview } from 'src/pages/settings/organisation/contacts';
import { CreateContact } from 'src/pages/settings/organisation/contacts/create';
import { EditContact } from 'src/pages/settings/organisation/contacts/edit';
import { UsersOverview } from 'src/pages/settings/organisation/users';
import { UserDetails } from 'src/pages/settings/organisation/users/details';
import { AssignPermissionGroups } from 'src/pages/settings/organisation/users/details/assign-permissions';
import { EditUser } from 'src/pages/settings/organisation/users/details/edit';
import { UpgradeToAdmin } from 'src/pages/settings/organisation/users/details/upgrade-to-admin';
import { InviteUser } from 'src/pages/settings/organisation/users/invite';
import { InviteUserSuccess } from 'src/pages/settings/organisation/users/invite/success';

export const OrganisationManagement: FC = () => {
  const organisationService = useOrganisationService();

  if (!organisationService.response) return <PageSpinner />;

  return (
    <RootRoutes>
      <Route
        path="/settings/organisation/users"
        element={<UsersOverview organisationService={organisationService} />}
      />

      <Route
        path="/settings/organisation/users/invite"
        element={<InviteUser organisationService={organisationService} />}
      />

      <Route
        path="/settings/organisation/users/invite/success"
        element={<InviteUserSuccess />}
      />

      <Route
        path="/settings/organisation/users/:userId"
        element={<UserDetails organisationService={organisationService} />}
      />

      <Route
        path="/settings/organisation/users/:userId/edit"
        element={<EditUser organisationService={organisationService} />}
      />

      <Route
        path="/settings/organisation/users/:userId/permission-groups"
        element={
          <AssignPermissionGroups organisationService={organisationService} />
        }
      />

      <Route
        path="/settings/organisation/users/:userId/upgrade-to-admin"
        element={<UpgradeToAdmin organisationService={organisationService} />}
      />

      <Route
        path="/settings/organisation/contacts"
        element={<ContactsOverview organisationService={organisationService} />}
      />

      <Route
        path="/settings/organisation/contacts/create"
        element={<CreateContact organisationService={organisationService} />}
      />

      <Route
        path="/settings/organisation/contacts/:contactId/edit"
        element={<EditContact organisationService={organisationService} />}
      />

      <Route
        path="/settings/organisation/company-information"
        element={
          <CompanyInformationOverview
            organisationService={organisationService}
          />
        }
      />

      <Route
        path="/settings/organisation/invoices"
        element={<InvoicesSettings />}
      />

      <Route
        path="/settings/organisation/company-information/edit"
        element={
          <EditGeneralInformation organisationService={organisationService} />
        }
      />

      <Route
        path="/settings/organisation/company-information/edit-shipper-details"
        element={
          <EditShipperInformation organisationService={organisationService} />
        }
      />

      <Route
        path="/settings/organisation/company-information/edit-sso-details"
        element={
          <EditSsoInformation
            title="Edit SSO Related Information"
            organisationService={organisationService}
          />
        }
      />

      <Route
        path="/settings/organisation/company-information/edit-tso-details"
        element={
          <EditTsoInformation
            title="Edit TSO Related Information"
            organisationService={organisationService}
          />
        }
      />

      <Route
        path="/settings/organisation/company-information/edit-lso-details"
        element={
          <EditLsoInformation
            title="Edit LSO Related Information"
            organisationService={organisationService}
          />
        }
      />

      <Route
        path="/settings/organisation/company-information/apply-for-shipper-role/*"
        element={
          <ShipperRoleWizard organisationService={organisationService} />
        }
      />

      <Route path="*" element={<ThrowNotFound />} />
    </RootRoutes>
  );
};
