import type { ErrorInfo, ReactNode } from 'react';
import { Component, lazy } from 'react';
import { Title } from 'src/components/feedback/error-boundary';
import { SimplePageLayout } from 'src/components/layout/simple-page-layout';
import { MissingMock } from 'src/utils/errors';
import { reportToSentry } from 'src/utils/report-to-sentry';

const UnknownError = lazy(() => import('../../pages/errors/unknown'));

type State = { error: unknown };

export class FatalErrorBoundary extends Component<
  { children?: ReactNode },
  State
> {
  override state: State = { error: undefined };

  static getDerivedStateFromError(error: unknown) {
    if (error instanceof MissingMock) throw error;
    return { error };
  }

  override componentDidCatch(error: unknown, errorInfo: ErrorInfo) {
    reportToSentry(error, errorInfo);
  }

  override render() {
    const { error } = this.state;
    if (error === undefined) return this.props.children;

    return (
      <SimplePageLayout>
        <Title>Fatal Error</Title>
        <UnknownError />
      </SimplePageLayout>
    );
  }
}
