import { z } from 'zod';
import type { ReportingBalancingGroupAllocationsSortBy } from 'src/apis/capacity-management/types';
import {
  descSortDirectionSchema,
  formOptionSchema,
  getPaginationSchema,
} from 'src/components/form/zod-schemas';
import {
  optionalDefault,
  preprocessAsArray,
} from 'src/components/form/zod-utilities';
import { ownNetworkPointSchema } from 'src/pages/reporting/products/drafted/overview/own-network-searchable-multi-select';

export function getPageParamsSchema(gasMonth: string) {
  return z
    .object({
      creationDateStart: z.string().nullable().default(null),
      creationDateEnd: z.string().nullable().default(null),
      networkPointId: z.preprocess(
        preprocessAsArray,
        z.array(ownNetworkPointSchema).default([])
      ),
      balancingGroupId: z.preprocess(
        preprocessAsArray,
        z.array(formOptionSchema).default([])
      ),
      allocationIntervalIntersectionFilterStart: z.preprocess(
        optionalDefault(gasMonth),
        z.string().nullable()
      ),
      allocationIntervalIntersectionFilterEnd: z
        .string()
        .nullable()
        .default(null),
      sortBy: z
        .enum([
          'CREATION_DATE',
          'BALANCING_GROUP',
          'SHIPPER_NAME' as ReportingBalancingGroupAllocationsSortBy,
        ])
        .default('CREATION_DATE'),
      sortDirection: descSortDirectionSchema,
    })
    .merge(getPaginationSchema());
}
