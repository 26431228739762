import {
  AuthError,
  confirmSignIn,
  confirmUserAttribute,
  fetchUserAttributes,
  sendUserAttributeVerificationCode,
  signIn,
  signOut,
  updateMFAPreference,
  updatePassword,
  updateUserAttributes,
  resetPassword,
  confirmResetPassword,
  fetchAuthSession,
  getCurrentUser,
  setUpTOTP,
  verifyTOTPSetup,
  updateUserAttribute,
} from 'aws-amplify/auth';
import type { FC, ReactNode } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';
import type { Uuid } from 'src/apis/api-utilities';
import type { AccountsContextType } from 'src/hooks/use-accounts';
import { useAuthSessionChannel } from 'src/hooks/use-auth-session-channel';
import { useCatchError } from 'src/hooks/use-catch-error';
import type { CognitoLastAuthUser } from 'src/hooks/use-cognito-last-auth-user';
import { useCognitoLastAuthUser } from 'src/hooks/use-cognito-last-auth-user';
import { assertContext } from 'src/utils/assert-context';
import { commitSha } from 'src/utils/commit-sha';
import type { StrictOmit } from 'src/utils/utility-types';

/**
 * Example values:
 * {
 *   "custom:auth_method": "software",
 *   "sub": "c36ff733-3217-48e0-8f84-507aa47752ed",
 *   "email_verified": "true",
 *   "email": "test.INT+Carma-shipper-admin@prisma-capacity.eu"
 * }
 *
 * Another example:
 * {
 *   "custom:auth_method": "software",
 *   "sub": "7908ec95-c5c7-4be2-ba3c-7d11435bc36f",
 *   "email_verified": "true",
 *   "phone_number_verified": "true",
 *   "phone_number": "+4915116343641",
 *   "email": "philipp.zins+mfa-test-5@prisma-capacity.eu"
 * }
 */
export type CognitoUserAttributesData = {
  'custom:auth_method': 'software' | 'hardware';
  /**
   * This is not the actual user id as used in the platform, but a Cognito specific id.
   */
  sub: Uuid;
  email_verified: 'true' | 'false';
  email: string;
  phone_number_verified?: 'true' | 'false';
  phone_number?: string;
};

const createCognitoClient = ({
  cognitoLastAuthUser,
  onLogoutSuccess,
}: {
  cognitoLastAuthUser: CognitoLastAuthUser;
  onLogoutSuccess: () => void;
}) => ({
  /**
   * This is intended to be overwritten by the `useCognito` hook.
   *
   * Why is it needed? The error needs to be thrown inside a React lifecycle method and ideally _within_ the error boundary.
   */
  catchError: (error: unknown) => {
    console.error(
      'The following error was catch outside of React render lifecycle. Please handle it properly.'
    );
    setTimeout(() => {
      throw error;
    }, 1);
  },
  /**
   * Use this function to start the login process. Depending
   * on the stage (if MFA is enabled or not) and depending
   * on the state of your account the next steps will be different.
   */
  async login({
    values,
    onConfirmSignInWithNewPasswordRequired,
    onContinueSignInWithTotpSetup,
    onConfirmSignInWithTotpCode,
    onContinueSignInWithMfaSelection,
    onConfirmSignInWithSmsCode,
    onSuccess,
    onNotAuthorizedException,
    onPasswordResetRequiredException,
    onInvalidParameterException,
  }: {
    values: { email: string; password: string };
    /**
     * E.g. freshly invited user logging in with the temporary password.
     */
    onConfirmSignInWithNewPasswordRequired: () => void;
    /**
     * E.g. freshly invited user logging with an already changed password, but not yet configured mfa setup.
     */
    onContinueSignInWithTotpSetup: (sharedSecret: string) => void;
    /**
     * E.g. a user logging in that has NO verified fallback phone number.
     */
    onConfirmSignInWithTotpCode: () => void;
    /**
     * E.g. a user logging in that HAS a verified fallback phone number.
     */
    onContinueSignInWithMfaSelection: () => void;
    /**
     * See https://prisma-european-capacity-platf.sentry.io/issues/5424570957/.
     * When does it happen? When the user somehow has an invalid MFA device setup?
     */
    onConfirmSignInWithSmsCode: (codeDeliveryDetails: {
      deliveryMedium: 'SMS';
      destination: string;
    }) => void;
    /**
     * E.g. a user logging in on stages that don't require MFA.
     */
    onSuccess: () => void;
    onNotAuthorizedException: () => void;
    onPasswordResetRequiredException: () => void;
    /**
     * E.g. the user entered whitespace within the email.
     */
    onInvalidParameterException: () => void;
  }) {
    try {
      cognitoLastAuthUser.remove();

      const signInOutput = await signIn({
        username: values.email,
        password: values.password,
        options: { authFlowType: 'USER_PASSWORD_AUTH' },
      });

      // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check -- default case is sufficient
      switch (signInOutput.nextStep.signInStep) {
        case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
          onConfirmSignInWithNewPasswordRequired();
          break;
        case 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP':
          onContinueSignInWithTotpSetup(
            signInOutput.nextStep.totpSetupDetails.sharedSecret
          );
          break;
        case 'CONFIRM_SIGN_IN_WITH_TOTP_CODE':
          onConfirmSignInWithTotpCode();
          break;
        case 'CONTINUE_SIGN_IN_WITH_MFA_SELECTION':
          onContinueSignInWithMfaSelection();
          break;
        case 'CONFIRM_SIGN_IN_WITH_SMS_CODE':
          onConfirmSignInWithSmsCode(
            signInOutput.nextStep.codeDeliveryDetails as {
              deliveryMedium: 'SMS';
              destination: string;
            }
          );
          break;
        case 'DONE':
          onSuccess();
          break;
        default:
          throw new Error(
            `Unexpected next step in signInOutput: ${signInOutput.nextStep.signInStep}`
          );
      }
    } catch (error) {
      if (isAuthError(error, 'NotAuthorizedException')) {
        onNotAuthorizedException();
      } else if (isAuthError(error, 'PasswordResetRequiredException')) {
        onPasswordResetRequiredException();
      } else if (isAuthError(error, 'InvalidParameterException')) {
        onInvalidParameterException();
      } else {
        this.catchError(error);
      }
    }
  },
  /**
   * Use this function to set a new password (e.g. for a
   * freshly invited user) and proceed with the login process.
   *
   * If you want to change the password of an already signed
   * in user, use the `updatePassword` function instead!
   */
  async confirmNewPassword({
    values,
    onContinueSignInWithTotpSetup,
    onSuccess,
    onUserUnAuthenticatedException,
    onInvalidPasswordException,
    onContinueSignInWithMfaSelection,
    onNotAuthorizedException,
  }: {
    values: { newPassword: string };
    /**
     * E.g. freshly invited user logging with an already changed password, but not yet configured mfa setup.
     */
    onContinueSignInWithTotpSetup: (sharedSecret: string) => void;
    onContinueSignInWithMfaSelection: () => void;
    /**
     * E.g. a user logging in on stages that don't require MFA.
     */
    onSuccess: () => void;
    onUserUnAuthenticatedException: () => void;
    onInvalidPasswordException: () => void;
    onNotAuthorizedException: () => void;
  }) {
    try {
      const signInOutput = await confirmSignIn({
        challengeResponse: values.newPassword,
      });
      // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check -- default case is sufficient
      switch (signInOutput.nextStep.signInStep) {
        // e.g. freshly invited user logging with an already changed password, but not yet configured mfa setup
        case 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP':
          onContinueSignInWithTotpSetup(
            signInOutput.nextStep.totpSetupDetails.sharedSecret
          );
          break;
        case 'CONTINUE_SIGN_IN_WITH_MFA_SELECTION':
          onContinueSignInWithMfaSelection();
          break;
        case 'DONE':
          onSuccess();
          break;
        default:
          throw new Error(
            `Unexpected next step in signInOutput: ${signInOutput.nextStep.signInStep}`
          );
      }
    } catch (error) {
      if (isAuthError(error, 'UserUnAuthenticatedException')) {
        onUserUnAuthenticatedException();
      } else if (isAuthError(error, 'InvalidPasswordException')) {
        onInvalidPasswordException();
      } else if (isAuthError(error, 'NotAuthorizedException')) {
        // see https://prisma-european-capacity-platf.sentry.io/issues/5443740441/
        onNotAuthorizedException();
      } else {
        this.catchError(error);
      }
    }
  },
  /**
   * Use this function to setup TOTP as the response to `CONTINUE_SIGN_IN_WITH_TOTP_SETUP` as part of
   * the login flow.
   *
   * If you want to confirm the update to the TOTP setup of an already signed in user use `confirmUpdateTotpSetup` instead.
   */
  async confirmInitialTotpSetup({
    values,
    onSuccess,
    onNotAuthorizedException,
    onInvalidVerificationCode,
  }: {
    values: { verificationCode: string };
    /**
     * MFA setup was successful.
     */
    onSuccess: () => void;
    onNotAuthorizedException: () => void;
    onInvalidVerificationCode: () => void;
  }) {
    try {
      await confirmSignIn({ challengeResponse: values.verificationCode });
      onSuccess();
    } catch (error) {
      if (isAuthError(error, 'NotAuthorizedException')) {
        onNotAuthorizedException();
      } else if (
        isAuthError(error, 'EnableSoftwareTokenMFAException') ||
        isAuthError(error, 'InvalidParameterException')
      ) {
        onInvalidVerificationCode();
      } else {
        this.catchError(error);
      }
    }
  },
  async confirmTotpCode({
    values,
    isSelectMfaCase,
    onSuccess,
    onConfigurePhone,
    onVerifyPhone,
    onNotAuthorizedException,
    onCodeMismatchException,
    onLimitExceededExceptionForVerifyPhone,
    onExpiredCodeException,
  }: {
    values: { verificationCode: string };
    /**
     * Within the "Select MFA" case we first need to tell Cognito
     * that a TOTP code will be provided. Outside of the "Select MFA"
     * case we can directly provide the TOTP code.
     */
    isSelectMfaCase: boolean;
    /**
     * The provided TOTP code was correct and there is a verified fallback phone number.
     */
    onSuccess: () => void;
    /**
     * The provided TOTP code was correct, but no fallback phone
     * number was configured. This can only happen outside of the "Select MFA" case.
     */
    onConfigurePhone: () => void;
    /**
     * The provided TOTP code was correct and fallback phone
     * number was configured, but not yet verified. This can only happen outside
     * of the "Select MFA" case).
     */
    onVerifyPhone: (phone: string) => void;
    onNotAuthorizedException: () => void;
    onCodeMismatchException: () => void;
    /**
     * This can happen if you run into the `onVerifyPhone` case, but it wasn't possible to
     * finish it, because the phone verification limit was exceeded.
     */
    onLimitExceededExceptionForVerifyPhone: () => void;
    onExpiredCodeException: () => void;
  }) {
    try {
      if (isSelectMfaCase) await confirmSignIn({ challengeResponse: 'TOTP' });

      const signInOutput = await confirmSignIn({
        challengeResponse: values.verificationCode,
      });
      // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check -- default case is sufficient
      switch (signInOutput.nextStep.signInStep) {
        case 'DONE':
          if (isSelectMfaCase) {
            onSuccess();
          } else {
            const attributes = await fetchUserAttributes();
            const phone = attributes.phone_number;
            const phoneVerified = attributes.phone_number_verified;
            if (phone && phoneVerified === 'true') {
              // in some edge cases we can land in the 'totp-required' case
              // even though we have a verified phone number, which
              // prevents authentication via SMS.
              // this happens when software tokens have a preferance (happens due to
              // misconfigured account in cognito?).
              await updateMFAPreference({
                sms: 'NOT_PREFERRED',
                totp: 'PREFERRED',
              });
              onSuccess();
            } else if (phone) {
              await this.requestPhoneVerification({
                onSuccess() {
                  onVerifyPhone(phone);
                },
                onNotAuthorizedException,
                onLimitExceededException:
                  onLimitExceededExceptionForVerifyPhone,
              });
            } else {
              onConfigurePhone();
            }
          }
          break;
        default:
          throw new Error(
            `Unexpected next step in signInOutput: ${signInOutput.nextStep.signInStep}`
          );
      }
    } catch (error) {
      if (isAuthError(error, 'CodeMismatchException')) {
        onCodeMismatchException();
      } else if (isAuthError(error, 'NotAuthorizedException')) {
        onNotAuthorizedException();
      } else if (isAuthError(error, 'ExpiredCodeException')) {
        onExpiredCodeException();
      } else {
        this.catchError(error);
      }
    }
  },
  /**
   * Use this function during the login process in case the user
   * wants to use the fallback phone number for authentication.
   */
  async requestSmsChallenge({
    onSuccess,
    onNotAuthorizedException,
  }: {
    /**
     * The SMS challenge will be started.
     */
    onSuccess: (codeDeliveryDetails: {
      deliveryMedium: 'SMS';
      destination: string;
    }) => void;
    onNotAuthorizedException: () => void;
  }) {
    try {
      const signInOutput = await confirmSignIn({
        challengeResponse: 'SMS',
      });
      // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check -- default case is sufficient
      switch (signInOutput.nextStep.signInStep) {
        case 'CONFIRM_SIGN_IN_WITH_SMS_CODE':
          onSuccess(
            signInOutput.nextStep.codeDeliveryDetails as {
              deliveryMedium: 'SMS';
              destination: string;
            }
          );
          break;
        default:
          throw new Error(
            `Unexpected next step in signInOutput: ${signInOutput.nextStep.signInStep}`
          );
      }
    } catch (error) {
      if (isAuthError(error, 'NotAuthorizedException')) {
        onNotAuthorizedException();
      } else {
        this.catchError(error);
      }
    }
  },
  async confirmSmsCode({
    values,
    onSuccess,
    onNotAuthorizedException,
    onCodeMismatchException,
  }: {
    values: { verificationCode: string };
    /**
     * The provided SMS code was correct.
     */
    onSuccess: () => void;
    onNotAuthorizedException: () => void;
    onCodeMismatchException: () => void;
  }) {
    try {
      const signInOutput = await confirmSignIn({
        challengeResponse: values.verificationCode,
      });
      // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check -- default case is sufficient
      switch (signInOutput.nextStep.signInStep) {
        case 'DONE':
          onSuccess();
          break;
        default:
          throw new Error(
            `Unexpected next step in signInOutput: ${signInOutput.nextStep.signInStep}`
          );
      }
    } catch (error) {
      if (isAuthError(error, 'CodeMismatchException')) {
        onCodeMismatchException();
      } else if (isAuthError(error, 'NotAuthorizedException')) {
        onNotAuthorizedException();
      } else {
        this.catchError(error);
      }
    }
  },
  async updatePhone({
    values,
    onSuccess,
    onNotAuthorizedException,
    onInvalidParameterException,
  }: {
    values: { mobile: string };
    /**
     * A phone number was provided and needs to be verified now.
     */
    onSuccess: () => void;
    onNotAuthorizedException: () => void;
    onInvalidParameterException: () => void;
  }) {
    try {
      await updateUserAttributes({
        userAttributes: { phone_number: values.mobile.replaceAll(' ', '') },
      });
      onSuccess();
    } catch (error) {
      if (isAuthError(error, 'InvalidParameterException')) {
        onInvalidParameterException();
      } else if (isAuthError(error, 'NotAuthorizedException')) {
        onNotAuthorizedException();
      } else {
        this.catchError(error);
      }
    }
  },
  async requestPhoneVerification({
    onSuccess,
    onNotAuthorizedException,
    onLimitExceededException,
  }: {
    /**
     * A phone number was provided and needs to be verified now.
     */
    onSuccess: () => void;
    onNotAuthorizedException: () => void;
    onLimitExceededException: () => void;
  }) {
    try {
      await sendUserAttributeVerificationCode({
        userAttributeKey: 'phone_number',
      });
      onSuccess();
    } catch (error) {
      if (isAuthError(error, 'LimitExceededException')) {
        onLimitExceededException();
      } else if (isAuthError(error, 'NotAuthorizedException')) {
        onNotAuthorizedException();
      } else {
        this.catchError(error);
      }
    }
  },
  async confirmPhone({
    values,
    onSuccess,
    onNotAuthorizedException,
    onInvalidVerificationCode,
    onLimitExceededException,
  }: {
    values: { verificationCode: string };
    /**
     * The phone number was verified successfully.
     */
    onSuccess: () => void;
    onNotAuthorizedException: () => void;
    onInvalidVerificationCode: () => void;
    onLimitExceededException: () => void;
  }) {
    try {
      await confirmUserAttribute({
        confirmationCode: values.verificationCode,
        userAttributeKey: 'phone_number',
      });
      onSuccess();
    } catch (error) {
      if (isAuthError(error, 'NotAuthorizedException')) {
        onNotAuthorizedException();
      } else if (isAuthError(error, 'LimitExceededException')) {
        onLimitExceededException();
      } else if (
        isAuthError(error, 'EnableSoftwareTokenMFAException') ||
        isAuthError(error, 'InvalidParameterException') ||
        isAuthError(error, 'CodeMismatchException')
      ) {
        onInvalidVerificationCode();
      } else {
        this.catchError(error);
      }
    }
  },
  async resetPassword({
    values,
    onSuccess,
    onInvalidParameterException,
    onLimitExceededException,
  }: {
    values: {
      email: string;
    };
    onSuccess: () => void;
    onLimitExceededException: () => void;
    onInvalidParameterException: () => void;
  }) {
    try {
      await resetPassword({ username: values.email });
      onSuccess();
    } catch (error) {
      if (isAuthError(error, 'LimitExceededException')) {
        onLimitExceededException();
      } else if (isAuthError(error, 'InvalidParameterException')) {
        onInvalidParameterException();
      } else {
        this.catchError(error);
      }
    }
  },
  async confirmResetPassword({
    values,
    onSuccess,
    onInvalidParameterException,
    onCodeMismatchException,
    onInvalidPasswordException,
    onLimitExceededException,
    onExpiredCodeException,
  }: {
    values: {
      email: string;
      newPassword: string;
      verificationCode: string;
    };
    onSuccess: () => void;
    onLimitExceededException: () => void;
    onCodeMismatchException: () => void;
    onInvalidPasswordException: () => void;
    onInvalidParameterException: () => void;
    onExpiredCodeException: () => void;
  }) {
    try {
      await confirmResetPassword({
        confirmationCode: values.verificationCode,
        newPassword: values.newPassword,
        username: values.email,
      });
      onSuccess();
    } catch (error) {
      if (isAuthError(error, 'LimitExceededException')) {
        onLimitExceededException();
      } else if (isAuthError(error, 'InvalidParameterException')) {
        onInvalidParameterException();
      } else if (isAuthError(error, 'CodeMismatchException')) {
        onCodeMismatchException();
      } else if (isAuthError(error, 'InvalidPasswordException')) {
        onInvalidPasswordException();
      } else if (isAuthError(error, 'ExpiredCodeException')) {
        onExpiredCodeException();
      } else {
        this.catchError(error);
      }
    }
  },
  /**
   * Use this function to change the password of an already signed
   * in user.
   *
   * If you want to set a new password as part of the login process
   * (e.g. for a freshly invited user), use the `confirmNewPassword`
   * function instead!
   */
  async updatePassword({
    values,
    onSuccess,
    onNotAuthorizedException,
    onLimitExceededException,
  }: {
    values: {
      currentPassword: string;
      newPassword: string;
    };
    onSuccess: () => void;
    onNotAuthorizedException: () => void;
    onLimitExceededException: () => void;
  }) {
    try {
      await updatePassword({
        oldPassword: values.currentPassword,
        newPassword: values.newPassword,
      });
      onSuccess();
    } catch (error) {
      if (isAuthError(error, 'NotAuthorizedException')) {
        onNotAuthorizedException();
      } else if (isAuthError(error, 'LimitExceededException')) {
        onLimitExceededException();
      } else {
        this.catchError(error);
      }
    }
  },
  /**
   * This is usually not triggered manually via the UI, but as part of some interceptor logic.
   */
  async logoutFromCurrentAccount() {
    await signOut({ global: true });
    onLogoutSuccess();
  },
  /**
   * This is triggered as part of our "Switch Account" feature, when you want to forget an account
   * that is currently not used.
   * *IMPORTANT*: You need to manually manage `LastAuthUser` before and after calling this function.
   */
  async logoutFromCurrentlyUnusedAccount() {
    await signOut({ global: true });
  },
  /**
   * If you're looking for a "logout" functionality, this is probably what you want. It is called
   * via our UI when you press "Logout", even if you have just one account.
   */
  async logoutFromAllAccounts(accounts: AccountsContextType) {
    if (accounts.items.length) {
      // sequentially log out from all accounts (do not parallelize!)
      for (const account of accounts.items) {
        // this updates "LastAuthUser" which is needed, so we can properly call "signOut" afterwards
        cognitoLastAuthUser.set(account);
        await signOut({ global: true });
      }
    } else {
      // note: this should only happen during the rollout of the "Switch Account" feature:
      // if a user is authenticated via the old login flow "accounts.items" will always be empty
      // and therefore we cannot loop through it.
      await signOut({ global: true });
    }
    onLogoutSuccess();
  },
  /**
   * Use this for an already signed in user in order to
   * update the TOTP setup.
   */
  async updateTotpSetup({
    onSuccess,
    onNotAuthorizedException,
  }: {
    onSuccess: (secret: string) => void;
    onNotAuthorizedException: () => void;
  }) {
    try {
      const setupDetails = await setUpTOTP();
      onSuccess(setupDetails.sharedSecret);
    } catch (error) {
      if (isAuthError(error, 'NotAuthorizedException')) {
        onNotAuthorizedException();
      } else {
        this.catchError(error);
      }
    }
  },
  /**
   * Use this for an already signed in user in order to
   * confirm the update of the TOTP setup.
   *
   * If you need to confirm the initial TOTP setup as part of the login process,
   * use `confirmInitialTotpSetup` instead.
   */
  async confirmUpdateTotpSetup({
    values,
    onSuccess,
    onNotAuthorizedException,
    onInvalidVerificationCode,
  }: {
    values: { verificationCode: string };
    onSuccess: () => void;
    onNotAuthorizedException: () => void;
    onInvalidVerificationCode: () => void;
  }) {
    try {
      await verifyTOTPSetup({
        code: values.verificationCode,
      });
      onSuccess();
    } catch (error) {
      if (isAuthError(error, 'NotAuthorizedException')) {
        onNotAuthorizedException();
      } else if (
        isAuthError(error, 'EnableSoftwareTokenMFAException') ||
        isAuthError(error, 'InvalidParameterException')
      ) {
        onInvalidVerificationCode();
      } else {
        this.catchError(error);
      }
    }
  },
  async switchToMobileToken({
    onSuccess,
    onNotAuthorizedException,
  }: {
    onSuccess: (secret: string) => void;
    onNotAuthorizedException: () => void;
  }) {
    await updateUserAttribute({
      userAttribute: {
        attributeKey: 'custom:auth_method',
        value: 'software',
      },
    });
    this.updateTotpSetup({
      onSuccess,
      onNotAuthorizedException,
    });
  },
  /**
   * Example return value:
   * {
   *   "username": "c36ff733-3217-48e0-8f84-507aa47752ed",
   *   "userId": "c36ff733-3217-48e0-8f84-507aa47752ed",
   *   "signInDetails": {
   *      "loginId": "test.INT+Carma-shipper-admin@prisma-capacity.eu",
   *      "authFlowType": "USER_PASSWORD_AUTH"
   *   }
   * }
   */
  getCurrentUser,
  /**
   * Example return value:
   * {
   *   "tokens": {
   *     "accessToken": {
   *       "payload": {
   *         "sub": "c36ff733-3217-48e0-8f84-507aa47752ed",
   *         "event_id": "f35d5d84-bb1e-4dee-a19f-a64794bf1a58",
   *         "token_use": "access",
   *         "scope": "aws.cognito.signin.user.admin",
   *         "auth_time": 1715582312,
   *         "iss": "https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_LJq12y9UE",
   *         "exp": 1715582612,
   *         "iat": 1715582312,
   *         "jti": "e109e87b-bd9a-4dda-a092-237b2ae90024",
   *         "client_id": "3665hjln7pldvhsel3s3aseh9k",
   *         "username": "c36ff733-3217-48e0-8f84-507aa47752ed"
   *       }
   *     },
   *     "idToken": {
   *       "payload": {
   *         "sub": "c36ff733-3217-48e0-8f84-507aa47752ed",
   *         "legacy_roles": "ROLE_TK_ADMIN,ROLE_TK_USER",
   *         "email_verified": true,
   *         "iss": "https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_LJq12y9UE",
   *         "cognito:username": "c36ff733-3217-48e0-8f84-507aa47752ed",
   *         "organisation_name": "Carma Aktiengesellschaft",
   *         "organisation_id": "1a7dd51c-11b3-4f7a-80a4-a6e59fae634b",
   *         "custom:auth_method": "software",
   *         "aud": "3665hjln7pldvhsel3s3aseh9k",
   *         "event_id": "f35d5d84-bb1e-4dee-a19f-a64794bf1a58",
   *         "user_id": "077df606-0917-4b82-9f6f-3bf384ef308c",
   *         "token_use": "id",
   *         "auth_methods": "UserAccount:077df606-0917-4b82-9f6f-3bf384ef308c",
   *         "auth_time": 1715582312,
   *         "exp": 1715582612,
   *         "iat": 1715582312,
   *         "eic": "21X0000000010792",
   *         "email": "test.INT+Carma-shipper-admin@prisma-capacity.eu"
   *       }
   *     },
   *     "signInDetails": {
   *       "loginId": "test.INT+Carma-shipper-admin@prisma-capacity.eu",
   *       "authFlowType": "USER_PASSWORD_AUTH"
   *     }
   *   },
   *   "userSub": "c36ff733-3217-48e0-8f84-507aa47752ed"
   * }
   */
  fetchAuthSession,
  fetchUserAttributes() {
    return fetchUserAttributes() as Promise<CognitoUserAttributesData>;
  },
  /**
   * This function updates the `fe_commit_sha` attribute.
   * It is intended to be called once the user is signed in the background.
   * It will not be awaited and no error will be logged.
   */
  updateUsedCommitSha() {
    updateUserAttribute({
      userAttribute: {
        attributeKey: 'custom:fe_commit_sha',
        value: commitSha ?? 'local-build',
      },
    }).catch((error) => {
      console.error('Failed to update "fe_commit_sha" attribute:', error);
    });
  },
});

export type Cognito = ReturnType<typeof createCognitoClient>;

// pass undefined as any, because we run assertContext at runtime
const CognitoContext = createContext<Cognito>(undefined as any);

export function useCognito() {
  const catchError = useCatchError();

  const context = useContext(CognitoContext);
  assertContext(context, 'Cognito');

  // in order to make proper use of the error boundary, we need to manually
  // overwrite the `catchError` function of the context here
  const cognito = useMemo(
    () => ({ ...context, catchError }),
    [context, catchError]
  );

  return cognito;
}

// exposed for mocking
export const MockedCognitoProvider = CognitoContext.Provider;

export const CognitoProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const postToAuthChannel = useAuthSessionChannel();
  const cognitoLastAuthUser = useCognitoLastAuthUser();

  const [cognitoClient] = useState(() =>
    createCognitoClient({
      cognitoLastAuthUser,
      onLogoutSuccess() {
        postToAuthChannel({ event: 'logout' });
      },
    })
  );

  return (
    <CognitoContext.Provider value={cognitoClient}>
      {children}
    </CognitoContext.Provider>
  );
};

/**
 * Note: A big list of potential error cases per Cognito use case can be found here:
 * https://github.com/aws-amplify/amplify-js/blob/09b14edaa3328659e97f80564b4df90d00b1aa94/packages/auth/src/providers/cognito/types/errors.ts
 *
 * But not all of them are relevant for us.
 */
type AuthErrorName =
  /**
   * E.g. this happens if you call `signIn` while there is already a signed in user.
   *
   * Example:
   * {
   *   name: 'UserAlreadyAuthenticatedException';
   *   recoverySuggestion: 'Call signOut before calling signIn again.';
   *   underlyingError: undefined;
   *   message: 'There is already a signed in user.';
   * }
   */
  | 'UserAlreadyAuthenticatedException'
  /**
   * E.g. this happens if you call `updatePassword` too often.
   */
  | 'LimitExceededException'
  /**
   * E.g. this happens if the user's email or password is incorrect or if no valid session was found.
   *
   * The variant with "Invalid session" can happen if you call `confirmSignIn({ challengeResponse: 'TOTP' })`
   * twice after you got `'CONTINUE_SIGN_IN_WITH_MFA_SELECTION'` in the `signIn` output.
   *
   * Example:
   * {
   *   name: 'NotAuthorizedException';
   *   recoverySuggestion: undefined;
   *   underlyingError: undefined;
   *   message: 'Incorrect username or password.' | 'Invalid session for the user.';
   * }
   */
  | 'NotAuthorizedException'
  /**
   * E.g. this happens if you call `getCurrentUser` or `updatePassword` without having a signed in user.
   *
   * Example:
   * {
   *   name: 'UserUnAuthenticatedException';
   *   recoverySuggestion: 'Sign in before calling this API again.';
   *   underlyingError: undefined;
   *   message: 'User needs to be authenticated to call this API.';
   * }
   */
  | 'UserUnAuthenticatedException'
  /**
   * This happens if you got `'CONTINUE_SIGN_IN_WITH_MFA_SELECTION'` in the `signIn` output, but
   * proceed with `confirmSignIn` and passing the verification code right away instead of
   * literally passing either `'TOTP'` or `'SMS'`.
   *
   * Example:
   * {
   *   name: 'IncorrectMFAMethod';
   *   recoverySuggestion: 'Try to pass TOTP or SMS as the challengeResponse';
   *   underlyingError: undefined;
   *   message: 'Incorrect MFA method was chosen. It should be either SMS or TOTP';
   * };
   */
  | 'IncorrectMFAMethodException'
  /**
   * Example:
   * {
   *   name: 'ExpiredCodeException';
   *   recoverySuggestion: undefined;
   *   underlyingError: undefined;
   *   message: 'Your software token has already been used once.';
   * }
   */
  | 'ExpiredCodeException'
  /**
   * This seems to not just cover simply incorrect codes, but by now also
   * outdated codes. (In the past there was a dedicated `ExpiredCodeException` for this.)
   *
   * Example:
   * {
   *   name: 'CodeMismatchException';
   *   recoverySuggestion: undefined;
   *   underlyingError: undefined;
   *   message: 'Invalid code received for user';
   * }
   */
  | 'CodeMismatchException'
  /**
   * This happens for example if your phone number contains "-" or if your
   * verification code contains characters.
   *
   * Example:
   * {
   *   name: 'InvalidParameterException';
   *   recoverySuggestion: undefined;
   *   underlyingError: undefined;
   *   message:
   *     | 'Invalid phone number format.'
   *     | "1 validation error detected: Value at 'userCode' failed to satisfy constraint: Member must satisfy regular expression pattern: [0-9]+";
   * }
   */
  | 'InvalidParameterException'
  /**
   * This happens if you try to setup TOTP MFA with an invalid code.
   *
   * Example:
   * {
   *   name: 'EnableSoftwareTokenMFAException';
   *   recoverySuggestion: undefined;
   *   underlyingError: undefined;
   *   message: 'Code mismatch';
   * }
   */
  | 'EnableSoftwareTokenMFAException'
  /**
   * An error occurred during the sign in process.
   *
   * This most likely occurred due to:
   * 1. signIn was not called before confirmSignIn.
   * 2. signIn threw an exception.
   * 3. page was refreshed during the sign in flow.
   */
  | 'SignInException'
  /**
   * If your password was resetted by CS in the AWS Console.
   */
  | 'PasswordResetRequiredException'
  | 'InvalidPasswordException';

type SpecificAuthError<ErrorName extends AuthErrorName> = StrictOmit<
  AuthError,
  'name'
> & {
  name: ErrorName;
};

export function isAuthError(
  error: unknown,
  name: AuthErrorName
): error is SpecificAuthError<AuthErrorName> {
  return error instanceof AuthError && error.name === name;
}
