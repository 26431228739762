import { useEffect } from 'react';
import urlTemplate from 'url-template';
import type {
  CapacityCategoriesResponse,
  CapacityManagementRootResponse,
} from 'src/apis/capacity-management/types';
import type { CapacityManagementRootRequest } from 'src/apis/capacity-management/use-capacity-management-root';
import { useAxios } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';

type HookConfig = {
  capacityManagementRoot: CapacityManagementRootRequest;
  scope?: 'organisation' | 'all';
};

type RequestOptions = {
  rootData: CapacityManagementRootResponse;
  scope?: 'organisation' | 'all';
};

export function useCapacityCategories({
  capacityManagementRoot,
  scope,
}: HookConfig) {
  const capacityCategories = useAxios(
    (axios, baseConfig, { rootData, scope }: RequestOptions) => {
      if (!rootData._links?.capacityCategories) throw new Forbidden();

      const url = urlTemplate
        .parse(rootData._links.capacityCategories.href)
        .expand({ scope });

      return axios.request<CapacityCategoriesResponse>({
        ...baseConfig,
        url,
      });
    },
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!capacityManagementRoot.response) return;

    const rootData = capacityManagementRoot.response.data;
    const options = { rootData, scope };

    capacityCategories.execute(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capacityManagementRoot.response, scope]);

  return capacityCategories;
}

export type CapacityCategoriesRequest = ReturnType<
  typeof useCapacityCategories
>;
