import type { FC } from 'react';
import { lazy } from 'react';
import type { AggregateEuRootRequest } from 'src/apis/aggregate-eu/use-aggregate-eu-root';
import type { Successful } from 'src/hooks/use-axios';

const Page = lazy(() => import('./page'));

export const AggregateEuCentralBuyer: FC<{
  aggregateEuRootRequest: Successful<AggregateEuRootRequest>;
}> = ({ aggregateEuRootRequest }) => {
  return <Page aggregateEuRootRequest={aggregateEuRootRequest} />;
};
