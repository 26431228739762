import { useEffect } from 'react';
import type { AggregateEuRootResponse } from 'src/apis/aggregate-eu/types';
import { isServerError, useAxios } from 'src/hooks/use-axios';

export function useAggregateEuRoot() {
  const aggregateEuRoot = useAxios(
    (axios, baseConfig) =>
      axios.request<AggregateEuRootResponse>({
        ...baseConfig,
        url: PRISMA_CONFIG.aggregateEu,
      }),
    {
      neededOnPageLoad: true,
      onError(error) {
        if (isServerError(error, 402)) {
          return error;
        }
      },
    }
  );

  useEffect(() => {
    aggregateEuRoot.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return aggregateEuRoot;
}

export type AggregateEuRootRequest = ReturnType<typeof useAggregateEuRoot>;
