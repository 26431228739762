import type { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { Colors } from 'src/styles';

export type Mode = 'title' | 'sub-section' | 'footer' | 'section' | 'card';

export type HeadingProps = {
  /**
   * Changes the look of your heading.
   */
  mode: Mode;
};

/**
 * For h1, h2 and h3:
 * - size increase by a factor of 1.5
 * - leading (line-height) is also set to 1.5
 **/

const TitleHeading = styled.h1`
  color: ${Colors.brand};
  font-size: 4.5rem;
  font-weight: 900;
  letter-spacing: -0.1rem;
  line-height: 6.75rem;
  font-family: 'Lineto circular pro', 'Roboto', 'sans-serif';
`;

const SectionHeading = styled.h2`
  color: ${Colors.brand};
  font-size: 3rem;
  font-weight: 900;
  line-height: 4.5rem;
  letter-spacing: -0.06rem;
  font-family: 'Lineto circular pro', 'Roboto', 'sans-serif';
`;

const CardHeading = styled.h3`
  color: ${Colors.brand};
  font-size: 2rem;
  font-weight: 900;
  line-height: 3.2rem;
  letter-spacing: -0.04rem;
  font-family: 'Lineto circular pro', 'Roboto', 'sans-serif';
`;

const SubSectionHeading = styled.h4`
  color: ${Colors.brand};
  font-size: 1.8rem;
  font-weight: 900;
  line-height: 3.2rem;
`;

const FooterHeading = styled.h4`
  color: white;
  font-size: 1.8rem;
  font-weight: 900;
  line-height: 3.2rem;
  margin-top: 1.05rem;
  margin-bottom: 1.05rem;
`;

export const Heading: FC<HeadingProps & HTMLAttributes<HTMLHeadingElement>> = ({
  mode,
  ...props
}) => {
  switch (mode) {
    case 'title':
      return <TitleHeading {...props} />;
    case 'section':
      return <SectionHeading {...props} />;
    case 'card':
      return <CardHeading {...props} />;
    case 'sub-section':
      return <SubSectionHeading {...props} />;
    case 'footer':
      return <FooterHeading {...props} />;
  }
};
