import { useEffect } from 'react';
import type {
  BundleSituation,
  NetworkPointSimple,
} from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';

export function useMarketAreas() {
  const request = useAxios(
    (axios, baseConfig) =>
      axios
        .request<string[]>({
          ...baseConfig,
          url: `${PRISMA_CONFIG.monolithApiUrl}/tso/marketAreas`,
          method: 'GET',
        })
        .then((response) => {
          // hack for PROD to filter out irrelevant market area
          // see https://prisma.atlassian.net/browse/UXX-193
          response.data = response.data.filter(
            (area) => area !== 'SSO Dummy Market Area'
          );
          return response;
        }),
    {
      neededOnPageLoad: true,
    }
  );

  useEffect(() => {
    request.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return request;
}

export type MarketAreasRequest = ReturnType<typeof useMarketAreas>;

export function useCapacityCategories() {
  const request = useAxios(
    (axios, baseConfig) =>
      axios.request<string[]>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/networkPoints/capacityCategories`,
        method: 'GET',
      }),
    {
      neededOnPageLoad: true,
    }
  );

  useEffect(() => {
    request.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return request;
}

export type CapacityCategoriesRequest = ReturnType<
  typeof useCapacityCategories
>;

export function useBundleSituation(id: number) {
  const request = useAxios(
    (axios, baseConfig, id: number) =>
      axios.request<BundleSituation>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/networkPoints/${id}/bundle-situation`,
        method: 'GET',
      }),
    {
      neededOnPageLoad: true,
    }
  );

  useEffect(() => {
    request.execute(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return request;
}

export function useRelatedBundles(id: number) {
  const request = useAxios(
    (axios, baseConfig, id: number) =>
      axios.request<NetworkPointSimple[]>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/networkPoints/${id}/related-bundles`,
        method: 'GET',
      }),
    {
      neededOnPageLoad: true,
    }
  );

  useEffect(() => {
    request.execute(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return request;
}
