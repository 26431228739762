import type { TextareaHTMLAttributes } from 'react';
import { forwardRef, useEffect, useId } from 'react';
import mergeRefs from 'react-merge-refs';
import styled, { css } from 'styled-components';
import { ErrorMessage } from 'src/components/form/error-message';
import { useFieldGroup } from 'src/components/form/field-group';
import { FieldItem, FieldLayout } from 'src/components/form/field-layout';
import type { FormFieldProps } from 'src/components/form/form-field-props';
import { FieldLabel } from 'src/components/form/label';
import {
  borderRadius,
  borderWidth,
  placeholderStyle,
} from 'src/components/form/styles';
import { useCustomField } from 'src/components/form/use-custom-field';
import { useFocusManager } from 'src/components/form/use-focus-manager';
import { Tooltip } from 'src/components/overlay/tooltip';
import { Hint } from 'src/components/text/hint';
import { useDefaultStacked } from 'src/hooks/use-default-stacked';

import { Colors } from 'src/styles';

export const SimpleTextarea = styled.textarea<{ error?: boolean }>`
  @-moz-document url-prefix() {
    // inline-table instead block needed to display correct baseline for Firefox
    display: inline-table;
    vertical-align: baseline;
  }
  display: block;
  width: 100%;
  color: ${Colors.brand};
  border: ${borderWidth} solid ${Colors.brand};
  border-radius: ${borderRadius};
  padding-top: 0.6rem;
  padding-bottom: 0.7rem;
  padding-left: 2rem;
  padding-right: 2rem;
  line-height: normal; /* needed on iOS for centering the placeholder */
  min-height: 8rem;
  resize: vertical;

  ${({ placeholder }) =>
    placeholder &&
    css`
      ::placeholder {
        ${placeholderStyle};
      }
    `}

  ${({ error }) =>
    error &&
    css`
      color: ${Colors.error};
      border-color: ${Colors.error};
      background-color: ${Colors.errorLighter};

      ::placeholder {
        color: ${Colors.error};
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      border-color: ${Colors.inactive};
      background: ${Colors.inactiveLighter};
      cursor: not-allowed;
      color: ${Colors.inactive};

      ::placeholder {
        color: ${Colors.inactive};
      }
    `}
`;

export type TextareaProps = {
  stacked?: boolean;
  nested?: boolean;
} & FormFieldProps &
  TextareaHTMLAttributes<HTMLTextAreaElement>;

/**
 * Use this component whenever a user needs to provide a longer text input.
 *
 * For shorter text input you can use [Input](../?path=/docs/components-form-input--docs).
 *
 * If the user needs to provide Markdown use [Markdown Editor](../?path=/docs/components-form-markdown-editor--docs) instead.
 */
export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => {
    const { isStacked, minTablet } = useDefaultStacked();

    const {
      label,
      name,
      stacked = isStacked,
      nested,
      hideLabel = false,
      hint,
      info,
      markAsRequired,
      ...inputProps
    } = props;
    const id = useId();

    const [field, showError, error] = useCustomField<string | null>(
      name,
      label
    );

    const formGroup = useFieldGroup();
    useEffect(() => {
      if (formGroup) {
        formGroup.onError({ name, showError });
      }
    }, [showError, name, formGroup]);

    const focusRef = useFocusManager();

    const fieldId = formGroup?.id || id;
    const displayLabel = `${label}${markAsRequired ? '*' : ''}`;
    const ariaLabel = hideLabel ? displayLabel : undefined;

    const fieldItem = (
      <FieldItem>
        <Tooltip
          content={
            inputProps.disabled && inputProps.disabledMessage
              ? inputProps.disabledMessage
              : undefined
          }
        >
          {(targetProps) => (
            <SimpleTextarea
              ref={mergeRefs([focusRef, ref])}
              id={fieldId}
              error={showError}
              data-test-error={showError}
              data-testid={field.name}
              {...field}
              aria-label={ariaLabel}
              {...inputProps}
              // if value is null, default to empty string
              value={field.value ?? ''}
              {...targetProps}
            />
          )}
        </Tooltip>

        {hint && (
          <Hint
            disabled={props.disabled}
            mode="formInput"
            data-testid={`${field.name}Hint`}
          >
            {hint}
          </Hint>
        )}

        {showError && error && (
          <ErrorMessage
            data-testid={`${name}Error`}
            error={error}
            label={label}
          />
        )}
      </FieldItem>
    );

    return ((formGroup && !minTablet) || !formGroup) && !hideLabel ? (
      <FieldLayout stacked={stacked}>
        <FieldLabel
          name={name}
          displayLabel={displayLabel}
          fieldId={fieldId}
          info={info}
          showError={showError}
          nested={nested}
        />

        {fieldItem}
      </FieldLayout>
    ) : (
      fieldItem
    );
  }
);
