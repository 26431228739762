import { useEffect } from 'react';
import type { AuctionOrder } from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';

type Options = {
  onlyIfShipper?: boolean;
  auctionId: string;
};

type Params = {
  auctionId: string;
};

export function useOrders({ auctionId, onlyIfShipper }: Options) {
  const monolithUser = useOptionalAuthenticatedMonolithUser();
  const ignoreRequest = onlyIfShipper && !monolithUser?.isShipper;

  const orders = useAxios(
    (axios, baseConfig, { auctionId }: Params) =>
      axios.request<AuctionOrder[]>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/auctions/${auctionId}/orders`,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (ignoreRequest) return;
    orders.execute({ auctionId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auctionId]);

  if (ignoreRequest) return;

  return orders;
}

export type OrdersRequest = ReturnType<typeof useOrders>;
