import type {
  ComfortBidConfig,
  ComfortBidConfigParams,
} from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';

export function useComfortBidConfig() {
  const comfortBidConfig = useAxios(
    (axios, baseConfig, { params }: { params: ComfortBidConfigParams }) =>
      axios.request<ComfortBidConfig>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/auctions/comfortBids/config`,
        params,
      }),
    { neededOnPageLoad: true }
  );

  return comfortBidConfig;
}

export type ComfortBidConfigRequest = ReturnType<typeof useComfortBidConfig>;
