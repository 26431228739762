import { useEffect } from 'react';
import type { PremiumServicesDebtor } from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';

export function usePremiumServicesBillingAddress(referenceId: number) {
  const api = useAxios(
    (axios, baseConfig, referenceId: number) =>
      axios.request<PremiumServicesDebtor>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/shippers/${referenceId}/shipper-premium-services-billing-address`,
        method: 'GET',
        timeout: 5000,
      }),
    {
      neededOnPageLoad: true,
      cacheKey: `PremiumServicesBillingAddress-${referenceId}`,
    }
  );

  useEffect(() => {
    api.execute(referenceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referenceId]);

  return api;
}

export type PremiumServicesBillingAddressRequest = ReturnType<
  typeof usePremiumServicesBillingAddress
>;
