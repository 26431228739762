import { useEffect } from 'react';
import type { MyUserDetailsResponse } from 'src/apis/organisation-service/types';
import type { OrganisationServiceRequest } from 'src/apis/organisation-service/use-organisation-service';
import type { Successful } from 'src/hooks/use-axios';
import { useAxios } from 'src/hooks/use-axios';

type Options = {
  organisationService: OrganisationServiceRequest;
};

type Params = {
  organisationService: Successful<OrganisationServiceRequest>;
};

export function useMyUserDetails({ organisationService }: Options) {
  const userDetails = useAxios(
    (axios, baseConfig, { organisationService }: Params) =>
      axios.request<MyUserDetailsResponse>({
        ...baseConfig,
        url: organisationService.response.data._links.getMyUserDetails.href,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!organisationService.response) return;
    userDetails.execute({ organisationService });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationService.response]);

  return userDetails;
}

export type MyUserDetailsRequest = ReturnType<typeof useMyUserDetails>;
