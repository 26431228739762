import { useEffect } from 'react';
import type { ShipperUser } from 'src/apis/monolith/types';
import { useAxios, isServerError } from 'src/hooks/use-axios';

export function useShipperUser(id: string) {
  const shipperUser = useAxios(
    (axios, baseConfig) =>
      axios.request<ShipperUser>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/shippers/user/${id}`,
      }),
    {
      neededOnPageLoad: true,
      onError(error) {
        // return 500 with UNKNOWN_SHIPPER_USER for deactivated users :(
        if (
          isServerError(error, 500) &&
          error.response.data &&
          (error.response.data as any).message === 'UNKNOWN_SHIPPER_USER'
        ) {
          return error;
        } else {
          throw error;
        }
      },
    }
  );

  useEffect(() => {
    shipperUser.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return shipperUser;
}

export type ShipperUserRequest = ReturnType<typeof useShipperUser>;
