import type {
  MonthlyStatementStatus,
  MonthlyStatementType,
} from 'src/apis/capacity-management/types';
import { labelsToOptions } from 'src/utils/labels-to-options';
import { labelsToValues } from 'src/utils/labels-to-values';

export const monthlyStatementStatusLabels: Record<
  MonthlyStatementStatus,
  string
> = {
  DRAFT: 'Drafted',
  APPROVED: 'Approved',
};

export const monthlyStatementStatusValues = labelsToValues(
  monthlyStatementStatusLabels
);

export const monthlyStatementStatusOptions = labelsToOptions(
  monthlyStatementStatusLabels
);

export const monthlyStatementTypeLabels: Record<MonthlyStatementType, string> =
  {
    COMMODITY_FEE: 'Commodity Fee',
    CAPACITY_FEE: 'Capacity Fee',
  };

export const monthlyStatementTypeOptions = labelsToOptions(
  monthlyStatementTypeLabels
);
