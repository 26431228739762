import { useEffect } from 'react';
import type {
  StorageOffers,
  StorageOffersSearchParams,
} from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';

export function useStorageOffers(params: StorageOffersSearchParams) {
  const storageOffers = useAxios(
    (axios, baseConfig, params: StorageOffersSearchParams) =>
      axios.request<StorageOffers>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/storage-offer/page`,
        method: 'GET',
        params,
      }),
    {
      neededOnPageLoad: true,
    }
  );

  useEffect(() => {
    storageOffers.execute(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return storageOffers;
}

export type StorageOffersRequest = ReturnType<typeof useStorageOffers>;
