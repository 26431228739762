import { z } from 'zod';
import type { AmountType, CostType } from 'src/apis/monolith/types';
import { flowDirectionValues } from 'src/components/data-display/direction';
import { monolithFilterOptionsSchema } from 'src/components/form/select/searchable-multi-select-monolith';
import { getPaginationSchema } from 'src/components/form/zod-schemas';
import {
  optionalDefault,
  preprocessAsArray,
} from 'src/components/form/zod-utilities';
import { periodTypeLabels } from 'src/pages/reporting/auctions/components/period-type';
import { capacityLabels } from 'src/pages/reporting/capacity';
import { rawProductStateValues } from 'src/pages/reporting/products/types';
import { labelsToValues } from 'src/utils/labels-to-values';

const amountSettingLabels: Record<AmountType, string> = {
  KILO: 'kWh',
  MEGA: 'mWh Number',
};
const amountSettingValues = labelsToValues(amountSettingLabels);

const costTypeLabels: Record<CostType, string> = {
  UNIT: 'Unit',
  SUBUNIT: 'Subunit',
};
const costTypeValues = labelsToValues(costTypeLabels);

const capacityCategoryValues = labelsToValues(capacityLabels);

const periodTypeValues = labelsToValues(periodTypeLabels);

export const uploadedProductsOverviewSchema = z
  .object({
    sortColumn: z
      .enum([
        'changedAt',
        'offerId',
        'networkPointName',
        'capacityCategory',
        'periodType',
        'runtimeStart',
        'regulatedTariff',
        'amountQuantityValue',
        'state',
      ])
      .default('changedAt'),
    sortAscending: z.string().default('desc'),
    amountSetting: z.enum(amountSettingValues).default('KILO'),
    costSetting: z.enum(costTypeValues).default('SUBUNIT'),
    changedAtFrom: z.string().nullable().default(null),
    changedAtTo: z.string().nullable().default(null),
    offerId: z.string().nullable().default(null),
    networkPointName: monolithFilterOptionsSchema,
    direction: z.preprocess(
      preprocessAsArray,
      z.array(z.enum(flowDirectionValues)).default([])
    ),
    capacityCategory: z.preprocess(
      preprocessAsArray,
      z.array(z.enum(capacityCategoryValues)).default([])
    ),
    periodType: z.preprocess(
      preprocessAsArray,
      z.array(z.enum(periodTypeValues)).default([])
    ),
    runtimeFrom: z.string().nullable().default(null),
    runtimeTo: z.string().nullable().default(null),
    'regulatedTariff-min': z.coerce.number().nullable().default(null),
    'regulatedTariff-max': z.coerce.number().nullable().default(null),
    'amount-min': z.coerce.number().nullable().default(null),
    'amount-max': z.coerce.number().nullable().default(null),
    rawProductState: z.preprocess(
      optionalDefault(['UPLOADED', 'SUBMITTED']),
      z.preprocess(preprocessAsArray, z.array(z.enum(rawProductStateValues)))
    ),
  })
  .merge(getPaginationSchema());
