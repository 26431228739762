import type { FC } from 'react';
import { lazy } from 'react';
import { useMemoOne } from 'use-memo-one';
import type {
  NwpCapacityGraphParams,
  ShipperTransactionsParams,
} from 'src/apis/capacity-management/types';
import type { CapacityManagementRootRequest } from 'src/apis/capacity-management/use-capacity-management-root';
import { useNwpCapacityGraph } from 'src/apis/capacity-management/use-nwp-capacity-graph';
import { useShipperTransactions } from 'src/apis/capacity-management/use-shipper-transactions';
import type { NetworkPoint } from 'src/apis/monolith/types';
import type { NetworkPointRequest } from 'src/apis/monolith/use-network-point';
import { PageSpinner } from 'src/components/spinner-container';
import { useCapacityOverviewPageParams } from 'src/pages/transport/network-points/details/capacity-overview/use-page-params';

const Page = lazy(() => import('./page'));

type ContentProps = {
  networkPoint: NetworkPoint;
  capacityManagementRoot: CapacityManagementRootRequest;
};

const Content: FC<ContentProps> = ({
  networkPoint,
  capacityManagementRoot,
}) => {
  const pageParams = useCapacityOverviewPageParams();

  const transactionsParams = useMemoOne<ShipperTransactionsParams>(() => {
    const {
      start,
      pageSize,
      sortBy,
      sortDirection,
      runtimeIntersectionFilterStart,
      runtimeIntersectionFilterEnd,
    } = pageParams.value;

    return {
      offset: start,
      limit: pageSize,
      sortBy,
      sortDirection,
      status: ['SUCCESSFUL'],
      networkPointId: [networkPoint.networkPointUuid],
      runtimeIntersectionFilterStart,
      runtimeIntersectionFilterEnd,
    };
  }, [pageParams.value, networkPoint.networkPointUuid]);

  const shipperTransactions = useShipperTransactions({
    capacityManagementRoot,
    params: transactionsParams,
    throwOnForbidden: false,
  });

  const paramsForGraph = useMemoOne<NwpCapacityGraphParams>(() => {
    return {
      runtimeIntersectionFilterStart:
        pageParams.value.runtimeIntersectionFilterStart,
      runtimeIntersectionFilterEnd:
        pageParams.value.runtimeIntersectionFilterEnd,
      granularity: pageParams.value.granularity,
      nwpId: networkPoint.networkPointUuid,
    };
  }, [pageParams.value, networkPoint.networkPointUuid]);

  const nwpCapacityGraph = useNwpCapacityGraph({
    capacityManagementRoot,
    params: paramsForGraph,
  });

  return (
    <Page
      capacityManagementRoot={capacityManagementRoot}
      networkPoint={networkPoint}
      shipperTransactions={shipperTransactions}
      pageParams={pageParams}
      nwpCapacityGraph={nwpCapacityGraph}
    />
  );
};

type Props = {
  networkPoint: NetworkPointRequest;
  capacityManagementRoot: CapacityManagementRootRequest;
};

export const CapacityOverview: FC<Props> = ({
  networkPoint,
  capacityManagementRoot,
}) => {
  if (!networkPoint.response) return <PageSpinner />;

  return (
    <Content
      networkPoint={networkPoint.response.data}
      capacityManagementRoot={capacityManagementRoot}
    />
  );
};
