import { useEffect } from 'react';
import urltemplate from 'url-template';
import type {
  UnderlyingDataResponse,
  UnderlyingDataType,
} from 'src/apis/capacity-management/types';
import { useAxios } from 'src/hooks/use-axios';
import type { DraftedProductRequest } from 'src/pages/reporting/products/drafted/detail/use-drafted-product';
import type { PageParams } from 'src/pages/reporting/products/uploaded/detail/use-page-params';
import type { UploadedProductRequest } from 'src/pages/reporting/products/uploaded/detail/use-uploaded-product';

type HookConfig = {
  product: DraftedProductRequest | UploadedProductRequest;
  pageParams: PageParams;
};

type RequestOptions = {
  url: string;
};

export function useUnderlyingData({ product, pageParams }: HookConfig) {
  const underlyingData = useAxios(
    (axios, baseConfig, { url }: RequestOptions) =>
      axios.request<UnderlyingDataResponse>({
        ...baseConfig,
        url,
      }),
    {
      neededOnPageLoad: true,
    }
  );

  useEffect(() => {
    if (!product.response) return;

    const linkKey = linkMap[pageParams.value.underlyingData];
    const link = product.response.data._links[linkKey];
    if (!link) return;

    underlyingData.execute({
      url: urltemplate.parse(link.href).expand({
        limit: pageParams.value.pageSize,
        offset: pageParams.value.start,
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    product.response,
    pageParams.value.underlyingData,
    pageParams.value.start,
    pageParams.value.pageSize,
  ]);

  return underlyingData;
}

export type UnderlyingDataRequest = ReturnType<typeof useUnderlyingData>;

type UnderlyingDataLinks =
  | 'technicalCapacityUnderlyingData'
  | 'availableTechnicalCapacityUnderlyingData'
  | 'nominatedCapacityUnderlyingData'
  | 'requestedSurrenderCapacityUnderlyingData'
  | 'fdaUioliCapacityUnderlyingData'
  | 'bookedCapacityUnderlyingData';

const linkMap: Record<UnderlyingDataType, UnderlyingDataLinks> = {
  TECHNICAL_CAPACITY: 'technicalCapacityUnderlyingData',
  AVAILABLE_TECHNICAL_CAPACITY: 'availableTechnicalCapacityUnderlyingData',
  NOMINATED_CAPACITY: 'nominatedCapacityUnderlyingData',
  REQUESTED_SURRENDER_CAPACITY: 'requestedSurrenderCapacityUnderlyingData',
  FDAUIOLI_CAPACITY: 'fdaUioliCapacityUnderlyingData',
  BOOKED_CAPACITY: 'bookedCapacityUnderlyingData',
};
