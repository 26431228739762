import type { FC } from 'react';
import { lazy } from 'react';
import { useParams } from 'react-router-dom';
import type { AssignmentServiceRequest } from 'src/apis/assignment-service/use-assignment-service-root';
import { useAssignmentServicePublic } from 'src/apis/assignment-service/use-assignment-service-root';
import type { ContactsParams } from 'src/apis/organisation-service/types';
import { useContacts } from 'src/apis/organisation-service/use-contacts';
import type { OrganisationDetailsRequest } from 'src/apis/organisation-service/use-organisation-details';
import { useOrganisationDetails } from 'src/apis/organisation-service/use-organisation-details';
import type { OrganisationServiceRequest } from 'src/apis/organisation-service/use-organisation-service';
import { PageSpinner } from 'src/components/spinner-container';
import type { Successful } from 'src/hooks/use-axios';
import { useContact } from 'src/pages/settings/organisation/contacts/edit/use-contact';
import { Forbidden } from 'src/utils/errors';

const contactsParams: ContactsParams = {
  limit: 0, // we just need the edit link
};

const Page = lazy(() => import('./page'));

type Props = {
  organisationService: Successful<OrganisationServiceRequest>;
};

export const EditContact: FC<Props> = ({ organisationService }) => {
  if (!organisationService.response.data._links.getMyOrganisationDetails)
    throw new Forbidden();
  const assignmentService = useAssignmentServicePublic();

  const organisationDetails = useOrganisationDetails({
    url: organisationService.response.data._links.getMyOrganisationDetails.href,
  });

  if (!organisationDetails.response || !assignmentService.response)
    return <PageSpinner />;

  return (
    <LoadContact
      organisationDetails={organisationDetails}
      assignmentService={assignmentService}
    />
  );
};

const LoadContact: FC<{
  organisationDetails: Successful<OrganisationDetailsRequest>;
  assignmentService: Successful<AssignmentServiceRequest>;
}> = ({ organisationDetails, assignmentService }) => {
  if (!organisationDetails.response.data._links.contacts) throw new Forbidden();

  const { contactId } = useParams<{ contactId: string }>();

  const contacts = useContacts({
    url: organisationDetails.response.data._links.contacts.href,
    params: contactsParams,
  });
  const contact = useContact({ contactId, contacts });

  if (!contact.response) return <PageSpinner />;

  return (
    <Page
      organisationDetails={organisationDetails}
      contact={contact}
      assignmentService={assignmentService}
    />
  );
};
