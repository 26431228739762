import type { AxiosResponse } from 'axios';
import { useEffect } from 'react';
import type { UserAssignmentResponse } from 'src/apis/monolith/types';
import { isServerError, useAxios } from 'src/hooks/use-axios';

// enriches the original server type with client-side only data
type EnhancedUserAssignmentResponse =
  | UserAssignmentResponse
  | {
      state: 'NOT_REQUESTED';
    };

type Params = {
  userId: string | number;
  tsoId: string | number;
};

/**
 * Requests assignment data between a single shipper user and a single operator.
 */
export function useUserAssignment({ userId, tsoId }: Params) {
  const assignment = useAxios(
    (axios, baseConfig, { userId, tsoId }: Params) =>
      axios
        .request<EnhancedUserAssignmentResponse>({
          ...baseConfig,
          url: `${PRISMA_CONFIG.monolithApiUrl}/shipperUsers/${userId}/assignments/${tsoId}`,
          method: 'GET',
        })
        .catch((error) => {
          if (isServerError(error, 400)) {
            // having so assignment requested is sadly treated as a 400 error ("No TSO assignment for id X")
            const response: AxiosResponse<EnhancedUserAssignmentResponse> = {
              ...error.response,
              data: { state: 'NOT_REQUESTED' },
            };
            return response;
          } else {
            throw error;
          }
        }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    assignment.execute({ userId, tsoId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, tsoId]);

  return assignment;
}

export type UserAssignmentRequest = ReturnType<typeof useUserAssignment>;
