import type { FC } from 'react';
import { lazy, useEffect, useState } from 'react';
import type { ShipperUserPlatformIds } from 'src/apis/monolith/types';
import { useGetOldUserIdDeprecated } from 'src/apis/monolith/use-get-old-user-id';
import { PageSpinner } from 'src/components/spinner-container';
import { useOnChange } from 'src/hooks/use-on-change';

const Page = lazy(() => import('./page'));

export const ApplyForAssignmentOld: FC = () => {
  const [oldUserId, setOldUserId] = useState<
    ShipperUserPlatformIds | undefined
  >();
  const getOldUserId = useGetOldUserIdDeprecated();

  useEffect(() => {
    getOldUserId.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useOnChange((response) => {
    if (!response) {
      return;
    }

    if (response.status === 200) {
      setOldUserId(response.data);
    }
  }, getOldUserId.response);

  if (!getOldUserId.response || !oldUserId) return <PageSpinner />;

  return <Page oldUserId={oldUserId} />;
};
