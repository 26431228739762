import { createContext, useContext, useEffect } from 'react';
import type { AggregateEuRootResponse } from 'src/apis/aggregate-eu/types';
import { isServerError, useAxios } from 'src/hooks/use-axios';
import { handleError } from 'src/utils/handle-error';
import { reportToSentry } from 'src/utils/report-to-sentry';

export function useAggregateEuRequest() {
  const aggregateEuRoot = useAxios(
    (axios, baseConfig) =>
      axios.request<AggregateEuRootResponse>({
        ...baseConfig,
        url: PRISMA_CONFIG.aggregateEu,
      }),
    {
      neededOnPageLoad: true,
      onError(error) {
        if (isServerError(error, 402)) {
          return error;
        }
        const [_, report] = handleError(error, () => {});
        if (report) reportToSentry(error);
        return error;
      },
    }
  );

  useEffect(() => {
    aggregateEuRoot.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return aggregateEuRoot;
}

export type AggregateEuRequest = ReturnType<typeof useAggregateEuRequest>;

const AggregateEuContext = createContext<AggregateEuRootResponse | undefined>(
  undefined
);

export const AggregateEuProvider = AggregateEuContext.Provider;

export function useAggregateEu() {
  return useContext(AggregateEuContext);
}
