import { createContext, useContext, useEffect, useState } from 'react';
import { useAxios } from 'src/hooks/use-axios';

type EntrypointAuthorization = {
  canView: boolean;
  canManage: boolean;
};

export type GdmFlags = {
  userCanAcces: boolean;
  authorization?: EntrypointAuthorization;
  error?: unknown;
};

const defaultGdmFlags: GdmFlags = {
  userCanAcces: false,
};

const GdmFlagsContext = createContext<GdmFlags>(defaultGdmFlags);

function useEntrypointAuthRequest() {
  const [error, setError] = useState<unknown>(undefined);

  const request = useAxios(
    (axios, baseConfig) =>
      axios.request<{ canView: boolean; canManage: boolean }>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.gasDataManagement}/entrypoint/authorization`,
        method: 'GET',
        timeout: 5000,
      }),
    {
      neededOnPageLoad: true,
      onError: setError,
    }
  );

  useEffect(() => {
    request.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loaded: Boolean(request.response || error),
    data: request.response?.data,
    error,
  };
}

type GdmFlagsValue = {
  loaded: boolean;
  value: GdmFlags;
};

export function useGdmFlagsValue() {
  const { loaded, error, data } = useEntrypointAuthRequest();
  return {
    loaded,
    value: {
      userCanAcces: data?.canView ?? false,
      authorization: data,
      error,
    },
  } as GdmFlagsValue;
}

export function useGmdFlags() {
  return useContext(GdmFlagsContext);
}

export function useGmdAuthorization() {
  return useContext(GdmFlagsContext).authorization;
}

export const GdmFlagsProvider = GdmFlagsContext.Provider;
