import type { FC } from 'react';
import type { TsoRequest } from 'src/apis/monolith/use-tso';
import { Card } from 'src/components/data-display/card';
import { Stack } from 'src/components/layout/stack';
import { ExternalLink } from 'src/components/navigation/link';
import { Heading } from 'src/components/text/heading';

type ContractingServicesProps = {
  tso: TsoRequest;
};
export const AssignmentDetailsContractingServices: FC<
  ContractingServicesProps
> = ({ tso }) => {
  return (
    <>
      {tso.response && tso.response.data.contractingServicesList.length > 0 && (
        <Card>
          <Stack gap={1}>
            <Stack flow="column" alignItems="center" autoColumns="1fr auto">
              <Heading mode="card">Contracting Services</Heading>
              <ExternalLink
                size="small"
                mode="button-primary"
                href={`${PRISMA_CONFIG.angularUrl}/#/account-settings/assignment-of-tsos/contracting-services/${tso.response.data.id}`}
              >
                Request New
              </ExternalLink>
            </Stack>
          </Stack>
        </Card>
      )}
    </>
  );
};
