import styled from 'styled-components';
import { Colors } from 'src/styles';

export const Divider = styled.hr`
  width: 100%;
  border: none;
  border-top: 0.1rem solid ${Colors.brandLight3};
`;

export const CardDivider = styled.hr`
  width: calc(100% + 3rem);
  margin: 0 -1.5rem;
  border: none;
  border-top: 0.1rem solid ${Colors.brandLight3};
`;
