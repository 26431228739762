import type { FC } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { PageSpinner } from 'src/components/spinner-container';
import { useNetworkPointByUuid } from 'src/hooks/use-network-point-by-uuid';

export const NetworkPointRedirect: FC = () => {
  const { networkPointUuid } = useParams<{ networkPointUuid: string }>();
  const networkPoint = useNetworkPointByUuid({ uuid: networkPointUuid });

  if (networkPoint.error) return <ThrowNotFound />;
  if (!networkPoint.response) return <PageSpinner />;

  return (
    <Navigate
      replace
      to={`/platform/#/network-point/details/${networkPoint.response.data.id}`}
    />
  );
};
