import { faBan } from '@fortawesome/pro-solid-svg-icons';
import type { FC } from 'react';
import type { PublicOperatorDetailsResponse } from 'src/apis/organisation-service/types';
import { Stack } from 'src/components/layout/stack';
import { ExternalLink } from 'src/components/navigation/link';
import { Modal } from 'src/components/overlay/modal';
import { useCountdown } from 'src/hooks/use-countdown';

type Props = {
  close: () => void;
  onConfirm: () => void;
  pending: boolean;
  operatorDetails: PublicOperatorDetailsResponse;
};

export const CancelOrgAssignmentModal: FC<Props> = ({
  close,
  onConfirm,
  pending,
  operatorDetails,
}) => {
  const seconds = useCountdown();

  return (
    <Modal
      data-testid="cancel-assignment-modal"
      title="Cancel Organisation Assignment"
      icon={faBan}
      onDismiss={close}
      footer={{
        confirmButton: {
          label: seconds
            ? `Wait ${seconds}s to proceed...`
            : pending
              ? 'Pending...'
              : 'Cancel Assignment',
          onClick: onConfirm,
          disabled: pending || seconds !== 0,
          dataTestId: seconds === 0 ? 'cancel-assignment-submit' : undefined,
        },
      }}
    >
      <Stack>
        <p data-testid="cancel-assignment-content">
          Please, confirm that you want to cancel the organisation assignment
          and all user assignments to{' '}
          <strong>
            <ExternalLink href={operatorDetails.gtcUrl}>
              {operatorDetails.shortName}
            </ExternalLink>
          </strong>
          .
          <br />
          <br />
          The cancellation of an organisation assignment will also cancel all
          user assignments.
        </p>
      </Stack>
    </Modal>
  );
};
