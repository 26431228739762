import type { FC } from 'react';
import { lazy } from 'react';
import type { CmNetworkPointRequest } from 'src/apis/capacity-management/use-cm-network-point';
import { useTariff } from 'src/apis/capacity-management/use-tariff';
import { useTitle } from 'src/hooks/use-title';

const Page = lazy(() => import('./page'));

type Props = {
  cmNetworkPoint: CmNetworkPointRequest;
};

const EditPriceSettings: FC<Props> = ({ cmNetworkPoint }) => {
  const tariff = useTariff({ cmNetworkPoint });

  useTitle('Edit Price Settings');

  return <Page cmNetworkPoint={cmNetworkPoint} tariff={tariff} />;
};

export default EditPriceSettings;
