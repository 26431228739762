import { useEffect } from 'react';
import type { DvgwConfigurationResponse } from 'src/apis/assignment-service/types';
import { useAxios } from 'src/hooks/use-axios';

export function useOptionalDvgwConfiguration(url?: string) {
  const dvgwConfiguration = useAxios(
    (axios, baseConfig, { url }: { url: string }) =>
      axios.request<DvgwConfigurationResponse>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!url) return;
    dvgwConfiguration.execute({ url });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  if (!url) return;

  return dvgwConfiguration;
}

export type DvgwConfigurationRequest = ReturnType<
  typeof useOptionalDvgwConfiguration
>;
