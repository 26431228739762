import type { FC } from 'react';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import type { UsersAssignmentsByOperatorParams } from 'src/apis/assignment-service/types';
import { useAssignmentInfo } from 'src/apis/assignment-service/use-assignment-info';
import type { AssignmentServiceRequest } from 'src/apis/assignment-service/use-assignment-service-root';
import { useAssignmentServicePublic } from 'src/apis/assignment-service/use-assignment-service-root';
import { useMyAssignmentsOptional } from 'src/apis/assignment-service/use-my-assignments';
import { usePublicRequiredDocuments } from 'src/apis/assignment-service/use-public-required-documents';
import { useUserAssignmentsByOperator } from 'src/apis/assignment-service/use-user-assignments-by-operator';
import { useContractManagement } from 'src/apis/contract-management/use-contract-management';
import { useOperatorByTso } from 'src/apis/contract-management/use-operator-by-tso';
import { useOldUserId } from 'src/apis/monolith/use-get-old-user-id';
import { useOperatorPlatformId } from 'src/apis/monolith/use-operator-platform-id';
import { useOrganisationAssignments } from 'src/apis/monolith/use-organisation-assignments';
import { useShipperUser } from 'src/apis/monolith/use-shipper-user';
import { useTso } from 'src/apis/monolith/use-tso';
import { useUserAssignment } from 'src/apis/monolith/use-user-assignment';
import type { ContactsParams } from 'src/apis/organisation-service/types';
import { useOptionalContacts } from 'src/apis/organisation-service/use-optional-contacts';
import { useOrganisationService } from 'src/apis/organisation-service/use-organisation-service';
import { useOrganisationServicePublic } from 'src/apis/organisation-service/use-organisation-service-public';
import type { PublicOperatorDetailsRequest } from 'src/apis/organisation-service/use-public-operator-details';
import { usePublicOperatorDetails } from 'src/apis/organisation-service/use-public-operator-details';
import { PageSpinner } from 'src/components/spinner-container';
import type { Successful } from 'src/hooks/use-axios';
import { isNetworkError } from 'src/hooks/use-axios';
import { Page } from 'src/pages/operators/assignments/details/page';
import { useAssignmentsAndContactsParams } from 'src/pages/operators/assignments/details/use-page-params';
import { Forbidden } from 'src/utils/errors';

export const AssigmentDetails = () => {
  const { operatorUuid, userUuid } = useParams<{
    operatorUuid: string;
    userUuid: string;
  }>();

  const tsoPlatformIds = useOperatorPlatformId(operatorUuid);
  const oldUserId = useOldUserId({ userUuid });
  const assignmentService = useAssignmentServicePublic();
  const organisationServicePublic = useOrganisationServicePublic();
  const operatorDetails = usePublicOperatorDetails({
    organisationServicePublic,
    operatorUuid,
  });

  if (
    !oldUserId.response ||
    !tsoPlatformIds.response ||
    !operatorDetails.response ||
    !assignmentService.response
  )
    return <PageSpinner />;

  if (oldUserId.response.data.customerUserId === undefined)
    throw new Forbidden();

  return (
    <AssignmentsDetailsWithOldId
      oldTsoId={tsoPlatformIds.response.data.referenceId.toString()}
      userId={oldUserId.response.data.customerUserId.toString()}
      operatorDetails={operatorDetails}
      operatorUuid={operatorUuid}
      assignmentService={assignmentService}
    />
  );
};

type Props = {
  oldTsoId: string;
  operatorUuid: string;
  userId: string;
  operatorDetails: Successful<PublicOperatorDetailsRequest>;
  assignmentService: Successful<AssignmentServiceRequest>;
};

const AssignmentsDetailsWithOldId: FC<Props> = ({
  oldTsoId,
  operatorUuid,
  userId,
  operatorDetails,
  assignmentService,
}) => {
  const tso = useTso(oldTsoId);
  const contractManagement = useContractManagement();
  const organisationAssignments = useOrganisationAssignments();
  const organisationService = useOrganisationService();

  const userAssignment = useUserAssignment({
    tsoId: oldTsoId,
    userId,
  });
  const shipperUser = useShipperUser(userId);
  const caMaOperator = useOperatorByTso({ tso });
  const assignmentInfo = useAssignmentInfo({
    assignmentService,
    operatorId: operatorUuid,
  });

  const pageParams = useAssignmentsAndContactsParams();

  const params = useMemo(
    () =>
      ({
        limit: pageParams.contactDetails.value.pageSize,
        offset: pageParams.contactDetails.value.start,
      }) satisfies ContactsParams,
    [pageParams.contactDetails.value]
  );

  const paramsUserAssignments = useMemo(() => {
    return {
      sortBy: pageParams.userAssignmentsByOperator.value.sortBy,
      sortDirection: pageParams.userAssignmentsByOperator.value.sortDirection,
      limit: pageParams.userAssignmentsByOperator.value.pageSize,
      offset: pageParams.userAssignmentsByOperator.value.start,
      userName: pageParams.userAssignmentsByOperator.value.userName,
      userEmail: pageParams.userAssignmentsByOperator.value.userEmail,
      status: pageParams.userAssignmentsByOperator.value.status,
    } satisfies UsersAssignmentsByOperatorParams;
  }, [pageParams.userAssignmentsByOperator.value]);

  const userAssignments = useUserAssignmentsByOperator({
    url: assignmentService.response.data._links.getUserAssignmentsByOperator
      ?.href,
    params: paramsUserAssignments,
    operatorId: operatorUuid,
  });

  const contacts = useOptionalContacts({
    assignmentInfo,
    params,
  });

  const myAssignmentsRequest = useMyAssignmentsOptional({
    assignmentService,
    operatorId: operatorUuid,
  });

  const publicRequiredDocuments = usePublicRequiredDocuments({
    assignmentService,
    operatorId: operatorUuid,
  });

  if (
    !tso.response ||
    !shipperUser.response ||
    !organisationAssignments.response ||
    !publicRequiredDocuments.response ||
    contacts?.response === null ||
    !userAssignment.response ||
    myAssignmentsRequest?.response === null ||
    (caMaOperator && !caMaOperator.loaded) ||
    (!contractManagement.response &&
      !isNetworkError(contractManagement.error)) ||
    !assignmentInfo?.response ||
    !organisationService.response ||
    userAssignments?.response === null
  ) {
    return <PageSpinner />;
  }

  return (
    <Page
      tsoId={oldTsoId}
      userId={userId}
      tso={tso}
      shipperUser={shipperUser}
      contractManagement={contractManagement}
      organisationAssignments={organisationAssignments}
      userAssignment={userAssignment}
      publicRequiredDocuments={publicRequiredDocuments}
      operatorDetails={operatorDetails}
      assignmentInfo={assignmentInfo}
      contacts={contacts}
      organisationService={organisationService}
      assignmentService={assignmentService}
      myAssignmentsRequest={myAssignmentsRequest}
      userAssignments={userAssignments}
      paramsUserAssignments={pageParams}
    />
  );
};
