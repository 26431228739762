import * as Sentry from '@sentry/react';
import type { FC, ReactNode } from 'react';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'src/utils/cognito-config';
import { App } from 'src/app';
import { GlobalErrorHandler } from 'src/components/feedback/global-error-handler';
import { ReactShell } from 'src/components/layout/react-shell';
import { isNetworkError, isServerError } from 'src/hooks/use-axios';
import { AxiosInstanceProvider } from 'src/hooks/use-axios/axios-instance';
import { CognitoProvider, useCognito } from 'src/hooks/use-cognito';
import { ReauthenticateProvider } from 'src/hooks/use-reauthenticate';
import { useSafeLocalStorage } from 'src/hooks/use-storage';
import { ToastsProvider } from 'src/hooks/use-toasts';
import { DeveloperToolsProvider } from 'src/pages/developer-tools/use-developer-tools';
import { createAxiosInstance } from 'src/utils/axios-instance';
import { baseUrl } from 'src/utils/base-url';
import { getBreadcrumbsForSentry } from 'src/utils/breadcrumbs';
import { metaJson } from 'src/utils/fetch-meta-json';
import { getSimplifiedUrl } from 'src/utils/report-to-sentry';

if (process.env.NODE_ENV === 'production') {
  const commitShaIndexHtml = document.querySelector('[data-commit-sha]');
  Sentry.init({
    dsn: 'https://d888e86e50654461b4ed7712f9757df2@sentry.io/1832012',
    release: import.meta.env.VITE_CI_COMMIT_SHA,
    environment: PRISMA_CONFIG.stage,
    beforeSend(event, hint) {
      const error = hint.originalException;

      // manual ignore list
      if (
        error instanceof Error &&
        // assumption: we get "Failed to fetch dynamically imported module"s from vite, because of flaky connections
        // on the client as we can't fix those, we'll ignore them here
        (error.message.startsWith(
          'Failed to fetch dynamically imported module'
        ) ||
          error.message === 'NetworkError when attempting to fetch resource.' ||
          error.message === 'error loading dynamically imported module' ||
          // we don't know why we get this error, because it is NOT a cross-origin frame.
          // we cannot reproduce it and everything seems to be fine. therefor we ignore it.
          // (one of our guesses: this can be seen when people try to somehow embed our app in an iframe which we don't support anyway
          // or it happens for some crawlers. no real user ever complained about this..)
          error.message.includes(
            'Blocked a frame with origin "https://app.prisma-capacity.eu" from accessing a cross-origin frame.'
          ))
      )
        return null;

      // Group Errors With Greater Granularity
      // see https://docs.sentry.io/platforms/javascript/data-management/event-grouping/sdk-fingerprinting/#group-errors-with-greater-granularity
      if (isNetworkError(error)) {
        const { url, method } = error.config;
        const { path, service } = getSimplifiedUrl(url);
        event.fingerprint = [
          '{{ default }}',
          PRISMA_CONFIG.stage,
          service,
          path,
          String(method),
        ];
      }
      if (isServerError(error, null)) {
        const { url, method } = error.config;
        const { path, service } = getSimplifiedUrl(url);
        event.fingerprint = [
          '{{ default }}',
          PRISMA_CONFIG.stage,
          service,
          path,
          String(method),
          String(error.response.status),
        ];
      }
      if (error && typeof (error as any).code === 'string') {
        event.fingerprint = [
          '{{ default }}',
          PRISMA_CONFIG.stage,
          (error as any).code,
        ];
      }

      event.extra = {
        ...event.extra,
        breadcrumbs: getBreadcrumbsForSentry(),
        commitSha: import.meta.env.VITE_CI_COMMIT_SHA,
        commitShaIndexHtml:
          commitShaIndexHtml?.getAttribute('data-commit-sha') ??
          'NOT_AVAILABLE',
        commitShaMetaJson: metaJson.value?.commitSha ?? 'NOT_AVAILABLE',
      };

      return event;
    },
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 0.1, // measure 10% of the traffic (to not exceed quota)
    normalizeDepth: 10,
    enabled: location.protocol === 'https:',
  });
  Sentry.setContext('device', {
    screen_resolution: `${window.screen.width}x${window.screen.height}`,
    screen_height_pixels: window.screen.height,
    screen_width_pixels: window.screen.width,
  });
}

const AxiosInstance: FC<{ children: ReactNode }> = ({ children }) => {
  const cognito = useCognito();
  const safeLocalStorage = useSafeLocalStorage();
  return (
    <AxiosInstanceProvider
      value={createAxiosInstance({ cognito, safeLocalStorage })}
    >
      {children}
    </AxiosInstanceProvider>
  );
};

createRoot(document.getElementById('app')!).render(
  <StrictMode>
    <DeveloperToolsProvider>
      <CognitoProvider>
        <AxiosInstance>
          <BrowserRouter basename={baseUrl}>
            {/*
              React handles errors differently between development and production mode.
              During development catched errors are still marked as uncaught.
              For that reason our global error handling would kick in even though we already
              handled that error - that's why we'll just deactivate the global error handler
              during development.
              See https://github.com/facebook/react/issues/10384#issuecomment-334142138
            */}
            {process.env.NODE_ENV === 'production' && <GlobalErrorHandler />}
            <ToastsProvider>
              <ReactShell />

              <ReauthenticateProvider>
                <App />
              </ReauthenticateProvider>
            </ToastsProvider>
          </BrowserRouter>
        </AxiosInstance>
      </CognitoProvider>
    </DeveloperToolsProvider>
  </StrictMode>
);
