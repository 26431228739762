import type { FC } from 'react';
import { lazy } from 'react';
import { useMemoOne } from 'use-memo-one';
import type { CorrectionsParams } from 'src/apis/capacity-management/types';
import type { CapacityManagementRootRequest } from 'src/apis/capacity-management/use-capacity-management-root';
import { useCorrections } from 'src/apis/capacity-management/use-corrections';
import { usePageParams } from 'src/pages/reporting/monthly-statements/corrections/use-page-params';

const Page = lazy(() => import('./page'));

type Params = {
  capacityManagementRoot: CapacityManagementRootRequest;
};

export const CorrectionsOverview: FC<Params> = ({ capacityManagementRoot }) => {
  const pageParams = usePageParams();

  const params = useMemoOne<CorrectionsParams>(() => {
    const { start, pageSize, shipperId, contractId, ...rest } =
      pageParams.value;
    return {
      offset: start,
      limit: pageSize,
      shipperId: shipperId.map(({ value }) => value),
      contractId: contractId.map(({ value }) => value),
      ...rest,
    };
  }, [pageParams.value]);

  const corrections = useCorrections({ capacityManagementRoot, params });

  return <Page pageParams={pageParams} corrections={corrections} />;
};
