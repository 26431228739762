import type { FC } from 'react';
import { lazy } from 'react';
import { useParams } from 'react-router-dom';
import type { AggregateEuRootRequest } from 'src/apis/aggregate-eu/use-aggregate-eu-root';
import { useGetContractReport } from 'src/apis/aggregate-eu/use-get-contract-report';
import { PageSpinner } from 'src/components/spinner-container';
import type { Successful } from 'src/hooks/use-axios';
import type { ContractFormDependencies } from 'src/pages/aggregate-eu/contract-reports/common/use-contract-form-dependencies';
import { useContractFormDependencies } from 'src/pages/aggregate-eu/contract-reports/common/use-contract-form-dependencies';

const Page = lazy(() => import('./page'));

export const AggregateEuEditContractReport: FC<{
  aggregateEuRootRequest: Successful<AggregateEuRootRequest>;
}> = ({ aggregateEuRootRequest }) => {
  const { contractReportId } = useParams<{ contractReportId: string }>();
  const contractFormDependencies = useContractFormDependencies({
    aggregateEuRootRequest,
  });

  const contract = useGetContractReport({
    contracts: contractFormDependencies.contracts,
    contractReportId,
  });

  if (!contractFormDependencies.successful || !contract.response)
    return <PageSpinner />;
  return (
    <Page
      contractFormDependencies={
        contractFormDependencies as Successful<ContractFormDependencies>
      }
      contract={contract.response.data}
    />
  );
};
