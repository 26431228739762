import { memo } from 'react';
import type { CapacityCategory } from 'src/apis/monolith/types';
import { labelsToOptions } from 'src/utils/labels-to-options';

export const capacityLabels: Record<CapacityCategory, string> = {
  FIRM: 'Firm',
  FZK: 'FZK',
  B_FZK: 'bFZK',
  B_FZK_C: 'bFZKc',
  B_FZK_1: 'bFZK1',
  DZK: 'DZK',
  TVK: 'TVK',
  BZK: 'BZK',
  BACKHAUL_CAPACITY: 'Backhaul Capacity',
  BACKHAUL_LEVEL_1: 'Backhaul Level 1',
  BACKHAUL_LEVEL_2: 'Backhaul Level 2',
  BACKHAUL_LEVEL_N: 'Backhaul Level n',
  FIRM_ADDITIONAL: 'Firm Additional',
  INTERRUPTIBLE: 'Interruptible',
  INTERRUPTIBLE_LEVEL_1: 'Interruptible Level 1',
  INTERRUPTIBLE_LEVEL_2: 'Interruptible Level 2',
  INTERRUPTIBLE_LEVEL_N: 'Interruptible Level n',
  U_BZK: 'uBZK',
  FZK_C: 'FZKc',
  FZK_INC: 'FZKinc',
  U_KC: 'UKc',
  DZK_1: 'DZK1',
  DZK_2: 'DZK2',
  DZK_3: 'DZK3',
  DZK_4: 'DZK4',
  DZK_5: 'DZK5',
  DZK_SH: 'DZK-SH',
  IUKCF_1: 'IUKCF1',
  INCREMENTAL_OFFER_LEVEL_1: 'Incremental Offer Level 1',
  INCREMENTAL_OFFER_LEVEL_2: 'Incremental Offer Level 2',
  INCREMENTAL_OFFER_LEVEL_3: 'Incremental Offer Level 3',
  INCREMENTAL_OFFER_LEVEL_4: 'Incremental Offer Level 4',
  INCREMENTAL_OFFER_LEVEL_5: 'Incremental Offer Level 5',
};

export const capacityOptions = labelsToOptions(capacityLabels);

type Props = { value: CapacityCategory };

export const Capacity = memo<Props>(({ value }) => {
  const { label } = capacityOptions.find((option) => option.value === value)!;
  return <>{label}</>;
});
