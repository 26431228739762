import type { FC } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useCapacityManagementRoot } from 'src/apis/capacity-management/use-capacity-management-root';
import { useMonthlyStatements } from 'src/apis/capacity-management/use-monthly-statements';
import { Alert } from 'src/components/feedback/alert';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { PageHeader } from 'src/components/layout/page-header';
import { Stack } from 'src/components/layout/stack';
import { RootRoutes } from 'src/components/root-routes';
import { CorrectionsOverview } from 'src/pages/reporting/monthly-statements/corrections';
import { CreateMonthlyStatement } from 'src/pages/reporting/monthly-statements/create';
import { CurtailmentsOverview } from 'src/pages/reporting/monthly-statements/curtailments';
import { EditMonthlyStatement } from 'src/pages/reporting/monthly-statements/edit';
import { MonthlyStatementsOverview } from 'src/pages/reporting/monthly-statements/overview';

export const MonthlyStatements: FC = () => {
  const capacityManagementRoot = useCapacityManagementRoot();

  // share this resource across sub-pages, so it is not unnecessary re-fetchted,
  // but every page takes care of fetching it initially individually (because
  // the initial params might differ and _some_ sub-pages might not need it)
  const monthlyStatements = useMonthlyStatements();

  if (monthlyStatements.error)
    return (
      <Stack gap={2}>
        <PageHeader title="Monthly Statements" />
        <Alert type="warning">
          Monthly Statements can only be used, if you are using the PRISMA
          capacity management service.
        </Alert>
      </Stack>
    );

  return (
    <RootRoutes>
      <Route
        path="/reporting/monthly-statements"
        element={
          <Navigate replace to="/reporting/monthly-statements/overview" />
        }
      />

      <Route
        path="/reporting/monthly-statements/overview/*"
        element={
          <MonthlyStatementsOverview
            capacityManagementRoot={capacityManagementRoot}
            monthlyStatements={monthlyStatements}
          />
        }
      />

      <Route
        path="/reporting/monthly-statements/corrections/*"
        element={
          <CorrectionsOverview
            capacityManagementRoot={capacityManagementRoot}
          />
        }
      />

      <Route
        path="/reporting/monthly-statements/curtailments/*"
        element={
          <CurtailmentsOverview
            capacityManagementRoot={capacityManagementRoot}
          />
        }
      />

      <Route
        path="/reporting/monthly-statements/create/*"
        element={
          <CreateMonthlyStatement
            capacityManagementRoot={capacityManagementRoot}
            monthlyStatements={monthlyStatements}
          />
        }
      />

      <Route
        path="/reporting/monthly-statements/:monthlyStatementId/edit/*"
        element={
          <EditMonthlyStatement
            capacityManagementRoot={capacityManagementRoot}
            monthlyStatements={monthlyStatements}
          />
        }
      />

      <Route path="*" element={<ThrowNotFound />} />
    </RootRoutes>
  );
};
