import { useEffect } from 'react';
import type { InvoicesThresholdData } from 'src/apis/capacity-management/types';
import type { CapacityManagementRootRequest } from 'src/apis/capacity-management/use-capacity-management-root';
import type { Successful } from 'src/hooks/use-axios';
import { useAxios } from 'src/hooks/use-axios';
import { isConstraintViolation } from 'src/utils/is-constraint-violation';

type InvoicesThresholdOptions = {
  capacityManagementService: CapacityManagementRootRequest;
};

export function useInvoicesThreshold({
  capacityManagementService,
}: InvoicesThresholdOptions) {
  const invoicesThreshold = useAxios(
    (
      axios,
      baseConfig,
      {
        capacityManagementService,
      }: {
        capacityManagementService: Successful<CapacityManagementRootRequest>;
      }
    ) =>
      axios.request<InvoicesThresholdData>({
        ...baseConfig,
        url: capacityManagementService.response.data._links?.settingsInvoicing
          ?.href,
      }),
    {
      neededOnPageLoad: true,
    }
  );

  useEffect(() => {
    if (!capacityManagementService.response) return;
    invoicesThreshold.execute({ capacityManagementService });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capacityManagementService.response]);

  return invoicesThreshold;
}

export type InvoicesThresholdRequest = ReturnType<typeof useInvoicesThreshold>;

type EditInvoicesThresholdOptions = {
  capacityManagementService: Successful<CapacityManagementRootRequest>;
};

export function useEditInvoicesThreshold({
  capacityManagementService,
}: EditInvoicesThresholdOptions) {
  return useAxios(
    (axios, baseConfig, data: InvoicesThresholdData) =>
      axios.request<InvoicesThresholdData>({
        ...baseConfig,
        method: 'PUT',
        url: capacityManagementService.response.data._links?.settingsInvoicing
          ?.href,
        data,
      }),
    {
      neededOnPageLoad: false,
      onError(error) {
        if (isConstraintViolation(error)) {
          return error;
        } else {
          throw error;
        }
      },
    }
  );
}

export type EditInvoicesThresholdRequest = ReturnType<
  typeof useEditInvoicesThreshold
>;
