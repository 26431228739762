import type { FC, ReactNode } from 'react';
import { createContext, useContext } from 'react';
import { assertContext } from 'src/utils/assert-context';

export type FormModeContextValue = 'regular' | 'filter' | 'search';

// pass undefined as any, because we run assertContext at runtime
const FormModeContext = createContext<FormModeContextValue>(undefined as any);

export function useFormMode() {
  const context = useContext(FormModeContext);
  assertContext(context, 'FormMode');
  return context;
}

export const FormModeProvider: FC<{
  mode: FormModeContextValue;
  children: ReactNode;
}> = ({ children, mode }) => {
  return (
    <FormModeContext.Provider value={mode}>{children}</FormModeContext.Provider>
  );
};
