import type { FC } from 'react';
import { useParams } from 'react-router-dom';
import type { ContractManagementResponse } from 'src/apis/contract-management/types';
import type { ShipperPlatformIdsRequest } from 'src/apis/monolith/use-shipper-platform-ids';
import type { OrganisationServiceRequest } from 'src/apis/organisation-service/use-organisation-service';
import { Sidebar } from 'src/components/layout/sidebar';
import { ExternalLink, Link } from 'src/components/navigation/link';
import type { Successful } from 'src/hooks/use-axios';
import { useAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import type { ShipperResponse } from 'src/pages/shippers/details/information/types';

type ShipperInformationSidebarProps = {
  contractManagement?: ContractManagementResponse;
  contractManagementShipper?: ShipperResponse | '';
  shipperPlatformIds: Successful<ShipperPlatformIdsRequest>;
  organisationService: Successful<OrganisationServiceRequest>;
};

export const ShipperInformationSidebar: FC<ShipperInformationSidebarProps> = ({
  contractManagement,
  contractManagementShipper,
  shipperPlatformIds,
  organisationService,
}) => {
  const shipperRefId = shipperPlatformIds.response.data.referenceId.toString();
  const { shipperUuid } = useParams<{ shipperUuid: string }>();
  const monolithUser = useAuthenticatedMonolithUser();
  return (
    <Sidebar>
      <Link
        to={`/dashboard/shippers/${shipperUuid}/information`}
        mode="sidebar"
      >
        Shipper Overview
      </Link>

      {monolithUser.isOperator && (
        <ExternalLink
          href={`${PRISMA_CONFIG.angularUrl}/#/shippers/details/${shipperRefId}/credit-limits`}
          mode="sidebar"
        >
          Credit Limits
        </ExternalLink>
      )}

      {organisationService.response.data._links.getAssignedShipperDetails && (
        <Link
          to={`/dashboard/shippers/${shipperUuid}/contact-details`}
          mode="sidebar"
        >
          Contact Details
        </Link>
      )}

      <Link to={`/dashboard/shippers/${shipperUuid}/documents`} mode="sidebar">
        Documents
      </Link>

      {contractManagement?._links.contracts && (
        <Link
          to={`/dashboard/shippers/${shipperUuid}/contracts`}
          mode="sidebar"
        >
          Contracts
        </Link>
      )}

      {contractManagementShipper &&
        contractManagementShipper._links.getFinancialSecurities && (
          <Link
            to={`/dashboard/shippers/${shipperUuid}/financial-securities`}
            mode="sidebar"
          >
            Financial Securities
          </Link>
        )}
    </Sidebar>
  );
};
