import escapedString from 'escape-string-regexp';
import type { FC } from 'react';
import { useEffect, useMemo } from 'react';
import { z } from 'zod';
import type { Lsos } from 'src/apis/lng-marketing/types';
import { SearchableMultiSelect } from 'src/components/form/select/searchable-multi-select';
import { formOptionSchema } from 'src/components/form/zod-schemas';
import { useAxios } from 'src/hooks/use-axios';
import { useSearch } from 'src/hooks/use-search';

type Props = {
  url: string;
  label: string;
};

export const lsoOptionSchema = formOptionSchema.merge(
  z.object({ shortName: z.string() })
);

type LsoOption = z.input<typeof lsoOptionSchema>;

export const LsoSearchableMultiSelect: FC<Props> = ({ url, label }) => {
  const [searchValue, setSearchValue, searchQuery] = useSearch('', {
    minLength: 1,
  });

  const searchRequest = useAxios(
    (axios, baseConfig) =>
      axios.request<Lsos>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: false }
  );

  // on the very first search input we try to fetch the initial list of lsos
  useEffect(() => {
    if (!searchQuery || searchRequest.response) return;
    searchRequest.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, searchRequest.response]);

  const searchResult = useMemo(() => {
    if (!searchQuery || !searchRequest.response) return;

    const escapedSearchQuery = new RegExp(
      escapedString(searchQuery.toLowerCase())
    );

    return searchRequest.response.data._embedded.items
      .filter(
        (lso) =>
          lso.shortName.toLowerCase().match(escapedSearchQuery) ||
          lso.name.toLowerCase().match(escapedSearchQuery)
      )
      .map((lso) => ({
        value: lso.id,
        label: lso.name,
        shortName: lso.shortName,
      })) satisfies LsoOption[];
  }, [searchQuery, searchRequest.response]);

  return (
    <SearchableMultiSelect
      label={label}
      name="lsoOrganisationId"
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      searchValueForCurrentOptions={searchValue}
      pending={searchRequest.pending}
      options={searchResult}
      stacked
      placeholder="E.g. LSO-1"
      renderOptionHint={(option) => option.shortName}
      renderSelectedOptionTooltip={(option) => (
        <>
          <strong>Short Name:</strong> {option.shortName}
        </>
      )}
    />
  );
};
