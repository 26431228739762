import type { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { GroupWrap } from 'src/components/group-wrap';
import type { ActiveSection } from 'src/components/layout/header/types';
import { Link } from 'src/components/navigation/link';
import { useBreakpoints } from 'src/hooks/use-breakpoints';

export const isGdmActive = (pathname: string) => pathname.startsWith('/gdm');

export const SecondaryNavGdm: FC<{ activeSection: ActiveSection }> = ({
  activeSection,
}) => {
  const { pathname } = useLocation();
  const { minTablet } = useBreakpoints();

  if (!activeSection.gdm) return null;

  const isActiveVerificationsLink =
    pathname.startsWith('/gdm/ts-verification') ||
    pathname.startsWith('/gdm/ts-completeness-check-violation');

  return (
    <GroupWrap
      gap={[1, 2]}
      data-testid="secondary-nav"
      alignItems={!minTablet ? 'stretch' : 'center'}
      flexDirection={!minTablet ? 'column' : 'row'}
    >
      <Link mode="secondary" to="/gdm/ts-master">
        Time Series
      </Link>

      <Link
        mode="secondary"
        to="/gdm/ts-verification"
        isActive={isActiveVerificationsLink}
      >
        Verifications
      </Link>

      <Link mode="secondary" to="/gdm/ts-report">
        Reports
      </Link>
    </GroupWrap>
  );
};
