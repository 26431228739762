import type { FC, ReactNode } from 'react';
import { lazy } from 'react';
import type { ProductCalculation } from 'src/apis/capacity-management/types';
import type { CapacityManagementRootRequest } from 'src/apis/capacity-management/use-capacity-management-root';
import { useDraftedProducts } from 'src/apis/capacity-management/use-drafted-products';
import { Alert } from 'src/components/feedback/alert';
import { Stack } from 'src/components/layout/stack';
import { PageSpinner } from 'src/components/spinner-container';
import { useReferrer } from 'src/hooks/use-referrer';
import type { DraftedProductRequest } from 'src/pages/reporting/products/drafted/detail/use-drafted-product';
import { useDraftedProduct } from 'src/pages/reporting/products/drafted/detail/use-drafted-product';
import { useDraftedProductsDetailParams } from 'src/pages/reporting/products/drafted/detail/use-page-params';
import { useProductComparisonGraph } from 'src/pages/reporting/products/drafted/detail/use-product-comparison-graph';
import { useUnderlyingData } from 'src/pages/reporting/products/drafted/detail/use-underlying-data';

const Page = lazy(() => import('./page'));

type LoadDataProps = {
  draftedProduct: DraftedProductRequest;
  calculation?: ProductCalculation;
  backLink: ReactNode;
};

const LoadData: FC<LoadDataProps> = ({
  draftedProduct,
  calculation,
  backLink,
}) => {
  const pageParams = useDraftedProductsDetailParams({ calculation });
  const underlyingData = useUnderlyingData({
    product: draftedProduct,
    pageParams,
  });
  const productComparisonGraph = useProductComparisonGraph({
    product: draftedProduct.response?.data,
  });

  return (
    <Page
      underlyingData={underlyingData}
      draftedProduct={draftedProduct}
      pageParams={pageParams}
      backLink={backLink}
      productComparisonGraph={productComparisonGraph}
    />
  );
};

type Props = {
  capacityManagementRoot: CapacityManagementRootRequest;
};

export const ReportingUploadsDraftedDetail: FC<Props> = ({
  capacityManagementRoot,
}) => {
  const draftedProducts = useDraftedProducts({ capacityManagementRoot });
  const draftedProduct = useDraftedProduct({ draftedProducts });

  const referrer = useReferrer({
    label: 'Products',
    location: '/reporting/products/drafted',
  });

  // we only handle 410 explicitely - see hook
  if (draftedProduct.error)
    return (
      <Stack gap={1}>
        {referrer.backLink}
        <Alert type="warning">This drafted product is outdated.</Alert>
      </Stack>
    );

  if (!draftedProduct.response) return <PageSpinner />;
  return (
    <LoadData
      draftedProduct={draftedProduct}
      calculation={draftedProduct.response.data.calculation}
      backLink={referrer.backLink}
    />
  );
};
