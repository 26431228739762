import type { FC } from 'react';
import { lazy } from 'react';
import { useAggregateEuRoot } from 'src/apis/aggregate-eu/use-aggregate-eu-root';
import { useOrganisationRegistration } from 'src/apis/organisation-registration/use-organisation-registration';

const Page = lazy(() => import('./page'));

export const Registration: FC = () => {
  const organisationRegistration = useOrganisationRegistration();
  const aggregateEuRootRequest = useAggregateEuRoot();

  return (
    <Page
      organisationRegistration={organisationRegistration}
      aggregateEuRootRequest={aggregateEuRootRequest}
    />
  );
};
