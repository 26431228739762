import { useEffect } from 'react';
import urlTemplate from 'url-template';
import type {
  DocumentsParams,
  OperatorDocumentTableList,
} from 'src/apis/document-exchange/types';
import { useAxios } from 'src/hooks/use-axios';

type HookConfig = {
  url: string;
  params?: DocumentsParams;
};

type RequestOptions = {
  params?: DocumentsParams;
};

export function useOperatorDocumentsOutbox({ url, params }: HookConfig) {
  const documents = useAxios(
    (axios, baseConfig, { params }: RequestOptions) =>
      axios.request<OperatorDocumentTableList>({
        ...baseConfig,
        method: 'GET',
        url: urlTemplate.parse(url).expand({}),
        params,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    const options = { params };
    documents.execute(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return documents;
}

export type OperatorDocumentsOutboxRequest = ReturnType<
  typeof useOperatorDocumentsOutbox
>;
