import * as Sentry from '@sentry/react';
import axios from 'axios';
import { stringify } from 'qs';
import type { RequestSection } from 'src/hooks/use-axios/axios-config';
import { isServerError } from 'src/hooks/use-axios/errors';
import type { Cognito } from 'src/hooks/use-cognito';
import { addBreadcrumb } from 'src/utils/breadcrumbs';
import { normalizePayload } from 'src/utils/normalize-payload';
import { services } from 'src/utils/services';

export const paramsSerializer = (params: unknown) =>
  stringify(params, { arrayFormat: 'repeat', skipNulls: true });

declare module 'axios' {
  // 1) we need to extend the axios config interface in order to add an optional
  //    custom field, which will be used in our interceptors
  // 2) passing a custom field to axios is generally allowed
  //    see https://github.com/axios/axios/pull/2207
  export interface AxiosRequestConfig {
    publicFallback?: string;
    /**
     * This can be used to "tag" requests based on the place where they are made.
     * This is used e.g. in our ownership addon.
     */
    requestSection?: RequestSection;
  }
}

function isOurService(url: string | undefined) {
  return services.some((service) => url?.startsWith(PRISMA_CONFIG[service]));
}

function usesCognito(url: string | undefined) {
  return (
    isOurService(url) &&
    !url?.startsWith(PRISMA_CONFIG.organisationRegistration)
  );
}

// see https://prisma.atlassian.net/browse/FOO-988
const StateTokenHeader = 'x-prisma-iip-state-token';
let stateTokenForIip: string | null = null;

export async function getCognitoToken(cognito: Cognito) {
  try {
    const session = await cognito.fetchAuthSession();
    if (!session.userSub) return null;

    const cognitoUserName = session.userSub;
    const accessTokenJwt = session.tokens!.accessToken;
    updateSentryTracking(cognitoUserName);
    return { accessTokenJwt, cognitoUserName };
  } catch (err) {
    if (PRISMA_CONFIG.stage === 'storybook') console.error(err); // e.g. missing mock
    try {
      await cognito.logoutFromCurrentAccount();
    } catch (err) {
      // ignore
      if (PRISMA_CONFIG.stage === 'storybook') console.error(err); // e.g. missing mock
    }
    updateSentryTracking(null);
    return null;
  }
}

// track cognito user name in sentry
function updateSentryTracking(cognitoUserName: string | null) {
  Sentry.setExtra('cognitoUserName', cognitoUserName);
}

export function createAxiosInstance({
  cognito,
  safeLocalStorage,
}: {
  cognito: Cognito;
  safeLocalStorage: Storage;
}) {
  const axiosInstance = axios.create({
    paramsSerializer: { serialize: paramsSerializer },
  });

  axiosInstance.interceptors.request.use(async (config) => {
    if (config.url?.startsWith(PRISMA_CONFIG.monolithApiUrl)) {
      // needed, because of different monolith nodes to stay sticky
      config.withCredentials = true;
    }

    // if token is available, we add it automatically to new api url requests
    const token = await getCognitoToken(cognito);
    if (usesCognito(config.url) && token) {
      // keep existing Authorization header if present (e.g. our retry logic might
      // added an Authorization header already)
      config.headers.Authorization =
        config.headers.Authorization ?? `Bearer ${token.accessTokenJwt}`;

      if (
        config.url?.startsWith(PRISMA_CONFIG.monolithApiUrl) &&
        safeLocalStorage.getItem('ENFORCE_SHIPPER_ROLE') === 'true'
      )
        config.headers['X-Prisma-Switch-User'] = 'true';
    }

    // if token is not available, but a public fallback is, we automatically switch to the fallback
    if (isOurService(config.url) && !token && config.publicFallback) {
      config.url = config.publicFallback;
      delete config.publicFallback;
    }

    if (
      isOurService(config.url) &&
      !config.url?.startsWith(PRISMA_CONFIG.monolithApiUrl)
    ) {
      config.data = normalizePayload(config.data);
    }

    if (config.url?.startsWith(PRISMA_CONFIG.iipService) && stateTokenForIip) {
      config.headers[StateTokenHeader] = stateTokenForIip;
    }

    return config;
  });

  axiosInstance.interceptors.request.use((config) => {
    if (config.url) {
      try {
        const url = new URL(config.url);
        // remove duplicated slashes due to wrong concatenation
        // (currently we need this, when you use a MR-specific backend instance)
        url.pathname = url.pathname.replace(/\/\//g, '/');
        config.url = url.toString();
        // special case: `pathname` is never allowed to be an empty string by spec,
        // so it will be initialized with `/` and we don't want this for the root requests
        if (`${url.origin}/` === config.url) config.url = url.origin;
      } catch (err) {
        // we ignore this. it most likely means an invalid url was passed to `new URL()`.
        // while in reality we usually only use valid URLs, actually axios supports
        // "partial URLs" with the `baseURL` option. on top of that many old mocks/tests don't
        // use valid URLs.
      }
    }
    return config;
  });

  axiosInstance.interceptors.response.use((value) => {
    addBreadcrumb({
      type: 'http',
      response: {
        method: value.config.method,
        url: value.config.url,
        status: value.status,
        statusText: value.statusText,
        traceId: value.headers['x-amzn-trace-id'],
      },
      frontend: 'react',
    });

    if (
      value.config.url?.startsWith(PRISMA_CONFIG.iipService) &&
      value.headers[StateTokenHeader]
    ) {
      stateTokenForIip = value.headers[StateTokenHeader];
    }
    return value;
  });

  const WithRefreshedTokenHeader = 'X-Axios-Interceptor-Jwt-Refresh';

  axiosInstance.interceptors.response.use(undefined, async (error) => {
    // handle 401s and retry requests if possible
    if (
      isServerError(error, 401) &&
      usesCognito(error.config.url) &&
      error.config.headers[WithRefreshedTokenHeader] !== 'true'
    ) {
      // try to refresh token once
      try {
        const result = await getCognitoToken(cognito);
        if (!result) throw error;

        return axiosInstance.request({
          ...error.config,
          headers: {
            ...error.config.headers,
            Authorization: `Bearer ${result.accessTokenJwt}`,
            [WithRefreshedTokenHeader]: 'true',
          },
        });
      } catch (tokenRefreshError) {
        // if any error happened during refreshing the token, we try to use a public fallback
        if (error.config.publicFallback) {
          return axiosInstance.request({
            ...error.config,
            url: error.config.publicFallback,
            publicFallback: undefined,
          });
        } else {
          throw error;
        }
      }
    } else {
      throw error;
    }
  });

  return axiosInstance;
}
