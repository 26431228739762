import type { ComboboxPopoverProps } from '@ariakit/react';
import {
  ComboboxItem,
  ComboboxProvider,
  useComboboxStore,
  useStoreState,
} from '@ariakit/react';
import type { Placement } from '@floating-ui/react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faTimes as faTimesSolid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FieldInputProps } from 'formik';
import type { ReactNode } from 'react';
import { useEffect, useId, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { EmptyValue } from 'src/components/data-display/empty-value';
import { tagBaseStyle, tagGap } from 'src/components/data-display/tag';
import { normalizeGroupOptions } from 'src/components/form/checkbox-group';
import { ErrorMessage } from 'src/components/form/error-message';
import { useFieldGroup } from 'src/components/form/field-group';
import { FieldItem, FieldLayout } from 'src/components/form/field-layout';
import type { FormFieldProps } from 'src/components/form/form-field-props';
import type {
  GroupedFormOption,
  FormOption,
} from 'src/components/form/form-option';
import { InnerAddon } from 'src/components/form/inner-addon';
import { FieldLabel } from 'src/components/form/label';
import { RemoveButton } from 'src/components/form/select/components/remove-button';
import { SelectToggleIcon } from 'src/components/form/select/components/select-toggle';
import { Separator } from 'src/components/form/select/components/separator';
import {
  multiSelectItemStyle,
  MultiSelectOptions,
} from 'src/components/form/select/multi-select';
import {
  SearchableSelectInput,
  SearchableSelectPopover,
} from 'src/components/form/select/searchable-single-select';
import type { SortByOptions } from 'src/components/form/select/utils';
import { useCustomField } from 'src/components/form/use-custom-field';
import { useFocusManager } from 'src/components/form/use-focus-manager';
import { GroupWrap } from 'src/components/group-wrap';
import { Stack } from 'src/components/layout/stack';
import { Tooltip } from 'src/components/overlay/tooltip';
import { Heading } from 'src/components/text/heading';
import { Hint } from 'src/components/text/hint';
import { useDefaultStacked } from 'src/hooks/use-default-stacked';
import { ParentZIndexProvider } from 'src/hooks/use-parent-z-index';
import { Colors } from 'src/styles';

export type SearchableMultiSelectProps<
  Value extends string,
  Options extends FormOption<Value>[] | GroupedFormOption<Value>[],
> = {
  stacked?: boolean;
  inline?: boolean;
  /**
   * If you pass `undefined`, this means that the initial list of options is still loading.
   */
  options: Options | undefined;
  toggleAll?: boolean;
  /**
   * When you use server side search (or maybe a debounced client side search) the options you
   * see don't necessarily reflect the current search value as the result is asynchronous and you could
   * have changed the search value in the meantime. This prop is used to keep track of the search value
   * that belongs to the current options.
   * (For a non-debounced client side search this _always_ equals the current search value.)
   *
   * `undefined` means there was no search yet.
   * `null` (or empty string) means we got a search result, but haven't used a search value.
   */
  searchValueForCurrentOptions: string | null | undefined;
  placeholder?: string;
  /**
   * Defaults to 'manual' which makes most sense for server side search.
   */
  sortBy?: SortByOptions;
  /**
   * The search value always reflects the input value of our search field.
   */
  searchValue: string;
  setSearchValue: (value: string) => void;
  initialOpen?: boolean;
  leftAddon?: ReactNode;
  popoverProps?: (
    props: ComboboxPopoverProps<'div'>
  ) => ComboboxPopoverProps<'div'>;
  renderOptions?: (props: { children: ReactNode }) => ReactNode;
  pending: boolean;
  placement?: Placement;
  selectionLimit?: number;
  /**
   * Usually you can directly set a `hint` field for each option, but we want to re-use the hint
   * on the selected options as a tooltip. If your hint is not a plain string, this becomes hard to serialize
   * and usually you want to keep your selected options fully serializable (e.g. so you can deep link
   * to an existing filter and still show the tooltip on hover).
   *
   * In order to support non-string hints (or in other words: hints that use React components) you need to *add all
   * the data that you need for rendering the hint to the schema* (in order to serialize them to strings) and then use
   * `renderOptionHint`.
   *
   * If your tooltip should actually be formatted _differently_ then the option hint you can use `renderSelectedOptionTooltip`.
   */
  renderOptionHint?: (option: Options[number]) => ReactNode;
  /**
   * `renderSelectedOptionTooltip` works just like `renderOptionHint` and you *only* need it if your the tooltip
   * of your selected option should explicitly differ from the hint of the option.
   */
  renderSelectedOptionTooltip?: (option: Options[number]) => ReactNode;
} & FormFieldProps;

/**
 * Use this if you have a long list of options (more then ~5) or not enough screen space where the user can select multiple values and want to support search.
 *
 * If you don't need search, consider using the [Multi Select](../?path=/docs/components-form-multi-select--docs) component.
 *
 * If you have a short list of options (e.g. 2-5) or enough screen space, consider using the [Checkbox Group](../?path=/docs/components-form-checkbox-group--docs) component.
 */
export function SearchableMultiSelect<
  Value extends string,
  Options extends FormOption<Value>[] | GroupedFormOption<Value>[],
>(props: SearchableMultiSelectProps<Value, Options>) {
  const { isStacked, minTablet } = useDefaultStacked();

  const {
    name,
    label,
    pending = false,
    placeholder = 'Type to Search',
    stacked = isStacked,
    inline,
    toggleAll,
    searchValueForCurrentOptions,
    hideLabel = false,
    options,
    searchValue,
    setSearchValue,
    disabled,
    disabledMessage,
    initialOpen,
    hint,
    info,
    leftAddon,
    popoverProps,
    renderOptions,
    sortBy,
    markAsRequired,
    placement,
    selectionLimit,
    renderOptionHint,
    renderSelectedOptionTooltip,
  } = props;

  const [field, showError, error, helper] = useCustomField<FormOption<Value>[]>(
    name,
    label
  );

  const formGroup = useFieldGroup();

  useEffect(() => {
    if (formGroup) {
      formGroup.onError({ name, showError });
    }
  }, [showError, name, formGroup]);

  const id = useId();

  const fieldId = formGroup?.id || id;

  const normalizedOptions = useMemo(
    () =>
      options?.map((option) => ({
        ...option,
        hint: renderOptionHint?.(option) ?? option.hint,
      })) as Options | undefined,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options]
  );

  const fieldItem = (
    <FieldItem>
      <Tooltip
        content={disabled && disabledMessage ? disabledMessage : undefined}
      >
        {(targetProps) => (
          <Combobox
            toggleAll={toggleAll && !selectionLimit}
            initialOpen={initialOpen}
            pending={pending}
            name={name}
            placeholder={placeholder}
            options={normalizedOptions}
            id={fieldId}
            label={label}
            searchValueForCurrentOptions={searchValueForCurrentOptions}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            showError={showError}
            field={field}
            disabled={disabled}
            leftAddon={leftAddon}
            popoverProps={popoverProps}
            renderOptions={renderOptions}
            sortBy={sortBy}
            placement={placement}
            selectionLimit={selectionLimit}
            renderOptionHint={renderOptionHint}
            renderSelectedOptionTooltip={renderSelectedOptionTooltip}
            selectedValue={field.value.map((item) => item.value)}
            setSelectedValue={(values) => {
              const items = values.map((newValue) => {
                // find new item
                for (const item of options ?? []) {
                  if (isGroupedOption(item)) {
                    const subItem = item.options.find(
                      (subItem) => subItem.value === newValue
                    );
                    if (subItem) return subItem;
                  } else if (item.value === newValue) {
                    return item;
                  }
                }

                const existingItem = field.value.find(
                  (item) => item.value === newValue
                );
                if (existingItem) return existingItem;

                // this should never happen
                throw new Error('The selected option could not be found.');
              });
              helper.setValue(items);
            }}
            {...targetProps}
          />
        )}
      </Tooltip>

      {hint && (
        <Hint disabled={props.disabled} mode="formInput" children={hint} />
      )}

      {showError && error && (
        <ErrorMessage
          data-testid={`${name}Error`}
          error={error}
          label={label}
        />
      )}
    </FieldItem>
  );

  const displayLabel = `${label}${markAsRequired ? '*' : ''}`;

  return ((formGroup && !minTablet) || !formGroup) && !hideLabel ? (
    <FieldLayout stacked={stacked} inline={inline}>
      <FieldLabel
        name={name}
        displayLabel={displayLabel}
        fieldId={fieldId}
        info={info}
        showError={showError}
      />

      {fieldItem}
    </FieldLayout>
  ) : (
    fieldItem
  );
}

function isGroupedOption(
  option: FormOption | GroupedFormOption
): option is GroupedFormOption {
  return 'options' in option;
}

type ComboboxProps<
  Value extends string,
  Options extends FormOption<Value>[] | GroupedFormOption<Value>[],
> = {
  id: string;
  pending: boolean;
  searchValue: string;
  setSearchValue: (value: string) => void;
  showError?: boolean;
  /**
   * If you pass `undefined`, this means that the initial list of options is still loading.
   */
  options: Options | undefined;
  toggleAll?: boolean;
  /**
   * When you use server side search (or maybe a debounced client side search) the options you
   * see don't necessarily reflect the current search value as the result is asynchronous and you could
   * have changed the search value in the meantime. This prop is used to keep track of the search value
   * that belongs to the current options.
   * (For a non-debounced client side search this _always_ equals the current search value.)
   *
   * `undefined` means there was no search yet.
   * `null` (or empty string) means we got a search result, but haven't used a search value.
   */
  searchValueForCurrentOptions: string | null | undefined;
  placeholder: string;
  field: FieldInputProps<FormOption<Value>[]>;
  initialOpen?: boolean;
  leftAddon?: ReactNode;
  popoverProps?: (
    props: ComboboxPopoverProps<'div'>
  ) => ComboboxPopoverProps<'div'>;
  renderOptions?: (props: { children: ReactNode }) => ReactNode;
  /**
   * Usually you can directly set a `hint` field for each option, but we want to re-use the hint
   * on the selected options as a tooltip. If your hint is not a plain string, this becomes hard to serialize
   * and usually you want to keep your selected options fully serializable (e.g. so you can deep link
   * to an existing filter and still show the tooltip on hover).
   *
   * In order to support non-string hints (or in other words: hints that use React components) you need to *add all
   * the data that you need for rendering the hint to the schema* (in order to serialize them to strings) and then use
   * `renderOptionHint`.
   *
   * If your tooltip should actually be formatted _differently_ then the option hint you can use `renderSelectedOptionTooltip`.
   */
  renderOptionHint?: (option: Options[number]) => ReactNode;
  /**
   * `renderSelectedOptionTooltip` works just like `renderOptionHint` and you *only* need it if your the tooltip
   * of your selected option should explicitly differ from the hint of the option.
   */
  renderSelectedOptionTooltip?: (option: Options[number]) => ReactNode;
  /**
   * Defaults to 'manual' which makes most sense for server side search.
   */
  sortBy?: SortByOptions;
  selectedValue: Value[];
  setSelectedValue: (values: Value[]) => void;
  selectionLimit?: number;
  placement?: Placement;
} & FormFieldProps;

function Combobox<
  Value extends string,
  Options extends FormOption<Value>[] | GroupedFormOption<Value>[],
>(props: ComboboxProps<Value, Options>) {
  const {
    name,
    searchValue,
    id,
    field,
    placeholder,
    showError,
    disabled,
    pending,
    initialOpen,
    setSearchValue,
    leftAddon,
    popoverProps = (props) => props,
    renderOptions = ({ children }) => children,
    renderOptionHint,
    renderSelectedOptionTooltip,
    sortBy = 'manual',
    options: unsortedOptions,
    toggleAll,
    selectedValue,
    setSelectedValue,
    selectionLimit,
    placement,
  } = props;

  const { optionGroups, allChecked, toggleAllChecked } = normalizeGroupOptions(
    selectedValue,
    unsortedOptions ?? [],
    sortBy
  );

  const focusRef = useFocusManager<HTMLInputElement>();

  const showRemoveButton = Boolean(selectedValue.length);

  const store = useComboboxStore({
    selectedValue,
    setSelectedValue,
    value: searchValue,
    setValue: setSearchValue,
    defaultOpen: initialOpen,
    placement,
  });

  const isOpen = useStoreState(store, 'open');
  const activeId = useStoreState(store, 'activeId');
  const isFocused =
    document.activeElement && focusRef.current === document.activeElement;

  const zIndex = 1;

  const options = (
    <>
      {pending ? (
        <SearchableMultiSelectContent>
          <EmptyValue label="Loading search results..." />
        </SearchableMultiSelectContent>
      ) : (
        <>
          {!unsortedOptions && (
            <SearchableMultiSelectContent>
              <EmptyValue label="Type to search..." />
            </SearchableMultiSelectContent>
          )}

          {unsortedOptions && unsortedOptions.length !== 0 && (
            <MultiSelectOptions
              toggleAll={toggleAll}
              toggleAllChecked={toggleAllChecked}
              name={name}
              onChange={setSelectedValue}
              optionGroups={optionGroups}
              showError={showError}
              allChecked={allChecked}
              activeId={activeId}
              id={id}
              value={selectedValue}
              selectionLimit={selectionLimit}
              Item={SearchableMultiSelectItem}
            />
          )}

          {unsortedOptions?.length === 0 && searchValue && (
            <SearchableMultiSelectContent>
              <EmptyValue label={`Could not find ${searchValue}.`} />
            </SearchableMultiSelectContent>
          )}

          {unsortedOptions?.length === 0 && !searchValue && (
            <SearchableMultiSelectContent>
              <EmptyValue label="No options available to search for." />
            </SearchableMultiSelectContent>
          )}
        </>
      )}
    </>
  );

  return (
    <ComboboxProvider store={store}>
      <InnerAddon
        left={leftAddon}
        right={
          <Stack flow="column" gap={0.5}>
            {selectedValue.length > 0 && (
              <Counter isDisabled={disabled} hasError={showError}>
                {selectedValue.length}
              </Counter>
            )}

            {showRemoveButton && (
              <Stack flow="column" gap={0.5}>
                <RemoveButton
                  error={showError}
                  disabled={disabled}
                  data-testid={`${name}-remove-selection`}
                  onClick={() => {
                    setSelectedValue([]);
                  }}
                  style={{ pointerEvents: 'initial' }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </RemoveButton>
                <Separator error={showError} disabled={disabled} />
              </Stack>
            )}

            <SelectToggleIcon
              showError={showError}
              disabled={disabled}
              isOpen={isOpen}
              // testid just needed for backward compatibility to ease transition
              // to switch from single select to searchable single select
              data-testid={`${name}-togglebutton`}
            />
          </Stack>
        }
      >
        {(addonBounds) => (
          <SearchableSelectInput
            id={id}
            ref={focusRef}
            error={showError}
            disabled={disabled}
            data-name={name} // for focus manager
            data-testid={`${name}Search`}
            placeholder={placeholder}
            {...addonBounds}
            style={{
              fontStyle: isFocused ? 'italic' : undefined,
            }}
            onBlur={(e) => {
              setSearchValue('');
            }}
          />
        )}
      </InnerAddon>

      <ParentZIndexProvider value={zIndex}>
        <SearchableSelectPopover
          {...popoverProps({
            'aria-busy': pending,
            style: { zIndex, minWidth: '60rem' },
            'data-testid': `${name}-options`,
            unmountOnHide: true,
          })}
        >
          <Stack templateColumns="1fr 1fr" alignItems="stretch">
            <PopoverPane
              tabIndex={-1} // we can scroll by selecting items in the popover
            >
              <SearchableMultiSelectContent
                style={{
                  position: 'sticky',
                  top: 0,
                  backgroundColor: 'white',
                  zIndex: 1,
                }}
              >
                <Heading mode="sub-section">Search Results</Heading>
              </SearchableMultiSelectContent>

              {renderOptions({ children: options })}
            </PopoverPane>

            <PopoverPane
              style={{
                borderLeft: `0.1rem solid ${Colors.brandLight3}`,
              }}
              tabIndex={-1} // de-selecting options via keyboard is possible in the left pane
            >
              <SearchableMultiSelectContent
                style={{
                  position: 'sticky',
                  top: 0,
                  backgroundColor: 'white',
                  zIndex: 1,
                }}
              >
                <Heading mode="sub-section">
                  Selection
                  {selectionLimit !== undefined && ` (up to ${selectionLimit})`}
                </Heading>
              </SearchableMultiSelectContent>

              <SearchableMultiSelectContent
                data-testid={`${name}-selected-options`}
              >
                {field.value.length ? (
                  <GroupWrap gap={0.5}>
                    {field.value.map((item, index) => (
                      <Tooltip
                        key={item.value}
                        content={
                          renderSelectedOptionTooltip?.(item) ??
                          renderOptionHint?.(item) ??
                          item.hint
                        }
                      >
                        {(targetProps) => (
                          <SelectedItem
                            {...targetProps}
                            data-testid={`${name}-remove-selected-option-${item.value}`}
                            value={item.value}
                            onClick={(event) => {
                              event.preventDefault(); // step out of ariakit's event handling

                              setSelectedValue([
                                ...selectedValue.slice(0, index),
                                ...selectedValue.slice(index + 1),
                              ]);
                            }}
                          >
                            <Stack flow="column" gap={tagGap}>
                              {item.label}
                              <FontAwesomeIcon icon={faTimesSolid} />
                            </Stack>
                          </SelectedItem>
                        )}
                      </Tooltip>
                    ))}
                  </GroupWrap>
                ) : (
                  <EmptyValue label="Nothing selected, yet." />
                )}
              </SearchableMultiSelectContent>
            </PopoverPane>
          </Stack>
        </SearchableSelectPopover>
      </ParentZIndexProvider>
    </ComboboxProvider>
  );
}

const SearchableMultiSelectItem = styled(ComboboxItem).attrs({
  focusOnHover: true,
  setValueOnClick: false,
  resetValueOnSelect: false,
  hideOnClick: false,
})`
  ${multiSelectItemStyle};
`;

const SelectedItem = styled(ComboboxItem).attrs({
  focusOnHover: true,
  setValueOnClick: false,
  resetValueOnSelect: false,
  hideOnClick: false,
})`
  ${tagBaseStyle};
  cursor: pointer;
  text-transform: uppercase;

  &:hover,
  &:focus,
  &[data-active-item] {
    background-color: ${Colors.brandSecondary};
  }
`;

export const SearchableMultiSelectContent = styled.div`
  padding: 0.25rem 0.5rem;
`;

const PopoverPane = styled.div`
  max-height: 23.5rem;
  overflow: auto;
  padding-bottom: 0.8rem;
`;

const Counter = styled.div<{
  isDisabled?: boolean;
  hasError?: boolean;
}>`
  ${tagBaseStyle};

  ${({ hasError }) =>
    hasError &&
    css`
      background-color: ${Colors.error};
    `};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      background-color: ${Colors.inactive};
    `};
`;
