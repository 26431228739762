import { useEffect } from 'react';
import urlTemplate from 'url-template';
import type { AssignmentInfoRequest } from 'src/apis/assignment-service/use-assignment-info';
import type {
  Contacts,
  ContactsParams,
} from 'src/apis/organisation-service/types';
import { useAxios } from 'src/hooks/use-axios';

type Options = {
  assignmentInfo?: AssignmentInfoRequest;
  params?: ContactsParams;
};

export function useOptionalContacts({ assignmentInfo, params }: Options) {
  const request = useAxios(
    (
      axios,
      baseConfig,
      { url, params }: { url: string; params?: ContactsParams }
    ) =>
      axios.request<Contacts>({
        ...baseConfig,
        url: urlTemplate.parse(url).expand({}),
        params,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!assignmentInfo?.response?.data._links.getAssignmentContacts) return;

    request.execute({
      url: assignmentInfo.response.data._links.getAssignmentContacts.href,
      params,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentInfo?.response, params]);

  if (!assignmentInfo?.response?.data._links.getAssignmentContacts) return;

  return request;
}

export type OptionalContactsRequest = ReturnType<typeof useOptionalContacts>;
