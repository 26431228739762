import type { FC, ReactNode } from 'react';
import { createContext, useContext, useMemo } from 'react';

export type OnErrorMeta = { isPageLoadError: boolean };

type CachedResponse =
  | {
      type: 'pending';
      response: Promise<unknown>;
      signal: AbortSignal;
    }
  | {
      type: 'fulfilled';
      response: unknown;
      signal?: undefined;
    };

export type RequestSection = 'page-content';

export type AxiosHookConfig = {
  onError?: ((error: unknown, meta: OnErrorMeta) => void) | null;
  cache: Record<string, CachedResponse>;
  /**
   * This can be used to "tag" requests based on the place where they are made.
   * This is used e.g. in our ownership addon.
   */
  requestSection?: RequestSection;
};

const AxiosHookConfigContext = createContext<AxiosHookConfig>({
  cache: {},
});

export function useAxiosHookConfig() {
  return useContext(AxiosHookConfigContext);
}

export const AxiosHookConfigProvider = AxiosHookConfigContext.Provider;

export const TransformAxiosConfig: FC<{
  transform: (config: AxiosHookConfig) => AxiosHookConfig;
  children: ReactNode;
}> = ({ transform, children }) => {
  const currentConfig = useAxiosHookConfig();

  const transformedConfig = useMemo(
    () => transform(currentConfig),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <AxiosHookConfigProvider value={transformedConfig}>
      {children}
    </AxiosHookConfigProvider>
  );
};
