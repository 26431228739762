import { orderBy } from 'lodash';
import type { FC } from 'react';
import { useMemo } from 'react';
import type { StorageLocationsParams } from 'src/apis/monolith/types';
import type { StorageLocationsRequest } from 'src/apis/monolith/use-storage-locations';
import { useStorageLocations } from 'src/apis/monolith/use-storage-locations';
import { PageSpinner } from 'src/components/spinner-container';
import type { Successful } from 'src/hooks/use-axios';
import { Page } from 'src/pages/storage/locations/page';
import type { PageParams } from 'src/pages/storage/locations/use-page-params';
import { usePageParams } from 'src/pages/storage/locations/use-page-params';

export const StorageLocationsOverview: FC = () => {
  const pageParams = usePageParams();

  const params = useMemo(
    () =>
      ({
        favoritesOnly: pageParams.value.favoritesOnly,
      }) satisfies StorageLocationsParams,
    [pageParams.value.favoritesOnly]
  );

  const storageLocations = useStorageLocations({ params });

  if (!storageLocations.response) return <PageSpinner />;

  return (
    <FilteredStorageLocations
      pageParams={pageParams}
      storageLocations={storageLocations}
    />
  );
};

const FilteredStorageLocations: FC<{
  storageLocations: Successful<StorageLocationsRequest>;
  pageParams: PageParams;
}> = ({ pageParams, storageLocations }) => {
  // As BE does not support filtering and pagination, we have to do it on the client side
  const filteredStorageLocations = useMemo(() => {
    const filteredCollection = storageLocations.response.data
      .filter((item) => {
        if (pageParams.value.name)
          return item.name
            .toLowerCase()
            .includes(pageParams.value.name.toLowerCase());
        return true;
      })
      .filter((item) => {
        if (pageParams.value.ssoShortName)
          return (
            item.ssoName
              .toLowerCase()
              .includes(pageParams.value.ssoShortName.toLowerCase()) ||
            item.ssoShortName
              .toLowerCase()
              .includes(pageParams.value.ssoShortName.toLowerCase())
          );
        return true;
      })
      .filter((item) => {
        if (pageParams.value.refId)
          return item.refId.toString() === pageParams.value.refId;
        return true;
      });

    const paginatedCollection = orderBy(
      filteredCollection,
      pageParams.value.sortColumn,
      pageParams.value.sortDirection
    ).slice(
      pageParams.value.start,
      pageParams.value.start + pageParams.value.pageSize
    );

    return { items: paginatedCollection, totalSize: filteredCollection.length };
  }, [pageParams.value, storageLocations.response.data]);

  return (
    <Page
      pageParams={pageParams}
      storageLocations={storageLocations}
      filteredStorageLocations={filteredStorageLocations}
    />
  );
};
