import { isEqual, mapValues, omitBy } from 'lodash';
import { parse, stringify } from 'qs';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import type { z, ZodObject } from 'zod';
import { ZOD_EXPLICIT_EMPTY } from 'src/components/form/zod-utilities';

type FilterConfig = {
  label: string;
};

type Filter<T> = {
  label: string;
  default: T;
  optionalDefault: boolean;
};

export function usePageParamsFromSchema<
  Schema extends z.AnyZodObject,
  Values extends z.output<Schema>,
  FilterKeys extends keyof Values = never,
>(
  schema: Schema,
  config?: {
    /**
     * Use this prefix if you would like to group page params
     * and separate them from other groups so that they can be handled individually.
     *
     * ```tsx
     * export function usePageParams() {
     *   const users = usePageParamsFromSchema(paginationSchema, {
     *     groupPrefix: 'users',
     *   });
     *   const organisations = usePageParamsFromSchema(paginationSchema, {
     *     groupPrefix: 'organisations',
     *   });
     *   return { users, organisations };
     * }
     *
     * export type PageParams = ReturnType<typeof usePageParams>;
     * ```
     */
    groupPrefix?: string;
    filters?: Record<FilterKeys, FilterConfig>;
  }
) {
  const filtersConfig = config?.filters ?? {};
  const filterKeys = Object.keys(filtersConfig) as FilterKeys[];

  const { search } = useLocation();
  const navigate = useNavigate();

  const { value, unrelatedValue } = useMemo(
    () => {
      // search is either an empty string or starts with a "?"
      const normalizedSearch = search.substring(1);

      const rawValue = parse(normalizedSearch, {
        // see https://prisma.atlassian.net/browse/ISR-1353
        arrayLimit: Infinity,
      });

      // handle group prefix
      const normalizedValue = {} as Record<string, any>;
      const unrelatedValue = {} as Record<string, any>;
      for (const key in rawValue) {
        if (config?.groupPrefix) {
          if (key.startsWith(config.groupPrefix)) {
            normalizedValue[key.replace(config.groupPrefix, '')] =
              rawValue[key];
          } else {
            unrelatedValue[key] = rawValue[key];
          }
        } else {
          normalizedValue[key] = rawValue[key];
        }
      }

      const value = schema.parse(normalizedValue) as Values;
      return { value, unrelatedValue };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search]
  );

  const filterSchema = useMemo(
    () => {
      const picks = {} as Record<FilterKeys, true>;
      for (const filter of filterKeys) {
        picks[filter] = true;
      }
      return schema.pick(picks as object) as ZodObject<
        Pick<Schema['shape'], FilterKeys>
      >;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const filterValues = useMemo(
    () => {
      const filterValues = {} as Pick<Values, FilterKeys>;
      for (const filter of filterKeys) {
        filterValues[filter] = value[filter];
      }
      return filterValues;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  );

  const defaultValues = useMemo(
    () => schema.parse({}) as Values,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const defaultFilterValues = useMemo(
    () => {
      const defaultFilterValues = {} as Pick<Values, FilterKeys>;
      for (const filter of filterKeys) {
        defaultFilterValues[filter] = defaultValues[filter];
      }
      return defaultFilterValues;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const filter = useMemo(
    () => {
      const filterLabels = {} as { [Key in FilterKeys]: Filter<Values[Key]> };
      for (const filter of filterKeys) {
        const isArray = Array.isArray(defaultFilterValues[filter]);
        const emptyValue = isArray ? [] : null;

        // check if the filter has an optional default value (this means it can be explicitly set to null)
        const parsedOptionalDefault = filterSchema
          .pick({ [filter]: true } as object)
          .safeParse({ [filter]: ZOD_EXPLICIT_EMPTY }) as z.SafeParseReturnType<
          Partial<z.input<Schema>>,
          Partial<Values>
        >;

        const isOptionalDefault =
          parsedOptionalDefault.success &&
          isEqual(parsedOptionalDefault.data[filter], emptyValue);

        filterLabels[filter] = {
          label: (filtersConfig as Record<FilterKeys, FilterConfig>)[filter]
            .label,
          default: defaultFilterValues[filter],
          optionalDefault: isOptionalDefault,
        };
      }
      return filterLabels;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const toSearchParams = useCallback(
    /**
     * Returns the current page params as a search params string (e.g. `hello=world`, _without_ preprended `?`).
     * You can also override individual values.
     */
    (newValue?: Partial<Values>) => {
      const valueWithExplicitNulls = mapValues(
        // merge values
        { ...value, ...newValue },
        // map to explicit nulls, if needed
        (value, key) => {
          if (
            (value === null || isEqual(value, [])) &&
            key in filter &&
            filter[key as FilterKeys].optionalDefault
          )
            return ZOD_EXPLICIT_EMPTY;
          return value;
        }
      );
      const valueWithoutDefaults = omitBy(
        valueWithExplicitNulls,
        // remove default values (for "nicer" URLs)
        (value, key) =>
          key in defaultValues
            ? isEqual(value, defaultValues[key as keyof typeof defaultValues])
            : false
      );

      // handle group prefix
      const prefixedValues = {} as Record<string, any>;
      for (const key in valueWithoutDefaults) {
        prefixedValues[(config?.groupPrefix ?? '') + key] =
          valueWithoutDefaults[key];
      }

      return stringifySearchParams({ ...unrelatedValue, ...prefixedValues });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value, unrelatedValue]
  );

  const set = useCallback(
    /**
     * Set all or some of the filters. This will change the URl!
     */
    (newValues: Partial<Values>) =>
      navigate({ search: toSearchParams(newValues) }),
    [navigate, toSearchParams]
  );

  const setFilters = useCallback(
    /**
     * Set all or some of the filters. This will change the URL.
     *
     * Whenever you do this the "start" parameter will be set back to 0 (in case
     * there is a "start" parameter).
     *
     * This basically means: if you change filters, people get back to the first results.
     */
    (newValues: Partial<Pick<Values, FilterKeys>>) =>
      set({
        ...newValues,
        ...(('start' in value ? { start: 0 } : {}) as any),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [set]
  );

  const hasChangedFilters = useMemo(
    () => {
      for (const filter of filterKeys) {
        if (!isEqual(filterValues[filter], defaultFilterValues[filter]))
          return true;
      }
      return false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterValues]
  );

  const resetFilters = useCallback(
    /**
     * Sets all filters back to their default values. This will change the URL.
     *
     *
     * Whenever you do this the "start" parameter will be set back to 0 (in case
     * there is a "start" parameter).
     *
     * This basically means: if you change filters, people get back to the first results.
     */
    () => setFilters(defaultFilterValues),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setFilters]
  );

  return {
    value,
    set,
    filterValues,
    hasChangedFilters,
    setFilters,
    resetFilters,
    schema,
    filter,
    filterSchema,
    toSearchParams,
  };
}

export type PageParamsFromSchema<
  Schema extends z.AnyZodObject,
  Values extends z.output<Schema>,
  FilterKeys extends keyof Values = never,
> = ReturnType<typeof usePageParamsFromSchema<Schema, Values, FilterKeys>>;

export function stringifySearchParams(data: unknown) {
  return stringify(data, { skipNulls: true });
}
