import type { FC } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { useOptionalOrganisationService } from 'src/apis/organisation-service/use-organisation-service';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { RootRoutes } from 'src/components/root-routes';
import { PageSpinner } from 'src/components/spinner-container';
import { LngOfferCreate } from 'src/pages/lng/offers/create';
import { LngOfferDetails } from 'src/pages/lng/offers/details';
import { LngOfferEdit } from 'src/pages/lng/offers/edit';
import { LngOffers } from 'src/pages/lng/offers/overview';

export const Lng: FC = () => {
  const organisationService = useOptionalOrganisationService();
  if (!organisationService.loaded) {
    return <PageSpinner />;
  }

  return (
    <RootRoutes>
      <Route path="/lng" element={<Navigate to="/lng/offers" />} />
      <Route path="/lng/offers" element={<LngOffers />} />
      <Route
        path="/lng/offers/:offerId"
        element={<LngOfferDetails organisationService={organisationService} />}
      />
      <Route path="/lng/offers/:offerId/edit" element={<LngOfferEdit />} />
      <Route path="/lng/offers/create" element={<LngOfferCreate />} />
      <Route path="*" element={<ThrowNotFound />} />
    </RootRoutes>
  );
};
