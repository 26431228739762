import { useEffect } from 'react';
import urlTemplate from 'url-template';
import type { RequiredDocumentsResponse } from 'src/apis/assignment-service/types';
import type { AssignmentServiceRequest } from 'src/apis/assignment-service/use-assignment-service-root';
import type { Successful } from 'src/hooks/use-axios';
import { useAxios } from 'src/hooks/use-axios';

export function usePublicRequiredDocuments({
  assignmentService,
  operatorId,
}: {
  assignmentService: Successful<AssignmentServiceRequest>;
  operatorId: string;
}) {
  const publicRequiredDocuments = useAxios(
    (axios, baseConfig, url: string) =>
      axios.request<RequiredDocumentsResponse>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    const url = urlTemplate
      .parse(
        assignmentService.response.data._links.publicRequiredDocuments.href
      )
      .expand({ organisationId: operatorId });

    publicRequiredDocuments.execute(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operatorId]);

  return publicRequiredDocuments;
}

export type PublicRequiredDocumentsRequest = ReturnType<
  typeof usePublicRequiredDocuments
>;

export function useManualPublicRequiredDocuments({
  assignmentService,
}: {
  assignmentService: Successful<AssignmentServiceRequest>;
}) {
  const publicRequiredDocuments = useAxios(
    (axios, baseConfig, operatorId: string) => {
      const url = urlTemplate
        .parse(
          assignmentService.response.data._links.publicRequiredDocuments.href
        )
        .expand({ organisationId: operatorId });
      return axios.request<RequiredDocumentsResponse>({
        ...baseConfig,
        url,
      });
    },
    { neededOnPageLoad: false }
  );

  return publicRequiredDocuments;
}

export type ManualPublicRequiredDocumentsRequest = ReturnType<
  typeof useManualPublicRequiredDocuments
>;
