import type {
  LsoContactTypesClient,
  LsoContactTypesServer,
  ShipperContactTypes,
  SsoContactTypesClient,
  SsoContactTypesServer,
  TsoContactTypesClient,
  TsoContactTypesServer,
} from 'src/apis/organisation-service/types';
import { labelsToOptions } from 'src/utils/labels-to-options';

export const lsoContactTypeLabels: Record<LsoContactTypesClient, string> = {
  BUSINESS: 'Business',
  TECHNICAL: 'Technical',
  EMERGENCY: 'Emergency',
  TESTCOORDINATION: 'Test Coordination',
  LSO_ASSIGNMENTS: 'Assignments',
};
export const ssoContactTypeLabels: Record<SsoContactTypesClient, string> = {
  SSO_ASSIGNMENTS: 'Assignments',
};
export const tsoContactTypeLabels: Record<TsoContactTypesClient, string> = {
  TSO_ASSIGNMENTS: 'Assignments',
};

export const lsoContactTypeLabelsForOverview: Record<
  LsoContactTypesServer,
  string
> = {
  BUSINESS: 'Business',
  TECHNICAL: 'Technical',
  EMERGENCY: 'Emergency',
  TESTCOORDINATION: 'Test Coordination',
  ASSIGNMENTS: 'Assignments (LSO)',
};
export const ssoContactTypeLabelsForOverview: Record<
  SsoContactTypesServer,
  string
> = {
  ASSIGNMENTS: 'Assignments (SSO)',
};
export const tsoContactTypeLabelsForOverview: Record<
  TsoContactTypesServer,
  string
> = {
  ASSIGNMENTS: 'Assignments (TSO)',
};

export const lsoContactTypeOptions = labelsToOptions(lsoContactTypeLabels);
export const ssoContactTypeOptions = labelsToOptions(ssoContactTypeLabels);
export const tsoContactTypeOptions = labelsToOptions(tsoContactTypeLabels);

export const shipperContactTypeLabels: Record<ShipperContactTypes, string> = {
  BILLING: 'Billing',
  DISPATCHING: 'Dispatching',
  IT: 'IT',
  OPERATIONS: 'Operations',
};

export const shipperContactTypeOptions = labelsToOptions(
  shipperContactTypeLabels
);

export const lsoContactTypeLabelsForMarketParticipant: Record<string, string> =
  {
    BUSINESS: 'Business',
    TECHNICAL: 'Technical',
    EMERGENCY: 'Emergency',
    TESTCOORDINATION: 'Test Coordination',
  };
