import { useEffect } from 'react';
import type { RemitReportingSubscriptionInfoDetails } from 'src/apis/remit-reporting/types';
import { useAxios } from 'src/hooks/use-axios';

export function useRemitSecondaryReportingSubscriptionInfoDetails() {
  const subscriptionInfo = useAxios(
    (axios, baseConfig) =>
      axios.request<RemitReportingSubscriptionInfoDetails>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.remitReporting}/api/platform/remit-reporting-settings/secondary/details`,
        method: 'GET',
        timeout: 5000,
      }),
    {
      neededOnPageLoad: false,
      cacheKey: `RemitSecondaryReportingSubscriptionInfoDetails`,
    }
  );

  useEffect(() => {
    subscriptionInfo.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return subscriptionInfo;
}

export type RemitSecondaryReportingSubscriptionInfoDetailsRequest = ReturnType<
  typeof useRemitSecondaryReportingSubscriptionInfoDetails
>;
