import type { UseFloatingOptions } from '@floating-ui/react';
import {
  autoUpdate,
  shift,
  useFloating as useFloatingOriginal,
} from '@floating-ui/react';
import type { CSSProperties } from 'react';
import { useState } from 'react';
import type { StrictOmit } from 'src/utils/utility-types';

export type UseFloatingProps = StrictOmit<
  Partial<UseFloatingOptions>,
  'open' | 'onOpenChange' | 'whileElementsMounted'
>;

/**
 * This hook is a wrapper around `useFloating` from the floating ui. It simplifies the usage by encapsulating
 * the needed styles for the floating element and providing the open state.
 *
 * See https://floating-ui.com/docs/react.
 *
 * It does not take care of the question "Is element A shown?".
 * For this please use `useDropdown`.
 */
export function useFloating(options: UseFloatingProps = {}) {
  const [open, setIsOpen] = useState(false);
  const floating = useFloatingOriginal({
    open,
    onOpenChange: setIsOpen,
    middleware: [shift()],
    whileElementsMounted: (ref, floating, update) =>
      autoUpdate(ref, floating, () => requestAnimationFrame(update)),
    ...options,
  });
  return {
    ...floating,
    setIsOpen,
    style: {
      left: floating.x,
      top: floating.y,
      position: floating.strategy,
    } as Readonly<CSSProperties>,
  };
}
