import type { FC, ReactNode } from 'react';
import { createContext, useContext, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { assertContext } from 'src/utils/assert-context';

type ContextValue = [boolean, (value: boolean) => void];

// pass undefined as any, because we run assertContext at runtime
const FullwidthContext = createContext<ContextValue>(undefined as any);

export function useFullwidth() {
  const context = useContext(FullwidthContext);
  assertContext(context, 'Fullwidth');
  return context;
}

export type FullwidthProviderProps = {
  initialState?: boolean;
  children: ReactNode;
};

export const FullwidthProvider: FC<FullwidthProviderProps> = ({
  children,
  initialState,
}) => {
  const isServerSideRendering = typeof window === 'undefined';
  const isReportingSection = isServerSideRendering
    ? false
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useMatch('/reporting/*');

  const state = useState(initialState ?? Boolean(isReportingSection));
  return (
    <FullwidthContext.Provider value={state}>
      {children}
    </FullwidthContext.Provider>
  );
};
