import { useEffect } from 'react';
import urlTemplate from 'url-template';
import type { TradeResponse } from 'src/apis/contract-management/types';
import { isServerError, useAxios } from 'src/hooks/use-axios';

type HookConfig = {
  tradeUrl: string;
  tradeUuid: string;
};

type RequestOptions = {
  tradeUuid: string;
};

export function useContractManagementTrade({
  tradeUrl,
  tradeUuid,
}: HookConfig) {
  const trade = useAxios(
    (axios, baseConfig, { tradeUuid }: RequestOptions) => {
      const url = urlTemplate.parse(tradeUrl).expand({ tradeId: tradeUuid });

      return axios.request<TradeResponse>({
        ...baseConfig,
        url,
      });
    },
    {
      neededOnPageLoad: true,
      onError(err) {
        if (isServerError(err, 403)) {
          /**
           * The trade templated link is for everyone who subscribed contract-management,
           * we have to check if the user has access to a specific trade.
           */
          return err;
        } else if (isServerError(err, 404)) {
          /**
           * Because of eventual consistency or due to an error in event processing it could
           * always be the case that a trade is known in the monolith, but not (yet) in Contract Management.
           */
          return err;
        } else {
          throw err;
        }
      },
    }
  );

  useEffect(() => {
    trade.execute({ tradeUuid });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradeUuid]);

  return trade;
}

export type ContractManagementTradeRequest = ReturnType<
  typeof useContractManagementTrade
>;
