import { createContext, useContext, useMemo } from 'react';
import type { TsoMinimal } from 'src/apis/monolith/types';
import { useTsos } from 'src/apis/monolith/use-tsos';

export const useTsoMap = () => {
  const tsos = useTsos();

  return useMemo(
    () =>
      tsos.response
        ? new Map(tsos.response.data.map((tso) => [tso.organizationId, tso]))
        : null,
    [tsos.response]
  );
};

const TsoMapContext = createContext<Map<string, TsoMinimal>>(new Map());

export function useTsoMapContext() {
  return useContext(TsoMapContext);
}

export const TsoMapProvider = TsoMapContext.Provider;
