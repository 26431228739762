import { useEffect } from 'react';
import type {
  CreditLimitsRequestParams,
  CreditLimitsResponse,
} from 'src/apis/monolith/types';
import { isServerError, useAxios } from 'src/hooks/use-axios';

export function useCreditLimits(params: CreditLimitsRequestParams) {
  const creditLimitRequest = useAxios(
    (axios, baseConfig, params: CreditLimitsRequestParams) =>
      axios.request<CreditLimitsResponse>({
        ...baseConfig,
        params,
        url: `${PRISMA_CONFIG.monolithApiUrl}/credit-limit/allForTso`,
      }),
    {
      neededOnPageLoad: true,
      onError(error) {
        if (isServerError(error, 404)) {
          // this happens if we search for a non-existing response id
          return error;
        } else {
          throw error;
        }
      },
    }
  );

  useEffect(() => {
    creditLimitRequest.execute(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);
  return creditLimitRequest;
}

export type CreditLimitsRequest = ReturnType<typeof useCreditLimits>;
