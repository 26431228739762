import type { FC } from 'react';
import { lazy } from 'react';

const Page = lazy(
  () => import('src/pages/settings/organisation/users/invite/success/page')
);

export const InviteUserSuccess: FC = () => {
  return <Page />;
};
