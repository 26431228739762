import { useEffect } from 'react';
import type { ServicePackage } from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';

export function useServicePackages() {
  const api = useAxios(
    (axios, baseConfig) =>
      axios.request<ServicePackage[]>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/shipper-premium-service-subscriptions/packages`,
        method: 'GET',
        timeout: 5000,
      }),
    {
      neededOnPageLoad: true,
      // cacheKey: 'ServicePackages',
    }
  );

  useEffect(() => {
    api.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return api;
}

export type ServicePackagesRequest = ReturnType<typeof useServicePackages>;
