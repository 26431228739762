import { useState } from 'react';
import type { AggregateEuRootRequest } from 'src/apis/aggregate-eu/use-aggregate-eu-root';
import type { GetConsortiaRequest } from 'src/apis/aggregate-eu/use-get-consortia';
import { useGetConsortia } from 'src/apis/aggregate-eu/use-get-consortia';
import type { ContractsRequest } from 'src/apis/aggregate-eu/use-get-contract-reports';
import { useGetContractReports } from 'src/apis/aggregate-eu/use-get-contract-reports';
import type { GetLocationsRequest } from 'src/apis/aggregate-eu/use-get-locations';
import { useGetLocations } from 'src/apis/aggregate-eu/use-get-locations';
import type { GetPossibleSellersForCreateRequest } from 'src/apis/aggregate-eu/use-get-possible-sellers-for-create';
import { useGetPossibleSellersForCreate } from 'src/apis/aggregate-eu/use-get-possible-sellers-for-create';
import type { Successful } from 'src/hooks/use-axios';

type HookConfig = {
  aggregateEuRootRequest: Successful<AggregateEuRootRequest>;
};
const contractParams = { limit: 0, offset: 0 };

/**
 * Includes the necessary requests for the CreateContract form.
 * @param aggregateEuRootRequest
 */
export const useContractFormDependencies = ({
  aggregateEuRootRequest,
}: HookConfig) => {
  const contracts = useGetContractReports({
    contractsLink: aggregateEuRootRequest.response.data._links?.contractReports,
    params: contractParams,
  });
  const locations = useGetLocations({ aggregateEuRootRequest });
  const consortia = useGetConsortia({
    aggregateEuRootRequest,
  });
  const [consortiumId, setConsortiumId] = useState<string | undefined>();
  const sellers = useGetPossibleSellersForCreate({
    contracts: contracts.response?.data,
    consortiumId,
  });

  return {
    contracts,
    locations,
    consortia,
    sellers,
    setConsortiumId,
    successful:
      !contracts.response ||
      !locations.response ||
      !consortia.response ||
      !sellers.response
        ? (false as const)
        : ({
            contracts: contracts as Successful<ContractsRequest>,
            locations: locations as Successful<GetLocationsRequest>,
            consortia: consortia as Successful<GetConsortiaRequest>,
            sellers: sellers as Successful<GetPossibleSellersForCreateRequest>,
            setConsortiumId,
          } as const),
  };
};

export type ContractFormDependencies = ReturnType<
  typeof useContractFormDependencies
>;

export type SuccessfulContractFormDependencies = Exclude<
  ContractFormDependencies['successful'],
  false
>;
