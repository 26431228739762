// note: in order to mirror standard behaviour of links
// we generate a link and click event on the fly.
// e.g. while `window.open` can be used to open new tabs as well,
import type { PointerEvent, MouseEvent as ReactMouseEvent } from 'react';

// some browsers might open the tab in the background while others focus them.
function openInBackgroundTab(url: string) {
  const a = document.createElement('a');
  a.href = url;
  a.target = '_blank';
  const e = new MouseEvent('click', {
    ctrlKey: true, // for Windows or Linux
    metaKey: true, // for MacOS
  });
  a.dispatchEvent(e);
}

/**
 * Use this helper function *only* if you need link-like behaviour, but cannot
 * use a real link. Treat this as a last resort.
 *
 * Imagine a table row that should act as a link. A `<tr/>` cannot have `<a/>`
 * as a child or parent. This would be invalid HTML.
 *
 * Given a `PointerEvent` and a `url`, this function will open the `url` in a
 * new tab in the background if the user clicks on the link with the left mouse
 * button while holding the `ctrl` key (on Windows or Linux) or the `meta` key
 * (on MacOS). It will also work if the user clicks with the middle mouse
 * button.
 *
 * If this happened, the event will be stopped from propagating further.
 */
export function handleFakeOpenInBackgroundTab(
  event: PointerEvent | ReactMouseEvent,
  url: string
) {
  const leftMouseClickWithModifier =
    event.button === 0 && (isMac() ? event.metaKey : event.ctrlKey);
  const middleMouseClick = event.button === 1;

  if (leftMouseClickWithModifier || middleMouseClick) {
    openInBackgroundTab(url);
    event.stopPropagation();
  }
}

// this is a "best guess", but pretty reliable:
// - `navigator.userAgentData.platform` is still experimental and not yet widely supported
// - `navigator.platform` is deprecated, but widely used
function isMac() {
  if (navigator.userAgentData?.platform)
    return navigator.userAgentData.platform === 'macOS';
  if (navigator.platform) return navigator.platform.startsWith('Mac');
  return false;
}
