import type { FC } from 'react';
import { lazy } from 'react';
import { useParams } from 'react-router-dom';
import { useContractManagement } from 'src/apis/contract-management/use-contract-management';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { PageSpinner } from 'src/components/spinner-container';
import { useSearchParams } from 'src/hooks/use-search-params';

const PageWrapper = lazy(() => import('./page'));

export const RequestFmct: FC = () => {
  const { operatorUuid } = useParams<{ operatorUuid: string }>();
  const params = useSearchParams();
  const tsoId = params.get('tsoId');

  const contractManagement = useContractManagement();

  if (!contractManagement.response) return <PageSpinner />;

  if (!tsoId || !contractManagement.response.data._links.operator)
    return <ThrowNotFound />;

  return (
    <PageWrapper
      tsoId={tsoId}
      operatorUuid={operatorUuid}
      operatorUrl={contractManagement.response.data._links.operator.href}
    />
  );
};
