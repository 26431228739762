import { useEffect } from 'react';
import type { AuctionTile, AuctionTileParams } from 'src/apis/monolith/types';
import { isServerError, useAxios } from 'src/hooks/use-axios';

type Options = {
  params: AuctionTileParams;
};

export function useAuctionTile({ params }: Options) {
  const auctions = useAxios(
    (axios, baseConfig, params: AuctionTileParams) =>
      axios.request<AuctionTile>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/auctions/tile`,
        params,
        validateStatus(status) {
          // if we get 204 the data of the auction tile is no longer available because the auction closed (data === '')
          // this can be treated like a "410 Gone" error
          if (status === 204) return false;

          return status >= 200 && status < 300; // default
        },
      }),
    {
      neededOnPageLoad: true,
      onError(error) {
        if (isServerError(error, 204)) {
          return error;
        } else {
          throw error;
        }
      },
    }
  );

  useEffect(() => {
    auctions.execute(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return auctions;
}

export type AuctionTileRequest = ReturnType<typeof useAuctionTile>;
