import type { NetworkPointSuggestion } from 'src/components/domain-specifics/network-points/nwp-multi-select';

export function handleNetworkPointQuickFilter({
  networkPointQuickFilter,
  networkPoint,
}: {
  networkPointQuickFilter: string[];
  networkPoint: NetworkPointSuggestion[];
}): string[] {
  // a custom quick filter has higher prio then manually selected network points,
  // that's why we "remove" manually selected network points, if they aren't part of the
  // quick filter pool.
  // in case there is no network point left, we'll manually limit the search to 0 by providing
  // a fake filter.
  const relevantNetworkPoint =
    networkPointQuickFilter.length && networkPoint.length
      ? networkPointQuickFilter.filter((networkPointId) =>
          networkPoint.some((nwp) => nwp.value === networkPointId)
        )
      : networkPointQuickFilter.length
        ? networkPointQuickFilter
        : networkPoint.map((nwp) => nwp.value);

  // if we wouldn't manually handle the case by providing a fake filter, we'd get _all_
  // auctions.
  const forceEmptyNetworkPoint = Boolean(
    networkPointQuickFilter.length &&
      networkPoint.length &&
      !relevantNetworkPoint.length
  );

  return forceEmptyNetworkPoint
    ? ['-1'] // fake filter hack: -1 as a fake id
    : relevantNetworkPoint;
}
