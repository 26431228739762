import type { FC } from 'react';
import { lazy, useEffect } from 'react';
import { useOrganisationDetails } from 'src/apis/organisation-service/use-organisation-details';
import type { OrganisationServiceRequest } from 'src/apis/organisation-service/use-organisation-service';
import { useShipperDetails } from 'src/apis/organisation-service/use-shipper-details';
import { PageSpinner } from 'src/components/spinner-container';
import type { Successful } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(
  () =>
    import(
      'src/pages/settings/organisation/company-information/edit-shipper-information/page'
    )
);
type EditShipperInformationProps = {
  organisationService: Successful<OrganisationServiceRequest>;
};

export const EditShipperInformation: FC<EditShipperInformationProps> = ({
  organisationService,
}) => {
  if (!organisationService.response.data._links.getMyOrganisationDetails)
    throw new Forbidden();

  const organisationDetails = useOrganisationDetails({
    url: organisationService.response.data._links.getMyOrganisationDetails.href,
  });

  useEffect(() => {
    if (
      organisationDetails.response &&
      !organisationDetails.response.data._links.shipperDetails
    )
      throw new Forbidden();
  }, [organisationDetails]);

  const shipperDetails = useShipperDetails({ organisationDetails });

  if (!organisationDetails.response || !shipperDetails.response)
    return <PageSpinner />;

  if (!shipperDetails.response.data._links.edit) throw new Forbidden();
  return (
    <Page
      shipperEditLink={shipperDetails.response.data._links.edit.href}
      shipperDetails={shipperDetails}
      ibanUrl={
        organisationService.response.data._links.getBicAndBankForValidIban.href
      }
    />
  );
};
