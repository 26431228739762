import { addYears } from 'date-fns';
import type { FC } from 'react';
import { lazy } from 'react';
import { useParams } from 'react-router-dom';
import { useMemoOne } from 'use-memo-one';
import type {
  AuctionsParams,
  TradeProposalsParams,
} from 'src/apis/monolith/types';
import { useAuctions } from 'src/apis/monolith/use-auctions';
import type { NetworkPointRequest } from 'src/apis/monolith/use-network-point';
import { useNwpCapacityGraph } from 'src/apis/monolith/use-nwp-capacity-graph';
import { useTradeProposals } from 'src/apis/monolith/use-trade-proposals';
import { useNow } from 'src/hooks/use-now';
import { useNwpDetailParams } from 'src/pages/transport/network-points/details/point-details/use-page-params';

const Page = lazy(
  () => import('src/pages/transport/network-points/details/point-details/page')
);

type Props = {
  networkPoint: NetworkPointRequest;
};

export const NetworkPointsDetails: FC<Props> = ({ networkPoint }) => {
  const pageParams = useNwpDetailParams();
  const { oldNetworkPointId } = useParams<{ oldNetworkPointId: string }>();

  const nwpCapacityGraph = useNwpCapacityGraph({ oldNetworkPointId });
  const now = useNow();

  const longTermParams = useMemoOne<AuctionsParams>(
    () => ({
      periodType: ['MONTH', 'QUARTER', 'YEAR', 'REST_OF_YEAR'],
      phase: ['PUBLISHED', 'OPEN', 'CLOSED'],
      networkPoint: [oldNetworkPointId],
      pageSize: pageParams.longTermAuctions.value.pageSize,
      start: pageParams.longTermAuctions.value.start,
      runtimeFrom: new Date(now).toISOString(),
      runtimeTo: addYears(now, 15).toISOString(),
    }),
    [
      oldNetworkPointId,
      pageParams.longTermAuctions.value.pageSize,
      pageParams.longTermAuctions.value.start,
    ]
  );
  const longTermAuctions = useAuctions({ params: longTermParams });

  const shortTermParams = useMemoOne<AuctionsParams>(
    () => ({
      periodType: ['DAY', 'WITHINDAY'],
      phase: ['OPEN'],
      networkPoint: [oldNetworkPointId],
      pageSize: pageParams.shortTermAuctions.value.pageSize,
      start: pageParams.shortTermAuctions.value.start,
      runtimeFrom: new Date(now).toISOString(),
      runtimeTo: addYears(now, 15).toISOString(),
    }),
    [
      oldNetworkPointId,
      pageParams.shortTermAuctions.value.pageSize,
      pageParams.shortTermAuctions.value.start,
    ]
  );
  const shortTermAuctions = useAuctions({ params: shortTermParams });

  const offeredProposalsParams = useMemoOne<TradeProposalsParams>(
    () =>
      ({
        proposalType: 'PROPOSAL_TO_SELL',
        networkPoint: [oldNetworkPointId],
        pageSize: pageParams.offeredProposals.value.pageSize,
        start: pageParams.offeredProposals.value.start,
      }) satisfies TradeProposalsParams,
    [
      oldNetworkPointId,
      pageParams.offeredProposals.value.pageSize,
      pageParams.offeredProposals.value.start,
    ]
  );
  const offeredProposals = useTradeProposals(offeredProposalsParams);

  const requestedProposalsParams = useMemoOne<TradeProposalsParams>(
    () =>
      ({
        proposalType: 'PROPOSAL_TO_BUY',
        networkPoint: [oldNetworkPointId],
        pageSize: pageParams.requestedProposals.value.pageSize,
        start: pageParams.requestedProposals.value.start,
      }) satisfies TradeProposalsParams,
    [
      oldNetworkPointId,
      pageParams.requestedProposals.value.pageSize,
      pageParams.requestedProposals.value.start,
    ]
  );
  const requestedProposals = useTradeProposals(requestedProposalsParams);

  return (
    <Page
      pageParams={pageParams}
      networkPoint={networkPoint}
      nwpCapacityGraph={nwpCapacityGraph}
      longTermAuctions={longTermAuctions}
      shortTermAuctions={shortTermAuctions}
      offeredProposals={offeredProposals}
      requestedProposals={requestedProposals}
    />
  );
};
