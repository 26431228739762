import { throttle } from 'lodash';
import type { FC, MutableRefObject, ReactNode } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { assertContext } from 'src/utils/assert-context';

type DropTargetRegistry = Map<HTMLElement, HTMLElement>;

type ContextValue = {
  hasActiveDrag: boolean;
  onlyDropTarget: boolean;
  closestDropTarget: HTMLElement | null;
  registry: MutableRefObject<DropTargetRegistry>;
  register: (dropTarget: HTMLElement) => void;
  unregister: (dropTarget: HTMLElement) => void;
};

// pass undefined as any, because we run assertContext at runtime
export const DropManagerContext = createContext<ContextValue>(undefined as any);

/**
 * By using the `dropTargetRef` which is returned from the `useDropManager`
 * you automatically (un)register your drop target during (un)mounting in the
 * drop manager registry.
 * That way you know, if you're the only drop target on the page or the nearest
 * drop target during a "drag process".
 */
export function useDropManager() {
  const dropManager = useContext(DropManagerContext);
  assertContext(dropManager, 'DropManager');

  const [dropTarget, setDropTarget] = useState<HTMLElement | null>(null);

  const dropTargetRef = useCallback(
    (element: HTMLElement | null) => {
      if (element) {
        dropManager.register(element);
      } else {
        if (dropTarget) dropManager.unregister(dropTarget);
      }
      setDropTarget(element);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dropTarget]
  );

  return {
    dropTargetRef,
    isClosestDropTarget: dropTarget === dropManager.closestDropTarget,
    hasActiveDrag: dropManager.hasActiveDrag,
    onlyDropTarget: dropManager.onlyDropTarget,
  };
}

/**
 * The `DropManagerProvider` tells us:
 * - if someone is dragging something across our page
 * - if we have one or zero/more drop targets inside our page
 * - what the nearest drop target relative to the mouse pointer would be
 *
 * To figure this out we need to do a couple of things.
 * 1) Drop targets need to be able to (un)register themselves.
 * 2) Is someone starting a drag event? (Track 'dragenter'.)
 * 3.1) Is someone stopping to drag via 'dragleave' events?
 * 3.2) Is someone stopping to drag via a 'drop' event?
 * 4) What's my nearest drop target during 'dragover' events?
 *
 * Note that 'dragenter' and 'dragleave' aren't emitted _once_ during a "drag process",
 * but _every time_ for _every_ child element which I'm dragging over inside the element
 * I'm actually watching. That's why we need to count how often those events occur to know
 * when we really enter or leave the actual relevant element.
 */
export const DropManagerProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const dragCount = useRef(0);

  const [hasActiveDrag, setHasActiveDrag] = useState(false);
  const [onlyDropTarget, setOnlyDropTarget] = useState(true);
  const [closestDropTarget, setClosestDropTarget] =
    useState<HTMLElement | null>(null);

  // 1) Drop targets need to be able to (un)register themselves.
  const registry = useRef<DropTargetRegistry>(new Map());
  const register = useCallback((dropTarget: HTMLElement) => {
    registry.current.set(dropTarget, dropTarget);
    setOnlyDropTarget(registry.current.size === 1);
  }, []);
  const unregister = useCallback((dropTarget: HTMLElement) => {
    registry.current.delete(dropTarget);
    setOnlyDropTarget(registry.current.size === 1);
  }, []);

  // 2) Is someone starting a drag event? (Track 'dragenter'.)
  useEffect(() => {
    const onDragEnter = () => {
      dragCount.current += 1;
      if (dragCount.current === 1) setHasActiveDrag(true);
    };
    window.addEventListener('dragenter', onDragEnter);
    return () => window.removeEventListener('dragenter', onDragEnter);
  }, []);

  // 3.1) Is someone stopping to drag via 'dragleave' events?
  useEffect(() => {
    const onDragLeave = () => {
      dragCount.current -= 1;
      if (dragCount.current === 0) {
        setHasActiveDrag(false);
        setClosestDropTarget(null);
      }
    };
    window.addEventListener('dragleave', onDragLeave);
    return () => window.removeEventListener('dragleave', onDragLeave);
  }, []);

  // 3.2) Is someone stopping to drag via a 'drop' event?
  useEffect(() => {
    const onDrop = () => {
      dragCount.current = 0;
      setHasActiveDrag(false);
      setClosestDropTarget(null);
    };
    window.addEventListener('drop', onDrop);
    return () => window.removeEventListener('drop', onDrop);
  }, []);

  // * 4) What's my nearest drop target during 'dragover' events?
  useEffect(() => {
    if (!hasActiveDrag) return;

    // dragover is called very often, so we need to throttle it
    const onDragOver = throttle(
      (event: MouseEvent) => {
        let closest = null as { element: HTMLElement; distance: number } | null;
        registry.current.forEach((element) => {
          const elementPos = getPositionAtCenter(element);
          const distance = Math.hypot(
            elementPos.x - event.clientX,
            elementPos.y - event.clientY
          );
          if (!closest) closest = { element, distance };
          else if (distance < closest.distance) closest = { element, distance };
        });
        setClosestDropTarget(closest?.element ?? null);
      },
      200,
      { trailing: false }
    );
    window.addEventListener('dragover', onDragOver);
    return () => {
      window.removeEventListener('dragover', onDragOver);
      setClosestDropTarget(null);
    };
  }, [hasActiveDrag]);

  const value = useMemo(
    () => ({
      hasActiveDrag,
      onlyDropTarget,
      closestDropTarget,
      registry,
      register,
      unregister,
    }),
    [hasActiveDrag, onlyDropTarget, closestDropTarget, register, unregister]
  );

  return (
    <DropManagerContext.Provider value={value}>
      {children}
    </DropManagerContext.Provider>
  );
};

function getPositionAtCenter(element: HTMLElement) {
  const { top, left, width, height } = element.getBoundingClientRect();
  return {
    x: left + width / 2,
    y: top + height / 2,
  };
}
