import { useEffect } from 'react';
import type { QuickFilter } from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';

export function useQuickFilters() {
  const quickFilters = useAxios(
    (axios, baseConfig) =>
      axios.request<QuickFilter[]>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/networkPoints/quickFilters`,
        transformResponse(data) {
          // if unauthenticated or TSO, we get back 204
          if (data === '') return [];
          return JSON.parse(data);
        },
      }),
    { neededOnPageLoad: true }
  );
  useEffect(() => {
    quickFilters.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return quickFilters;
}

export type QuickFiltersRequest = ReturnType<typeof useQuickFilters>;
