import type { FC } from 'react';
import { lazy } from 'react';
import { useMemoOne } from 'use-memo-one';
import type { DraftedProductsParams } from 'src/apis/capacity-management/types';
import { useCapacityCategories } from 'src/apis/capacity-management/use-capacity-categories';
import type { CapacityManagementRootRequest } from 'src/apis/capacity-management/use-capacity-management-root';
import { useDraftedProducts } from 'src/apis/capacity-management/use-drafted-products';
import { useDraftedProductsOverviewParams } from 'src/pages/reporting/products/drafted/overview/use-page-params';

const Page = lazy(() => import('./page'));

type Props = { capacityManagementRoot: CapacityManagementRootRequest };

export const ReportingUploadsDraftedOverview: FC<Props> = ({
  capacityManagementRoot,
}) => {
  const pageParams = useDraftedProductsOverviewParams();

  const params = useMemoOne<DraftedProductsParams>(() => {
    const { start, networkPointId, ...rest } = pageParams.value;
    return {
      offset: start,
      networkPointId: networkPointId.map((id) => id.value),
      ...rest,
    };
  }, [pageParams.value]);

  const draftedProducts = useDraftedProducts({
    capacityManagementRoot,
    params,
  });

  const capacityCategories = useCapacityCategories({
    capacityManagementRoot,
    scope: 'organisation',
  });

  return (
    <Page
      draftedProducts={draftedProducts}
      pageParams={pageParams}
      capacityCategories={capacityCategories}
    />
  );
};
