import type { FC } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { Box } from 'src/components/box';
import { Button } from 'src/components/buttons-and-actions/button';
import { Container } from 'src/components/layout/container';
import { Stack } from 'src/components/layout/stack';
import { ExternalLink } from 'src/components/navigation/link';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { useToast } from 'src/hooks/use-toasts';
import { useTrackingConfig } from 'src/hooks/use-tracking-config';
import { readTrackingCookie } from 'src/utils/tracking-cookie';

const Banner = styled(Box)`
  background-color: white;
`;

export const CookieBanner: FC = () => {
  const { updateTrackingConfig } = useTrackingConfig();
  const monolithUser = useOptionalAuthenticatedMonolithUser();

  const [configured, setConfigured] = useState(() => {
    if (monolithUser && 'allow' in monolithUser.trackingSettings) {
      return 'allow' in monolithUser.trackingSettings;
    } else {
      return Boolean(readTrackingCookie());
    }
  });

  const notify = useToast();

  const onChange = (allow: boolean) => {
    updateTrackingConfig(allow);
    setConfigured(true);
    notify({
      type: 'success',
      children: 'Successfully saved cookie preference!',
    });
  };

  if (configured) return null;

  return (
    <Banner>
      <Container>
        <Stack gap={1.5}>
          <p>
            <strong>Take part in PRISMA usability research</strong>
          </p>

          <p>
            We would like your help in improving your experience with PRISMA. If
            you agree to participate, we will automatically gather usage data
            via browser cookies that we aggregate and analyse. We will also
            occasionally request feedback from you via survey. In no case is
            personally-identifiable data collected.
          </p>

          <Stack flow="column" gap={1} justifyContent="end">
            <ExternalLink
              href="https://www.prisma-capacity.eu/privacy"
              target="_blank"
            >
              More Information
            </ExternalLink>
            <Button mode="secondary" onClick={() => onChange(false)}>
              Decline
            </Button>
            <Button onClick={() => onChange(true)}>Accept & Close</Button>
          </Stack>
        </Stack>
      </Container>
    </Banner>
  );
};
