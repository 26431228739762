import { useEffect } from 'react';
import type {
  ReverseAuctionsReport,
  ReverseAuctionsReportParams,
} from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';

type Options = {
  params?: ReverseAuctionsReportParams;
};

export function useReverseAuctions({ params }: Options) {
  const reverseAuctions = useAxios(
    (axios, baseConfig, params?: ReverseAuctionsReportParams) =>
      axios.request<ReverseAuctionsReport>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/auctions/reverse-auctions`,
        params,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    reverseAuctions.execute(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return reverseAuctions;
}

export type ReverseAuctionsRequest = ReturnType<typeof useReverseAuctions>;
