import type { PremiumServicesDebtor } from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';
import { handleError } from 'src/utils/handle-error';
import { reportToSentry } from 'src/utils/report-to-sentry';

type SubscribeShipperInput = {
  referenceId: number;
  debtor: PremiumServicesDebtor;
  servicePackage: string;
};

const useSubscribeShipper = () => {
  const subscribeShipper = useAxios(
    (
      axios,
      baseConfig,
      { referenceId, debtor, servicePackage }: SubscribeShipperInput
    ) => {
      return axios.request<unknown>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/shippers/${referenceId}/shipper-premium-services-subscription`,
        data: { servicePackage, debtor },
        method: 'POST',
      });
    },
    {
      neededOnPageLoad: false,
      onError(error) {
        const [_, report] = handleError(error, () => {});
        if (report) reportToSentry(error);
        return error;
      },
    }
  );

  return subscribeShipper;
};

export default useSubscribeShipper;
