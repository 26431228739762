import { faChevronCircleLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FC } from 'react';
import { lazy, useEffect } from 'react';
import { useMemoOne } from 'use-memo-one';
import { auctionTitles } from 'src/apis/monolith/mappings';
import type {
  ActivitiesParams,
  AuctionsParams,
  AuctionsSortColumn,
  AuctionTile,
  AuctionTileParams,
  HasOpenAuctionsAtFavoritePointsParams,
} from 'src/apis/monolith/types';
import { useActivities } from 'src/apis/monolith/use-activities';
import { useAuctionTile } from 'src/apis/monolith/use-auction-tile';
import type { AuctionsRequest } from 'src/apis/monolith/use-auctions';
import { useAuctions } from 'src/apis/monolith/use-auctions';
import type { CalendarsRequest } from 'src/apis/monolith/use-calendars';
import { useCalendars } from 'src/apis/monolith/use-calendars';
import { useHasOpenAuctionsAtFavoritePoints } from 'src/apis/monolith/use-has-auctions-at-favorites';
import type { PlatformSettings } from 'src/apis/monolith/use-platform-settings';
import { usePlatformSettings } from 'src/apis/monolith/use-platform-settings';
import type { QuickFiltersRequest } from 'src/apis/monolith/use-quick-filters';
import { useQuickFilters } from 'src/apis/monolith/use-quick-filters';
import { EmptyCard } from 'src/components/data-display/empty-card';
import { PageHeader } from 'src/components/layout/page-header';
import { Stack } from 'src/components/layout/stack';
import { Link } from 'src/components/navigation/link';
import { PageSpinner } from 'src/components/spinner-container';
import type { Successful } from 'src/hooks/use-axios';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import type { RunningAuctionsPageParams } from 'src/pages/transport/auctions/overview/use-page-params';
import {
  useInitialPageParams,
  useRunningAuctionsPageParams,
} from 'src/pages/transport/auctions/overview/use-page-params';
import { handleNetworkPointQuickFilter } from 'src/pages/transport/auctions/overview/utils';

const Page = lazy(() => import('./page'));

export const RunningAuctionsOverview: FC = () => {
  const initialPageParams = useInitialPageParams();
  const platformSettings = usePlatformSettings();

  const isShortTerm =
    initialPageParams.value.periodType === 'WITHINDAY' ||
    initialPageParams.value.periodType === 'DAY';

  const hasOpenAuctionsWithFavoritesParams = useMemoOne(() => {
    const hasFavoritesParams: HasOpenAuctionsAtFavoritePointsParams = {
      periodType: [initialPageParams.value.periodType],
      slot: initialPageParams.value.slot,
      runtimeFrom: initialPageParams.value['runtime-from'],
      runtimeTo: initialPageParams.value['runtime-to'],
      runtimeExactMatch: isShortTerm ? true : undefined,
      calendarIdentifier: initialPageParams.value.identifier,
    };
    return hasFavoritesParams;
  }, [
    initialPageParams.value.periodType,
    initialPageParams.value.slot,
    initialPageParams.value['runtime-from'],
    initialPageParams.value['runtime-to'],
    isShortTerm,
    initialPageParams.value.identifier,
  ]);

  const hasOpenAuctionsWithFavorites = useHasOpenAuctionsAtFavoritePoints({
    params: hasOpenAuctionsWithFavoritesParams,
  });

  if (!hasOpenAuctionsWithFavorites.loaded || !platformSettings.loaded) {
    return <PageSpinner />;
  }

  return (
    <ConfigureFullPageParams
      defaultToFavoriteFilter={
        hasOpenAuctionsWithFavorites.response?.data
          .hasAuctionsAtFavoriteNetworkPoints || false
      }
      isShortTerm={isShortTerm}
      platformSettings={platformSettings.response?.data ?? null}
    />
  );
};

const ConfigureFullPageParams: FC<{
  isShortTerm: boolean;
  defaultToFavoriteFilter: boolean;
  platformSettings: PlatformSettings | null;
}> = ({ defaultToFavoriteFilter, isShortTerm, platformSettings }) => {
  const monolithUser = useOptionalAuthenticatedMonolithUser();
  const pageParams = useRunningAuctionsPageParams({
    defaultToFavoriteFilter,
    platformSettings,
  });

  const quickFilters = useQuickFilters();

  const runningAuctionsParams = useMemoOne(() => {
    const params: AuctionsParams = {
      start: pageParams.value.start,
      pageSize: pageParams.value.pageSize,
      amountSetting: pageParams.value.amountSetting,
      costSetting: pageParams.value.costSetting,
      sortAscending: pageParams.value.sortAscending,
      sortColumn: pageParams.value.sortColumn as AuctionsSortColumn,
      periodType: [pageParams.value.periodType],
      slot: pageParams.value.slot,
      phase: [pageParams.value.phase],
      runtimeFrom:
        pageParams.value.runtimeFromFilter ?? pageParams.value['runtime-from'],
      runtimeTo:
        pageParams.value.runtimeToFilter ?? pageParams.value['runtime-to'],
      runtimeExactMatch: isShortTerm ? true : undefined,
      favoritesOnly: pageParams.value.favoritesOnly,
      ownTsoOnly: pageParams.value.ownTsoOnly,
      withoutBids: true,
      networkPoint: handleNetworkPointQuickFilter(pageParams.value),
      tso: pageParams.value.tso.map((item) => item.value),
      direction: pageParams.value.direction,
      'marketable-min': pageParams.value['marketable-min'],
      'marketable-max': pageParams.value['marketable-max'],
      'startingPrice-min': pageParams.value['startingPrice-min'],
      'startingPrice-max': pageParams.value['startingPrice-max'],
      capacityCategory: pageParams.value['capacityCategory'],
      auctionId: pageParams.value.auctionId
        ? [Number(pageParams.value.auctionId)]
        : undefined,
      calendarIdentifier: pageParams.value.identifier,
    };
    return params;
  }, [pageParams.value]);
  const auctions = useAuctions({ params: runningAuctionsParams });

  const auctionTileParams = useMemoOne<AuctionTileParams>(() => {
    const params: AuctionTileParams = {
      phase: pageParams.value.phase,
      slot: pageParams.value.slot,
      identifier: pageParams.value.identifier,
      periodType: pageParams.value.periodType,
      runtimeFrom: isShortTerm ? pageParams.value['runtime-from'] : null,
      runtimeTo: isShortTerm ? pageParams.value['runtime-to'] : null,
    };
    return params;
  }, [pageParams.value]);
  const auctionTile = useAuctionTile({
    params: auctionTileParams,
  });

  const calendars = useCalendars();

  useEffect(() => {
    const tilePhase = auctionTile.response?.data.phase;
    if (!tilePhase) return;
    // let's say the countdown of the auction ended and it transitioned from
    // PUBLISHED to OPEN. if we refresh now the page params still contain
    // "?phase=PUBLISHED". as a consequence the activities and auctions are
    // empty (or even wrong). interestingly the auction tile _knows_ the correct
    // phase even though it was requested with an outdated one.
    // if we figure out that the phases diverged, we'll update the page params
    if (tilePhase !== pageParams.value.phase)
      pageParams.set({ phase: tilePhase });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auctionTile.response?.data.phase]);

  if (auctionTile.error)
    return (
      <Stack gap={2}>
        <PageHeader
          title={auctionTitles[pageParams.value.periodType]}
          backLink={
            <Link to="/transport/auctions">
              <FontAwesomeIcon icon={faChevronCircleLeft} aria-hidden /> Back to
              Auctions
            </Link>
          }
        />

        <EmptyCard label="Auction calendar is no longer open." />
      </Stack>
    );

  if (
    !auctions.response ||
    !auctionTile.response ||
    !quickFilters.response ||
    !calendars.response
  )
    return <PageSpinner />;

  return (
    <LoadActivities
      isShortTerm={isShortTerm}
      auctions={auctions}
      pageParams={pageParams}
      isShipper={monolithUser?.isShipper}
      auctionTile={auctionTile.response.data}
      quickFilters={quickFilters}
      calendars={calendars}
    />
  );
};

const LoadActivities: FC<{
  isShortTerm: boolean;
  auctionTile: AuctionTile;
  pageParams: RunningAuctionsPageParams;
  auctions: Successful<AuctionsRequest>;
  isShipper?: boolean;
  quickFilters: Successful<QuickFiltersRequest>;
  calendars: Successful<CalendarsRequest>;
}> = ({
  isShortTerm,
  auctionTile,
  pageParams,
  auctions,
  isShipper,
  quickFilters,
  calendars,
}) => {
  const activitiesParams = useMemoOne<ActivitiesParams>(() => {
    // find the correct publish date and use this one to request activities
    // (we don't want to request too few days or we loose activities - see ISR-768,
    // but we also don't always want to ask for the maximum (which is 90 days), because
    // the request is *very* slow).
    const calendarList =
      calendars.response.data[pageParams.value.periodType] ?? [];
    const calendar = calendarList.find(
      (calendar) =>
        (calendar.calendarIdentifier ?? '') ===
          (pageParams.value.identifier ?? '') &&
        calendar.slot === pageParams.value.slot &&
        // it should be fine to just check the first item in `runtimeList`
        // as they all should have the same publish date
        calendar.runtime.start === auctionTile.runtimeList[0].start &&
        calendar.runtime.end === auctionTile.runtimeList[0].end
    );

    const activitiesParams: ActivitiesParams = {
      phase: pageParams.value.phase,
      category: isShortTerm
        ? 'SHORT_TERM_AUCTION'
        : !isShortTerm &&
            (pageParams.value.phase === 'OPEN' ||
              pageParams.value.phase === 'CLOSED')
          ? 'LONG_TERM_AUCTION'
          : 'COMFORT_BID',
      periodType: pageParams.value.periodType,
      cached: false,
      from: calendar?.publishDate, // if no calendar can be found we use the server default (30 days)
    };
    return activitiesParams;
  }, [pageParams.value]);
  const activities = useActivities({
    params: activitiesParams,
    onlyIfAuthenticated: true,
  });
  const platformSettings = usePlatformSettings();

  if (activities?.response === null || !platformSettings.loaded)
    return <PageSpinner />;

  return (
    <Page
      auctions={auctions}
      activities={activities}
      pageParams={pageParams}
      isShipper={isShipper}
      auctionTile={auctionTile}
      quickFilters={quickFilters}
      platformSettings={platformSettings.response?.data}
    />
  );
};
