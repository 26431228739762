import type { FC } from 'react';
import { lazy, useMemo } from 'react';
import { Route } from 'react-router-dom';
import type {
  LngOffersReportingParams,
  PublicOfferReportingStatusParams,
} from 'src/apis/lng-marketing/types';
import { useLngMarketingRoot } from 'src/apis/lng-marketing/use-lng-marketing-root';
import { useLngOffers } from 'src/apis/lng-marketing/use-lng-offers';
import { useOptionalOrganisationService } from 'src/apis/organisation-service/use-organisation-service';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { RootRoutes } from 'src/components/root-routes';
import { PageSpinner } from 'src/components/spinner-container';
import { LngOfferDetails } from 'src/pages/lng/offers/details';
import { useLngReportingPageParams } from 'src/pages/reporting/lng/use-page-params';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(() => import('./page'));

export const ReportingLngRoutes: FC = () => {
  const organisationService = useOptionalOrganisationService();
  if (!organisationService.loaded) {
    return <PageSpinner />;
  }

  return (
    <RootRoutes>
      <Route path="/reporting/lng" element={<ReportingLng />} />
      <Route
        path="/reporting/lng/offers/:offerId"
        element={<LngOfferDetails organisationService={organisationService} />}
      />
      <Route path="*" element={<ThrowNotFound />} />
    </RootRoutes>
  );
};
export const ReportingLng: FC = () => {
  const lngMarketingRoot = useLngMarketingRoot();
  const pageParams = useLngReportingPageParams();

  const params = useMemo(() => {
    // due to BE implementation, when nothing is selected, we need to add all statuses in query params
    const status: Array<PublicOfferReportingStatusParams> = pageParams.value
      .status.length
      ? pageParams.value.status
      : ['FINISHED', 'CANCELLED', 'UNSUCCESSFUL', 'FAILED'];

    return {
      sortBy: pageParams.value.sortBy,
      sortDirection: pageParams.value.sortDirection,
      limit: pageParams.value.pageSize,
      offset: pageParams.value.start,
      terminalId: pageParams.value.terminalId.map((terminal) => terminal.value),
      lsoOrganisationId: pageParams.value.lsoOrganisationId.map(
        (lso) => lso.value
      ),
      status,
      offerName: pageParams.value.offerName,
      offerBusinessId: pageParams.value.offerBusinessId,
      serviceRuntimeStart: pageParams.value.serviceRuntimeStart,
      serviceRuntimeEnd: pageParams.value.serviceRuntimeEnd,
      serviceType: pageParams.value.serviceType,
      ownOffersOnTop: pageParams.value.ownOffersOnTop,
      offersWithAllocations: pageParams.value.offersWithAllocations,
    } satisfies LngOffersReportingParams;
  }, [pageParams.value]);

  const lngOffers = useLngOffers({
    lngMarketingRoot,
    params,
  });

  if (!lngOffers || !lngMarketingRoot) throw new Forbidden();

  if (lngOffers.response === null || lngMarketingRoot.response === null)
    return <PageSpinner />;

  return (
    <Page
      pageParams={pageParams}
      lngOffers={lngOffers}
      lngMarketingRoot={lngMarketingRoot}
    />
  );
};
