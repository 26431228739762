import type { FC } from 'react';
import { lazy } from 'react';
import type { Shipper } from 'src/apis/monolith/types';
import type { ContractManagementShipperRequest } from 'src/pages/shippers/details/information/use-contract-management-shipper';

const Page = lazy(() => import('./page'));

type Props = {
  shipper: Shipper;
  contractManagementShipper?: ContractManagementShipperRequest;
};

export const ShipperFinancialSecurities: FC<Props> = ({
  contractManagementShipper,
  shipper,
}) => {
  if (
    !contractManagementShipper?.response?.data ||
    !contractManagementShipper.response.data._links.getFinancialSecurities
  )
    return null;

  return (
    <Page
      shipperName={shipper.name}
      contractManagementShipper={contractManagementShipper}
    />
  );
};
