import type { FC } from 'react';
import { lazy } from 'react';
import { useOurProfiler } from 'src/hooks/use-our-profiler';
import { useStandardPageParams } from 'src/pages/reporting/standard/use-page-params';

const Page = lazy(() => import('./page'));

export const ReportingStandard: FC = () => {
  useOurProfiler('ReportingStandard');
  return <Page />;
};
