import type { FC, ReactNode } from 'react';
import { createContext, useContext } from 'react';
import { useMemoOne } from 'use-memo-one';
import { useMatchMedia } from 'src/hooks/use-match-media';
import { Breakpoints } from 'src/styles';
import { assertContext } from 'src/utils/assert-context';

type ContextValue = {
  minTablet: boolean;
  minDesktop: boolean;
  minWideDesktop: boolean;
};

// pass undefined as any, because we run assertContext at runtime
const BreakpointsContext = createContext<ContextValue>(undefined as any);

export function useBreakpoints() {
  const context = useContext(BreakpointsContext);
  assertContext(context, 'Breakpoints');
  return context;
}

export const BreakpointsProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const minTablet = useMatchMedia(`(${Breakpoints.minTablet})`);
  const minDesktop = useMatchMedia(`(${Breakpoints.minDesktop})`);
  const minWideDesktop = useMatchMedia(`(${Breakpoints.minWideDesktop})`);

  const value = useMemoOne(
    () => ({ minTablet, minDesktop, minWideDesktop }),
    [minTablet, minDesktop, minWideDesktop]
  );

  return (
    <BreakpointsContext.Provider value={value}>
      {children}
    </BreakpointsContext.Provider>
  );
};
