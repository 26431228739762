import type { FC } from 'react';
import type { PremiumServicesBillingAddressRequest } from 'src/apis/monolith/use-premium-services-billing-address';
import type { ServicePackagesRequest } from 'src/apis/monolith/use-service-packages';
import type { ShipperBriefInfoRequest } from 'src/apis/monolith/use-shipper-brief-info';
import type { ShipperPremiumServicesRequest } from 'src/apis/monolith/use-shipper-premium-services';
import type { RemitReportingSubscriptionInfoDetails } from 'src/apis/remit-reporting/types';
import type { RemitReportingEntrypointRequest } from 'src/apis/remit-reporting/use-remit-reporting-entrypoint';
import { useRemitSecondaryReportingSubscriptionInfoDetails } from 'src/apis/remit-reporting/use-remit-secondary-reporting-subscription-info-details';
import { PageHeader } from 'src/components/layout/page-header';
import { Stack } from 'src/components/layout/stack';
import { PageSpinner } from 'src/components/spinner-container';
import RemitSubscriptionCard from 'src/pages/settings/premium-services/remit-subscription-card';
import ServicePackagesCard from 'src/pages/settings/premium-services/service-packages-card';
import { Forbidden } from 'src/utils/errors';

const Page: FC<{
  remitReportingEntrypointRequest: RemitReportingEntrypointRequest;
  servicePackagesRequest?: ServicePackagesRequest;
  shipperPremiumServicesRequest?: ShipperPremiumServicesRequest;
  shipperBriefInfoRequest?: ShipperBriefInfoRequest;
  premiumServicesBillingAddressRequest?: PremiumServicesBillingAddressRequest;
  referenceId: number;
}> = ({
  remitReportingEntrypointRequest,
  servicePackagesRequest,
  shipperPremiumServicesRequest,
  shipperBriefInfoRequest,
  premiumServicesBillingAddressRequest,
  referenceId,
}) => {
  if (
    !remitReportingEntrypointRequest.response?.data._links?.[
      'secondary-settings-details'
    ] &&
    !shipperPremiumServicesRequest
  ) {
    throw new Forbidden();
  }

  return remitReportingEntrypointRequest.response?.data._links?.[
    'secondary-settings-details'
  ] ? (
    <WithRemitSubscriptionCard
      servicePackagesRequest={servicePackagesRequest}
      shipperPremiumServicesRequest={shipperPremiumServicesRequest}
      shipperBriefInfoRequest={shipperBriefInfoRequest}
      premiumServicesBillingAddressRequest={
        premiumServicesBillingAddressRequest
      }
      referenceId={referenceId}
    />
  ) : (
    <PageContent
      servicePackagesRequest={servicePackagesRequest}
      shipperPremiumServicesRequest={shipperPremiumServicesRequest}
      shipperBriefInfoRequest={shipperBriefInfoRequest}
      premiumServicesBillingAddressRequest={
        premiumServicesBillingAddressRequest
      }
      referenceId={referenceId}
    />
  );
};

const PageContent: FC<{
  remitReportingSubscriptionInfoDetails?: RemitReportingSubscriptionInfoDetails;
  servicePackagesRequest?: ServicePackagesRequest;
  shipperPremiumServicesRequest?: ShipperPremiumServicesRequest;
  shipperBriefInfoRequest?: ShipperBriefInfoRequest;
  premiumServicesBillingAddressRequest?: PremiumServicesBillingAddressRequest;
  referenceId: number;
}> = ({
  remitReportingSubscriptionInfoDetails,
  servicePackagesRequest,
  shipperPremiumServicesRequest,
  shipperBriefInfoRequest,
  premiumServicesBillingAddressRequest,
  referenceId,
}) => {
  return (
    <>
      <PageHeader title="Premium Services" />

      <Stack gap={1}>
        {remitReportingSubscriptionInfoDetails && (
          <RemitSubscriptionCard
            remitReportingSubscriptionInfoDetails={
              remitReportingSubscriptionInfoDetails
            }
          />
        )}
        {shipperPremiumServicesRequest?.response &&
          premiumServicesBillingAddressRequest?.response &&
          servicePackagesRequest?.response &&
          shipperBriefInfoRequest?.response && (
            <ServicePackagesCard
              servicePackages={servicePackagesRequest.response.data}
              shipperPremiumServices={
                shipperPremiumServicesRequest.response.data
              }
              shipperBriefInfo={shipperBriefInfoRequest.response.data}
              debtor={premiumServicesBillingAddressRequest.response.data}
              referenceId={referenceId}
            />
          )}
      </Stack>
    </>
  );
};

const WithRemitSubscriptionCard: FC<{
  servicePackagesRequest?: ServicePackagesRequest;
  shipperPremiumServicesRequest?: ShipperPremiumServicesRequest;
  shipperBriefInfoRequest?: ShipperBriefInfoRequest;
  premiumServicesBillingAddressRequest?: PremiumServicesBillingAddressRequest;
  referenceId: number;
}> = ({
  servicePackagesRequest,
  shipperPremiumServicesRequest,
  shipperBriefInfoRequest,
  premiumServicesBillingAddressRequest,
  referenceId,
}) => {
  const remitSecondaryReportingSubscriptionInfoDetailsRequest =
    useRemitSecondaryReportingSubscriptionInfoDetails();

  if (!remitSecondaryReportingSubscriptionInfoDetailsRequest.response) {
    return <PageSpinner />;
  }

  return (
    <PageContent
      remitReportingSubscriptionInfoDetails={
        remitSecondaryReportingSubscriptionInfoDetailsRequest.response.data
      }
      servicePackagesRequest={servicePackagesRequest}
      shipperPremiumServicesRequest={shipperPremiumServicesRequest}
      shipperBriefInfoRequest={shipperBriefInfoRequest}
      premiumServicesBillingAddressRequest={
        premiumServicesBillingAddressRequest
      }
      referenceId={referenceId}
    />
  );
};

export default Page;
