import type { FC } from 'react';
import { lazy } from 'react';
import type { CmNetworkPointRequest } from 'src/apis/capacity-management/use-cm-network-point';
import { usePriceSteps } from 'src/apis/capacity-management/use-price-steps';
import { useTitle } from 'src/hooks/use-title';

const Page = lazy(() => import('./page'));

type Props = {
  cmNetworkPoint: CmNetworkPointRequest;
};

const EditPriceSteps: FC<Props> = ({ cmNetworkPoint }) => {
  const priceSteps = usePriceSteps({ cmNetworkPoint });

  useTitle('Edit Price Steps');

  return <Page cmNetworkPoint={cmNetworkPoint} priceSteps={priceSteps} />;
};

export default EditPriceSteps;
