import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FC } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { useManualNetworkPointByUuid } from 'src/apis/monolith/use-network-point-by-uuid';
import { Form } from 'src/components/form/form';
import {
  NetworkPointSelect,
  nullableNetworkPointSelectSchema,
} from 'src/components/form/select/network-point-select/network-point-select';
import { Spinner } from 'src/components/spinner-container';

type Props = {
  initialOpen?: boolean; // Just needed for storybook
  initialSearch?: string; // Just needed for storybook
};

export const NetworkPointSearch: FC<Props> = ({
  initialOpen,
  initialSearch,
}) => {
  const navigate = useNavigate();
  const oldNetworkPoint = useManualNetworkPointByUuid();

  useEffect(() => {
    if (!oldNetworkPoint.response) return;
    navigate(
      `/platform/#/network-point/details/${oldNetworkPoint.response.data.id}`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldNetworkPoint.response]);

  return (
    <Form
      initialValues={{ globalNetworkPoint: null }}
      schema={networkPointSearchSchema}
      onSubmit={(values, helpers) => {
        if (values.globalNetworkPoint?.value) {
          oldNetworkPoint.execute(values.globalNetworkPoint.value);
          helpers.setFieldValue('globalNetworkPoint', null);
        }
      }}
      mode="search"
      constraintViolation={null}
      cancelOrResetButton={null}
      submitButton={null}
    >
      {({ submitForm }) => (
        <NetworkPointSelect
          name="globalNetworkPoint"
          label="Network Point"
          placeholder="Find a Network Point"
          hideLabel
          onChange={submitForm}
          leftAddon={
            oldNetworkPoint.pending ? (
              <Spinner />
            ) : (
              <FontAwesomeIcon icon={faSearch} />
            )
          }
          initialOpen={initialOpen}
          initialSearch={initialSearch}
          placement="bottom-end"
        />
      )}
    </Form>
  );
};

const networkPointSearchSchema = z.object({
  globalNetworkPoint: nullableNetworkPointSelectSchema,
});
