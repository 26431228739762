import type { FC } from 'react';
import type { MixedProductType } from 'src/apis/capacity-management/types';
import { labelsToOptions } from 'src/utils/labels-to-options';
import { labelsToValues } from 'src/utils/labels-to-values';

export type PeriodTypeValue =
  | 'WITHINDAY'
  | 'DAY'
  | 'MONTH'
  | 'QUARTER'
  | 'YEAR';

export function assertPeriodType(
  value: string
): asserts value is PeriodTypeValue {
  if (
    value === 'WITHINDAY' ||
    value === 'DAY' ||
    value === 'MONTH' ||
    value === 'QUARTER' ||
    value === 'YEAR'
  ) {
    type TypeCheck<T extends PeriodTypeValue = typeof value> = T;
  } else {
    throw new Error(`${value} is not of type "PeriodType".`);
  }
}

export function assertPeriodTypes(
  values: string[]
): asserts values is PeriodTypeValue[] {
  values.forEach(assertPeriodType);
}

export const periodTypeLabels: Record<PeriodTypeValue, string> = {
  WITHINDAY: 'Within-Day',
  DAY: 'Day',
  MONTH: 'Month',
  QUARTER: 'Quarter',
  YEAR: 'Year',
};

const { WITHINDAY, ...draftedPeriodTypeLabels } = periodTypeLabels;

export { draftedPeriodTypeLabels };

export const periodTypeOptions = labelsToOptions(periodTypeLabels);
export const periodTypeValues = labelsToValues(periodTypeLabels);

export const mixedProductTypeLabels: Record<MixedProductType, string> = {
  ...periodTypeLabels,
  CUSTOM: 'Custom',
};

export const mixedProductTypeOptions = labelsToOptions(mixedProductTypeLabels);
export const mixedProductTypeValues = labelsToValues(mixedProductTypeLabels);

export const PeriodType: FC<{ value: PeriodTypeValue }> = ({ value }) => {
  return <>{value.substring(0, 1)}</>;
};
