import type { AxiosInstance, AxiosRequestConfig, Canceler } from 'axios';
import axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import 'src/utils/axios-instance';
import { useAxiosHookConfig } from 'src/hooks/use-axios/axios-config';
import { useAxiosInstance } from 'src/hooks/use-axios/axios-instance';
import { useMounted } from 'src/hooks/use-mounted';
import type { StrictOmit, Pretty } from 'src/utils/utility-types';

export * from './axios-config';
export * from './axios-instance';
export * from './errors';

type ResponseState<T> = { response: T } | { response: null };
type ErrorState<E> = { error: E } | { error: null };
type LoadingState =
  | { pending: true; canceler: Canceler }
  | { pending: false; canceler: null };

export type Request<T, E = null> = ErrorState<E> &
  ResponseState<T> &
  LoadingState;

export type RequestOptions<CatchedError> = {
  /**
   * Use the `onError` handler, if
   * - you don't want the default error handle to kick in
   * - you don't want to override an existing successful response
   *   (which would happen, if you use `validateStatus` in the Axios config).
   * Just return the error you want to catch (and re-throw all other errors).
   */
  onError?: (error: unknown) => CatchedError;
  /**
   * By setting `neededOnPageLoad` to `true` or `false` you'll change the default error handler behaviour.
   *
   * In case of `neededOnPageLoad: false` you indicate that your initial request is run when the page _has already loaded_.
   * If an error would occur now an error notification would be shown on top of your existing page.
   *
   * In case of `neededOnPageLoad: true` you indicate that your initial request is run when the page _loads_.
   * If an error would occur now an error page would be shown.
   *
   * In both cases you need to execute the request on your own!
   */
  neededOnPageLoad: boolean;
  /**
   * If you provide a cache key the response will be retrieved from the cache, if it already exists.
   * This also dedupes multiple pending requests for the same cache key.
   * The cache can be manually invalidated by `useAxiosCache().invalidate(cacheKey);`.
   */
  cacheKey?: string;
};

export type UseAxiosReturnType<
  Response,
  Params extends any[] = [],
  CatchedError = null,
> = Request<Response, CatchedError> & {
  execute: (...params: Params) => void;
  setResponse: (response: Response | null) => void;
  refresh: (() => void) | null;
  loaded: boolean;
};

export function useAxios<
  Response,
  Params extends any[] = [],
  CatchedError = null,
>(
  callback: (
    axios: AxiosInstance,
    baseConfig: AxiosRequestConfig,
    ...params: Params
  ) => Promise<Response>,
  options: RequestOptions<CatchedError>
): Pretty<UseAxiosReturnType<Response, Params, CatchedError>> {
  const { onError, neededOnPageLoad } = options;

  const axiosInstance = useAxiosInstance();
  const hookConfig = useAxiosHookConfig();

  const [request, setRequest] = useState<Request<Response, CatchedError>>({
    pending: false,
    canceler: null,
    response: null,
    error: null,
  });

  // error handling
  const [catchedError, setCatchedError] = useState<CatchedError | null>(null);
  const handleUncaughtError = (error: unknown) => {
    if (hookConfig.onError) {
      const isPageLoadError = Boolean(
        neededOnPageLoad && request.response === null && catchedError === null
      );
      hookConfig.onError(error, { isPageLoadError });
    } else {
      throw error;
    }
  };
  useEffect(() => {
    if (request.error) {
      if (onError) {
        try {
          setCatchedError(onError(request.error));
        } catch (error) {
          handleUncaughtError(error);
        }
      } else {
        handleUncaughtError(request.error);
      }
    } else {
      setCatchedError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request.error]);

  const abortControllerRef = useRef<AbortController | null>(null);
  const mounted = useMounted();

  // keep a reference to the params of the last successful request
  const lastParams = useRef<Params | null>(null);

  async function handleCallback(...params: Params) {
    const executeStack = new Error().stack;

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;

    setRequest(
      ({ response, error }) =>
        ({
          pending: true,
          canceler: () => controller.abort(),
          error,
          response,
        }) as Request<Response, CatchedError>
    );
    try {
      let response: Awaited<Response> | null = null;

      // use response from cache if available
      // note: a previous request might already be aborted, but still pending as this is an async process.
      // in this case we skip the cache. (this happens e.g. during local development in Reacts strict mode)
      if (
        options.cacheKey &&
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        hookConfig.cache[options.cacheKey] &&
        !hookConfig.cache[options.cacheKey].signal?.aborted
      ) {
        const cachedResponse = hookConfig.cache[options.cacheKey];
        // cached response could pending
        response = (
          cachedResponse.type === 'pending'
            ? await cachedResponse.response
            : cachedResponse.response
        ) as Awaited<Response>;
      } else {
        const responsePromise = callback(
          axiosInstance,
          {
            signal: controller.signal,
            requestSection: hookConfig.requestSection,
          },
          ...params
        );
        // cache pending response
        if (options.cacheKey)
          hookConfig.cache[options.cacheKey] = {
            type: 'pending',
            response: responsePromise,
            signal: controller.signal,
          };
        response = await responsePromise;
        // cache response
        if (options.cacheKey)
          hookConfig.cache[options.cacheKey] = {
            type: 'fulfilled',
            response,
          };
      }

      lastParams.current = params;

      if (!mounted.current) return;
      setRequest(() => ({
        pending: false,
        canceler: null,
        error: null,
        response,
      }));
    } catch (error) {
      if (error instanceof Error) {
        error.stack += `\n\n[cause]: ${executeStack}`;
      }

      if (axios.isCancel(error)) {
        // canceled requests will be ignored
        // if a new request was started, we have a new abort controller that
        // is not aborted ,yet. stop here, so we don't set
        // pending back to false, but keep `pending: true` from the new request
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!abortControllerRef.current?.signal.aborted) return;

        // if the request was cancelled, because the component was unmounted
        // we'll not call setRequest
        if (!mounted.current) return;

        setRequest(
          ({ error, response }) =>
            ({
              pending: false,
              canceler: null,
              response,
              error,
            }) as Request<Response, CatchedError>
        );
      } else {
        // clear cache pending response
        if (
          options.cacheKey &&
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          hookConfig.cache[options.cacheKey] &&
          hookConfig.cache[options.cacheKey].type === 'pending'
        )
          delete hookConfig.cache[options.cacheKey];

        setRequest(
          ({ response }) =>
            ({
              pending: false,
              canceler: null,
              response,
              error,
            }) as Request<Response, CatchedError>
        );
      }
    }
  }

  function cancelPending() {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort(
        'Request was canceled automatically in useAxios hook.'
      );
      abortControllerRef.current = null;
    }
  }

  useEffect(() => cancelPending, []);

  const memoizedCallback = useCallback(
    (...params: Params) => {
      handleCallback(...params); // do not return promise
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options.cacheKey]
  );

  const setResponse = useCallback((response: Response | null) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
    }

    lastParams.current = null;

    setRequest(
      () =>
        ({
          pending: false,
          canceler: null,
          error: null,
          response,
        }) as Request<Response, CatchedError>
    );
  }, []);

  const refresh = useCallback(
    () => memoizedCallback(...lastParams.current!),

    [memoizedCallback]
  );

  // for some edge cases it is important to keep a stable identity
  // e.g. if you have something like `<Formik validate={(values) => validate(value, someRequest.error )} />`,
  // then `someRequest.error` will only be up-to-date with a stable identity
  const identyRef = useRef(
    {} as UseAxiosReturnType<Response, Params, CatchedError>
  );
  Object.assign(identyRef.current, {
    ...request,
    error: catchedError,
    execute: memoizedCallback,
    setResponse,
    refresh: lastParams.current ? refresh : null,
    loaded: Boolean(request.response || catchedError),
  });

  return identyRef.current;
}

// removes "null" as a possible value from "response" in any request
export type Successful<MyRequest> = MyRequest extends { response: unknown }
  ? StrictOmit<MyRequest, 'response'> & {
      response: Exclude<MyRequest['response'], null>;
    }
  : never;
