import type { FC } from 'react';
import type { OperatorResponse } from 'src/apis/contract-management/types';
import type { FinancialSecuritiesRequest } from 'src/apis/contract-management/use-financial-securities';
import type { TsoRequest } from 'src/apis/monolith/use-tso';
import { Card } from 'src/components/data-display/card';
import { Spacer } from 'src/components/layout/spacer';
import { Stack } from 'src/components/layout/stack';
import { Link } from 'src/components/navigation/link';
import { Heading } from 'src/components/text/heading';
import type { Successful } from 'src/hooks/use-axios';
import { useSaveReferrer } from 'src/hooks/use-referrer';
import { FinancialSecuritiesTable } from 'src/pages/operators/assignments/details/components/financial-securities-table';

type Props = {
  tso: Successful<TsoRequest>;
  operator: OperatorResponse;
  financialSecurities: FinancialSecuritiesRequest;
};

export const FinancialSecurities: FC<Props> = ({
  tso,
  operator,
  financialSecurities,
}) => {
  const savedReferrer = useSaveReferrer({
    label: tso.response.data.name,
  });

  if (
    !operator._links.contractsForFinancialSecurity ||
    !operator._links.getFinancialSecurities ||
    !operator._links.submitCashDeposit ||
    !operator._links.submitRating ||
    !operator._links.submitBankGuarantee ||
    !operator._links.submitParentCompanyGuarantee
  )
    return null;

  return (
    <Card>
      <Stack flow="column" justifyContent="space-between" gap={2}>
        <Heading mode="card">Financial Securities</Heading>
        <Link
          data-testid="submit-financial-security"
          size="small"
          mode="button-secondary"
          {...savedReferrer.getLinkProps(
            `/operators/assignments/operators/${operator.id}/submit-financial-security`
          )}
        >
          Submit Financial Security
        </Link>
      </Stack>
      <Spacer />
      <FinancialSecuritiesTable
        referrer={savedReferrer}
        detailsPath={`/operators/assignments/operators/${operator.id}`}
        financialSecurities={financialSecurities}
      />
    </Card>
  );
};
