import { useEffect } from 'react';
import type { RegistrationServiceRoot } from 'src/apis/organisation-registration/types';
import { useAxios } from 'src/hooks/use-axios';

export function useOrganisationRegistration() {
  const organisationRegistration = useAxios(
    (axios, baseConfig) =>
      axios.request<RegistrationServiceRoot>({
        ...baseConfig,
        url: PRISMA_CONFIG.organisationRegistration,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    organisationRegistration.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return organisationRegistration;
}

export type OrganisationRegistrationRequest = ReturnType<
  typeof useOrganisationRegistration
>;
