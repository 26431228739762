import { useEffect } from 'react';
import urlTemplate from 'url-template';
import type { MonthlyStatementDetails } from 'src/apis/capacity-management/types';
import type { MonthlyStatementsRequest } from 'src/apis/capacity-management/use-monthly-statements';
import { useAxios } from 'src/hooks/use-axios';

type HookConfig = {
  monthlyStatements: MonthlyStatementsRequest;
  monthlyStatementId: string;
};

type RequestConfig = {
  instanceUrlTemplate: string;
  monthlyStatementId: string;
};

export function useMonthlyStatement({
  monthlyStatements,
  monthlyStatementId,
}: HookConfig) {
  const monthlyStatement = useAxios(
    (
      axios,
      baseConfig,
      { instanceUrlTemplate, monthlyStatementId }: RequestConfig
    ) => {
      const url = urlTemplate.parse(instanceUrlTemplate).expand({
        monthlyStatementId,
      });
      return axios.request<MonthlyStatementDetails>({
        ...baseConfig,
        url,
      });
    },
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!monthlyStatements.response) return;

    const instanceUrlTemplate =
      monthlyStatements.response.data._links.instance.href;
    monthlyStatement.execute({
      instanceUrlTemplate,
      monthlyStatementId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    monthlyStatements.response?.data._links.instance.href,
    monthlyStatementId,
  ]);

  return monthlyStatement;
}

export type MonthlyStatementRequest = ReturnType<typeof useMonthlyStatement>;
