import { useEffect } from 'react';
import type { PublicOperators } from 'src/apis/organisation-service/types';
import type { OrganisationServicePublicRequest } from 'src/apis/organisation-service/use-organisation-service-public';
import { useAxios } from 'src/hooks/use-axios';

type HookOptions = {
  organisationServicePublic: OrganisationServicePublicRequest;
  randomOrder?: boolean;
};

export function usePublicOperators({
  randomOrder = false,
  organisationServicePublic,
}: HookOptions) {
  const operators = useAxios(
    (axios, baseConfig, url: string) =>
      axios
        .request<PublicOperators>({
          ...baseConfig,
          url,
          method: 'GET',
        })
        .then((response) => {
          if (randomOrder) {
            response.data._embedded.items.sort(() => Math.random() - 0.5);
          }
          return response;
        }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!organisationServicePublic.response) return;
    operators.execute(
      organisationServicePublic.response.data._links.getOperators.href
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationServicePublic.response]);

  return operators;
}

export type PublicOperatorsRequest = ReturnType<typeof usePublicOperators>;
