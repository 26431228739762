import { useEffect } from 'react';
import urlTemplate from 'url-template';
import type {
  CentralBuyerListParams,
  ConsortiaResponse,
} from 'src/apis/aggregate-eu/types';
import type { AggregateEuRootRequest } from 'src/apis/aggregate-eu/use-aggregate-eu-root';
import type { Successful } from 'src/hooks/use-axios';
import { useAxios } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';

type HookConfig = {
  aggregateEuRootRequest: Successful<AggregateEuRootRequest>;
  params?: CentralBuyerListParams;
};

export function useGetConsortia({
  aggregateEuRootRequest,
  params = {},
}: HookConfig) {
  const link = aggregateEuRootRequest.response.data._links?.consortia?.href;

  const consortia = useAxios(
    (axios, baseConfig, requestParams: CentralBuyerListParams) => {
      if (!link) throw new Forbidden();

      const url = urlTemplate.parse(link).expand({});

      return axios.request<ConsortiaResponse>({
        ...baseConfig,
        url,
        params: requestParams,
      });
    },
    {
      neededOnPageLoad: true,
      onError(error) {
        if (error instanceof Forbidden) {
          return error;
        } else {
          throw error;
        }
      },
    }
  );
  useEffect(() => {
    if (!link) return;
    consortia.execute(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link]);

  return consortia;
}

export type GetConsortiaRequest = ReturnType<typeof useGetConsortia>;
