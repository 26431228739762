import type { FC } from 'react';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { usePlatformSettings } from 'src/apis/monolith/use-platform-settings';
import { useRemitReportingEntrypoint } from 'src/apis/remit-reporting/use-remit-reporting-entrypoint';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { RootRoutes } from 'src/components/root-routes';
import { PageSpinner } from 'src/components/spinner-container';
import { ExternalTradesEntry } from 'src/pages/reporting/secondary-tradings/external-trades';

const Page = lazy(() => import('./page'));

export const ReportingSecondaryTradings: FC = () => {
  const platformSettings = usePlatformSettings();
  const remitReportingEntrypoint = useRemitReportingEntrypoint();

  if (!platformSettings.loaded || !remitReportingEntrypoint.loaded) {
    return <PageSpinner />;
  }

  return (
    <RootRoutes>
      <Route
        path="/reporting/secondary"
        element={<Navigate replace to="/reporting/secondary/trades" />}
      />
      {[
        '/reporting/secondary/trades',
        '/reporting/secondary/external-trades',
        '/reporting/secondary/proposals',
        '/reporting/secondary/remit-reporting',
      ].map((path) => (
        <Route
          key={path}
          path={path}
          element={
            <Page
              platformSettings={platformSettings}
              remitReportingEntrypoint={remitReportingEntrypoint.response?.data}
            />
          }
        />
      ))}

      <Route
        path="/reporting/secondary/external-trades/*"
        element={<ExternalTradesEntry />}
      />

      <Route path="*" element={<ThrowNotFound />} />
    </RootRoutes>
  );
};
