import type { FC } from 'react';
import { Colors } from 'src/styles';

type SeparatorProps = {
  error?: boolean;
  disabled?: boolean;
};

export const Separator: FC<SeparatorProps> = (props) => {
  return (
    <div
      style={{
        background: props.disabled
          ? `${Colors.inactive}`
          : props.error
            ? `${Colors.error}`
            : `${Colors.brand}`,
        width: '1px',
        height: '130%',
      }}
    />
  );
};
