import { useEffect } from 'react';
import type { OperatorDetailsResponse } from 'src/apis/organisation-service/types';
import type { OrganisationDetailsRequest } from 'src/apis/organisation-service/use-organisation-details';
import { useAxios } from 'src/hooks/use-axios';

export function useTsoOrSsoDetails({
  organisationDetails,
}: {
  organisationDetails: OrganisationDetailsRequest;
}) {
  const request = useAxios(
    (axios, baseConfig, url: string) =>
      axios.request<OperatorDetailsResponse>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (
      organisationDetails.response &&
      (organisationDetails.response.data._links.tsoDetails ||
        organisationDetails.response.data._links.ssoDetails)
    ) {
      request.execute(
        organisationDetails.response.data._links.tsoDetails?.href ??
          organisationDetails.response.data._links.ssoDetails?.href!
      );
    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationDetails.response]);

  return request;
}

export type TsoOrSsoDetailsRequest = ReturnType<typeof useTsoOrSsoDetails>;
