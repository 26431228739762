import type { AxiosResponse } from 'axios';
import type { AxiosErrorWithConfig } from 'src/hooks/use-axios';
import { isServerError } from 'src/hooks/use-axios';
import type { StrictOmit } from 'src/utils/utility-types';

const type = 'https://zalando.github.io/problem/constraint-violation';

export type Violation = { field: string; message: string };

export type ConstraintViolationResponse = {
  type: typeof type;
  status: 400;
  violations: Violation[];
  title: 'Constraint Violation';
};

export type ConstraintViolation = StrictOmit<
  AxiosErrorWithConfig,
  'response'
> & {
  response: AxiosResponse<ConstraintViolationResponse>;
};

export function isConstraintViolation(
  err: unknown
): err is ConstraintViolation {
  return (
    isServerError(err, null) &&
    !!err.response.data &&
    (err.response.data as any).type === type
  );
}

// creates a new violation from an existing on with different violations
export function createViolationError(
  violationError: ConstraintViolation,
  violations: Violation[]
): ConstraintViolation | null {
  if (!violations.length) return null;
  return {
    ...violationError,
    response: {
      ...violationError.response,
      data: {
        ...violationError.response.data,
        violations,
      },
    },
  };
}
