import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import urlTemplate from 'url-template';
import type { SurrenderRequest } from 'src/apis/surrender-service/types';
import type { SurrenderRootRequest } from 'src/apis/surrender-service/use-surrender-root';
import { useAxios } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';

type RequestOptions = {
  url: string;
};

export function useSurrenderRequest(surrenderRoot: SurrenderRootRequest) {
  const { surrenderRequestId } = useParams<{ surrenderRequestId: string }>();

  const surrenderRequest = useAxios(
    (axios, baseConfig, { url }: RequestOptions) =>
      axios.request<SurrenderRequest>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!surrenderRoot.response) return;

    if (!surrenderRoot.response.data._links.getSurrenderRequest)
      throw new Forbidden();

    const url = urlTemplate
      .parse(surrenderRoot.response.data._links.getSurrenderRequest.href)
      .expand({ surrenderRequestId });

    surrenderRequest.execute({ url });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surrenderRoot.response, surrenderRequestId]);

  return surrenderRequest;
}

export type SurrenderRequestRequest = ReturnType<typeof useSurrenderRequest>;
