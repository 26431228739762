import { useEffect } from 'react';
import type { ShipperDetailsResponse } from 'src/apis/organisation-service/types';
import type { OrganisationDetailsRequest } from 'src/apis/organisation-service/use-organisation-details';
import { useAxios } from 'src/hooks/use-axios';

export function useShipperDetails({
  organisationDetails,
}: {
  organisationDetails: OrganisationDetailsRequest;
}) {
  const shipperDetails = useAxios(
    (axios, baseConfig, url: string) =>
      axios.request<ShipperDetailsResponse>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!organisationDetails.response?.data._links.shipperDetails?.href) return;
    shipperDetails.execute(
      organisationDetails.response.data._links.shipperDetails.href
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationDetails.response]);

  return shipperDetails;
}

export type ShipperDetailsRequest = ReturnType<typeof useShipperDetails>;
