import { z } from 'zod';
import { granularityValues } from 'src/apis/capacity-management/mappings';
import {
  descSortDirectionSchema,
  getPaginationSchema,
} from 'src/components/form/zod-schemas';
import { requiredOutput } from 'src/components/form/zod-utilities';

export const getCapacityOverviewPageParamsSchema = ({
  startDefault,
  endDefault,
}: {
  startDefault: string;
  endDefault: string;
}) =>
  z
    .object({
      sortBy: z.enum(['CREATED_AT']).default('CREATED_AT'),
      sortDirection: descSortDirectionSchema,
      granularity: z.enum(granularityValues).default('DAILY'),
      runtimeIntersectionFilterStart: z
        .string()
        .nullable()
        .transform(requiredOutput())
        .default(startDefault),
      runtimeIntersectionFilterEnd: z
        .string()
        .nullable()
        .transform(requiredOutput())
        .default(endDefault),
    })
    .merge(getPaginationSchema());
