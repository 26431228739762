import type { FC, ReactNode } from 'react';
import {
  CapacityManagementProvider,
  useCapacityManagementRequest,
} from 'src/apis/capacity-management/use-capacity-management';
import { LoadingPage } from 'src/components/loading-page';
import {
  AggregateEuProvider,
  useAggregateEuRequest,
} from 'src/hooks/use-aggregate-eu';
import {
  DirectFcfsFlagsProvider,
  useDirectFcfsFlagsValue,
} from 'src/hooks/use-direct-fcfs-flags';
import {
  FeatureFlagsProvider,
  useFeatureFlagsRequest,
} from 'src/hooks/use-feature-flags';
import { GdmFlagsProvider, useGdmFlagsValue } from 'src/hooks/use-gdm-flags';
import {
  AuthenticatedMonolithUserProvider,
  useAuthenticatedMonolithUserRequest,
} from 'src/hooks/use-monolith-user';
import {
  SystemInfoProvider,
  useSystemInfoRequest,
} from 'src/hooks/use-system-info';
import { TrFlagsProvider, useTrFlagsValue } from 'src/hooks/use-tr-flags';
import {
  DocumentExchangeProvider,
  useOptionalDocumentExchangeRequest,
} from 'src/pages/shippers/upload-documents/use-document-exchange';

/**
 * @deprecated Do NOT add more requests to this component. This was introduced
 * to make certain requests _once_ during start up and then handle the response
 * in different pages. The problem is that we _overfetch_ a lot (e.g. every page makes
 * a request to service X even though it's not needed for that particular page).
 *
 * You should rather use `useAxios(() => {}, { cacheKey })`. The `cacheKey` ensures that
 * you don't re-run certain requests as long as we have a response in the cache already.
 */
export const GlobalResourcesProvider: FC<{
  children: ReactNode;
  fallback?: ReactNode;
}> = ({ children, fallback = <LoadingPage /> }) => {
  const [loadedAuth, auth] = useAuthenticatedMonolithUserRequest();
  const [loadedFeatureFlags, featureFlags] = useFeatureFlagsRequest();
  const [loadedSystemInfo, systemInfo] = useSystemInfoRequest();
  const gdmFlags = useGdmFlagsValue();
  const trFlags = useTrFlagsValue();
  const directFcfsFlags = useDirectFcfsFlagsValue();
  const capacityManagement = useCapacityManagementRequest();
  const documentExchange = useOptionalDocumentExchangeRequest();
  const aggregateEu = useAggregateEuRequest();

  if (
    loadedAuth &&
    loadedFeatureFlags &&
    loadedSystemInfo &&
    gdmFlags.loaded &&
    trFlags.loaded &&
    directFcfsFlags.loaded &&
    capacityManagement.loaded &&
    documentExchange.loaded
  ) {
    return (
      <AuthenticatedMonolithUserProvider value={auth}>
        <FeatureFlagsProvider value={featureFlags}>
          <SystemInfoProvider value={systemInfo}>
            <GdmFlagsProvider value={gdmFlags.value}>
              <TrFlagsProvider value={trFlags.value}>
                <DirectFcfsFlagsProvider value={directFcfsFlags.value}>
                  <AggregateEuProvider value={aggregateEu.response?.data}>
                    <CapacityManagementProvider
                      value={capacityManagement.response?.data}
                    >
                      <DocumentExchangeProvider
                        value={documentExchange.response?.data}
                      >
                        {children}
                      </DocumentExchangeProvider>
                    </CapacityManagementProvider>
                  </AggregateEuProvider>
                </DirectFcfsFlagsProvider>
              </TrFlagsProvider>
            </GdmFlagsProvider>
          </SystemInfoProvider>
        </FeatureFlagsProvider>
      </AuthenticatedMonolithUserProvider>
    );
  } else {
    return <>{fallback}</>;
  }
};
