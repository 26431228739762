import { useEffect } from 'react';
import type { TsoMinimal } from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';

type HookOptions = {
  randomOrder?: boolean;
};

export function useTsos({ randomOrder = false }: HookOptions = {}) {
  const tsos = useAxios(
    (axios, baseConfig) =>
      axios
        .request<TsoMinimal[]>({
          ...baseConfig,
          url: `${PRISMA_CONFIG.monolithApiUrl}/tso/?detailLevel=MINIMAL`,
          method: 'GET',
        })
        .then((response) => {
          if (randomOrder) {
            response.data = response.data.sort(() => Math.random() - 0.5);
          }
          return response;
        }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    tsos.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return tsos;
}

export type TsosRequest = ReturnType<typeof useTsos>;
