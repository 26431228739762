import { useEffect } from 'react';
import type { BalancingCodes, CapacityCategory } from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';
import type { Proposal } from 'src/pages/reporting/secondary-tradings/overview/types';

type HookOptions = {
  proposal: Proposal;
};

export type BalancingCodesParams = {
  networkPointId: number;
  capacityCategory0: CapacityCategory;
  capacityCategory1?: CapacityCategory;
  runtimeEnd: string;
  runtimeStart: string;
};

export function useBalancingCodes({ proposal }: HookOptions) {
  const balancingCodes = useAxios(
    (axios, baseConfig, params: BalancingCodesParams) =>
      axios.request<BalancingCodes>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/proposal/balancingCodes`,
        params,
      }),
    {
      neededOnPageLoad: true,
    }
  );

  useEffect(() => {
    balancingCodes.execute({
      networkPointId: proposal.networkPoint.id,
      capacityCategory0: proposal.components[0].capacityCategory,
      capacityCategory1: proposal.components[1]?.capacityCategory,
      runtimeStart: proposal.runtime.start,
      runtimeEnd: proposal.runtime.end,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposal]);

  return balancingCodes;
}

export type TsoConfigRequest = ReturnType<typeof useBalancingCodes>;
