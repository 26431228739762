import { useEffect } from 'react';
import type {
  HasOpenAuctionsAtFavoritePointsParams,
  OpenAuctionsAtFavoritePointsResult,
} from 'src/apis/monolith/types';
import { isNetworkError, isServerError, useAxios } from 'src/hooks/use-axios';

type Options = {
  params: HasOpenAuctionsAtFavoritePointsParams;
};

export function useHasOpenAuctionsAtFavoritePoints({ params }: Options) {
  const auctions = useAxios(
    (axios, baseConfig, params: HasOpenAuctionsAtFavoritePointsParams) =>
      axios.request<OpenAuctionsAtFavoritePointsResult>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/auctions/has-open-auctions-at-favorite-points`,
        params,
      }),
    {
      neededOnPageLoad: true,
      onError(error) {
        if (isNetworkError(error) || isServerError(error, 404)) {
          // we totally ignore this error, backend might not be deployed yet
          return error;
        }
        throw error;
      },
    }
  );

  useEffect(() => {
    auctions.execute(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return auctions;
}

export type HasOpenAuctionsAtFavoritePointsRequest = ReturnType<
  typeof useHasOpenAuctionsAtFavoritePoints
>;
