import type { FC } from 'react';
import { lazy } from 'react';
import { useParams } from 'react-router-dom';
import type { PublicOperatorDetailsResponse } from 'src/apis/organisation-service/types';
import { useOrganisationServicePublic } from 'src/apis/organisation-service/use-organisation-service-public';
import { usePublicOperatorDetails } from 'src/apis/organisation-service/use-public-operator-details';
import { useRemitReportingEntrypoint } from 'src/apis/remit-reporting/use-remit-reporting-entrypoint';
import { PageSpinner } from 'src/components/spinner-container';
import type { Successful } from 'src/hooks/use-axios';
import { useTitle } from 'src/hooks/use-title';
import type { BookingRequest } from 'src/pages/reporting/direct-fcfs/bookings/use-booking';
import { useBooking } from 'src/pages/reporting/direct-fcfs/bookings/use-booking';
import { usePrimaryRemitReportHistory } from 'src/pages/reporting/remit-reports/overview/use-primary-remit-report-history';

const Page = lazy(() => import('./page'));

export const ReportingBooking: FC = () => {
  const { bookingId } = useParams<{ bookingId: string }>();
  useTitle(`Direct FCFS Booking`);
  const booking = useBooking({ bookingId });
  const organisationService = useOrganisationServicePublic();

  const operatorDetails = usePublicOperatorDetails({
    organisationServicePublic: organisationService,
    operatorUuid: booking.response?.data.tsoId,
  });

  const peerOperatorDetails = usePublicOperatorDetails({
    organisationServicePublic: organisationService,
    operatorUuid: booking.response?.data.peerTsoId,
  });

  const remitReportingEntrypoint = useRemitReportingEntrypoint();

  if (
    !booking.response ||
    organisationService.pending ||
    operatorDetails.pending ||
    peerOperatorDetails.pending ||
    !remitReportingEntrypoint.loaded
  )
    return <PageSpinner />;

  const loadRemitReportHistory = Boolean(
    remitReportingEntrypoint.response?.data._links?.['primary-report-history']
  );

  return loadRemitReportHistory && booking.response.data.allocationId ? (
    <PageWithRemitHistory
      bookingRequest={booking}
      operatorDetails={operatorDetails.response?.data}
      peerOperatorDetails={peerOperatorDetails.response?.data}
      allocationId={booking.response.data.allocationId}
    />
  ) : (
    <Page
      bookingRequest={booking}
      operatorDetails={operatorDetails.response?.data}
      peerOperatorDetails={peerOperatorDetails.response?.data}
    />
  );
};

const PageWithRemitHistory: FC<{
  bookingRequest: Successful<BookingRequest>;
  operatorDetails?: PublicOperatorDetailsResponse;
  peerOperatorDetails?: PublicOperatorDetailsResponse;
  allocationId: string;
}> = ({
  bookingRequest,
  operatorDetails,
  peerOperatorDetails,
  allocationId,
}) => {
  const remitHistory = usePrimaryRemitReportHistory(allocationId);

  if (!remitHistory.loaded) {
    return <PageSpinner />;
  }

  return (
    <Page
      bookingRequest={bookingRequest}
      operatorDetails={operatorDetails}
      peerOperatorDetails={peerOperatorDetails}
      remitHistory={remitHistory.response?.data}
    />
  );
};
