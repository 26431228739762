import type { DialogProps } from '@reach/dialog';
import type { FC } from 'react';
import type { DocumentWithdrawRequest } from 'src/apis/document-exchange/use-document-withdraw';
import { Modal } from 'src/components/overlay/modal';
import { useCountdown } from 'src/hooks/use-countdown';

type Props = Pick<DialogProps, 'onDismiss'> & {
  documentWithdraw: DocumentWithdrawRequest;
};

export const WithdrawDocumentModal: FC<Props> = ({
  documentWithdraw,
  onDismiss,
}) => {
  const seconds = useCountdown();

  return (
    <Modal
      data-testid="withdraw-operator-document-modal"
      title="Withdraw Document"
      onDismiss={onDismiss}
      footer={{
        confirmButton: {
          label: seconds
            ? `Wait ${seconds}s to proceed...`
            : documentWithdraw.pending
              ? 'Withdrawing...'
              : 'Submit',
          dataTestId: 'withdraw-submit-btn-modal',
          disabled: documentWithdraw.pending || seconds !== 0,
          onClick: documentWithdraw.execute,
        },
      }}
    >
      <p>
        Do you want to withdraw this document? This document will be hidden for
        the shipper.
      </p>
    </Modal>
  );
};
