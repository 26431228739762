import type { FC } from 'react';
import { Route } from 'react-router-dom';
import type { CurrentShipperUserRequest } from 'src/apis/monolith/use-current-shipper-user';
import { useCurrentShipperUser } from 'src/apis/monolith/use-current-shipper-user';
import type { ShipperRequest } from 'src/apis/monolith/use-shipper';
import { useShipper } from 'src/apis/monolith/use-shipper';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { PageHeader } from 'src/components/layout/page-header';
import { RootRoutes } from 'src/components/root-routes';
import { PageSpinner } from 'src/components/spinner-container';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { getServices } from 'src/pages/settings/notifications/config';
import { EditNotificationSettings } from 'src/pages/settings/notifications/edit';
import { NotificationSettingsOverview } from 'src/pages/settings/notifications/overview';
import { EditShipperNotificationSettings } from 'src/pages/settings/notifications/shipper-edit';
import type { NotificationSettingsRequest } from 'src/pages/settings/notifications/use-notification-settings';
import { useNotificationSettings } from 'src/pages/settings/notifications/use-notification-settings';

export const NotificationSettings: FC = () => {
  const monolithUser = useOptionalAuthenticatedMonolithUser();
  const services = getServices(monolithUser);
  const notificationSettings = useNotificationSettings({
    services,
  });

  if (monolithUser?.isShipper)
    return <LoadShipperData notificationSettings={notificationSettings} />;

  return <Routing notificationSettings={notificationSettings} />;
};

const LoadShipperData: FC<{
  notificationSettings: NotificationSettingsRequest;
}> = ({ notificationSettings }) => {
  const shipperUser = useCurrentShipperUser();
  const shipper = useShipper();
  return (
    <Routing
      notificationSettings={notificationSettings}
      shipperUser={shipperUser}
      shipper={shipper}
    />
  );
};

const Routing: FC<{
  notificationSettings: NotificationSettingsRequest;
  shipperUser?: CurrentShipperUserRequest;
  shipper?: ShipperRequest;
}> = ({ notificationSettings, shipperUser, shipper }) => {
  if (
    notificationSettings?.response === null ||
    shipperUser?.response === null ||
    shipper?.response === null
  )
    return <PageSpinner />;

  return (
    <>
      <PageHeader title="Notification Settings" />
      <RootRoutes>
        {notificationSettings ? (
          <Route
            path="/settings/notifications/edit"
            element={
              <EditNotificationSettings
                notificationSettings={notificationSettings}
              />
            }
          />
        ) : null}

        {shipperUser && shipper ? (
          <Route
            path="/settings/notifications/shipper-edit"
            element={
              <EditShipperNotificationSettings
                shipperUser={shipperUser}
                shipper={shipper}
              />
            }
          />
        ) : null}

        <Route
          path="/settings/notifications"
          element={
            <NotificationSettingsOverview
              notificationSettings={notificationSettings}
              shipperUser={shipperUser}
              shipper={shipper}
            />
          }
        />

        <Route path="*" element={<ThrowNotFound />} />
      </RootRoutes>
    </>
  );
};
