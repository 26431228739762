import { useEffect } from 'react';
import urlTemplate from 'url-template';
import type { UserDetailsResponse } from 'src/apis/organisation-service/types';
import { useAxios } from 'src/hooks/use-axios';

type Options = {
  url: string;
  userId: string;
};

type Params = {
  userId: string;
};

export function useUserDetails({ url, userId }: Options) {
  const userDetails = useAxios(
    (axios, baseConfig, { userId }: Params) =>
      axios.request<UserDetailsResponse>({
        ...baseConfig,
        url: urlTemplate.parse(url).expand({ userId }),
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    userDetails.execute({ userId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return userDetails;
}

export type UserDetailsRequest = ReturnType<typeof useUserDetails>;
