import { faSync } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { Spacer } from 'src/components/layout/spacer';
import { Stack } from 'src/components/layout/stack';
import { useNow } from 'src/hooks/use-now';
import { rotateAnimation, rotateAnimationDurationInMS } from 'src/styles';

type Align = 'flex-start' | 'center' | 'flex-end';
type Justify = 'flex-start' | 'center' | 'flex-end';

const Relative = styled.div`
  position: relative;
  z-index: 0;
`;

const Fade = styled.div<{ pending: boolean }>`
  transition-property: opacity;
  transition-duration: 300ms;
  opacity: ${({ pending }) => (pending ? '0.4' : '1.0')};
  ${({ pending }) => pending && 'pointer-events: none'};
`;

const Center = styled.div<{
  align: Align;
  justify: Justify;
  px: string;
  py: string;
}>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};
  padding: ${({ px, py }) => `${px} ${py}`};
`;

export const Spinner = styled(FontAwesomeIcon).attrs({
  icon: faSync,
  'aria-label': 'Loading',
})`
  ${rotateAnimation};
`;

const PositionedSpinner = styled(Spinner)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

type Props = {
  pending: boolean;
  align?: Align;
  justify?: Justify;
  px?: string;
  py?: string;
  children: ReactNode;
  'data-testid'?: string;
};

export const SpinnerContainer: FC<Props> = (props) => {
  const {
    pending,
    justify = 'center',
    align = 'center',
    px = '0rem',
    py = '0rem',
    children,
    'data-testid': testId,
  } = props;

  return (
    <Relative>
      {pending && (
        <Center
          align={align}
          justify={justify}
          px={px}
          py={py}
          data-testid={testId}
        >
          <PositionedSpinner />
        </Center>
      )}
      <Fade pending={pending}>{children}</Fade>
    </Relative>
  );
};

export const PageSpinner: FC = () => {
  const now = useNow();
  // explanation and sandbox for debugging: https://twitter.com/PipoPeperoni/status/1588433621018611714
  const [delay] = useState(() => now % rotateAnimationDurationInMS);
  return (
    <Stack flow="column" justifyContent="center">
      <Spacer />
      <PositionedSpinner style={{ animationDelay: `-${delay}ms` }} />
      <Spacer />
    </Stack>
  );
};
