import { z } from 'zod';
import { contractStatusTypeValues } from 'src/apis/aggregate-eu/mappings';
import { getPaginationSchema } from 'src/components/form/zod-schemas';
import { preprocessAsArray } from 'src/components/form/zod-utilities';
import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';

const contractReportsSchema = z
  .object({
    locationId: z.array(z.string()).default([]),
    sellerId: z.array(z.string()).default([]),
    contractState: z.preprocess(
      preprocessAsArray,
      z.array(z.enum(contractStatusTypeValues)).default([])
    ),
    displayContractReportId: z.string().nullable().default(null),
    buyers: z.array(z.string()).default([]),
    runtimeStart: z.string().nullable().default(null),
    runtimeEnd: z.string().nullable().default(null),
    quantityMin: z.coerce.number().nullable().default(null),
    quantityMax: z.coerce.number().nullable().default(null),
  })
  .merge(getPaginationSchema());

export function usePageParams() {
  return usePageParamsFromSchema(contractReportsSchema, {
    filters: {
      locationId: { label: 'Locations' },
      sellerId: { label: 'Sellers' },
      contractState: { label: 'Contract Status' },
      displayContractReportId: { label: 'Contract Report ID' },
      buyers: { label: 'Buyers' },
      runtimeStart: { label: 'Runtime Start' },
      runtimeEnd: { label: 'Runtime End' },
      quantityMax: { label: 'Quantity' },
      quantityMin: { label: 'Quantity' },
    },
  });
}

export type PageParams = ReturnType<typeof usePageParams>;
