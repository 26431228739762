import type { FC } from 'react';
import { lazy } from 'react';
import { useMemoOne } from 'use-memo-one';
import type { ContractsParams } from 'src/apis/contract-management/types';
import type { ContractManagementRequest } from 'src/apis/contract-management/use-contract-management';
import type { Shipper } from 'src/apis/monolith/types';
import { useContracts } from 'src/pages/shippers/details/contracts/use-contracts';

const Page = lazy(() => import('./page'));

type ShipperContractsProps = {
  contractManagement: ContractManagementRequest;
  shipper: Shipper;
};

export const ShipperContracts: FC<ShipperContractsProps> = ({
  contractManagement,
  shipper,
}) => {
  const params = useMemoOne<ContractsParams>(
    () => ({ shipperId: [shipper.organizationId] }),
    [shipper]
  );
  const contracts = useContracts({ contractManagement, params });
  return <Page contracts={contracts} shipper={shipper} />;
};
