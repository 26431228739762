import type { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import type { RectReadOnly } from 'src/hooks/use-measure';
import { useMeasure } from 'src/hooks/use-measure';
import { Colors } from 'src/styles';

/**
 * @deprecated
 * Don't use `<InputContainer/>` directly, but use `<InnerAddon />` instead.
 * It allows you to set the addons dynamically and returns the the size of your
 * addon, in case you need it.
 */
export const InputContainer = styled.div`
  position: relative;
`;

export const addonPadding = '1rem';

/**
 * @deprecated Don't use `<AddonRight/>` directly, but use `<InnerAddon />` instead.
 * It allows you to set the addons dynamically and returns the the size of your
 * addon, in case you need it.
 */
export const AddonRight = styled.div<{
  left?: boolean;
  showError?: boolean;
  disabled?: boolean;
}>`
  position: absolute;
  top: 0;
  height: 100%;
  padding-left: ${addonPadding};
  padding-right: ${addonPadding};
  display: flex;
  align-items: center;

  ${({ left }) =>
    left
      ? css`
          left: 0;
        `
      : css`
          right: 0;
        `}

  pointer-events: none;

  ${({ showError }) =>
    showError &&
    css`
      color: ${Colors.error};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${Colors.inactive};
      cursor: not-allowed;
    `}
`;

type InnerAddonProps = {
  children: ({
    leftBounds,
    rightBounds,
  }: {
    leftBounds?: RectReadOnly;
    rightBounds?: RectReadOnly;
  }) => ReactNode;
  /**
   * If you need interactivity in your addon, you can wrap your custom UI with `pointer-events: initial;`.
   */
  right?: ReactNode;
  /**
   * If you need interactivity in your addon, you can wrap your custom UI with `pointer-events: initial;`.
   */
  left?: ReactNode;
  showError?: boolean;
  disabled?: boolean;
};

export const InnerAddon: FC<InnerAddonProps> = ({
  children,
  right,
  left,
  showError,
  disabled,
}) => {
  const [refLeft, leftBounds] = useMeasure();
  const [refRight, rightBounds] = useMeasure();

  if (!right && !left) return <>{children({})}</>;

  return (
    <InputContainer>
      {children({
        rightBounds: right ? rightBounds : undefined,
        leftBounds: left ? leftBounds : undefined,
      })}
      {left && (
        <AddonRight
          ref={refLeft}
          left
          showError={showError}
          disabled={disabled}
        >
          {left}
        </AddonRight>
      )}

      <AddonRight ref={refRight} showError={showError} disabled={disabled}>
        {right}
      </AddonRight>
    </InputContainer>
  );
};
