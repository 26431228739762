import { useEffect } from 'react';
import type { AffiliateInvoices } from 'src/apis/capacity-management/types';
import { useAxios } from 'src/hooks/use-axios';

type HookConfig = {
  url: string;
};

type RequestOptions = {
  url: string;
};

export function useAffiliateInvoices({ url }: HookConfig) {
  const affiliateInvoices = useAxios(
    (axios, baseConfig, { url }: RequestOptions) =>
      axios.request<AffiliateInvoices>({
        ...baseConfig,
        url,
      }),
    {
      neededOnPageLoad: true,
    }
  );

  useEffect(() => {
    affiliateInvoices.execute({ url });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return affiliateInvoices;
}

export type AffiliateInvoicesRequest = ReturnType<typeof useAffiliateInvoices>;
