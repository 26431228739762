import type { FC } from 'react';
import type {
  FinancialSecurity,
  FinancialSecurityType,
} from 'src/apis/contract-management/types';
import type { FinancialSecuritiesRequest } from 'src/apis/contract-management/use-financial-securities';
import { TableActions } from 'src/components/buttons-and-actions/table-actions';
import { EmptyValue } from 'src/components/data-display/empty-value';
import { FormatDate } from 'src/components/data-display/format-date';
import { FormatValue } from 'src/components/data-display/format-value';
import { Table } from 'src/components/data-display/smart-table';
import { Td, Th } from 'src/components/data-display/table';
import { Link } from 'src/components/navigation/link';
import { PageSpinner } from 'src/components/spinner-container';
import type { SavedReferrer } from 'src/hooks/use-referrer';
import { FinancialSecurityStatusDisplay } from 'src/pages/operators/assignments/details/financial-securities/details/financial-security-status';

export const financialSecurityTypeLabel: Record<FinancialSecurityType, string> =
  {
    CASH_DEPOSIT: 'Cash Deposit',
    RATING: 'Rating',
    PARENT_COMPANY_GUARANTEE: 'Parent Company Guarantee',
    BANK_GUARANTEE: 'Bank Guarantee',
  };

export const financialSecurityLinkText: Record<FinancialSecurityType, string> =
  {
    CASH_DEPOSIT: 'cash-deposit',
    RATING: 'rating',
    PARENT_COMPANY_GUARANTEE: 'parent-company-guarantee',
    BANK_GUARANTEE: 'bank-guarantee',
  };

export const FinancialSecuritiesTable: FC<{
  referrer: SavedReferrer;
  financialSecurities: FinancialSecuritiesRequest;
  detailsPath: string;
}> = ({ referrer, detailsPath, financialSecurities }) => {
  if (!financialSecurities.response) return <PageSpinner />;

  return (
    <Table
      pending={financialSecurities.pending}
      paginated={{
        ...financialSecurities.response.data,
        paramPrefix: 'financialSecurities',
      }}
      data={financialSecurities.response.data._embedded.items ?? []}
      cols={[
        {
          key: 'assignedId',
          head: <Th>ID</Th>,
          body: (data, index) => (
            <Td data-testid={`assignedId-${index}`}>{data.assignedId}</Td>
          ),
        },
        {
          key: 'amount',
          head: <Th>Amount</Th>,
          body: (data, index) => (
            <Td data-testid={`amount-${index}`}>
              <FormatValue
                smallLabel
                type="2-decimals"
                value={data.amount.value}
                label={data.amount.currency}
                stacked
              />
            </Td>
          ),
        },
        {
          key: 'type',
          head: <Th>Type</Th>,
          body: (data, index) => (
            <Td data-testid={`type-${index}`}>
              {financialSecurityTypeLabel[data.type]}
            </Td>
          ),
        },
        {
          key: 'startDate',
          head: <Th>Start</Th>,
          body: (data, index) => (
            <Td data-testid={`startDate-${index}`}>
              {data.startDate ? (
                <FormatDate value={data.startDate} type="date-time" />
              ) : (
                <EmptyValue />
              )}
            </Td>
          ),
        },
        {
          key: 'endDate',
          head: <Th>End</Th>,
          body: (data, index) => (
            <Td data-testid={`endDate-${index}`}>
              {!data.startDate ? (
                <EmptyValue />
              ) : data.endDate ? (
                <FormatDate value={data.endDate} type="date-time" />
              ) : data.type === 'BANK_GUARANTEE' ||
                data.type === 'PARENT_COMPANY_GUARANTEE' ? (
                <EmptyValue label="Renewable" />
              ) : (
                <EmptyValue label="Indefinite" />
              )}
            </Td>
          ),
        },
        {
          key: 'status',
          width: 1.5,
          head: <Th>Status</Th>,
          body: (data: FinancialSecurity, index) => (
            <Td data-testid={`status-${index}`}>
              <FinancialSecurityStatusDisplay value={data.status} />
            </Td>
          ),
        },
        {
          key: 'assignedContractId',
          head: <Th>Allocated to Contract</Th>,
          body: (data, index) => (
            <Td data-testid={`assignedContractId-${index}`}>
              {data.assignedContractId}
            </Td>
          ),
        },
        {
          key: 'actions',
          width: '7rem',
          head: <Th align="center">Actions</Th>,
          body: (data, index) => (
            <Td align="center" data-testid={`actions-${index}`}>
              <TableActions>
                <Link
                  {...referrer.getLinkProps(
                    `${detailsPath}/financial-securities/${
                      financialSecurityLinkText[data.type]
                    }/${data.id}`
                  )}
                >
                  Details
                </Link>
              </TableActions>
            </Td>
          ),
        },
      ]}
      empty={{
        label: 'You have no financial securities.',
      }}
    />
  );
};
