import { useEffect } from 'react';
import type { OrganisationDetailsResponse } from 'src/apis/organisation-service/types';
import type { OptionalOrganisationServiceRequest } from 'src/apis/organisation-service/use-organisation-service';
import { isServerError, useAxios } from 'src/hooks/use-axios';
import { useToast } from 'src/hooks/use-toasts';
import { handleErrorWithNotification } from 'src/utils/handle-error';

export function useOrganisationDetails({ url }: { url: string }) {
  const organisationDetails = useAxios(
    (axios, baseConfig, url: string) =>
      axios.request<OrganisationDetailsResponse>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    organisationDetails.execute(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return organisationDetails;
}

export type OrganisationDetailsRequest = ReturnType<
  typeof useOrganisationDetails
>;

export function useOptionalOrganisationDetails({
  organisationService,
}: {
  organisationService: OptionalOrganisationServiceRequest;
}) {
  const notify = useToast();

  const organisationDetails = useAxios(
    (axios, baseConfig, url: string) =>
      axios.request<OrganisationDetailsResponse>({
        ...baseConfig,
        url,
      }),
    {
      neededOnPageLoad: true,
      onError(error) {
        if (!isServerError(error, 401)) {
          handleErrorWithNotification({
            error,
            notify,
            setReauthenticate: () => {},
          });
        }
        return error;
      },
    }
  );

  useEffect(() => {
    if (!organisationService.response) return;

    organisationDetails.execute(
      organisationService.response.data._links.getMyOrganisationDetails.href
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationService.response]);

  if (!organisationService.response) return;

  return organisationDetails;
}

export type OptionalOrganisationDetailsRequest = ReturnType<
  typeof useOptionalOrganisationDetails
>;
