import type { FC } from 'react';
import { lazy } from 'react';
import type { AggregateEuRootRequest } from 'src/apis/aggregate-eu/use-aggregate-eu-root';
import { PageSpinner } from 'src/components/spinner-container';
import type { Successful } from 'src/hooks/use-axios';
import { useContractFormDependencies } from 'src/pages/aggregate-eu/contract-reports/common/use-contract-form-dependencies';

const Page = lazy(() => import('./page'));

export const AggregateEuCreateContractReport: FC<{
  aggregateEuRootRequest: Successful<AggregateEuRootRequest>;
}> = ({ aggregateEuRootRequest }) => {
  const contractFormDependencies = useContractFormDependencies({
    aggregateEuRootRequest,
  });
  if (!contractFormDependencies.successful) return <PageSpinner />;
  return (
    <Page contractFormDependencies={contractFormDependencies.successful} />
  );
};
