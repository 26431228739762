import type { FC, ReactNode } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { v4 as uuid } from 'uuid';
import { useMounted } from 'src/hooks/use-mounted';
import { assertContext } from 'src/utils/assert-context';

export type ToastTypes = 'error' | 'success' | 'warning';

export type NotifyItem = {
  id?: string;
  children: ReactNode;
  type: ToastTypes;
  /**
   * By default toasts will be shown for 5000ms, except
   * error toasts which don't have a timeout (`false`).
   *
   * You can change the timeout by passing any positive number or
   * disable the timeout by passing `false`.
   */
  timeout?: number | false;
};

type ReceivedNotifyItem = NotifyItem & {
  id: string;
};

type State = {
  items: ReceivedNotifyItem[];
};

const initialState: State = { items: [] };

type Action =
  | {
      type: 'add';
      item: ReceivedNotifyItem;
    }
  | {
      type: 'remove';
      id: string;
    };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'add':
      return { ...state, items: [...state.items, action.item] };
    case 'remove':
      return {
        ...state,
        items: state.items.filter((item) => item.id !== action.id),
      };
    default:
      throw new Error(`Found unknown action.type in ${action}.`);
  }
};

type ToastItemsContextValue = State & {
  remove: (id: string) => void;
};

// pass undefined as any, because we run assertContext at runtime
const ToastItemsContext = createContext<ToastItemsContextValue>(
  undefined as any
);

export function useToastItems() {
  const context = useContext(ToastItemsContext);
  assertContext(context, 'ToastItems');
  return context;
}

export type ToastContextValue = (item: NotifyItem) => void;

// pass undefined as any, because we run assertContext at runtime
const ToastContext = createContext<ToastContextValue>(undefined as any);

export function useToast() {
  const context = useContext(ToastContext);
  assertContext(context, 'Toasts');
  return context;
}

export type ToastsProviderProps = {
  initialItems?: NotifyItem[];
  children: ReactNode;
};

export const ToastsProvider: FC<ToastsProviderProps> = ({
  children,
  initialItems = [],
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const mounted = useMounted();

  const notify = useCallback(
    (item: NotifyItem) => {
      const id = item.id ?? uuid();
      const timeout = item.timeout ?? (item.type === 'error' ? false : 5000);

      dispatch({ type: 'add', item: { ...item, id, timeout } });

      if (timeout) {
        setTimeout(() => {
          if (!mounted.current) return;
          dispatch({ type: 'remove', id });
        }, timeout);
      }
    },
    [mounted]
  );

  const remove = useCallback(
    (id: string) => dispatch({ type: 'remove', id }),
    []
  );

  useEffect(() => {
    initialItems.forEach(notify);
  }, [initialItems, notify]);

  return (
    <ToastItemsContext.Provider value={{ ...state, remove }}>
      <ToastContext.Provider value={notify}>{children}</ToastContext.Provider>
    </ToastItemsContext.Provider>
  );
};
