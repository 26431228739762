import type { FC } from 'react';
import { lazy, useMemo } from 'react';
import type { UsersRequestParams } from 'src/apis/organisation-service/types';
import type { OrganisationServiceRequest } from 'src/apis/organisation-service/use-organisation-service';
import { usePermissionGroups } from 'src/apis/organisation-service/use-permission-groups';
import { PageSpinner } from 'src/components/spinner-container';
import type { Successful } from 'src/hooks/use-axios';
import { usePageParams } from 'src/pages/settings/organisation/users/use-page-params';
import { useUsers } from 'src/pages/settings/organisation/users/use-users';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(() => import('src/pages/settings/organisation/users/page'));

type Props = {
  organisationService: Successful<OrganisationServiceRequest>;
};

export const UsersOverview: FC<Props> = ({ organisationService }) => {
  if (
    !organisationService.response.data._links.getAllOrganisationUsers ||
    !organisationService.response.data._links.getMyOrganisationPermissionGroups
  )
    throw new Forbidden();

  const permissionGroups = usePermissionGroups({
    url: organisationService.response.data._links
      .getMyOrganisationPermissionGroups.href,
  });

  const pageParams = usePageParams();

  const params = useMemo(
    () =>
      ({
        sortBy: pageParams.value.sortBy,
        sortDirection: pageParams.value.sortDirection,
        limit: pageParams.value.pageSize,
        offset: pageParams.value.start,
        name: pageParams.value.name,
        email: pageParams.value.email,
        status: pageParams.value.status,
        permissionGroupId: pageParams.value.permissionGroupId ?? null,
      }) satisfies UsersRequestParams,
    [pageParams.value]
  );

  const users = useUsers({
    url: organisationService.response.data._links.getAllOrganisationUsers.href,
    params,
  });

  if (!users.response || !permissionGroups.response) return <PageSpinner />;

  return (
    <Page
      users={users}
      pageParams={pageParams}
      permissionGroups={permissionGroups}
    />
  );
};
