import type { FC } from 'react';
import { lazy } from 'react';
import type { AssignmentServiceRequest } from 'src/apis/assignment-service/use-assignment-service-root';
import { useOptionalDvgwConfiguration } from 'src/apis/assignment-service/use-dvgw-configuration';
import { PageSpinner } from 'src/components/spinner-container';
import type { Successful } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(
  () =>
    import(
      'src/pages/settings/assignment-settings/dvgw-configuration/edit/page'
    )
);

type AssignmentServiceProps = {
  assignmentService: Successful<AssignmentServiceRequest>;
};

export const EditDvgwConfiguration: FC<AssignmentServiceProps> = ({
  assignmentService,
}) => {
  if (
    !assignmentService.response.data._links
      .getAssignmentSettingsDvgwConfiguration
  ) {
    throw new Forbidden();
  }

  const dvgwConfiguration = useOptionalDvgwConfiguration(
    assignmentService.response.data._links
      .getAssignmentSettingsDvgwConfiguration.href
  );

  if (dvgwConfiguration?.response === null) return <PageSpinner />;

  return <Page dvgwConfiguration={dvgwConfiguration} />;
};
