import { setHours, startOfMonth, subMonths } from 'date-fns';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';
import { useMemo } from 'react';
import { z } from 'zod';
import {
  descSortDirectionSchema,
  getPaginationSchema,
} from 'src/components/form/zod-schemas';
import { preprocessAsArray } from 'src/components/form/zod-utilities';
import { useNow } from 'src/hooks/use-now';
import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import { shipperOptionSchema } from 'src/pages/reporting/shipper-transactions/shipper-searchable-multi-select';
import { timeZone } from 'src/utils/date-time-format';

const curtailmentsPageSchema = ({
  start,
  end,
}: {
  start: string;
  end: string;
}) =>
  z
    .object({
      curtailmentPeriodStart: z.string().nullable().default(start),
      curtailmentPeriodEnd: z.string().nullable().default(end),
      shipperId: z.preprocess(
        preprocessAsArray,
        z.array(shipperOptionSchema).default([])
      ),
      sortBy: z.string().default('CURTAILMENT_PERIOD_START'),
      sortDirection: descSortDirectionSchema,
    })
    .merge(getPaginationSchema());

export function usePageParams() {
  const now = useNow();

  // default is previous month
  const { start, end } = useMemo(() => {
    const zonedNow = toZonedTime(now, timeZone);

    const start = setHours(startOfMonth(subMonths(zonedNow, 1)), 6);
    const end = setHours(startOfMonth(zonedNow), 6);

    return {
      start: fromZonedTime(start, timeZone).toISOString(),
      end: fromZonedTime(end, timeZone).toISOString(),
    };
  }, [now]);
  return usePageParamsFromSchema(curtailmentsPageSchema({ start, end }), {
    filters: {
      curtailmentPeriodStart: { label: 'Curtailment Period' },
      curtailmentPeriodEnd: { label: 'Curtailment Period' },
      shipperId: { label: 'Shipper' },
    },
  });
}

export type CurtailmentPageParams = ReturnType<typeof usePageParams>;
