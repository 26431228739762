import type { FC } from 'react';
import styled from 'styled-components';
import { Container } from 'src/components/layout/container';
import { Stack } from 'src/components/layout/stack';
import { Logo } from 'src/components/media-and-icons/logo';
import { ExternalLink, Link } from 'src/components/navigation/link';
import { AngularModalDimmer } from 'src/components/overlay/angular-modal-dimmer';
import { Heading } from 'src/components/text/heading';
import { useNow } from 'src/hooks/use-now';
import { Breakpoints, Colors } from 'src/styles';

const FooterContainer = styled.footer`
  background-color: ${Colors.brand};
  padding-top: 3rem;
  padding-bottom: 5rem;
`;

const Columns = styled.div`
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: 1fr;
  border-bottom: 0.1rem solid ${Colors.brandLight2};
  padding-bottom: 5rem;
  margin-bottom: 3rem;

  @media (${Breakpoints.minTablet}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (${Breakpoints.minDesktop}) {
    grid-template-columns: 1.5fr 1fr 1fr 2.5fr;
  }
`;

const LegalColumns = styled.div`
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: 1fr;
  justify-items: start;

  @media (${Breakpoints.minTablet}) {
    grid-auto-flow: column;
    justify-content: space-between;
  }
`;

const LogoContainer = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

const Links = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const ListItem = styled.li`
  margin: 1.6rem 0;
`;

const LegalLinks = styled.div``;

const LegalLink = styled(ExternalLink)`
  color: ${Colors.brandLight2};
`;

const Divider = styled.span`
  color: ${Colors.brandLight2};
`;

const Copyright = styled.span`
  color: ${Colors.brandLight1};
`;

const Text = styled.span`
  color: ${Colors.brandLight2};
`;

const FooterLink = styled(ExternalLink)`
  font-size: 1.8rem;
  color: ${Colors.brandLight2};
`;

export const Footer: FC = () => {
  const now = new Date(useNow());
  return (
    <AngularModalDimmer>
      <FooterContainer>
        <Container ignoreFullwidth>
          <Columns>
            <div>
              <LogoContainer>
                <Logo />
              </LogoContainer>
            </div>

            <div>
              <Heading mode="footer">Explore</Heading>
              <Links>
                <ListItem>
                  <FooterLink
                    mode="inverse"
                    href="https://www.prisma-capacity.eu/"
                    target="_blank"
                  >
                    Company
                  </FooterLink>
                </ListItem>
                <ListItem>
                  <FooterLink
                    mode="inverse"
                    href="https://www.prisma-capacity.eu/services/"
                    target="_blank"
                  >
                    Services
                  </FooterLink>
                </ListItem>
                <ListItem>
                  <FooterLink
                    mode="inverse"
                    href="https://www.prisma-capacity.eu/careers/"
                    target="_blank"
                  >
                    Careers
                  </FooterLink>
                </ListItem>
                <ListItem>
                  <FooterLink
                    mode="inverse"
                    href="https://www.prisma-capacity.eu/news/"
                    target="_blank"
                  >
                    News
                  </FooterLink>
                </ListItem>
                <ListItem>
                  <FooterLink
                    mode="inverse"
                    href="https://www.prisma-capacity.eu/contact/"
                    target="_blank"
                  >
                    Contact
                  </FooterLink>
                </ListItem>
              </Links>
            </div>

            <div>
              <Heading mode="footer">Resources</Heading>
              <Links>
                <ListItem>
                  <FooterLink as={Link} mode="inverse" to="/umm">
                    UMM
                  </FooterLink>
                </ListItem>
                <ListItem>
                  <FooterLink
                    as={Link}
                    mode="inverse"
                    to="/market-information/players/tsos"
                  >
                    Market Information
                  </FooterLink>
                </ListItem>
                <ListItem>
                  <FooterLink
                    mode="inverse"
                    href="https://www.prisma-capacity.eu/user-infos/"
                    target="_blank"
                  >
                    PRISMA Infos
                  </FooterLink>
                </ListItem>
                <ListItem>
                  <FooterLink
                    mode="inverse"
                    href="https://www.prisma-capacity.eu/downloads/"
                    target="_blank"
                  >
                    Downloads
                  </FooterLink>
                </ListItem>
                <ListItem>
                  <FooterLink
                    mode="inverse"
                    href="https://help.prisma-capacity.eu/support/solutions/36000117872"
                    target="_blank"
                  >
                    FAQ
                  </FooterLink>
                </ListItem>
              </Links>
            </div>

            <div>
              <Heading mode="footer">Stay up to date</Heading>

              <Stack justifyItems="start" gap={2}>
                <Text>
                  Receive regular emails about the platform and the Gas Market,
                  surveys and invitations by subscribing to our newsletter. You
                  can unsubscribe at any time.
                </Text>

                <ExternalLink
                  mode="button-inverse"
                  href="https://share-eu1.hsforms.com/1_R7051mYSPOn6s5Tbt6evA2dtk5s/"
                >
                  Subscribe
                </ExternalLink>
              </Stack>
            </div>
          </Columns>

          <LegalColumns>
            <div>
              <ExternalLink
                mode="button-inverse"
                href="https://help.prisma-capacity.eu/"
              >
                Support Portal
              </ExternalLink>
            </div>

            <LegalLinks>
              <Stack flow="column" gap={1}>
                <LegalLink
                  mode="inverse"
                  href="https://www.prisma-capacity.eu/impressum/"
                  target="_blank"
                >
                  Imprint
                </LegalLink>
                <Divider>|</Divider>
                <LegalLink
                  mode="inverse"
                  href="https://www.prisma-capacity.eu/privacy/"
                  target="_blank"
                >
                  Privacy Policy
                </LegalLink>
                <Divider>|</Divider>
                <LegalLink
                  mode="inverse"
                  href="https://www.prisma-capacity.eu/platform-usage-contract-puc"
                  target="_blank"
                >
                  Platform Usage Conditions
                </LegalLink>
                <Divider>|</Divider>
                <Copyright>Copyright @ PRISMA {now.getFullYear()}</Copyright>
              </Stack>
            </LegalLinks>
          </LegalColumns>
        </Container>
      </FooterContainer>
    </AngularModalDimmer>
  );
};
