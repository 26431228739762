import type { MotionStyle } from 'framer-motion';
import { motion } from 'framer-motion';
import type { FC, ReactNode } from 'react';

const layoutDuration = 0.1;

export const CardAnimation: FC<{ children: ReactNode }> = ({ children }) => (
  <motion.div
    layout
    transition={{ duration: layoutDuration, ease: 'easeInOut' }}
  >
    {children}
  </motion.div>
);

export const ContentAnimation: FC<{
  style?: MotionStyle;
  children: ReactNode;
}> = ({ children, style = {} }) => {
  const transition = { duration: 0.2, ease: 'easeInOut' };
  return (
    <motion.div
      style={{ maxWidth: '100%', ...style }}
      initial={{ opacity: 0, pointerEvents: 'none' }}
      animate={{
        opacity: 1,
        transitionEnd: {
          pointerEvents: 'initial',
        },
      }}
      exit={{
        opacity: 0,
        transition,
      }}
      transition={{ ...transition, delay: layoutDuration }}
    >
      {children}
    </motion.div>
  );
};
