import { z } from 'zod';
import {
  ascSortDirectionSchema,
  getPaginationSchema,
} from 'src/components/form/zod-schemas';
import { preprocessAsArray } from 'src/components/form/zod-utilities';

export type UserAssignmentsSortBy =
  | 'USER_NAME'
  | 'USER_EMAIL'
  | 'USER_ASSIGNMENT_STATUS';

export const sortByValues = [
  'USER_NAME',
  'USER_EMAIL',
  'USER_ASSIGNMENT_STATUS',
] as const satisfies readonly UserAssignmentsSortBy[];

export function getUserAssignmentsForOperatorSchema() {
  return z
    .object({
      sortBy: z.enum(sortByValues).default('USER_NAME'),
      sortDirection: ascSortDirectionSchema,
      userName: z.string().nullable().default(null),
      userEmail: z.string().nullable().default(null),
      status: z.preprocess(
        preprocessAsArray,
        z
          .array(
            z.enum([
              'WAITING_FOR_APPROVAL',
              'APPROVED',
              'TEMPORARILY_DISABLED',
              'CANCELLED',
            ])
          )
          .default([])
      ),
    })
    .merge(getPaginationSchema());
}

export function getOperatorContactDetailsSchema() {
  return getPaginationSchema({ pageSize: 5 });
}
