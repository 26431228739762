import { useEffect } from 'react';
import type { ContractManagementResponse } from 'src/apis/contract-management/types';
import { isNetworkError, useAxios } from 'src/hooks/use-axios';

export function useContractManagement() {
  const contractManagement = useAxios(
    (axios, baseConfig) =>
      axios.request<ContractManagementResponse>({
        ...baseConfig,
        url: PRISMA_CONFIG.contractManagement,
      }),
    {
      neededOnPageLoad: true,
      onError(error) {
        if (isNetworkError(error)) {
          return error;
        } else {
          throw error;
        }
      },
    }
  );

  useEffect(() => {
    contractManagement.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return contractManagement;
}

export type ContractManagementRequest = ReturnType<
  typeof useContractManagement
>;
