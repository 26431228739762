import { z } from 'zod';
import {
  descSortDirectionSchema,
  getPaginationSchema,
} from 'src/components/form/zod-schemas';
import { preprocessAsArray } from 'src/components/form/zod-utilities';
import { contractOptionSchema } from 'src/pages/reporting/monthly-statements/corrections/contracts-searchable-multi-select';
import { correctionTypeLabels } from 'src/pages/reporting/monthly-statements/corrections/labels';
import { shipperOptionSchema } from 'src/pages/reporting/shipper-transactions/shipper-searchable-multi-select';
import { labelsToValues } from 'src/utils/labels-to-values';

export const correctionTypeValues = labelsToValues(correctionTypeLabels);

export const correctionsMonthlyStatementsSchema = z
  .object({
    creationDateStart: z.string().nullable().default(null),
    creationDateEnd: z.string().nullable().default(null),
    billingPeriodStart: z.string().nullable().default(null),
    billingPeriodEnd: z.string().nullable().default(null),
    type: z.preprocess(
      preprocessAsArray,
      z.array(z.enum(correctionTypeValues)).default([])
    ),
    shipperId: z.preprocess(
      preprocessAsArray,
      z.array(shipperOptionSchema).default([])
    ),
    contractId: z.preprocess(
      preprocessAsArray,
      z.array(contractOptionSchema).default([])
    ),
    sortBy: z
      .enum(['CREATION_DATE', 'BILLING_PERIOD_START'])
      .default('CREATION_DATE'),
    sortDirection: descSortDirectionSchema,
  })
  .merge(getPaginationSchema());
