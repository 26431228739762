import { useEffect } from 'react';
import urlTemplate from 'url-template';
import type {
  CapacityManagementRootResponse,
  MyPortfolioGraphResponse,
  MyPortfolioParams,
} from 'src/apis/capacity-management/types';
import type { CapacityManagementRootRequest } from 'src/apis/capacity-management/use-capacity-management-root';
import { useAxios } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';
import { isConstraintViolation } from 'src/utils/is-constraint-violation';

type HookConfig = {
  capacityManagementRoot: CapacityManagementRootRequest;
  params: MyPortfolioParams;
};

type RequestOptions = {
  rootData: CapacityManagementRootResponse;
  params: MyPortfolioParams;
};

export function useMyPortfolioGraph({
  capacityManagementRoot,
  params,
}: HookConfig) {
  const myPortfolio = useAxios(
    (axios, baseConfig, { rootData, params }: RequestOptions) => {
      if (!rootData._links?.myPortfolioGraph) throw new Forbidden();

      const url = urlTemplate
        .parse(rootData._links.myPortfolioGraph.href)
        .expand(params);

      return axios.request<MyPortfolioGraphResponse>({
        ...baseConfig,
        // params,
        url,
      });
    },
    {
      neededOnPageLoad: true,
      onError(error) {
        if (isConstraintViolation(error) && myPortfolio.response) {
          return error;
        } else {
          throw error;
        }
      },
    }
  );

  useEffect(() => {
    if (!capacityManagementRoot.response) return;

    const rootData = capacityManagementRoot.response.data;
    const options = { rootData, params };

    myPortfolio.execute(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capacityManagementRoot.response, params]);

  return myPortfolio;
}

export type MyPortfolioGraphRequest = ReturnType<typeof useMyPortfolioGraph>;
