import { useEffect } from 'react';
import type { AssignedShippersResponse } from 'src/apis/assignment-service/types';
import type { AssignedUsersRequest } from 'src/apis/assignment-service/use-assigned-users';

import { useAxios } from 'src/hooks/use-axios';

type Props = {
  url: string;
};

type HookOptions = {
  assignedUsers: AssignedUsersRequest;
};

export function useAssignedShippers({ assignedUsers }: HookOptions) {
  const assignedShippers = useAxios(
    (axios, baseConfig, { url }: Props) =>
      axios.request<AssignedShippersResponse>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!assignedUsers.response) return;
    assignedShippers.execute({
      url: assignedUsers.response.data._links.assignedShippers.href,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedUsers.response]);

  return assignedShippers;
}

export type AssignedShippersRequest = ReturnType<typeof useAssignedShippers>;
