import type { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { GroupWrap } from 'src/components/group-wrap';
import type { ActiveSection } from 'src/components/layout/header/types';
import { ExternalLink } from 'src/components/navigation/link';
import { useBreakpoints } from 'src/hooks/use-breakpoints';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';

export const isLngActive = (
  pathname: string,
  hash: string,
  newLngUsed: boolean
) =>
  !newLngUsed && pathname.startsWith('/platform') && hash.startsWith('#/lng');

export const SecondaryNavLng: FC<{ activeSection: ActiveSection }> = ({
  activeSection,
}) => {
  const { hash } = useLocation();
  const monolithUser = useOptionalAuthenticatedMonolithUser();
  const { minTablet } = useBreakpoints();

  if (!activeSection.lng) return null;

  return (
    <GroupWrap
      gap={[1, 2]}
      data-testid="secondary-nav"
      alignItems={!minTablet ? 'stretch' : 'center'}
      flexDirection={!minTablet ? 'column' : 'row'}
    >
      {monolithUser?.isShipper && (
        <ExternalLink
          mode="secondary"
          href={`${PRISMA_CONFIG.angularUrl}/#/lng/offers`}
        >
          Auctions
        </ExternalLink>
      )}

      {monolithUser?.isSso && (
        <>
          <ExternalLink
            mode="secondary"
            href={`${PRISMA_CONFIG.angularUrl}/#/lng/offers`}
            isActive={
              hash.startsWith('#/lng/offers') &&
              !hash.startsWith('#/lng/offers/create')
            }
          >
            Manage Offer
          </ExternalLink>

          <ExternalLink
            mode="secondary"
            href={`${PRISMA_CONFIG.angularUrl}/#/lng/locations`}
          >
            Locations
          </ExternalLink>
        </>
      )}
    </GroupWrap>
  );
};
