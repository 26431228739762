import type { Placement } from '@floating-ui/react';
import { arrow, offset, flip, shift } from '@floating-ui/react';
import type { FC, ReactNode, RefObject } from 'react';
import {
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  useId,
} from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';
import { CardDivider } from 'src/components/dividers';
import { useDropdown } from 'src/hooks/use-dropdown';

import { useMounted } from 'src/hooks/use-mounted';
import { useParentZIndex } from 'src/hooks/use-parent-z-index';
import { flyoutStyles } from 'src/styles';

const borderRadius = 5;

const TooltipDivider = styled(CardDivider)`
  margin: 0.8rem -1.5rem;
`;

export const tooltipContainerStyle = css`
  ${flyoutStyles}
  overflow: initial;
  max-width: 24rem;
  padding: 0.8rem 1.4rem;
  z-index: 1;
  pointer-events: none;
`;

const Container = styled.span`
  ${tooltipContainerStyle}
`;

const arrowOffset = '-0.4rem';

const Arrow = styled.div`
  &,
  &::before {
    position: absolute;
    width: 0.8rem;
    height: 0.8rem;
    transform: rotate(45deg);
    background: white;
    bottom: ${arrowOffset};
  }
`;

type Parent = {
  portalId: string;
  active: boolean;
};

const TooltipContext = createContext<Parent | null>(null);

type TargetProps = { 'aria-labelledby'?: string };

type Props = {
  initialActive?: boolean;
  /**
   * The content of the tooltip. If this is value is falsy, the children will be rendered directly
   * without a tooltip.
   */
  content: ReactNode;
  children: (props: TargetProps) => ReactNode;
  /**
   * If you pass true _or_ false, you'll signal that the tooltip
   * does NOT control its active state on its own, but from the outside.
   * You likely want to use this together with the `useTooltipControl` hook.
   */
  controlActive?: boolean;
  dataTestId?: string;
  /**
   * If you pass an `positionRef`, the tooltip will be positioned relative to the `positionRef`.
   * This is completely optional. By default it will be position relative to its children.
   */
  positionRef?: RefObject<HTMLElement>;
  /**
   * Be *very* cautious with this prop. If you set it to `true`, the tooltip will not be focusable.
   * That would make it impossible to see the tooltip with the keyboard. *Only* use this if the
   * `children` can be reached with the keyboard and take care of showing the tooltip.
   */
  noTabIndex?: boolean;
};

export const Tooltip: FC<Props> = (props) => {
  const parent = useContext(TooltipContext);

  if (!props.content) return <>{props.children({})}</>;

  if (parent) {
    return <TooltipChild {...props} parent={parent} />;
  } else {
    return <TooltipParent {...props} />;
  }
};

type ChildProps = {
  content: ReactNode;
  children: (props: TargetProps) => ReactNode;
  parent: Parent;
};

const getArrowPlacement = (originalPlacement: Placement): string => {
  return {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  }[originalPlacement.split('-')[0]]!;
};

const TooltipChild: FC<ChildProps> = ({ children, content, parent }) => {
  const [portalContainer, setPortalContainer] = useState<Element | null>(null);

  useEffect(() => {
    if (parent.active) {
      setPortalContainer(document.getElementById(parent.portalId));
    } else {
      setPortalContainer(null);
    }
  }, [parent.active, parent.portalId]);

  return (
    <>
      {portalContainer &&
        createPortal(
          <div>
            <TooltipDivider />
            {content}
          </div>,
          portalContainer
        )}
      {children({})}
    </>
  );
};

const TooltipParent: FC<Props> = ({
  children,
  content,
  initialActive,
  controlActive,
  dataTestId,
  positionRef,
  noTabIndex,
}) => {
  const id = useId();
  const portalId = `portal-${id}`;
  const mounted = useMounted();

  const arrowElement = useRef<HTMLDivElement>(null);

  const { active, setActive, setWrapperElement, ...floating } = useDropdown({
    initialActive,
    placement: 'top',
    middleware: [
      shift({ padding: 10 }),
      flip({ padding: 10 }),
      offset({ mainAxis: 10 }),
      arrow({ element: arrowElement, padding: borderRadius }),
    ],
  });

  // onMouseLeave does not work, if your child is disabled
  // see https://github.com/chakra-ui/chakra-ui/pull/2272/files#diff-fbb684f6a8aac198338c32fb54583cdee24725847675fd1d9396f4767038c7bcR154
  // for the inspiration of this workaround
  useEffect(() => {
    if (!floating.refs.domReference.current) return;

    const onMouseLeave = () => {
      setActive(false);
    };
    floating.refs.domReference.current.addEventListener(
      'mouseleave',
      onMouseLeave
    );
    return () => {
      if (floating.refs.domReference.current)
        floating.refs.domReference.current.removeEventListener(
          'mouseleave',
          onMouseLeave
        );
    };
  }, [floating.refs.domReference, setActive]);

  // if we scroll _anywhere_ we'll close the tooltip (_anywhere_ -> capture set to true)
  // it looks weird if the target of a tooltip becomes invisible (e.g. it's inside a container with
  // "overflow: hidden" or it gets covered by a "position: sticky" element)
  useEffect(() => {
    const onScroll = () => {
      if (mounted.current) setActive(false);
    };
    document.addEventListener('scroll', onScroll, true);
    return () => document.removeEventListener('scroll', onScroll);
  }, [setActive, mounted]);

  const parentZIndex = useParentZIndex();

  const parent = useMemo(() => ({ active, portalId }), [active, portalId]);

  useEffect(() => {
    if (controlActive === undefined) return;
    setActive(controlActive);
  }, [setActive, controlActive]);

  const activeHandlers =
    controlActive === undefined
      ? {
          onFocus: () => setActive(true),
          onBlur: () => setActive(false),
          onMouseEnter: () => setActive(true),
          // surprise! this does not work, if the child is disabled
          // see the workaround using useEffect
          // onMouseLeave={() => setActive(false)}
          onClick: () => setActive(!active),
        }
      : {};

  useLayoutEffect(() => {
    if (!positionRef) return;
    floating.refs.setPositionReference(positionRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionRef?.current]);

  return (
    <TooltipContext.Provider value={parent}>
      <span ref={setWrapperElement} {...activeHandlers}>
        <span
          ref={floating.refs.setReference}
          tabIndex={noTabIndex ? undefined : 0}
          data-testid={dataTestId ? `${dataTestId}-tooltip` : 'tooltip'}
        >
          {children({
            'aria-labelledby': active ? id : undefined,
          })}
        </span>

        {(controlActive ?? active) &&
          // render tooltip in a portal in case it is used inside a "overflow: hidden" container,
          // so it gets not clipped
          createPortal(
            <Container
              id={id}
              role="tooltip"
              ref={floating.refs.setFloating}
              style={{
                zIndex: parentZIndex + 1,
                ...floating.style,
              }}
            >
              {content}
              <span id={portalId} />
              <Arrow
                ref={arrowElement}
                style={{
                  left: floating.middlewareData.arrow?.x ?? undefined,
                  top: floating.middlewareData.arrow?.y ?? undefined,
                  [getArrowPlacement(floating.placement)]: arrowOffset,
                }}
              />
            </Container>,
            document.body
          )}
      </span>
    </TooltipContext.Provider>
  );
};

/**
 * Use this hook if a tooltip should not control itself,
 * but when it should be controlled by another element.
 *
 * ```
 * const myTooltipControl = useTooltipControl();
 * <button {...myTooltipControl.handlers}>Click</button>
 * <Tooltip controlActive={statusTooltipControl.active}></Tooltip>
 * ```
 */
export function useTooltipControl({ initialActive = false } = {}) {
  const [active, setActive] = useState(initialActive);

  const handlers = useMemo(
    () => ({
      onFocus: () => setActive(true),
      onBlur: () => setActive(false),
      onMouseEnter: () => setActive(true),
      onMouseLeave: () => setActive(false),
    }),
    []
  );

  return { active, handlers };
}
