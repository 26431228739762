import { useEffect } from 'react';
import urlTemplate from 'url-template';
import type { AssignedShipperDetailsResponse } from 'src/apis/organisation-service/types';
import { useAxios } from 'src/hooks/use-axios';

export function useAssignedShipperDetails({
  assignedShipperDetailsUrl,
  shipperUuid,
}: {
  assignedShipperDetailsUrl: string;
  shipperUuid: string;
}) {
  const request = useAxios(
    (axios, baseConfig, url: string) =>
      axios.request<AssignedShipperDetailsResponse>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    const url = urlTemplate
      .parse(assignedShipperDetailsUrl)
      .expand({ organisationId: shipperUuid });

    request.execute(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedShipperDetailsUrl, shipperUuid]);

  return request;
}

export type AssignedShipperDetailsRequest = ReturnType<
  typeof useAssignedShipperDetails
>;
