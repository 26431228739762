import { useEffect } from 'react';
import type { LngTerminals } from 'src/apis/lng-marketing/types';
import type { LngMarketingRootRequest } from 'src/apis/lng-marketing/use-lng-marketing-root';
import { useAxios } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';

type HookOptions = {
  lngMarketingRoot: LngMarketingRootRequest;
};

type ExecuteOptions = {
  url: string;
};

export function useOwnTerminals({ lngMarketingRoot }: HookOptions) {
  const ownTerminals = useAxios(
    (axios, baseConfig, { url }: ExecuteOptions) =>
      axios.request<LngTerminals>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!lngMarketingRoot?.response) return;
    if (!lngMarketingRoot.response.data._links.getOwnTerminals)
      throw new Forbidden();

    ownTerminals.execute({
      url: lngMarketingRoot.response.data._links.getOwnTerminals.href,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lngMarketingRoot?.response]);

  // not available on stage
  if (!lngMarketingRoot) return;

  return ownTerminals;
}

export type OwnTerminalsRequest = ReturnType<typeof useOwnTerminals>;
