import { useEffect } from 'react';
import urlTemplate from 'url-template';
import type {
  FinancialSecuritiesResponse,
  RelationsParams,
} from 'src/apis/contract-management/types';
import { useAxios } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';

type HookConfig = {
  url?: string;
  params?: RelationsParams;
};

type RequestOptions = {
  url?: string;
  params?: RelationsParams;
};

export function useFinancialSecurities({ url, params }: HookConfig) {
  const financialSecurities = useAxios(
    (axios, baseConfig, { url, params }: RequestOptions) => {
      if (!url) throw new Forbidden();

      return axios.request<FinancialSecuritiesResponse>({
        ...baseConfig,
        url: urlTemplate.parse(url).expand({}),
        params,
      });
    },
    {
      neededOnPageLoad: true,
      onError(error) {
        if (error instanceof Forbidden) {
          return error;
        } else {
          throw error;
        }
      },
    }
  );

  useEffect(() => {
    financialSecurities.execute({ url, params });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, params]);

  return financialSecurities;
}

export type FinancialSecuritiesRequest = ReturnType<
  typeof useFinancialSecurities
>;
