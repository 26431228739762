import styled from 'styled-components';
import {
  Button,
  buttonBaseStyle,
} from 'src/components/buttons-and-actions/button';
import { Colors } from 'src/styles';

export const PillButton = styled(Button).attrs({
  mode: 'link',
  type: 'button',
})<{
  active?: boolean;
}>`
  ${buttonBaseStyle}
  padding: 0.5rem 1rem;
  line-height: 1;
  border-radius: 2rem;
  border: none;

  ${({ active }) =>
    !active &&
    `
    &:hover {
      background-color: ${Colors.brandLight4};
      color: ${Colors.brand};
    }
  `}

  ${({ active }) =>
    active &&
    `
    background-color: ${Colors.brandSecondary};
    color: #fff;
  `}
`;
