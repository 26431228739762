import { faFileCsv } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FC } from 'react';
import type { PortfolioCode } from 'src/apis/monolith/types';
import { Card } from 'src/components/data-display/card';
import { FormatDate } from 'src/components/data-display/format-date';
import { Table } from 'src/components/data-display/smart-table';
import { Td, Th } from 'src/components/data-display/table';
import { DownloadLimit } from 'src/components/download-limit';
import { Stack } from 'src/components/layout/stack';
import { MonolithDownload } from 'src/components/monolith-download';
import { Heading } from 'src/components/text/heading';

export const PortfolioCodes: FC<{
  portfolioCodes: Readonly<PortfolioCode[]>;
  operatorOldId: number;
}> = ({ portfolioCodes, operatorOldId }) => {
  return (
    <Card>
      <Stack gap={2} alignItems="center">
        <Stack flow="column" justifyContent="space-between" gap={2}>
          <Heading mode="card">Portfolio Codes</Heading>

          <DownloadLimit totalItems={portfolioCodes.length}>
            <MonolithDownload
              mode="secondary"
              size="small"
              href={`${PRISMA_CONFIG.monolithApiUrl}/shipperUsers/assignments/${operatorOldId}/csv/portfolioCodes`}
            >
              <FontAwesomeIcon icon={faFileCsv} aria-hidden /> CSV
            </MonolithDownload>
          </DownloadLimit>
        </Stack>

        <Table
          data={portfolioCodes}
          setId={(item) => item.refId}
          cols={[
            {
              width: 1,
              key: 'code',
              head: <Th>Code</Th>,
              body: (code) => <Td>{code.name}</Td>,
            },
            {
              width: 1,
              key: 'validity',
              head: <Th>Validity</Th>,
              body: (code) => (
                <Td>
                  <FormatDate value={code.validity.start} type="date-time" />{' '}
                  {' - '}{' '}
                  <FormatDate value={code.validity.end} type="date-time" />
                </Td>
              ),
            },
          ]}
        />
      </Stack>
    </Card>
  );
};
