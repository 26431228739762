import type { DectOfferDocumentResponse } from 'src/apis/contract-management/types';
import { isServerError, useAxios } from 'src/hooks/use-axios';

type RequestOptions = {
  dectOfferDocumentUrl: string;
};

export function useDectOfferDocument() {
  const dectOfferDocument = useAxios(
    (axios, baseConfig, { dectOfferDocumentUrl }: RequestOptions) =>
      axios.request<DectOfferDocumentResponse>({
        ...baseConfig,
        url: dectOfferDocumentUrl,
      }),
    {
      neededOnPageLoad: false,
      onError(error) {
        // assume the dect offer is in the SUBMITTED state and the shipper
        // is able to see the download link, but *then* the corresponding trade
        // gets rejected by the TSO. now the document can't be downloaded by
        // the shipper any longer. the server would return 403.
        if (isServerError(error, 403)) {
          return error;
        } else {
          throw error;
        }
      },
    }
  );

  return dectOfferDocument;
}

export type DectOfferDocumentRequest = ReturnType<typeof useDectOfferDocument>;
