import { useEffect } from 'react';
import urlTemplate from 'url-template';
import type { Links } from 'src/apis/api-utilities';
import type {
  FinancialSecurity,
  RelationsParams,
} from 'src/apis/contract-management/types';
import { useAxios } from 'src/hooks/use-axios';

type HookConfig = {
  url?: string;
  params: RelationsParams;
};

type RequestOptions = {
  url: string;
  params: RelationsParams;
};

type ContractFinancialSecurityDetails = {
  _links: Links<'self' | 'first'>;
  limit: number;
  offset: number;
  total: number;
  _embedded: {
    items: FinancialSecurity[];
  };
};

export function useContractFinancialSecurities({ url, params }: HookConfig) {
  const financialSecurities = useAxios(
    (axios, baseConfig, { url, params }: RequestOptions) =>
      axios.request<ContractFinancialSecurityDetails>({
        ...baseConfig,
        url: urlTemplate.parse(url).expand({}),
        params,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!url) return;
    financialSecurities.execute({ url, params });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, params]);

  if (!url) return;

  return financialSecurities;
}

export type ContractFinancialSecuritiesRequest = ReturnType<
  typeof useContractFinancialSecurities
>;
