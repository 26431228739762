import escapedString from 'escape-string-regexp';
import type { FC } from 'react';
import { useEffect, useMemo } from 'react';
import { z } from 'zod';
import type { ContractsFilterForShipper } from 'src/apis/capacity-management/types';
import { SearchableMultiSelect } from 'src/components/form/select/searchable-multi-select';
import { formOptionSchema } from 'src/components/form/zod-schemas';
import { useAxios } from 'src/hooks/use-axios';
import { useSearch } from 'src/hooks/use-search';

type Props = {
  label: string;
  url: string;
  placeholder: string;
  name?: string;
};

export const contractOptionSchema = formOptionSchema.merge(
  z.object({ tsoShortName: z.string() })
);

type ContractOption = z.input<typeof contractOptionSchema>;

export const ContractsSearchableMultiSelect: FC<Props> = ({
  label,
  url,
  placeholder,
  name = 'contractId',
}) => {
  const [searchValue, setSearchValue, searchQuery] = useSearch('', {
    minLength: 1,
  });

  const searchRequest = useAxios(
    (axios, baseConfig) =>
      axios.request<ContractsFilterForShipper>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: false }
  );

  // on the very first search input we try to fetch the initial list
  useEffect(() => {
    if (!searchQuery || searchRequest.response) return;
    searchRequest.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, searchRequest.response]);

  const searchResult = useMemo(() => {
    if (!searchQuery || !searchRequest.response) return;

    const escapedSearchQuery = new RegExp(
      escapedString(searchQuery.toLowerCase())
    );

    return searchRequest.response.data._embedded.items
      .filter((contract) =>
        contract.assignedId.toLowerCase().match(escapedSearchQuery)
      )
      .map((contract) => ({
        value: contract.id,
        label: contract.assignedId,
        tsoShortName: contract.tsoShortName,
      })) satisfies ContractOption[];
  }, [searchQuery, searchRequest.response]);

  return (
    <SearchableMultiSelect
      label={label}
      name={name}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      searchValueForCurrentOptions={searchValue}
      pending={searchRequest.pending}
      options={searchResult}
      stacked
      placeholder={placeholder}
      renderOptionHint={(option) => option.tsoShortName}
      renderSelectedOptionTooltip={(option) => (
        <>
          <strong>TSO:</strong> {option.tsoShortName}
        </>
      )}
    />
  );
};
