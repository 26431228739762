import type { FC } from 'react';
import { lazy } from 'react';
import { useParams } from 'react-router-dom';
import urltemplate from 'url-template';
import { useContractManagement } from 'src/apis/contract-management/use-contract-management';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { PageSpinner } from 'src/components/spinner-container';
import { useLect } from 'src/pages/transport/secondary-tradings/trade/use-lect';

const Page = lazy(() => import('./page'));

const EditCreditLimitLect: FC<{
  lectId: string;
  getLect: string;
}> = ({ getLect, lectId }) => {
  const lect = useLect({
    url: urltemplate.parse(getLect).expand({ lectId }),
  });
  if (!lect.response) {
    return <PageSpinner />;
  }
  if (
    !lect.response.data._links.getCreditGraph ||
    !lect.response.data._links.setRequiredCredit
  ) {
    return <p>Not supported!</p>;
  }
  return (
    <Page
      creditGraphUrl={lect.response.data._links.getCreditGraph.href}
      setRequiredCreditUrl={lect.response.data._links.setRequiredCredit.href}
      contractType="LECT"
      contractAssignedId={lect.response.data.assignedId}
      contractId={lectId}
      shipperUuid={lect.response.data.shipperId}
    />
  );
};

export const EditCreditLimit: FC = () => {
  const { contractId } = useParams<{ contractId?: string }>();
  const contractManagementRoot = useContractManagement();

  if (!contractManagementRoot.response) return <PageSpinner />;

  if (!contractManagementRoot.response.data._links.getLect || !contractId) {
    return <ThrowNotFound />;
  }

  return (
    <EditCreditLimitLect
      lectId={contractId}
      getLect={contractManagementRoot.response.data._links.getLect.href}
    />
  );
};
