import { useEffect } from 'react';
import urlTemplate from 'url-template';
import type {
  CmNetworkPointResponse,
  CapacityManagementRootResponse,
} from 'src/apis/capacity-management/types';
import type { CapacityManagementRootRequest } from 'src/apis/capacity-management/use-capacity-management-root';
import type { NetworkPoint } from 'src/apis/monolith/types';
import type { NetworkPointRequest } from 'src/apis/monolith/use-network-point';
import { useAxios } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';

type HookConfig = {
  capacityManagementRoot: CapacityManagementRootRequest;
  networkPoint: NetworkPointRequest;
};

type RequestOptions = {
  rootData: CapacityManagementRootResponse;
  networkPointData: NetworkPoint;
};

export function useCmNetworkPoint({
  capacityManagementRoot,
  networkPoint,
}: HookConfig) {
  const cmNetworkPoint = useAxios(
    (axios, baseConfig, { rootData, networkPointData }: RequestOptions) => {
      if (!rootData._links?.networkpoint) throw new Forbidden();

      const url = urlTemplate
        .parse(rootData._links.networkpoint.href)
        .expand({ nwpId: networkPointData.networkPointUuid });

      return axios.request<CmNetworkPointResponse>({
        ...baseConfig,
        url,
      });
    },
    { neededOnPageLoad: true }
  );

  useEffect(
    () => {
      if (!networkPoint.response) return;
      if (!capacityManagementRoot.response) return;

      cmNetworkPoint.execute({
        rootData: capacityManagementRoot.response.data,
        networkPointData: networkPoint.response.data,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [capacityManagementRoot.response, networkPoint.response]
  );

  return cmNetworkPoint;
}

export type CmNetworkPointRequest = ReturnType<typeof useCmNetworkPoint>;
