import { useEffect } from 'react';
import type { AllocationProposalResponse } from 'src/apis/lng-marketing/types';
import type { LngOfferDetailsRequest } from 'src/apis/lng-marketing/use-lng-offer-details';
import { useAxios } from 'src/hooks/use-axios';

type HookOptions = {
  lngOfferDetails?: LngOfferDetailsRequest;
};

type ExecuteOptions = {
  url: string;
};

export function useOptionalLngAllocationProposal({
  lngOfferDetails,
}: HookOptions) {
  const allocationProposal = useAxios(
    (axios, baseConfig, { url }: ExecuteOptions) => {
      return axios.request<AllocationProposalResponse>({
        ...baseConfig,
        url,
      });
    },
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!lngOfferDetails?.response) return;
    if (!lngOfferDetails.response.data._links.getAllocationProposal) return;
    allocationProposal.execute({
      url: lngOfferDetails.response.data._links.getAllocationProposal.href,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lngOfferDetails?.response]);

  // not available on stage
  if (!lngOfferDetails) return;

  // no allocation proposal (or not allowed to get it)
  if (
    lngOfferDetails.response &&
    !lngOfferDetails.response.data._links.getAllocationProposal
  )
    return;

  return allocationProposal;
}

export type LngAllocationProposalRequest = ReturnType<
  typeof useOptionalLngAllocationProposal
>;
