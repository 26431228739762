import { useDatepicker } from '@datepicker-react/hooks';
import type { FC } from 'react';
import styled from 'styled-components';
import { Month } from 'src/components/form/datepicker/month';
import {
  TimeSelector,
  useTime,
} from 'src/components/form/datepicker/time-selector';
import type { Hour, InputTime } from 'src/components/form/datepicker/utils';
import {
  attachTime,
  checkBlockedDate,
} from 'src/components/form/datepicker/utils';
import { useNow } from 'src/hooks/use-now';

const CalendarContainer = styled.div`
  width: 28rem;
`;

type CalendarProps = {
  time?: InputTime;
  defaultHour?: Hour;
  onSelect: (value: Date | null) => void;
  value: Date | null;
  minBookingDate?: Date;
  maxBookingDate?: Date;
};

export const Calendar: FC<CalendarProps> = (props) => {
  const {
    time = false,
    defaultHour,
    value,
    onSelect,
    minBookingDate,
    maxBookingDate,
  } = props;

  const now = new Date(useNow());
  const { hours, minutes, setHours, setMinutes } = useTime(value);

  const {
    activeMonths,
    goToDate,
    goToPreviousMonthsByOneMonth,
    goToNextMonthsByOneMonth,
    isDateBlocked,
  } = useDatepicker({
    numberOfMonths: 1,
    startDate: value ?? now,
    endDate: null,
    focusedInput: null,
    onDatesChange: () => {},
    isDateBlocked(midnightInBerlin) {
      // date was already normalized before its passed to `useDay`,
      // so we know it's midnightInBerlin (but as UTC, so either 22:00 or 23:00).
      return checkBlockedDate({
        midnightInBerlin,
        time,
        minBookingDate,
        maxBookingDate,
      });
    },
    // note: to avoid timezone specific errors, we need to handle minBookingDate/maxBookingDate
    // in our own dateBlockChecker
    // minBookingDate,
    // maxBookingDate,
  });

  return (
    <CalendarContainer>
      {activeMonths.map((month) => (
        <Month
          key={`${month.year}-${month.month}`}
          year={month.year}
          month={month.month}
          date={month.date}
          startDate={value}
          endDate={value}
          hoverDate={null}
          onChange={(date) => goToDate(date)}
          onNextMonthClick={goToNextMonthsByOneMonth}
          onPrevMonthClick={goToPreviousMonthsByOneMonth}
          onDayHover={() => {}}
          onDateSelect={(date) =>
            onSelect(
              time
                ? attachTime({
                    originalDate: date,
                    hours,
                    minutes,
                    time,
                    defaultHour,
                    roundToNextMinute: true,
                  })
                : date
            )
          }
          isDateBlocked={isDateBlocked}
        />
      ))}

      {time && (
        <TimeSelector
          value={value}
          hours={hours}
          minutes={minutes}
          time={time}
          onHoursChange={(newHours) => {
            if (value)
              onSelect(
                attachTime({
                  originalDate: value,
                  hours: newHours,
                  minutes,
                  time,
                  defaultHour,
                  roundToNextMinute: false,
                })
              );
            else setHours(newHours);
          }}
          onMinutesChange={(newMinutes) => {
            if (value)
              onSelect(
                attachTime({
                  originalDate: value,
                  hours,
                  minutes: newMinutes,
                  time,
                  defaultHour,
                  roundToNextMinute: false,
                })
              );
            else setMinutes(newMinutes);
          }}
        />
      )}
    </CalendarContainer>
  );
};
