import { useEffect } from 'react';
import type { PriceStepsResponse } from 'src/apis/capacity-management/types';
import type { CmNetworkPointRequest } from 'src/apis/capacity-management/use-cm-network-point';
import { useAxios } from 'src/hooks/use-axios';
import type { NoContent } from 'src/utils/no-content';

type HookConfig = {
  cmNetworkPoint: CmNetworkPointRequest;
};

type RequestOptions = {
  url: string;
};

export function usePriceSteps({ cmNetworkPoint }: HookConfig) {
  const priceSteps = useAxios(
    (axios, baseConfig, { url }: RequestOptions) =>
      // NoContent, if platform defaults are used
      axios.request<PriceStepsResponse | NoContent>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!cmNetworkPoint.response) return;

    const url = cmNetworkPoint.response.data._links.getCustomPriceSteps.href;
    const options = { url };

    priceSteps.execute(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmNetworkPoint.response]);

  return priceSteps;
}

export type PriceStepsRequest = ReturnType<typeof usePriceSteps>;
