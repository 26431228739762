import type { FC } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useGetBookingProcessId } from 'src/apis/regular-fcfs/use-booking-process-details';
import { PageSpinner } from 'src/components/spinner-container';

export const FcfsBookingDetailsByBookingId: FC = () => {
  const { bookingId } = useParams<{
    bookingId: number;
  }>();

  const findBookingProcessId = useGetBookingProcessId(bookingId);

  if (!findBookingProcessId.response) return <PageSpinner />;

  return (
    <Navigate
      replace
      to={`/reporting/my-transactions/regular-fcfs/details/${findBookingProcessId.response.data}?bookingId=${bookingId}`}
    />
  );
};
