import type { FC } from 'react';
import { lazy, useEffect } from 'react';
import { useOrganisationDetails } from 'src/apis/organisation-service/use-organisation-details';
import type { OrganisationServiceRequest } from 'src/apis/organisation-service/use-organisation-service';
import { useTsoOrSsoDetails } from 'src/apis/organisation-service/use-tso-or-sso-details';
import { PageSpinner } from 'src/components/spinner-container';
import type { Successful } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(
  () =>
    import(
      'src/pages/settings/organisation/company-information/edit-tso-or-sso-information/page'
    )
);
type Props = {
  organisationService: Successful<OrganisationServiceRequest>;
  title: string;
};

export const EditTsoInformation: FC<Props> = ({
  organisationService,
  title,
}) => {
  if (!organisationService.response.data._links.getMyOrganisationDetails)
    throw new Forbidden();

  const organisationDetails = useOrganisationDetails({
    url: organisationService.response.data._links.getMyOrganisationDetails.href,
  });

  useEffect(() => {
    if (
      organisationDetails.response &&
      !organisationDetails.response.data._links.tsoDetails
    )
      throw new Forbidden();
  }, [organisationDetails]);

  const operatorData = useTsoOrSsoDetails({ organisationDetails });

  if (!(organisationDetails.response && operatorData.response))
    return <PageSpinner />;

  return <Page operatorData={operatorData} title={title} />;
};

export const EditSsoInformation: FC<Props> = ({
  organisationService,
  title,
}) => {
  if (!organisationService.response.data._links.getMyOrganisationDetails)
    throw new Forbidden();

  const organisationDetails = useOrganisationDetails({
    url: organisationService.response.data._links.getMyOrganisationDetails.href,
  });

  useEffect(() => {
    if (
      organisationDetails.response &&
      !organisationDetails.response.data._links.ssoDetails
    )
      throw new Forbidden();
  }, [organisationDetails]);

  const operatorData = useTsoOrSsoDetails({ organisationDetails });

  if (!(organisationDetails.response && operatorData.response))
    return <PageSpinner />;

  return <Page operatorData={operatorData} title={title} />;
};
