import type { FC } from 'react';
import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { RootRoutes } from 'src/components/root-routes';
import { useTitle } from 'src/hooks/use-title';

const FcfsSettings = lazy(() => import('./fcfs-settings'));
const FcfsGeneralSettingsForm = lazy(
  () => import('./fcfs-general-settings-form')
);
const UpgrageCapacitySettingsForm = lazy(
  () => import('./upgrade-capacity-settings-form')
);
const BgOrPcSettingsForm = lazy(() => import('./bg-or-pc-settings-form'));
const LeadTimeSettingsForm = lazy(() => import('./lead-time-settings-form'));

export const RegularFcfsSettings: FC = () => {
  useTitle('Settings - Regular FCFS');

  // use response information to build read only view
  // do we want a disabled form or a separate page for editing?
  return (
    <RootRoutes>
      <Route path="/settings/regular-fcfs" element={<FcfsSettings />} />
      <Route
        path="/settings/regular-fcfs/edit-general"
        element={<FcfsGeneralSettingsForm />}
      />
      <Route
        path="/settings/regular-fcfs/edit-upgrade-capacity"
        element={<UpgrageCapacitySettingsForm />}
      />
      <Route
        path="/settings/regular-fcfs/edit-balancing-group-or-portfolio-code"
        element={<BgOrPcSettingsForm />}
      />
      <Route
        path="/settings/regular-fcfs/edit-lead-time"
        element={<LeadTimeSettingsForm />}
      />
      <Route path="*" element={<ThrowNotFound />} />
    </RootRoutes>
  );
};
