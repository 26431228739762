import type { FC } from 'react';
import { FilterCard } from 'src/components/data-display/expandable-filters';
import { Input } from 'src/components/form/input';
import { MultiSelect } from 'src/components/form/select/multi-select';
import type { AssignmentsAndContactsParams } from 'src/pages/operators/assignments/details/use-page-params';
import { shipperAssignmentStatusOptions } from 'src/pages/operators/assignments/overview/utils';
import { userAssignmentStatusLabels } from 'src/pages/shippers/organisations-and-users/schema';

type ParamsProps = {
  pageParams: AssignmentsAndContactsParams;
};

export const UserAssignmentsByOperatorFilterCard: FC<ParamsProps> = ({
  pageParams: { userAssignmentsByOperator: pageParams },
}) => {
  return (
    <FilterCard
      pageParams={pageParams}
      constraintViolation={null}
      filters={{
        userName: {
          type: 'value',
          field: (
            <Input
              name="userName"
              label={pageParams.filter.userName.label}
              placeholder="E.g. Alex Doe"
            />
          ),
        },
        userEmail: {
          type: 'value',
          field: (
            <Input
              name="userEmail"
              label={pageParams.filter.userEmail.label}
              placeholder="E.g. user@example.com"
            />
          ),
        },
        status: {
          type: 'list',
          mappedValue: pageParams.value.status.map(
            (assignment) => userAssignmentStatusLabels[assignment]
          ),
          field: (
            <MultiSelect
              name="status"
              label={pageParams.filter.status.label}
              options={shipperAssignmentStatusOptions}
              placeholder="E.g. Approved"
            />
          ),
        },
      }}
    />
  );
};
