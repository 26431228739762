import type { FC } from 'react';
import { lazy } from 'react';
import { useParams } from 'react-router-dom';
import { useMemoOne } from 'use-memo-one';
import type { InvoicesParamsForTso } from 'src/apis/capacity-management/types';
import { useCapacityManagementRoot } from 'src/apis/capacity-management/use-capacity-management-root';
import { useInvoices } from 'src/apis/capacity-management/use-invoices';
import { Card } from 'src/components/data-display/card';
import { Alert } from 'src/components/feedback/alert';
import { PageSpinner } from 'src/components/spinner-container';
import type { Successful } from 'src/hooks/use-axios';
import { useAffiliateInvoices } from 'src/pages/shippers/invoices/detail/use-affiliate-invoices';
import type { TsoInvoiceRequest } from 'src/pages/shippers/invoices/detail/use-invoice-for-tso';
import { useInvoiceForTso } from 'src/pages/shippers/invoices/detail/use-invoice-for-tso';

const Page = lazy(() => import('./page'));

export const TsoInvoiceDetail: FC = () => {
  const capacityManagementRoot = useCapacityManagementRoot();
  const params = useMemoOne<InvoicesParamsForTso>(() => ({ limit: 1 }), []);
  const invoices = useInvoices({ capacityManagementRoot, params });

  const { invoiceId } = useParams<{ invoiceId: string }>();
  const invoice = useInvoiceForTso({ invoices, invoiceId });

  if (invoices.error)
    return (
      <Card>
        <Alert type="warning">
          The Invoices feature can only be used if you are using the PRISMA
          Invoicing Service.
        </Alert>
      </Card>
    );

  if (!invoice.response) return <PageSpinner />;

  if (invoice.response.data._links?.affiliateInvoices) {
    return (
      <LoadAffiliatedInvoices
        invoice={invoice}
        affiliateInvoicesUrl={
          invoice.response.data._links.affiliateInvoices.href
        }
      />
    );
  } else {
    return <Page invoice={invoice} />;
  }
};

type LoadAffiliatedInvoicesProps = {
  affiliateInvoicesUrl: string;
  invoice: Successful<TsoInvoiceRequest>;
};

const LoadAffiliatedInvoices: FC<LoadAffiliatedInvoicesProps> = ({
  invoice,
  affiliateInvoicesUrl,
}) => {
  const affiliateInvoices = useAffiliateInvoices({
    url: affiliateInvoicesUrl,
  });

  if (!affiliateInvoices.response) return <PageSpinner />;

  return <Page invoice={invoice} affiliateInvoices={affiliateInvoices} />;
};
