import type { FC } from 'react';
import { Suspense } from 'react';
import { Navigate, Route, useLocation } from 'react-router-dom';
import { useRegularFcfsRoot } from 'src/apis/regular-fcfs/use-regular-fcfs-root';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { PageHeader } from 'src/components/layout/page-header';
import { Spacer } from 'src/components/layout/spacer';
import { Stack } from 'src/components/layout/stack';
import { Link } from 'src/components/navigation/link';
import { RootRoutes } from 'src/components/root-routes';
import { PageSpinner } from 'src/components/spinner-container';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { AvailabilityCheck } from 'src/pages/transport/fcfs-booking/availability-check';
import DirectFcfs from 'src/pages/transport/fcfs-booking/direct';
import { RegularFcfs } from 'src/pages/transport/fcfs-booking/regular';

export const FcfsBooking: FC = () => {
  const { pathname } = useLocation();

  const optionalMonolithUser = useOptionalAuthenticatedMonolithUser();
  const regularFcfsRoot = useRegularFcfsRoot();

  if (!regularFcfsRoot.loaded) return <PageSpinner />;

  const showTsoView = regularFcfsRoot.response
    ? optionalMonolithUser?.isTso &&
      regularFcfsRoot.response.data._links?.['tso-availability-check']
    : optionalMonolithUser?.isTso;

  // specific view for TSOs
  if (showTsoView) {
    return <AvailabilityCheck />;
  }

  return (
    <Stack gap={1}>
      <PageHeader title="New Booking" />

      <Stack flow="column" justifyContent="space-between">
        <Stack flow="column" gap={2.5} inline>
          <Link
            to="/transport/fcfs-booking"
            isActive={pathname.startsWith('/transport/fcfs-booking/regular')}
            mode="pill"
          >
            Regular FCFS
          </Link>

          <Link to="/transport/fcfs-booking/direct" mode="pill">
            Direct FCFS
          </Link>
        </Stack>
      </Stack>

      <Spacer />

      <Suspense fallback={<PageSpinner />}>
        <RootRoutes>
          <Route
            path="/transport/fcfs-booking"
            element={<Navigate replace to="/transport/fcfs-booking/regular" />}
          />

          <Route
            path="/transport/fcfs-booking/regular/*"
            element={<RegularFcfs />}
          />

          <Route
            path="/transport/fcfs-booking/direct/*"
            element={<DirectFcfs />}
          />

          <Route path="*" element={<ThrowNotFound />} />
        </RootRoutes>
      </Suspense>
    </Stack>
  );
};
