import { useEffect } from 'react';
import type { ProposalTransactionType } from 'src/apis/monolith/types';
import { isServerError, useAxios } from 'src/hooks/use-axios';
import { useToast } from 'src/hooks/use-toasts';

type TradeValidationPeriodUnit = 'DAY' | 'HOUR';

type TradeValidationPeriod = {
  value: number;
  type: TradeValidationPeriodUnit;
};

export type TsoSecondarySettings = {
  tradeValidationPeriodConfigAssignment: TradeValidationPeriod;
  tradeValidationPeriodConfigTransferOfUse: TradeValidationPeriod;
};

type Options = {
  tsoId?: number;
};

export function useTsoSecondarySettings({ tsoId }: Options) {
  const toast = useToast();

  const tsoSecondarySettings = useAxios(
    (axios, baseConfig, tsoId: number) =>
      axios.request<TsoSecondarySettings>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/tso/${tsoId}/secondary-settings`,
      }),
    {
      neededOnPageLoad: false,
      onError(error) {
        if (isServerError(error, null) && !isServerError(error, 401)) {
          // TODO: remove this warning if the API can accept shipper calls
          toast({
            type: 'warning',
            children: `error reading TSO's (${tsoId}) secondary settings: ${
              (error.response.data as any).message
            } (${(error.response.data as any).errorCode})`,
          });
          return error;
        }
        throw error;
      },
    }
  );

  useEffect(() => {
    if (tsoId == null) return;
    tsoSecondarySettings.execute(tsoId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tsoId]);

  if (!tsoId) return;

  return tsoSecondarySettings;
}

export type TsoSecondarySettingsRequest = ReturnType<
  typeof useTsoSecondarySettings
>;

export function findTradeValidationPeriod({
  transactionType,
  tsoSecondarySettings,
}: {
  transactionType: ProposalTransactionType;
  tsoSecondarySettings?: TsoSecondarySettings;
}) {
  if (!tsoSecondarySettings) return;
  return transactionType === 'ASSIGNMENT'
    ? tsoSecondarySettings.tradeValidationPeriodConfigAssignment
    : tsoSecondarySettings.tradeValidationPeriodConfigTransferOfUse;
}
