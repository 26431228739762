import type { AxiosResponse } from 'axios';
import { useEffect, useMemo } from 'react';
import type {
  OfferBids,
  OfferFixedPriceBids,
  Round,
} from 'src/apis/lng-marketing/types';
import type { LngOfferRoundsRequest } from 'src/apis/lng-marketing/use-lng-offer-rounds';
import { useAxios } from 'src/hooks/use-axios';

type HookOptions = {
  lngOfferRounds?: LngOfferRoundsRequest;
};

type ExecuteOptions = {
  rounds: Round[];
};

export type RoundPriceAndAmountBidsPair = {
  bids: AxiosResponse<OfferBids, any>;
  round: Round;
  case: 'price-and-amount';
};

export type RoundFixedPriceBidsPair = {
  bids: AxiosResponse<OfferFixedPriceBids, any>;
  round: Round;
  case: 'fixed-price';
};

export type RoundBidsPair =
  | RoundPriceAndAmountBidsPair
  | RoundFixedPriceBidsPair;

export function useOptionalLngOfferRoundBidsPairs({
  lngOfferRounds,
}: HookOptions) {
  const bidsPerRound = useAxios(
    async (axios, baseConfig, { rounds }: ExecuteOptions) => {
      const pairs = await Promise.all(
        rounds.map((round) =>
          round._links?.getBidsWithPriceAndAmounts
            ? axios
                .request<OfferBids>({
                  ...baseConfig,
                  url: round._links.getBidsWithPriceAndAmounts.href,
                })
                .then(
                  (bids) =>
                    ({
                      round,
                      bids,
                      case: 'price-and-amount',
                    }) as RoundPriceAndAmountBidsPair
                )
            : axios
                .request<OfferFixedPriceBids>({
                  ...baseConfig,
                  url: round._links!.getFixedPriceBids!.href,
                })
                .then(
                  (bids) =>
                    ({
                      round,
                      bids,
                      case: 'fixed-price',
                    }) as RoundFixedPriceBidsPair
                )
        )
      );

      const currentRound = pairs.find((pair) => pair.round.current);
      const pastRounds = pairs.filter((pair) => !pair.round.current);

      return { currentRound, pastRounds };
    },
    { neededOnPageLoad: true }
  );

  const roundsWithBids = useMemo(
    () =>
      lngOfferRounds?.response?.data._embedded.items.filter(
        (round) =>
          round._links?.getBidsWithPriceAndAmounts ||
          round._links?.getFixedPriceBids
      ) || [],
    [lngOfferRounds?.response]
  );

  useEffect(() => {
    if (!roundsWithBids.length) return;
    bidsPerRound.execute({ rounds: roundsWithBids });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roundsWithBids]);

  // not available
  if (!roundsWithBids.length) return;

  return bidsPerRound;
}

export type LngOfferRoundBidsPairsRequest = ReturnType<
  typeof useOptionalLngOfferRoundBidsPairs
>;
