import type { FC } from 'react';
import { Navigate, Route, useLocation } from 'react-router-dom';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { RoleGateway } from 'src/components/role-gateway';
import { RootRoutes } from 'src/components/root-routes';
import { ReportingUploadsDraftedDetail } from 'src/pages/reporting/products/drafted/detail';
import { ReportingUploadsDraftedOverview } from 'src/pages/reporting/products/drafted/overview';
import { ReportingUploadsUploadedDetail } from 'src/pages/reporting/products/uploaded/detail';
import { ReportingUploadsUploadedOverview } from 'src/pages/reporting/products/uploaded/overview';
import { CapacityManagement } from 'src/pages/transport/network-points/details/availability-and-price-data/overview/capacity-management';

export const ReportingUploads: FC = () => {
  const { search } = useLocation();
  return (
    <CapacityManagement>
      {(root) => (
        <RootRoutes>
          <Route
            path="/reporting/products/uploaded"
            element={
              <>
                {/*
                  RoleGateway is needed, because "/rest/uploads/report"
                  doesn't handle 401 and 403, but returns 500
                */}
                <RoleGateway allowed={['TSO']}>
                  <ReportingUploadsUploadedOverview
                    capacityManagementRoot={root}
                  />
                </RoleGateway>
              </>
            }
          />

          <Route
            path="/reporting/products"
            element={
              <Navigate
                replace
                to={{ pathname: '/reporting/products/uploaded', search }}
              />
            }
          />

          <Route
            path="/reporting/products/uploaded/:rawDataId"
            element={
              <ReportingUploadsUploadedDetail capacityManagementRoot={root} />
            }
          />

          {root && (
            <Route
              path="/reporting/products/drafted"
              element={
                <ReportingUploadsDraftedOverview
                  capacityManagementRoot={root}
                />
              }
            />
          )}

          {root && (
            <Route
              path="/reporting/products/drafted/:draftedProductId"
              element={
                <ReportingUploadsDraftedDetail capacityManagementRoot={root} />
              }
            />
          )}

          <Route path="*" element={<ThrowNotFound />} />
        </RootRoutes>
      )}
    </CapacityManagement>
  );
};
