import type { FC } from 'react';

import type { StorageLocation } from 'src/apis/monolith/types';
import type { StorageLocationsRequest } from 'src/apis/monolith/use-storage-locations';
import { TableActions } from 'src/components/buttons-and-actions/table-actions';
import { Table } from 'src/components/data-display/smart-table';
import { SpecificSortHeader, Td, Th } from 'src/components/data-display/table';
import { PageHeader } from 'src/components/layout/page-header';
import { Stack } from 'src/components/layout/stack';
import { ExternalLink } from 'src/components/navigation/link';
import type { Successful } from 'src/hooks/use-axios';
import { useAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { useTitle } from 'src/hooks/use-title';
import { LocationsFilterCard } from 'src/pages/storage/locations/filter-card';
import { QuickFiltersBar } from 'src/pages/storage/locations/quick-filters-bar';
import type { PageParams } from 'src/pages/storage/locations/use-page-params';

export const Page: FC<{
  storageLocations: Successful<StorageLocationsRequest>;
  pageParams: PageParams;
  filteredStorageLocations: { totalSize: number; items: StorageLocation[] };
}> = ({ storageLocations, pageParams, filteredStorageLocations }) => {
  useTitle('Storage Locations');
  const monolithUser = useAuthenticatedMonolithUser();

  return (
    <Stack flow="row" gap={2}>
      <PageHeader
        title="Storage Locations"
        actions={
          <ExternalLink
            mode="button-primary"
            href={`${PRISMA_CONFIG.angularUrl}/#/storage/locations/create`}
            data-testid="add-storage-location-button"
          >
            Add Storage Location
          </ExternalLink>
        }
        data-testid="header-storage-locations"
      />

      <LocationsFilterCard pageParams={pageParams} />

      <QuickFiltersBar pageParams={pageParams} />

      <Table
        data-testid="networkPoints"
        pending={storageLocations.pending}
        paginated={{
          perPage: pageParams.value.pageSize,
          start: pageParams.value.start,
          totalSize: filteredStorageLocations.totalSize,
        }}
        data={filteredStorageLocations.items}
        setId={(item) => item.refId}
        cols={[
          {
            key: 'name',
            head: (
              <SpecificSortHeader
                label={pageParams.filter.name.label}
                params={pageParams}
                column="name"
                sortParam="sortColumn"
                directionParam="sortDirection"
              />
            ),
            body: (data) => (
              <Td data-testid={`location-name-${data.name}`}>{data.name}</Td>
            ),
          },
          {
            key: 'ssoShortName',
            head: (
              <SpecificSortHeader
                label={pageParams.filter.ssoShortName.label}
                params={pageParams}
                column="ssoShortName"
                sortParam="sortColumn"
                directionParam="sortDirection"
              />
            ),
            body: (data) => (
              <Td data-testid={`ssoShortName-${data.ssoName}`}>
                {data.ssoName}
              </Td>
            ),
          },
          {
            key: 'marketArea',
            head: <Th>Market Area</Th>,
            body: (data) => (
              <Td data-testid={`market-area-${data.ssoName}`}>
                {data.marketAreaList.join(', ')}
              </Td>
            ),
          },
          {
            key: 'refId',
            head: (
              <SpecificSortHeader
                label={pageParams.filter.refId.label}
                params={pageParams}
                column="refId"
                sortParam="sortColumn"
                directionParam="sortDirection"
              />
            ),
            body: (data) => (
              <Td data-testid={`location-id-${data.refId}`}>{data.refId}</Td>
            ),
          },
          {
            key: 'actions',
            width: '10rem',
            head: <Th align="center">Actions</Th>,
            body: (data) => (
              <Td data-testid={`actions-${data.refId}`} align="center">
                <TableActions>
                  <ExternalLink
                    href={`${PRISMA_CONFIG.angularUrl}/#/storage/locations/details/${data.refId}`}
                  >
                    Details
                  </ExternalLink>
                  {monolithUser.tsoName === data.ssoName && (
                    <ExternalLink
                      href={`${PRISMA_CONFIG.angularUrl}/#/storage/locations/edit/${data.refId}`}
                    >
                      Edit Storage Location
                    </ExternalLink>
                  )}
                </TableActions>
              </Td>
            ),
          },
        ]}
        empty={{
          label: 'No results match your search criteria.',
        }}
      />
    </Stack>
  );
};
