import type { FC } from 'react';
import { lazy } from 'react';
import { useGmdFlags } from 'src/hooks/use-gdm-flags';

const Page = lazy(() => import('./page'));

export const Gdm: FC = () => {
  const gdmFlags = useGmdFlags();
  if (!gdmFlags.userCanAcces) throw gdmFlags.error;
  return <Page />;
};
