import type { FC } from 'react';
import { lazy } from 'react';
import type { OrganisationDetailsResponse } from 'src/apis/organisation-service/types';
import { useLsoDetails } from 'src/apis/organisation-service/use-lso-details';
import { useOrganisationDetails } from 'src/apis/organisation-service/use-organisation-details';
import type { OrganisationServiceRequest } from 'src/apis/organisation-service/use-organisation-service';
import { useShipperDetails } from 'src/apis/organisation-service/use-shipper-details';
import { useTsoOrSsoDetails } from 'src/apis/organisation-service/use-tso-or-sso-details';
import { PageSpinner } from 'src/components/spinner-container';
import type { Successful } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(
  () => import('src/pages/settings/organisation/company-information/page')
);

type Props = {
  organisationService: Successful<OrganisationServiceRequest>;
};

export const CompanyInformationOverview: FC<Props> = ({
  organisationService,
}) => {
  if (!organisationService.response.data._links.getMyOrganisationDetails)
    throw new Forbidden();

  const organisationDetails = useOrganisationDetails({
    url: organisationService.response.data._links.getMyOrganisationDetails.href,
  });
  const tsoOrSsoDetails = useTsoOrSsoDetails({ organisationDetails });
  const lsoDetails = useLsoDetails({ organisationDetails });
  const shipperDetails = useShipperDetails({ organisationDetails });

  const operatorDetailsNeededAndNotLoadedYet =
    organisationDetails.response &&
    hasTsoOrSsoDetails(organisationDetails.response.data) &&
    !tsoOrSsoDetails.response;

  const shipperDetailsNeededAndNotLoadedYet =
    organisationDetails.response?.data._links.shipperDetails &&
    !shipperDetails.response;

  const lsoDetailsNeededAndNotLoadedYet =
    organisationDetails.response?.data._links.lsoDetails &&
    !lsoDetails.response;

  if (
    !organisationDetails.response ||
    operatorDetailsNeededAndNotLoadedYet ||
    shipperDetailsNeededAndNotLoadedYet ||
    lsoDetailsNeededAndNotLoadedYet
  )
    return <PageSpinner />;

  return (
    <Page
      organisationDetails={organisationDetails}
      operatorDetails={tsoOrSsoDetails}
      shipperDetails={shipperDetails}
      lsoDetails={lsoDetails}
    />
  );
};

export function hasTsoOrSsoDetails(resp: OrganisationDetailsResponse) {
  return resp._links.tsoDetails || resp._links.ssoDetails;
}
