import { useEffect } from 'react';
import urlTemplate from 'url-template';
import type {
  CapacityManagementRootResponse,
  InvoicesParamsForShipper,
  InvoicesForShipperResponse,
} from 'src/apis/capacity-management/types';
import type { CapacityManagementRootRequest } from 'src/apis/capacity-management/use-capacity-management-root';
import { useAxios } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';

type HookConfig = {
  capacityManagementRoot: CapacityManagementRootRequest;
  params?: InvoicesParamsForShipper;
};

type RequestOptions = {
  rootData: CapacityManagementRootResponse;
  params?: InvoicesParamsForShipper;
};

export function useMyInvoices({ capacityManagementRoot, params }: HookConfig) {
  const invoices = useAxios(
    (axios, baseConfig, { rootData, params }: RequestOptions) => {
      const link = rootData._links?.myInvoicesV2;
      if (!link) throw new Forbidden();

      const url = urlTemplate.parse(link.href).expand({});

      return axios.request<InvoicesForShipperResponse>({
        ...baseConfig,
        url,
        params,
      });
    },
    {
      neededOnPageLoad: true,
      onError(error) {
        if (error instanceof Forbidden) {
          return error;
        } else {
          throw error;
        }
      },
    }
  );

  useEffect(() => {
    if (!capacityManagementRoot.response) return;

    const options = { params, rootData: capacityManagementRoot.response.data };

    invoices.execute(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capacityManagementRoot.response, params]);

  return invoices;
}

export type MyInvoicesRequest = ReturnType<typeof useMyInvoices>;
