import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FC, ReactNode } from 'react';
import { useMatch } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { buttonBaseStyle } from 'src/components/buttons-and-actions/button';
import { Stack } from 'src/components/layout/stack';
import { Tooltip } from 'src/components/overlay/tooltip';
import type { WizardState } from 'src/components/wizard/state';
import { Colors } from 'src/styles';

const Indicator = styled.div<{
  active: boolean;
  available: boolean;
  error: boolean;
}>(({ active, available, error }) => [
  css`
    position: relative;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 3.2rem;
    height: 3.2rem;
    border: 0.2rem solid ${Colors.brandLight3};
    color: ${Colors.brandLight3};
    font-size: 1.8rem;
    line-height: 1;
    background-color: ${Colors.background};
  `,
  available &&
    css`
      border-color: ${Colors.brand};
      color: ${Colors.brand};
    `,
  active &&
    css`
      border-color: ${Colors.brand};
      background-color: ${Colors.brand};
      color: #fff;
    `,
  error &&
    css`
      border-color: ${Colors.errorDark};
      color: ${Colors.errorDark};
      background: ${Colors.errorLighter};
    `,
]);

const Title = styled.div<{
  active: boolean;
  finished: boolean;
  error: boolean;
}>(({ active, finished, error }) => [
  css`
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 1;
    color: ${Colors.brandLight3};
  `,
  (active || finished) &&
    css`
      color: ${Colors.brand};
    `,
  error &&
    css`
      color: ${Colors.errorDark};
    `,
]);

const RootButton = styled.button`
  ${buttonBaseStyle}
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  text-align: left;

  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 1;
          cursor: default;
        `
      : css`
          &:hover ${Indicator}, &:focus ${Indicator} {
            color: ${Colors.brandSecondary};
            border-color: ${Colors.brandSecondary};
          }

          &:hover ${Title}, &:focus ${Title} {
            color: ${Colors.brandSecondary};
            border-color: ${Colors.brandSecondary};
          }
        `};
`;

const Summary = styled.div`
  margin-top: 0.4rem;
  font-size: 1.2rem;
  color: ${Colors.brand};
`;

const ErrorIndicator = styled.div`
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  font-size: 1.4rem;
  background-color: white;
`;

const ErrorContent = styled.div`
  color: ${Colors.errorDark};
`;

type SimpleStepIndicatorProps = {
  step: number;
  title: string;
  wizardName: string;
  summary?: ReactNode;
  error?: ReactNode;
  active?: boolean;
  available?: boolean;
  onClick?: () => void;
};

export const SimpleStepIndicator: FC<SimpleStepIndicatorProps> = (props) => {
  const {
    step,
    title,
    summary,
    onClick,
    wizardName,
    active = false,
    available = false,
    error = false,
  } = props;

  return (
    <RootButton
      onClick={onClick}
      disabled={!onClick}
      data-testid={`${wizardName}-step-indicator-${step}`}
    >
      <Stack
        flow="column"
        gap={1.2}
        alignItems="baseline"
        templateColumns="auto 1fr"
      >
        <Indicator active={active} available={available} error={Boolean(error)}>
          {step}

          {error && (
            <ErrorIndicator>
              <Tooltip content={<ErrorContent>{error}</ErrorContent>}>
                {(targetProps) => (
                  <FontAwesomeIcon
                    icon={faExclamationCircle}
                    {...targetProps}
                  />
                )}
              </Tooltip>
            </ErrorIndicator>
          )}
        </Indicator>

        <Stack>
          <Title active={active} finished={available} error={Boolean(error)}>
            {title}
          </Title>

          {summary && <Summary>{summary}</Summary>}
        </Stack>
      </Stack>
    </RootButton>
  );
};

type StepIndicatorProps = {
  index: number;
  wizard: WizardState<unknown[]>;
  summary?: ({ wizard }: { wizard: WizardState<unknown[]> }) => ReactNode;
  wizardCompleted: boolean;
};

export const StepIndicator: FC<StepIndicatorProps> = ({
  wizard,
  index,
  summary,
  wizardCompleted,
}) => {
  const { path, error, title, data, completed } = wizard.steps[index];
  const match = useMatch({ path, end: true });

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const prevVisited = wizard.steps[index - 1]?.completed;

  const available = completed || prevVisited;
  const active = match !== null;

  return (
    <SimpleStepIndicator
      step={index + 1}
      title={title}
      available={available}
      active={match !== null}
      error={error}
      summary={Boolean(completed && data) ? summary?.({ wizard }) : undefined}
      wizardName={wizard.name}
      onClick={
        !wizardCompleted && available && !active
          ? () => wizard.goToStep(index)
          : undefined
      }
    />
  );
};
