import type { JWT } from 'aws-amplify/auth';
import type { FC, ReactNode } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import { LoadingPage } from 'src/components/loading-page';
import { useCognito } from 'src/hooks/use-cognito';
import { assertContext } from 'src/utils/assert-context';
import { getCognitoToken } from 'src/utils/axios-instance';

type CognitoUser = {
  accessTokenJwt: JWT;
  cognitoUserName: string;
};

// pass undefined as any, because we run assertContext at runtime
const CognitoUserContext = createContext<CognitoUser | null>(undefined as any);

export const useCognitoUser = () => {
  const context = useContext(CognitoUserContext);
  assertContext(context, 'CognitoUser');
  return context;
};

export const CognitoUserProvider: FC<{
  children: ReactNode;
  fallback?: ReactNode;
}> = ({ children, fallback = <LoadingPage /> }) => {
  const cognito = useCognito();

  // `undefined` means loading
  const [data, setData] = useState<CognitoUser | null | undefined>();

  useEffect(() => {
    getCognitoToken(cognito).then((user) => {
      setData(user);
      if (user) cognito.updateUsedCommitSha();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (data === undefined) return <>{fallback}</>;

  return (
    <CognitoUserContext.Provider value={data}>
      {children}
    </CognitoUserContext.Provider>
  );
};
