import type { FC } from 'react';
import { lazy } from 'react';
import { Route, useParams } from 'react-router-dom';
import { useCapacityManagementRoot } from 'src/apis/capacity-management/use-capacity-management-root';
import { useMultiYearAllocation } from 'src/apis/capacity-management/use-multi-year-allocation';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { RootRoutes } from 'src/components/root-routes';
import { PageSpinner } from 'src/components/spinner-container';

const DetailsPage = lazy(() => import('./details/page'));
const AddPage = lazy(() => import('./add/page'));
const EditPage = lazy(() => import('./edit/page'));

export const MultiYearAllocationToBalancingGroups: FC = () => {
  const { multiYearAllocationId } = useParams<{
    multiYearAllocationId: string;
  }>();

  const capacityManagementRoot = useCapacityManagementRoot();
  const multiYearAllocation = useMultiYearAllocation({
    capacityManagementRoot,
    multiYearAllocationId,
  });

  if (!capacityManagementRoot.response || !multiYearAllocation.response)
    return <PageSpinner />;

  return (
    <RootRoutes>
      <Route
        path="/reporting/my-transactions/multi-year/:multiYearAllocationId/allocation-to-balancing-groups/:balancingGroupAllocationId/edit"
        element={
          <EditPage
            multiYearAllocation={multiYearAllocation}
            capacityManagementRoot={capacityManagementRoot}
          />
        }
      />

      <Route
        path="/reporting/my-transactions/multi-year/:multiYearAllocationId/allocation-to-balancing-groups/add"
        element={
          <AddPage
            multiYearAllocation={multiYearAllocation}
            capacityManagementRoot={capacityManagementRoot}
          />
        }
      />

      <Route
        path="/reporting/my-transactions/multi-year/:multiYearAllocationId/allocation-to-balancing-groups"
        element={
          <DetailsPage
            multiYearAllocation={multiYearAllocation}
            capacityManagementRoot={capacityManagementRoot}
          />
        }
      />

      <Route path="*" element={<ThrowNotFound />} />
    </RootRoutes>
  );
};
