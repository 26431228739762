import type { FC } from 'react';
import { useEffect } from 'react';
import { NotFound } from 'src/utils/errors';

export const ThrowNotFound: FC = () => {
  useEffect(() => {
    throw new NotFound();
  }, []);
  return null;
};
