/* eslint-disable prisma-rule/safe-date-now */
import { createContext, useCallback, useContext } from 'react';
import { useMemoOne } from 'use-memo-one';

const NowContext = createContext<number | null>(null);
export const NowProvider = NowContext.Provider;

/**
 * Use this hook, if you need Date.now() and you want to easily mock it's value (e.g. in storybook).
 */
export function useNow() {
  const value = useContext(NowContext);
  const now = useMemoOne(() => value ?? Date.now(), [value]);
  return now;
}

/**
 * Use this hook, if you need subsequent calls to Date.now() (e.g. inside setInterval).
 */
export function useNowCallback() {
  const value = useContext(NowContext);

  const data = useMemoOne(() => {
    const timestamp = Date.now();
    const now = value ?? timestamp;
    const wasMocked = Boolean(now);
    return { now, timestamp, wasMocked };
  }, [value]);

  const nowCallback = useCallback(() => {
    if (process.env.NODE_ENV === 'test') {
      return data.now;
    } else if (data.wasMocked) {
      return data.now + (Date.now() - data.timestamp);
    } else {
      return Date.now();
    }
  }, [data]);

  return nowCallback;
}
