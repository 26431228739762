import { useEffect } from 'react';
import urlTemplate from 'url-template';
import type { ContractReport } from 'src/apis/aggregate-eu/types';
import type { ContractsRequest } from 'src/apis/aggregate-eu/use-get-contract-reports';
import { useAxios } from 'src/hooks/use-axios';

type HookConfig = {
  contracts: ContractsRequest;
  contractReportId: string;
};

type RequestConfig = {
  instanceUrlTemplate: string;
  contractReportId: string;
};

export function useGetContractReport({
  contracts,
  contractReportId,
}: HookConfig) {
  const myDemand = useAxios(
    (
      axios,
      baseConfig,
      { instanceUrlTemplate, contractReportId }: RequestConfig
    ) => {
      const url = urlTemplate.parse(instanceUrlTemplate).expand({
        contractReportId,
      });
      return axios.request<ContractReport>({
        ...baseConfig,
        url,
      });
    },
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!contracts.response) return;

    const instanceUrlTemplate = contracts.response.data._links!.instance.href;
    myDemand.execute({
      instanceUrlTemplate,
      contractReportId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contracts.response?.data._links?.instance.href, contractReportId]);

  return myDemand;
}

export type ContractRequest = ReturnType<typeof useGetContractReport>;
