import type { WritePrice, WriteQuantity } from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';
import type { SharedSubmitBidData } from 'src/pages/transport/auctions/details/shared-form-logic';

export type SubmitShortTermBidData = SharedSubmitBidData & {
  minQuantity: WriteQuantity;
  maxQuantity: WriteQuantity;
  price: WritePrice;
  withinDayRollover: boolean;
};

type RequestOptions = {
  auctionId: number;
  data: SubmitShortTermBidData;
};

export function usePlaceShortTermBid() {
  const placeBid = useAxios(
    (axios, baseConfig, { auctionId, data }: RequestOptions) =>
      axios.request<null>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/auctions/${auctionId}/shortTermOrder`,
        method: 'POST',
        data,
      }),
    { neededOnPageLoad: false }
  );

  return placeBid;
}

export type PlaceShortTermBidRequest = ReturnType<typeof usePlaceShortTermBid>;
