import escapedString from 'escape-string-regexp';
import type { FC } from 'react';
import { useEffect, useMemo } from 'react';
import { z } from 'zod';
import type { TsoMinimal } from 'src/apis/monolith/types';
import { SearchableMultiSelect } from 'src/components/form/select/searchable-multi-select';
import { formOptionSchema } from 'src/components/form/zod-schemas';
import { useAxios } from 'src/hooks/use-axios';
import { useSearch } from 'src/hooks/use-search';

type Props = {
  label: string;
  placeholder: string;
  name?: string;
  valueField?: keyof TsoMinimal;
};

const tsoMetaSchema = z.object({ shortName: z.string() });

export const tsoOptionSchema = formOptionSchema.merge(tsoMetaSchema);

type TsoMetaOption = z.input<typeof tsoOptionSchema>;

export const TsoSearchableMultiSelect: FC<Props> = ({
  label,
  placeholder,
  name = 'tsoId',
  valueField = 'organizationId',
}) => {
  const [searchValue, setSearchValue, searchQuery] = useSearch('', {
    minLength: 1,
  });

  const searchRequest = useAxios(
    (axios, baseConfig) =>
      axios.request<TsoMinimal[]>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/tso/?detailLevel=MINIMAL`,
        method: 'GET',
      }),
    { neededOnPageLoad: false }
  );
  // on the very first search input we try to fetch the initial list
  useEffect(() => {
    if (!searchQuery || searchRequest.response) return;
    searchRequest.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, searchRequest.response]);

  const searchResult = useMemo(() => {
    if (!searchQuery || !searchRequest.response) return;

    const escapedSearchQuery = new RegExp(
      escapedString(searchQuery.toLowerCase())
    );

    return searchRequest.response.data
      .filter(
        (tso) =>
          tso.name.toLowerCase().match(escapedSearchQuery) ||
          tso.shortName.toLowerCase().match(escapedSearchQuery)
      )
      .map((tso) => ({
        value: String(tso[valueField]),
        label: tso.name,
        shortName: tso.shortName,
      })) satisfies TsoMetaOption[];
  }, [searchQuery, valueField, searchRequest.response]);

  return (
    <SearchableMultiSelect
      label={label}
      name={name}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      searchValueForCurrentOptions={searchValue}
      pending={searchRequest.pending}
      options={searchResult}
      stacked
      placeholder={placeholder}
      renderOptionHint={(option) => option.shortName}
      renderSelectedOptionTooltip={(option) => (
        <>
          <strong>Short Name:</strong> {option.shortName}
        </>
      )}
    />
  );
};
