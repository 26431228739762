import type { FC } from 'react';
import { useEffect } from 'react';
import type { UsersAssignmentByOperator } from 'src/apis/assignment-service/types';
import type { UserAssignmentsForOperatorRequest } from 'src/apis/assignment-service/use-user-assignments-by-operator';
import { useWithdrawUserAssignment } from 'src/apis/assignment-service/use-withdraw-user-assignment';
import type { PublicOperatorDetailsResponse } from 'src/apis/organisation-service/types';
import { Button } from 'src/components/buttons-and-actions/button';
import { TableActions } from 'src/components/buttons-and-actions/table-actions';
import { WithdrawUserAssignmentModal } from 'src/components/domain-specifics/assignements/withdraw-user-assignment-modal';
import type { Successful } from 'src/hooks/use-axios';
import { useModal2 } from 'src/hooks/use-modal';
import { useToast } from 'src/hooks/use-toasts';

export const UserAssignmentActions: FC<{
  userAssignmentDetails: UsersAssignmentByOperator;
  userAssignmentDetailsRequest: Successful<UserAssignmentsForOperatorRequest>;
  operatorDetails: PublicOperatorDetailsResponse;
}> = ({
  userAssignmentDetails,
  userAssignmentDetailsRequest,
  operatorDetails,
}) => {
  const withdrawAssignmentApplication = useWithdrawUserAssignment();
  const notify = useToast();

  const withdrawUserAssignmentApplication =
    userAssignmentDetails._links?.withdrawUserAssignmentApplication;

  const withdraw = useModal2(
    withdrawUserAssignmentApplication
      ? {
          modal: (props) => (
            <WithdrawUserAssignmentModal
              onConfirm={() => {
                withdrawAssignmentApplication.execute(
                  withdrawUserAssignmentApplication.href
                );
              }}
              operatorDetails={operatorDetails}
              pending={withdrawAssignmentApplication.pending}
              userName={userAssignmentDetails.userName}
              {...props}
            />
          ),
          trigger: (props) => (
            <Button
              data-testid={`withdraw-user-assignment-application-btn-${userAssignmentDetails.userAssignmentApplicationId}`}
              disabled={withdrawAssignmentApplication.pending}
              {...props}
            >
              {withdrawAssignmentApplication.pending
                ? 'Pending...'
                : 'Withdraw'}
            </Button>
          ),
        }
      : null
  );

  useEffect(() => {
    if (!withdrawAssignmentApplication.response) return;
    notify({
      type: 'success',
      children: 'User application was successfully withdrawn.',
    });
    withdraw?.close();
    userAssignmentDetailsRequest.refresh!();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withdrawAssignmentApplication.response]);

  return (
    <>
      <TableActions>{withdraw?.trigger}</TableActions>
      {withdraw?.modal}
    </>
  );
};
