import { useEffect } from 'react';
import type { AuthorizationServiceRoot } from 'src/apis/authorization-service/types';
import { isServerError, useAxios } from 'src/hooks/use-axios';
import { useToast } from 'src/hooks/use-toasts';
import { handleErrorWithNotification } from 'src/utils/handle-error';

/**
 * Use this, if it is_required_ to be authenticated to proceed.
 * The user would see a "Please login." page when they aren't authenticated.
 */
export function useAuthorizationService() {
  const request = useAxios(
    (axios, baseConfig) =>
      axios.request<AuthorizationServiceRoot>({
        ...baseConfig,
        url: PRISMA_CONFIG.authorizationService,
      }),
    { neededOnPageLoad: true, cacheKey: 'authorization-service-root' }
  );

  useEffect(() => {
    request.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return request;
}

export type AuthorizationServiceRequest = ReturnType<
  typeof useAuthorizationService
>;

/**
 * Use this, if you can be unauthenticated and if a 401 failure should not
 * block the page from rendering.
 */
export function useOptionalAuthorizationService() {
  const notify = useToast();

  const request = useAxios(
    (axios, baseConfig) =>
      axios.request<AuthorizationServiceRoot>({
        ...baseConfig,
        url: PRISMA_CONFIG.authorizationService,
        timeout: 5000,
      }),
    {
      neededOnPageLoad: true,
      cacheKey: 'authorization-service-root',
      onError(error) {
        if (!isServerError(error, 401)) {
          handleErrorWithNotification({
            error,
            notify,
            setReauthenticate: () => {},
          });
        }
        return error;
      },
    }
  );

  useEffect(() => {
    request.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return request;
}

export type OptionalAuthorizationServiceRequest = ReturnType<
  typeof useOptionalAuthorizationService
>;
