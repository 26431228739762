import { z } from 'zod';
import { getPaginationSchema } from 'src/components/form/zod-schemas';
import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import { periodTypeValues } from 'src/pages/reporting/auctions/components/period-type';

const recentAuctionsSchema = z.object({
  periodType: z.enum(periodTypeValues).default('WITHINDAY'),
});

const longTermAuctionsSchema = getPaginationSchema();

const shortTermAuctionsSchema = getPaginationSchema();

const offeredProposalsSchema = getPaginationSchema();

const requestedProposalsSchema = getPaginationSchema();

export function useNwpDetailParams() {
  const recentAuctions = usePageParamsFromSchema(recentAuctionsSchema, {
    groupPrefix: 'recentAuctions',
  });

  const longTermAuctions = usePageParamsFromSchema(longTermAuctionsSchema, {
    groupPrefix: 'longTermAuctions',
  });

  const shortTermAuctions = usePageParamsFromSchema(shortTermAuctionsSchema, {
    groupPrefix: 'shortTermAuctions',
  });

  const offeredProposals = usePageParamsFromSchema(offeredProposalsSchema, {
    groupPrefix: 'offeredProposals',
  });

  const requestedProposals = usePageParamsFromSchema(requestedProposalsSchema, {
    groupPrefix: 'requestedProposals',
  });

  return {
    recentAuctions,
    longTermAuctions,
    shortTermAuctions,
    offeredProposals,
    requestedProposals,
  };
}

export type NwpDetailParams = ReturnType<typeof useNwpDetailParams>;
