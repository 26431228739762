import { faSort } from '@fortawesome/pro-light-svg-icons';
import {
  faInfoCircle,
  faSortDown,
  faSortUp,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type {
  ButtonHTMLAttributes,
  FC,
  HTMLAttributes,
  MutableRefObject,
  ReactNode,
  RefObject,
  TdHTMLAttributes,
  ThHTMLAttributes,
} from 'react';
import { forwardRef, useCallback, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';
import { buttonBaseStyle } from 'src/components/buttons-and-actions/button';
import { Stack } from 'src/components/layout/stack';
import { Tooltip } from 'src/components/overlay/tooltip';
import { useHeaderHeight } from 'src/hooks/use-header-height';
import { Colors } from 'src/styles';

const tablePaddingLeftRight = '0.8rem';
const tablePaddingTopBottom = '1.6rem';

export const tablePadding = `${tablePaddingTopBottom} ${tablePaddingLeftRight}`;

export type SimpleTableProps = {
  /**
   * Either specify a fixed width like `'5.5rem'` as a string or a relative width
   * like `1` as a number which would be treated just like `'1fr'` in a CSS grid.
   *
   * Let's say you have a table like this:
   * ```tsx
   * <SimpleTable widths={[2, 1, '5.5rem']} />
   * ```
   *
   * This means your last column has a width of 5.5rem (plus padding which will be added later)
   * and the other two columns get the remaining space will the first one will be 66.6% and the
   * second one 33.3%.
   */
  widths: (string | number)[];
  sticky?: boolean;
  top?: string;
  children: ReactNode;
  light?: boolean;
};

export type TrProps = {
  isEven?: boolean;
  hideBorder?: boolean;
  isFocused?: boolean;
  light?: boolean;
  isSelected?: boolean;
} & HTMLAttributes<HTMLTableRowElement>;

export const Tr = styled.tr<TrProps>`
  border-bottom: 0.1rem solid ${Colors.brandBorder};

  ${({ isFocused, isSelected }) =>
    isFocused &&
    !isSelected &&
    css`
      background-color: ${Colors.background};
      &:hover {
        background-color: ${Colors.background};
      }
    `}

  ${({ isSelected }) =>
    isSelected &&
    css`
      outline: 0.2rem solid ${Colors.brand};
      outline-offset: -0.2rem;
    `}

  &:last-child {
    border-bottom: none;
  }

  ${({ onClick }) =>
    onClick &&
    css`
      &:hover {
        background-color: ${Colors.brandLight4};
        cursor: pointer;
      }
    `}

  ${({ hideBorder }) =>
    hideBorder &&
    css`
      border-bottom: none;
    `}

  ${({ isEven, isSelected, isFocused, light }) =>
    !isSelected &&
    !isFocused &&
    !light &&
    (isEven === undefined
      ? css`
          &:nth-child(even) {
            background: #f6fafd;
          }
        `
      : isEven
        ? css`
            background: #f6fafd;
          `
        : ``)}
`;

const colWidths = ({ widths }: SimpleTableProps) => {
  const relativeWidths = widths.filter((col) => typeof col === 'number');
  const relativeWidthsSum = relativeWidths.reduce((a, b) => a + b, 0);

  return widths.map((width, index) => {
    if (typeof width === 'string') {
      return css`
        & td:nth-child(${index + 1}),
        & th:nth-child(${index + 1}) {
          width: calc(${width} + 2 * ${tablePaddingLeftRight});
        }
      `;
    }

    const relativeFactor = width / relativeWidthsSum;
    return css`
      & td:nth-child(${index + 1}),
      & th:nth-child(${index + 1}) {
        width: calc(100% * ${relativeFactor});
      }
    `;
  });
};

const StyledTable = styled.table<SimpleTableProps>`
  width: 100%;
  background: white;
  border-collapse: separate;
  border-spacing: 0;
  word-break: break-word;
  border-top: 0.1rem solid ${Colors.brandLight3};
  border-bottom: 0.1rem solid ${Colors.brandLight3};
  position: relative;
  table-layout: fixed;

  ${colWidths}

  ${({ light }) =>
    light &&
    css`
      td,
      th {
        border-right: none;
        border-left: none;
        border-bottom: 0.1rem solid ${Colors.background};
      }

      th {
        background-color: ${Colors.brandLight5};
      }
    `}
    
  th {
    ${({ sticky = true, top }) =>
      sticky
        ? css`
            position: sticky;
            top: ${top};
            /*
          not exactly sure why, but we need a fake border-top or else 
          there's 1px line of the td's showing on top of th's
          when you scroll
        */
            box-shadow: 0 -1px 0 ${Colors.brandLight4};
          `
        : css`
            position: static;
          `}
  }
`;

/**
 * Don't use `<SimpleTable/>` directly. Prefer `<Table/>` which offers a better
 * API and wraps this component.
 * @deprecated
 */
export const SimpleTable: FC<SimpleTableProps> = (props) => {
  const { top, children, light, ...rest } = props;
  const { height: headerHeight } = useHeaderHeight();

  return (
    <StyledTable top={top ?? `${headerHeight}px`} light={light} {...rest}>
      {children}
    </StyledTable>
  );
};

export const Thead = styled.thead`
  /*
    previously we used "position: relative" here, but it looks like Safari messes up
    z-index if you don't use "position: sticky", because our th's might use "position: sticky"
  */
  position: sticky;
  z-index: 1;
  text-align: left;
  background-color: ${Colors.brandLight4};

  ${({ onClick }) =>
    onClick &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}
`;

export type TbodyProps = HTMLAttributes<HTMLTableSectionElement>;

export const Tbody = styled.tbody<TbodyProps>``;

const CellContainer = styled.div<{
  mode?: 'highlight' | 'header' | 'input';
  headerHighlight?: boolean;
}>`
  border-left: 0.1rem solid ${Colors.background};
  border-right: 0.1rem solid ${Colors.background};
  padding: ${tablePadding};

  & + & {
    border-left: none;
  }

  ${({ mode }) =>
    mode === 'input' &&
    css`
      vertical-align: top;
    `}

  ${({ mode }) =>
    mode === 'highlight' &&
    css`
      background-color: ${Colors.background};
      border-right-color: #ffffff;
    `}

  ${({ mode }) =>
    mode === 'header' &&
    css`
      background-color: ${Colors.brandBorder};
      border-right-color: #ffffff;
      border-top: 0.1rem solid #ebf2fb;
    `}

  ${({ headerHighlight }) =>
    headerHighlight &&
    css`
      background-color: ${Colors.brandBorder};
    `}
`;

type Align = 'left' | 'center' | 'right';

const CellContent = styled.div<{ align?: Align }>`
  text-align: ${({ align = 'left' }) => align};
  width: 100%;
`;

type ThProps = {
  align?: Align;
  children?: ReactNode;
} & ThHTMLAttributes<HTMLTableHeaderCellElement>;

export const Th: FC<ThProps> = ({ align, children, ...rest }) => {
  return (
    <CellContainer as="th" {...rest} headerHighlight>
      <CellContent align={align}>{children}</CellContent>
    </CellContainer>
  );
};

type TdProps = {
  align?: Align;
  mode?: 'highlight' | 'header' | 'input';
  children: ReactNode;
} & TdHTMLAttributes<HTMLTableCellElement>;

export const Td = forwardRef<HTMLTableCellElement, TdProps>(
  ({ align, children, ...rest }, ref) => {
    return (
      <CellContainer as="td" {...rest} ref={ref}>
        <CellContent align={align}>{children}</CellContent>
      </CellContainer>
    );
  }
);

export type SortableParams = { sortColumn: string; sortAscending: boolean };

type SortableParamsTuple = [
  SortableParams,
  (params: Partial<SortableParams>) => void,
];

type SortDirection = 'asc' | 'desc';

type SortableHeaderProps = {
  label: ReactNode;
  active: boolean;
  direction: SortDirection;
  onSort: (direction: SortDirection) => void;
  button?: (props: {
    buttonProps: ButtonHTMLAttributes<HTMLButtonElement>;
    labelRef: RefObject<HTMLElement>;
  }) => ReactNode;
  info?: ReactNode;
} & ButtonHTMLAttributes<HTMLButtonElement>;

type DoubleSortableHeaderProps<Params extends { [key: string]: unknown }> = {
  labels: [string, string];
  columns: [string, string];
  params: { value: Params; set: (value: Partial<Params>) => void };
  initialSortAscending?: boolean;
  button?: (props: {
    buttonProps: ButtonHTMLAttributes<HTMLButtonElement>;
    labelRef: RefObject<HTMLElement>;
  }) => ReactNode;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const ThButton = styled.button`
  ${buttonBaseStyle}
  border: none;
  background: transparent;
  width: 100%;
  cursor: pointer;
  color: inherit;
  font-weight: inherit;
  text-align: left;
  padding: ${tablePadding};

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

const Label = styled.span`
  ${buttonBaseStyle}
  cursor: inherit;

  ${ThButton}:hover:not([disabled]) & {
    color: ${Colors.brandSecondary};
  }
`;

const IconWrapper = styled.span`
  transition:
    color 0.35s,
    opacity 0.35s;
  font-size: 1.1rem;

  ${ThButton}:hover:not([disabled]) & {
    color: ${Colors.brandSecondary};
  }
`;

export const SortableHeader = forwardRef<HTMLDivElement, SortableHeaderProps>(
  (props, ref) => {
    const { label, active, direction, onSort, button, info, ...rest } = props;

    const handleSort = useCallback(() => {
      if (direction === 'asc') {
        onSort('desc');
      } else {
        onSort('asc');
      }
    }, [direction, onSort]);

    const sortIcon = useMemo(() => {
      if (!active) return faSort;
      return direction === 'asc' ? faSortUp : faSortDown;
    }, [direction, active]);

    const ariaLabel = useMemo(() => {
      if (!active) {
        return 'Click to sort this column';
      }

      if (direction === 'desc') {
        return 'Click to enable ascending sorting';
      }

      return 'Click to enable descending sorting';
    }, [direction, active]);

    const labelRef = useRef<HTMLElement>(null);

    const buttonProps: ButtonHTMLAttributes<HTMLButtonElement> = {
      onClick: (e) => {
        rest.onClick?.(e);
        handleSort();
      },
      ...rest,
      'aria-label': ariaLabel,
      children: (
        <Stack flow="column" gap={0.5} inline ref={ref}>
          <Label ref={labelRef}>{label}</Label>
          {!!info && (
            <Tooltip content={info}>
              {() => <FontAwesomeIcon icon={faInfoCircle} />}
            </Tooltip>
          )}
          <IconWrapper aria-hidden>
            <FontAwesomeIcon icon={sortIcon} />
          </IconWrapper>
        </Stack>
      ),
    };

    return (
      <CellContainer as="th" headerHighlight style={{ padding: 0 }}>
        {button ? (
          button({ buttonProps, labelRef })
        ) : (
          <ThButton {...buttonProps} />
        )}
      </CellContainer>
    );
  }
);

export const DoubleSortableHeader = forwardRef<
  HTMLDivElement,
  DoubleSortableHeaderProps<{ [key: string]: unknown }>
>((props, ref) => {
  const {
    labels,
    columns,
    params: { set: setParams },
    initialSortAscending = false,
    button,
    ...rest
  } = props;

  let sortColumn = props.params.value.sortBy as string;
  let sortDirection = props.params.value.sortDirection as SortDirection;
  let active = columns.includes(sortColumn);

  let label = labels[columns.indexOf(sortColumn)]
    ? labels[columns.indexOf(sortColumn)]
    : labels[0];

  const handleSort = useCallback(() => {
    const onSort = (sortDirection: string, sortBy: string) => {
      if (!active) {
        // If the sorted columns switch, do not toggle the `sortAscending` state, use `initialSortAscending`
        setParams({
          sortBy,
          sortDirection: initialSortAscending ? 'asc' : 'desc',
        });
      } else {
        setParams({ sortBy, sortDirection });
      }
    };

    let currentSortBy = sortColumn;
    let sortAscending = sortDirection === 'asc';

    if (sortAscending && currentSortBy === columns[0]) {
      onSort('desc', columns[0]);
    } else if (!sortAscending && currentSortBy === columns[0]) {
      onSort('asc', columns[1]);
    } else if (sortAscending && currentSortBy === columns[1]) {
      onSort('desc', columns[1]);
    } else if (!sortAscending && currentSortBy === columns[1]) {
      onSort('asc', columns[0]);
    } else {
      onSort('asc', columns[0]);
    }
  }, [
    sortColumn,
    sortDirection,
    setParams,
    active,
    columns,
    initialSortAscending,
  ]);

  const sortIcon = useMemo(() => {
    if (!active) return faSort;
    return sortDirection === 'asc' ? faSortUp : faSortDown;
  }, [sortDirection, active]);

  const ariaLabel = useMemo(() => {
    if (!active) {
      return 'Click to sort this column';
    }

    if (sortDirection === 'desc') {
      return 'Click to enable ascending sorting';
    }

    return 'Click to enable descending sorting';
  }, [sortDirection, active]);

  const labelRef = useRef<HTMLElement>(null);

  const buttonProps: ButtonHTMLAttributes<HTMLButtonElement> = {
    onClick: (e) => {
      rest.onClick?.(e);
      handleSort();
    },
    ...rest,
    'aria-label': ariaLabel,
    children: (
      <Stack flow="column" gap={0.5} inline ref={ref}>
        {/* TODO: check the labelRef */}
        <Label ref={labelRef}>{label}</Label>

        <IconWrapper aria-hidden>
          <FontAwesomeIcon icon={sortIcon} />
        </IconWrapper>
      </Stack>
    ),
  };

  return (
    <CellContainer as="th" headerHighlight style={{ padding: 0 }}>
      {button ? (
        button({ buttonProps, labelRef })
      ) : (
        <ThButton {...buttonProps} />
      )}
    </CellContainer>
  );
});

type GenericSortableHeaderProps = {
  label: ReactNode;
  sortableParams: SortableParamsTuple;
  column: string;
  initialSortAscending?: boolean;
};

export const GenericSortableHeader = forwardRef<
  HTMLDivElement,
  GenericSortableHeaderProps
>((props, ref) => {
  const {
    sortableParams: [{ sortColumn, sortAscending }, setParams],
    column,
    initialSortAscending = false,
    ...rest
  } = props;

  const active = sortColumn === column;
  const direction = (active ? sortAscending : initialSortAscending)
    ? 'asc'
    : 'desc';

  return (
    <SortableHeader
      direction={direction}
      active={active}
      ref={ref}
      data-testid={`sort-${column}`}
      onSort={() => {
        if (!active) {
          // If the sorted columns switch, do not toggle the `sortAscending` state, use `initialSortAscending`
          setParams({
            sortColumn: column,
            sortAscending: initialSortAscending,
          });
        } else if (sortAscending) {
          setParams({ sortColumn: column, sortAscending: false });
        } else {
          setParams({ sortColumn: column, sortAscending: true });
        }
      }}
      {...rest}
    />
  );
});

type SpecificSortHeaderProps<Params extends { [key: string]: unknown }> = {
  label: ReactNode;
  params: { value: Params; set: (value: Partial<Params>) => void };
  sortParam: keyof Params;
  directionParam: keyof Params;
  column: string;
  initialSortAscending?: boolean;
  button?: (props: {
    buttonProps: ButtonHTMLAttributes<HTMLButtonElement>;
    labelRef: RefObject<HTMLElement>;
  }) => ReactNode;
  info?: ReactNode;
};

function InnerSpecificSortHeader<Params extends { [key: string]: unknown }>(
  props: SpecificSortHeaderProps<Params>,
  // from interface ForwardRefRenderFunction
  ref:
    | ((instance: HTMLTableCellElement | null) => void)
    | MutableRefObject<HTMLTableCellElement | null>
    | null
) {
  const {
    params,
    sortParam,
    directionParam,
    column,
    initialSortAscending = false,
    ...rest
  } = props;

  return (
    <SortableHeader
      direction={params.value[directionParam] as SortDirection}
      active={params.value[sortParam] === column}
      ref={ref}
      onSort={(direction) => {
        if (params.value[sortParam] !== column) {
          // If the sorted columns switch, do not toggle the sorting order
          params.set({
            [sortParam]: column,
            [directionParam]: initialSortAscending ? 'asc' : 'desc',
          } as Partial<Params>);
        } else {
          params.set({
            [sortParam]: column,
            [directionParam]: direction,
          } as Partial<Params>);
        }
      }}
      {...rest}
    />
  );
}

// "as" is needed to get generic types right
// note: React v17 will offer a way to use refs without the need to use forwardRef in the future
export const SpecificSortHeader = forwardRef(
  InnerSpecificSortHeader
) as typeof InnerSpecificSortHeader;
