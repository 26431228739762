import type { FC } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useNetworkPointByUuid } from 'src/apis/monolith/use-network-point-by-uuid';
import { PageSpinner } from 'src/components/spinner-container';

export const NetworkPointsRedirect: FC = () => {
  const { networkPointId } = useParams<{ networkPointId: string }>();
  const navigate = useNavigate();
  const oldNetworkPoint = useNetworkPointByUuid(networkPointId);

  useEffect(() => {
    if (!oldNetworkPoint.response) return;
    navigate(
      `/platform/#/network-point/details/${oldNetworkPoint.response.data.id}`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldNetworkPoint.response]);

  return <PageSpinner />;
};
