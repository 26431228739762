import { faDownload, faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FC } from 'react';
import { useMemo } from 'react';
import type {
  AdditionalRequiredDocument,
  RequiredCreditRatingDocument,
  RequiredDocument,
} from 'src/apis/assignment-service/types';
import { useDownloadRequiredInformation } from 'src/apis/assignment-service/use-download-required-document';
import type { RequiredDocumentsRequest } from 'src/apis/assignment-service/use-required-documents';
import { Button } from 'src/components/buttons-and-actions/button';
import { Card } from 'src/components/data-display/card';
import { Spacer } from 'src/components/layout/spacer';
import { Stack } from 'src/components/layout/stack';
import { ExternalLink, Link } from 'src/components/navigation/link';
import { Heading } from 'src/components/text/heading';
import type { Successful } from 'src/hooks/use-axios';
import { DeliveryMethodLabels } from 'src/pages/settings/assignment-settings/required-information/edit/schema';
import { Warning } from 'src/pages/settings/registration/components/form-utils';

type Props = {
  requiredInformation: Successful<RequiredDocumentsRequest>;
};

const Page: FC<Props> = ({ requiredInformation }) => {
  const { data } = requiredInformation.response;

  const anyRequiredDocuments: boolean = useMemo(
    () =>
      [
        data.companyRegisterExtract,
        data.currentCompanyReport,
        data.mostRecentFullYearFinancialReport,
        data.mostRecentInterimFinancialReport,
        data.creditSolvencyCheck,
        data.poa,
        data.creditRating,
      ].some((doc) => doc && doc.acceptedDeliveryMethods.length > 0),
    [data]
  );

  return (
    <Card data-testid="required-information-card">
      <Stack gap={0.5}>
        <Stack flow="column" alignItems="start" autoColumns="1fr auto">
          <Heading
            mode="card"
            id="required-information"
            data-testid="required-information"
          >
            Required Information
          </Heading>
          {data._links.edit && (
            <Link
              to="/settings/assignment-settings/required-information/edit"
              mode="button-secondary"
              size="small"
              data-testid="required-information-edit"
            >
              Edit
            </Link>
          )}
        </Stack>
        <p>
          Please specify which documents and information shippers must provide
          to you for approval by your organisation.
        </p>
        <Spacer />
        <Heading mode="card">Shipper Organisation Assignment</Heading>

        {!anyRequiredDocuments &&
          !data.additionalOrganisationDocuments.length && (
            <Warning>Not configured</Warning>
          )}

        {anyRequiredDocuments && (
          <>
            <strong>Documents</strong>
            <Stack gap={1.5} data-testid="documents-list">
              {data.companyRegisterExtract && (
                <DocumentView
                  label="Company Register Extract"
                  document={data.companyRegisterExtract}
                />
              )}
              {data.currentCompanyReport && (
                <DocumentView
                  label="Current Company Report"
                  document={data.currentCompanyReport}
                />
              )}
              {data.mostRecentFullYearFinancialReport && (
                <DocumentView
                  label="Most Recent (Full-Year) Financial Report"
                  document={data.mostRecentFullYearFinancialReport}
                />
              )}
              {data.mostRecentInterimFinancialReport && (
                <DocumentView
                  label="Most Recent (Interim) Financial Report"
                  document={data.mostRecentInterimFinancialReport}
                />
              )}
              {data.creditSolvencyCheck && (
                <DocumentView
                  label="Credit/Solvency Check Document"
                  document={data.creditSolvencyCheck}
                />
              )}
              {data.poa && (
                <DocumentView
                  label="PRISMA Shipper Admin Power of Attorney"
                  document={data.poa}
                />
              )}
              {data.creditRating && (
                <DocumentView
                  label="Credit Rating Certification"
                  document={data.creditRating}
                  agency={data.creditRating.agency}
                />
              )}
            </Stack>
          </>
        )}

        {data.additionalOrganisationDocuments.length > 0 && (
          <>
            <Spacer />
            <strong>Custom Documents</strong>
            <Stack gap={1.5} data-testid="custom-documents-list">
              {data.additionalOrganisationDocuments.map(
                (organisationDocument) => (
                  <CustomDocumentView
                    key={organisationDocument.name}
                    additionalDocument={organisationDocument}
                  />
                )
              )}
            </Stack>
          </>
        )}

        <Spacer />

        <Heading mode="card">Shipper User Assignment</Heading>
        {data.additionalUserDocuments.length > 0 ? (
          <Stack gap={1.5} data-testid="user-documents-list">
            {data.additionalUserDocuments.map((userDocument) => (
              <CustomDocumentView
                key={userDocument.name}
                additionalDocument={userDocument}
              />
            ))}
          </Stack>
        ) : (
          <p>
            There are no additional documents that shipper users need to provide
            to be approved by your organisation.
          </p>
        )}
      </Stack>
    </Card>
  );
};

export const DocumentView: FC<{
  label: string;
  document?: RequiredDocument | RequiredCreditRatingDocument;
  agency?: string | null;
}> = ({ label, document, agency }) => {
  if (!document || !document.acceptedDeliveryMethods.length) return null;

  return (
    <div>
      <p>
        {label} -{' '}
        {document.acceptedDeliveryMethods
          .map((method) => DeliveryMethodLabels[method])
          .join(', ')}
      </p>
      {agency && <p>Agency: {agency}</p>}
    </div>
  );
};

type CustomDocumentViewProps = {
  additionalDocument: AdditionalRequiredDocument;
};

export const CustomDocumentView: FC<CustomDocumentViewProps> = ({
  additionalDocument,
}) => {
  const download = useDownloadRequiredInformation();

  return (
    <div>
      <p>
        {additionalDocument.name} -{' '}
        {additionalDocument.acceptedDeliveryMethods
          .map((method) => DeliveryMethodLabels[method])
          .join(', ')}
        {additionalDocument.url && (
          <>
            {' - '}
            <ExternalLink
              href={additionalDocument.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Show External Document <FontAwesomeIcon icon={faExternalLink} />
            </ExternalLink>
          </>
        )}
        {additionalDocument.document &&
          additionalDocument.document._links.download.href && (
            <>
              {' - '}
              <Button
                mode="link"
                onClick={() =>
                  download.execute({
                    url: additionalDocument.document!._links.download.href,
                  })
                }
                disabled={download.pending}
              >
                {download.pending ? 'Preparing...' : 'Download'}{' '}
                <FontAwesomeIcon icon={faDownload} />
              </Button>
            </>
          )}
      </p>
    </div>
  );
};

export default Page;
