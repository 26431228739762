import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FieldInputProps } from 'formik';
import { useFormikContext } from 'formik';
import { flattenDeep } from 'lodash';
import type { FC, InputHTMLAttributes, ReactNode } from 'react';
import { Fragment, useId } from 'react';
import { EmptyValue } from 'src/components/data-display/empty-value';
import { SimpleCheckbox } from 'src/components/form/checkbox';
import { ErrorMessage } from 'src/components/form/error-message';
import { FieldItem, FieldLayout } from 'src/components/form/field-layout';
import type { FormFieldProps } from 'src/components/form/form-field-props';
import type {
  FormOption,
  GroupedFormOption,
} from 'src/components/form/form-option';
import { FieldLabel } from 'src/components/form/label';
import type { SortByOptions } from 'src/components/form/select/utils';
import {
  applyGroupSortBy,
  applySortBy,
} from 'src/components/form/select/utils';
import { useCustomField } from 'src/components/form/use-custom-field';
import { Stack } from 'src/components/layout/stack';
import { Tooltip } from 'src/components/overlay/tooltip';
import { Hint } from 'src/components/text/hint';
import { useDefaultStacked } from 'src/hooks/use-default-stacked';

type CheckboxGroupProps = {
  options: FormOption<string>[] | GroupedFormOption<string>[];
  stacked?: boolean;
  stackedOptions?: boolean;
  toggleAll?: boolean;
  sortBy?: SortByOptions;
} & FormFieldProps &
  InputHTMLAttributes<HTMLInputElement>;

/**
 * Use this if you have a short list of options (e.g. 2-5) or enough screen space where the user can select multiple values.
 *
 * If you have a long list of options or need to save screen space, consider using the [Multi Select](../?path=/docs/components-form-multi-select--docs) component.
 *
 * If you have a long list of options where you want to search, consider using the [Searchable Multi Select](../?path=/docs/components-form-searchable-multi-select--docs) component.
 */
export const CheckboxGroup: FC<CheckboxGroupProps> = (props) => {
  const { isStacked } = useDefaultStacked();
  const {
    label,
    name,
    options,
    stacked = isStacked,
    stackedOptions = false,
    autoFocus,
    toggleAll = false,
    hideLabel = false,
    hint,
    info,
    markAsRequired = false,
    sortBy,
    ...inputProps
  } = props;

  const fieldId = useId();

  const { setFieldValue } = useFormikContext<any>();
  const [field, showError, error] = useCustomField<string[]>(name, label);

  const { optionGroups, allChecked, toggleAllChecked } = normalizeGroupOptions(
    field.value,
    options,
    sortBy
  );

  const displayLabel = `${label}${markAsRequired ? '*' : ''}`;

  const fieldItem = (
    <FieldItem>
      {/* div is needed for layout */}
      {options.length > 0 ? (
        <div>
          <Stack gap={2.5} inline alignItems="start">
            {toggleAll && (
              <strong>
                <SimpleCheckbox
                  showError={showError}
                  label={allChecked ? 'Unselect All' : 'Select All'}
                  checked={toggleAllChecked}
                  onChange={() =>
                    setFieldValue(
                      field.name,
                      toggleAllOptions({
                        toggleAllChecked,
                        optionGroups,
                        value: field.value,
                      })
                    )
                  }
                />
              </strong>
            )}

            {optionGroups.map((optionGroup, index) => (
              <Fragment key={optionGroup.label}>
                <OptionGroup
                  name={name}
                  optionGroup={optionGroup}
                  stackedOptions={optionGroup.label ? true : stackedOptions}
                  field={field}
                  showError={showError}
                  id={index === 0 ? fieldId : undefined}
                  {...inputProps}
                />
              </Fragment>
            ))}
          </Stack>
        </div>
      ) : (
        <EmptyValue label="No Options Available" />
      )}

      {hint && (
        <Hint disabled={props.disabled} mode="formInput" children={hint} />
      )}

      {showError && error && (
        <ErrorMessage
          data-testid={`${name}Error`}
          error={error}
          label={label}
        />
      )}
    </FieldItem>
  );

  return hideLabel ? (
    fieldItem
  ) : (
    <FieldLayout stacked={stacked}>
      <FieldLabel
        name={name}
        displayLabel={displayLabel}
        fieldId={fieldId}
        info={info}
        showError={showError}
      />

      {fieldItem}
    </FieldLayout>
  );
};

export function normalizeGroupOptions<Value extends string>(
  value: Value[],
  unsortedOptions: FormOption<Value>[] | GroupedFormOption<Value>[],
  sortBy: SortByOptions = 'label'
) {
  const unsortedOptionGroups = flattenGroupOptions(unsortedOptions);

  const optionGroups = applyGroupSortBy(sortBy, unsortedOptionGroups).map(
    (optionGroup) => ({
      ...optionGroup,
      options: applySortBy(sortBy, optionGroup.options),
    })
  );

  const checks = optionGroups.map((optionGroup) =>
    optionGroup.options
      .filter((option) => !optionGroup.disabled && !option.disabled)
      .map((option) => value.some((value) => value === option.value))
  );

  const allChecked = flattenDeep(checks).every((check) => check);

  const someChecked = flattenDeep(checks).some((check) => check);

  const toggleAllChecked = allChecked
    ? true
    : someChecked
      ? ('mixed' as const)
      : false;

  return {
    optionGroups,
    allChecked,
    toggleAllChecked,
  };
}

export function toggleAllOptions<Value extends string>({
  toggleAllChecked,
  optionGroups,
  value,
}: {
  toggleAllChecked: boolean | 'mixed';
  optionGroups: GroupedFormOption<Value>[];
  value: Value[];
}) {
  return flattenDeep(
    optionGroups.map((optionGroup) =>
      optionGroup.options
        .filter((option) => {
          const keepDisabledAndSelected =
            (optionGroup.disabled || option.disabled) &&
            value.includes(option.value);

          const selectIfNeededAndPossible =
            toggleAllChecked !== true &&
            !optionGroup.disabled &&
            !option.disabled;

          return keepDisabledAndSelected || selectIfNeededAndPossible;
        })
        .map((option) => option.value)
    )
  );
}

function flattenGroupOptions<Value extends string | string>(
  options: FormOption<Value>[] | GroupedFormOption<Value>[]
): GroupedFormOption<Value>[] {
  if (!options.length) return [{ label: '', options: [] }];

  if ('options' in options[0]) return options as GroupedFormOption<Value>[];

  return [{ label: '', options }] as GroupedFormOption<Value>[];
}

export function getGroupToggleState<Value extends string>({
  value,
  optionGroup,
}: {
  value: Value[];
  optionGroup: GroupedFormOption<Value>;
}) {
  const childChecked = optionGroup.options.map((option) => {
    return Boolean(value.find((value) => value === option.value));
  });

  const enabledChildChecked = optionGroup.options
    .filter((option) => (optionGroup.disabled ? true : !option.disabled))
    .map((option) => {
      return Boolean(value.find((value) => value === option.value));
    });

  const allChildChecks = enabledChildChecked.every((check) => check);
  const someChildChecks = enabledChildChecked.some((check) => check);

  const groupToggleAllChecked = allChildChecks
    ? true
    : someChildChecks
      ? ('mixed' as const)
      : false;

  return { groupToggleAllChecked, childChecked };
}

export function toggleAllGroupOptions<Value extends string>({
  groupToggleAllChecked,
  optionGroup,
  value,
}: {
  groupToggleAllChecked: boolean | 'mixed';
  optionGroup: GroupedFormOption<Value>;
  value: Value[];
}) {
  const otherValues = value.filter(
    (value) => !optionGroup.options.some((option) => option.value === value)
  );

  const groupValues = optionGroup.options
    .filter((option) => {
      const keepDisabledAndSelected =
        (optionGroup.disabled || option.disabled) &&
        value.includes(option.value);

      const selectIfNeededAndPossible =
        groupToggleAllChecked !== true &&
        !optionGroup.disabled &&
        !option.disabled;

      return keepDisabledAndSelected || selectIfNeededAndPossible;
    })
    .map((option) => option.value);

  return [...otherValues, ...groupValues];
}

type OptionGroupProps = {
  name: string;
  optionGroup: GroupedFormOption<string>;
  stackedOptions?: boolean;
  field: FieldInputProps<string[]>;
  showError: boolean;
  disabledMessage?: ReactNode;
} & InputHTMLAttributes<HTMLInputElement>;

const OptionGroup: FC<OptionGroupProps> = (props) => {
  const {
    optionGroup: {
      options,
      label: label,
      disabled: groupDisabled,
      disabledMessage: groupDisabledMessage,
      hint,
      info,
    },
    name,
    stackedOptions = false,
    field,
    showError,
    disabledMessage,
    ...inputProps
  } = props;

  const { setFieldValue } = useFormikContext<any>();

  const { groupToggleAllChecked, childChecked } = getGroupToggleState({
    value: field.value,
    optionGroup: props.optionGroup,
  });

  return (
    <div>
      <Stack
        gap={stackedOptions ? 0.5 : 3.5} // same as radios
        inline
        alignItems="start"
        flow={stackedOptions ? 'row' : 'column'}
      >
        {label && (
          <div>
            <div>
              <strong>
                <SimpleCheckbox
                  label={label}
                  data-testid={label}
                  checked={groupToggleAllChecked}
                  onChange={() =>
                    setFieldValue(
                      field.name,
                      toggleAllGroupOptions({
                        groupToggleAllChecked,
                        optionGroup: props.optionGroup,
                        value: field.value,
                      })
                    )
                  }
                  showError={showError}
                  disabled={inputProps.disabled || groupDisabled}
                  disabledMessage={
                    inputProps.disabled && disabledMessage
                      ? disabledMessage
                      : groupDisabled && groupDisabledMessage
                        ? groupDisabledMessage
                        : undefined
                  }
                />
              </strong>
              {info && (
                <>
                  {' '}
                  <Tooltip content={info}>
                    {(targetProps) => (
                      <FontAwesomeIcon {...targetProps} icon={faInfoCircle} />
                    )}
                  </Tooltip>
                </>
              )}
            </div>

            {hint && (
              <Hint
                disabled={inputProps.disabled || groupDisabled}
                mode="formInput"
                children={hint}
              />
            )}
          </div>
        )}

        {options.map((option, index) => (
          <div key={option.value}>
            <div>
              <WrappedCheckbox
                option={option}
                checked={childChecked[index]}
                field={field}
                showError={showError}
                data-testid={`${field.name}:${option.value}`}
                {...inputProps}
                disabled={
                  inputProps.disabled || groupDisabled || option.disabled
                }
                disabledMessage={
                  inputProps.disabled && disabledMessage
                    ? disabledMessage
                    : groupDisabled && groupDisabledMessage
                      ? groupDisabledMessage
                      : option.disabled && option.disabledMessage
                        ? option.disabledMessage
                        : undefined
                }
              />

              {option.info && (
                <>
                  {' '}
                  <Tooltip content={option.info}>
                    {(targetProps) => (
                      <FontAwesomeIcon {...targetProps} icon={faInfoCircle} />
                    )}
                  </Tooltip>
                </>
              )}
            </div>

            {option.hint && !option.hideHint && (
              <Hint
                children={option.hint}
                mode="formInput"
                disabled={
                  inputProps.disabled || groupDisabled || option.disabled
                }
                data-testid={`${field.name}:${option.value}Hint`}
              />
            )}
          </div>
        ))}
      </Stack>
    </div>
  );
};

type WrappedCheckboxProps = {
  option: FormOption<string>;
  checked?: boolean;
  field: FieldInputProps<string[]>;
  showError: boolean;
  disabledMessage?: ReactNode;
} & InputHTMLAttributes<HTMLInputElement>;

const WrappedCheckbox: FC<WrappedCheckboxProps> = ({
  option,
  checked,
  field,
  showError,
  disabledMessage,
  ...inputProps
}) => {
  const { setFieldValue } = useFormikContext<any>();
  return (
    <SimpleCheckbox
      label={option.label}
      {...field}
      {...inputProps}
      data-value={option.value}
      value={option.value}
      checked={checked}
      onChange={() => {
        if (!checked) {
          setFieldValue(field.name, [...field.value, option.value]);
        } else {
          const index = field.value.findIndex(
            (value) => value === option.value
          );
          setFieldValue(field.name, [
            ...field.value.slice(0, index),
            ...field.value.slice(index + 1),
          ]);
        }
      }}
      showError={showError}
      disabledMessage={disabledMessage}
    />
  );
};
