import { z } from 'zod';
import type {
  InvoicesStatus,
  InvoiceType,
  MonthlyStatementType,
  ShipperInvoicesSortBy,
} from 'src/apis/capacity-management/types';
import { PublishStatus } from 'src/apis/capacity-management/types';
import {
  descSortDirectionSchema,
  formOptionSchema,
  getPaginationSchema,
} from 'src/components/form/zod-schemas';
import {
  preprocessAsArray,
  preprocessAsBoolean,
} from 'src/components/form/zod-utilities';
import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import { contractOptionSchema } from 'src/pages/reporting/monthly-statements/corrections/contracts-searchable-multi-select';
import { shipperOptionSchema } from 'src/pages/reporting/shipper-transactions/shipper-searchable-multi-select';
import { labelsToOptions } from 'src/utils/labels-to-options';
import { labelsToValues } from 'src/utils/labels-to-values';

const sortByLabels: Record<ShipperInvoicesSortBy, string> = {
  CREATION_DATE: 'Creation Date',
  INVOICE_NUMBER: 'Invoice Number',
  MONTHLY_STATEMENT_ID: 'Monthly Statement ID',
  DUE_DATE: 'Due Date',
  SHIPPER_NAME: 'Shipper Name',
  AMOUNT: 'Amount',
  STATUS: 'Status',
};

const sortByValue = labelsToValues(sortByLabels);

export const invoicesStatusLabels: Record<InvoicesStatus, string> = {
  OPEN: 'Open',
  CANCELLED: 'Cancelled',
  PAID: 'Paid',
  WITHDRAWN: 'Withdrawn',
};

export const invoicesStatusOptions = labelsToOptions(invoicesStatusLabels);
export const invoicesStatusValues = labelsToValues(invoicesStatusLabels);

export const invoicesTypeLabels: Record<InvoiceType, string> = {
  INVOICE: 'Invoice',
  CREDIT_NOTE: 'Credit Note',
  DEBIT_NOTE: 'Debit Note',
  ARREARS: 'Arrears',
};

export const invoicesTypeOptions = labelsToOptions(invoicesTypeLabels);
export const invoicesTypeValues = labelsToValues(invoicesTypeLabels);

export const monthlyStatementTypeLabels: Record<MonthlyStatementType, string> =
  {
    CAPACITY_FEE: 'Capacity Fee Invoice',
    COMMODITY_FEE: 'Commodity Fee Invoice',
  };

export const monthlyStatementTypeOptions = labelsToOptions(
  monthlyStatementTypeLabels
);
export const monthlyStatementTypeValues = labelsToValues(
  monthlyStatementTypeLabels
);

export const publishStatusLabels: Record<PublishStatus, string> = {
  SHOW_ALL: 'Show All',
  ON_HOLD: 'On Hold',
  PUBLISHED: 'Published',
};

export const publishStatusOptions = labelsToOptions(publishStatusLabels);
export const publishStatusValues = labelsToValues(publishStatusLabels);

const invoicesPageParamsSchema = z
  .object({
    sortBy: z.enum(sortByValue).default('CREATION_DATE'),
    sortDirection: descSortDirectionSchema,
    creationDateStart: z.string().nullable().default(null),
    creationDateEnd: z.string().nullable().default(null),
    invoiceNumber: z.string().nullable().default(null),
    dueDateStart: z.string().nullable().default(null),
    dueDateEnd: z.string().nullable().default(null),
    shipperId: z.preprocess(
      preprocessAsArray,
      z.array(shipperOptionSchema).default([])
    ),
    contractId: z.preprocess(
      preprocessAsArray,
      z.array(contractOptionSchema).default([])
    ),
    monthlyStatementId: z.preprocess(
      preprocessAsArray,
      z.array(formOptionSchema).default([])
    ),
    status: z.preprocess(
      preprocessAsArray,
      z.array(z.enum(invoicesStatusValues)).default([])
    ),
    invoiceType: z.preprocess(
      preprocessAsArray,
      z.array(z.enum(invoicesTypeValues)).default([])
    ),
    monthlyStatementType: z.preprocess(
      preprocessAsArray,
      z.array(z.enum(monthlyStatementTypeValues)).default([])
    ),
    publishStatus: z.enum(publishStatusValues).default(PublishStatus.SHOW_ALL),
    onlyOverdue: z.preprocess(preprocessAsBoolean, z.boolean().default(false)),
  })
  .merge(getPaginationSchema());

export function usePageParams() {
  return usePageParamsFromSchema(invoicesPageParamsSchema, {
    filters: {
      creationDateStart: { label: 'Created on' },
      creationDateEnd: { label: 'Created on' },
      invoiceNumber: { label: 'Invoice Number' },
      dueDateStart: { label: 'Due Date' },
      dueDateEnd: { label: 'Due Date' },
      shipperId: { label: 'Shipper' },
      contractId: { label: 'Contract' },
      monthlyStatementId: { label: 'Monthly Statement ID' },
      status: { label: 'Invoice Status' },
      invoiceType: { label: 'Invoice Type' },
      monthlyStatementType: { label: 'Monthly Statement Type' },
      publishStatus: { label: 'Publication Status' },
      onlyOverdue: { label: 'Overdue Status' },
    },
  });
}

export type PageParams = ReturnType<typeof usePageParams>;
