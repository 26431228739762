import type {
  ContractState,
  TenderDuration,
} from 'src/apis/aggregate-eu/types';
import { labelsToOptions } from 'src/utils/labels-to-options';
import { labelsToValues } from 'src/utils/labels-to-values';

export const tenderDurationLabels: Record<TenderDuration, string> = {
  SINGLE_MONTH: 'REPowerEU Short Term',
  SIX_MONTH: 'REPowerEU Mid Term',
  MIXED_MONTH: 'REPowerEU',
};

export const tenderDurationValues = labelsToValues(tenderDurationLabels);

export const tenderDurationOptions = labelsToOptions(tenderDurationLabels);

export const contractStatusLabels: Record<ContractState, string> = {
  CONTRACTED: 'Contracted',
  IN_NEGOTIATION: 'In Negotiation',
  NEGOTIATION_UNSUCCESSFUL: 'Negotiation Unsuccessful',
  WITHDRAWN: 'Withdrawn',
};

export const contractStatusTypeValues = labelsToValues(contractStatusLabels);

export const contractStatusOptions = labelsToOptions(contractStatusLabels);

export const contractStatusValues = labelsToValues(contractStatusLabels);
