import type { FC } from 'react';
import { lazy } from 'react';
import { useParams } from 'react-router-dom';
import type { CmNetworkPointRequest } from 'src/apis/capacity-management/use-cm-network-point';
import { useInternalBooking } from 'src/apis/capacity-management/use-internal-booking';
import { useInternalBookings } from 'src/apis/capacity-management/use-internal-bookings';
import { useTitle } from 'src/hooks/use-title';

const Page = lazy(() => import('./page'));

type Props = {
  cmNetworkPoint: CmNetworkPointRequest;
};

const EditInternalBooking: FC<Props> = ({ cmNetworkPoint }) => {
  const { oldNetworkPointId, bookingId } = useParams<{
    oldNetworkPointId: string;
    bookingId: string;
  }>();

  const internalBookings = useInternalBookings({ cmNetworkPoint });

  const internalBooking = useInternalBooking({
    internalBookings,
    oldNetworkPointId,
    bookingId,
  });

  useTitle('Edit Internal Booking');

  return (
    <Page cmNetworkPoint={cmNetworkPoint} internalBooking={internalBooking} />
  );
};

export default EditInternalBooking;
