import { useEffect } from 'react';
import type { MarketInterestResponse } from 'src/apis/lng-marketing/types';
import type { LngOfferDetailsRequest } from 'src/apis/lng-marketing/use-lng-offer-details';
import { useAxios } from 'src/hooks/use-axios';

type HookOptions = {
  lngOfferDetails?: LngOfferDetailsRequest;
};

type ExecuteOptions = {
  url: string;
};

export function useOptionalLngOfferMarketInterest({
  lngOfferDetails,
}: HookOptions) {
  const marketInterest = useAxios(
    (axios, baseConfig, { url }: ExecuteOptions) => {
      return axios.request<MarketInterestResponse>({
        ...baseConfig,
        url,
      });
    },
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!lngOfferDetails?.response) return;
    if (!lngOfferDetails.response.data._links.getMarketInterest) return;
    marketInterest.execute({
      url: lngOfferDetails.response.data._links.getMarketInterest.href,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lngOfferDetails?.response]);

  // not available on stage
  if (!lngOfferDetails) return;

  // not allowed to get MarketInterest
  if (
    lngOfferDetails.response &&
    !lngOfferDetails.response.data._links.getMarketInterest
  )
    return;

  return marketInterest;
}

export type LngOfferMarketInterestRequest = ReturnType<
  typeof useOptionalLngOfferMarketInterest
>;
