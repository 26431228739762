import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import { getOperatorsOverviewPageParamsForOperatorsListSchema } from 'src/pages/operators/assignments/overview/schema';

export function useOperatorsOverviewPageParams() {
  return usePageParamsFromSchema(
    getOperatorsOverviewPageParamsForOperatorsListSchema(),
    {
      filters: {
        operatorName: { label: 'Operator' },
        organisationAssignmentStatus: { label: 'Organisation Assignment' },
        tradingMarket: { label: 'Market' },
        userName: { label: 'User Name' },
        userEmail: { label: 'User Email' },
        userAssignmentStatus: { label: 'User Assignment' },
      },
    }
  );
}

export type OperatorsOverviewPageParams = ReturnType<
  typeof useOperatorsOverviewPageParams
>;

export type SearchParams = OperatorsOverviewPageParams['value'];
