import { useEffect } from 'react';
import type { Product } from 'src/apis/monolith/types';
import { isServerError, useAxios } from 'src/hooks/use-axios';

type Options = {
  rawDataId: number | string;
};

export function useProduct({ rawDataId }: Options) {
  const product = useAxios(
    (axios, baseConfig, id: number | string) =>
      axios.request<Product>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/uploads/${id}`,
        method: 'GET',
        validateStatus(status) {
          // if we get 204 the data of the uploaded product is no longer available
          // this can be treated like a "410 Gone" error
          if (status === 204) return false;

          return status >= 200 && status < 300; // default
        },
      }),
    {
      neededOnPageLoad: true,
      onError(error) {
        if (isServerError(error, 204)) {
          return error;
        } else {
          throw error;
        }
      },
    }
  );

  useEffect(() => {
    product.execute(rawDataId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawDataId]);

  return product;
}

export type ProductRequest = ReturnType<typeof useProduct>;
