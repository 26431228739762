import type { Dictionary } from 'lodash';
import { mapKeys } from 'lodash';
import type { FC } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import type { NetworkPointDetail } from 'src/apis/monolith/types';
import { PageSpinner } from 'src/components/spinner-container';
import type { Successful } from 'src/hooks/use-axios';
import type { NetworkPointDetailRequest } from 'src/hooks/use-network-point-by-uuid';
import { useNetworkPointByUuid } from 'src/hooks/use-network-point-by-uuid';
import type { BalancingGroupSuggestion } from 'src/pages/reporting/balancing-group-allocations/balancing-group-searchable-multi-select';
import type { PageParams } from 'src/pages/reporting/balancing-group-allocations/use-page-params';
import { usePageParams } from 'src/pages/reporting/balancing-group-allocations/use-page-params';
import type { NetworkPointOption } from 'src/pages/reporting/my-transactions/network-point-searchable-multi-select';

type BgaRouteParams = {
  nwpUuid: string;
  balancingGroupId: string;
  balancingGroupName: string;
  allocationIntervalIntersectionStart: string;
  allocationIntervalIntersectionEnd: string;
};

const bgaRedirectSearchString = (
  params: BgaRouteParams,
  nwpResponse: NetworkPointDetail,
  paramLabels: Dictionary<string>
) => {
  const { name, marketArea0, tso0ShortName, direction } = nwpResponse;
  const {
    balancingGroupId: bgId,
    balancingGroupName: bgName,
    nwpUuid: uuid,
    allocationIntervalIntersectionStart: startDate,
    allocationIntervalIntersectionEnd: endDate,
  } = params;

  const nwpData = {
    value: uuid,
    label: name,
    entry:
      direction === 'ENTRY'
        ? { marketArea: marketArea0, tsoShortName: tso0ShortName }
        : undefined,
    exit:
      direction === 'EXIT'
        ? { marketArea: marketArea0, tsoShortName: tso0ShortName }
        : undefined,
  } as NetworkPointOption;

  const nwp = JSON.stringify(nwpData);

  const bgData: BalancingGroupSuggestion = {
    value: bgId,
    label: bgName,
  };
  const balancingGroupId = JSON.stringify(bgData);

  return `?${`${paramLabels.networkPointId}=${nwp}`}&${
    paramLabels.allocationIntervalIntersectionFilterStart
  }=${startDate}&${
    paramLabels.allocationIntervalIntersectionFilterEnd
  }=${endDate}&${paramLabels.balancingGroupId}=${balancingGroupId}`;
};

export const BgaRedirect: FC = () => {
  const params = useParams<BgaRouteParams>();

  const networkPointRequest = useNetworkPointByUuid({ uuid: params.nwpUuid });

  const pageParams = usePageParams();

  if (!networkPointRequest.response) return <PageSpinner />;

  if (networkPointRequest.error)
    return <Navigate replace to="/reporting/balancing-group-allocations" />;

  return (
    <LoadParams
      nwpResponse={networkPointRequest}
      params={params}
      pageParams={pageParams}
    />
  );
};

const LoadParams: FC<{
  pageParams: PageParams;
  params: BgaRouteParams;
  nwpResponse: Successful<NetworkPointDetailRequest>;
}> = ({ pageParams, params, nwpResponse }) => {
  // typed to the possible keys, renaming one of the parameters will cause compilation error here
  const paramsCollection: (keyof typeof pageParams.filter)[] = [
    'networkPointId',
    'allocationIntervalIntersectionFilterStart',
    'allocationIntervalIntersectionFilterEnd',
    'balancingGroupId',
  ];

  const paramLabels = mapKeys(paramsCollection, (value) => value);

  return (
    <Navigate
      replace
      to={{
        pathname: '/reporting/balancing-group-allocations',
        search: bgaRedirectSearchString(
          params,
          nwpResponse.response.data,
          paramLabels
        ),
      }}
    />
  );
};
