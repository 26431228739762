import type { FC, HTMLAttributes } from 'react';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import type { ButtonProps } from 'src/components/buttons-and-actions/button';
import { Button } from 'src/components/buttons-and-actions/button';
import { Card } from 'src/components/data-display/card';
import { EmptyValue } from 'src/components/data-display/empty-value';
import { Stack } from 'src/components/layout/stack';
import { useBreakpoints } from 'src/hooks/use-breakpoints';
import type { StrictOmit } from 'src/utils/utility-types';

type WithButton = {
  label: string;
  button: StrictOmit<ButtonProps, 'onClick'> & {
    onClick: () => void;
  };
  link?: undefined;
};

type WithLink = {
  label: string;
  button?: undefined;
  link: LinkProps;
};

type Regular = {
  label: string;
  button?: undefined;
  link?: undefined;
};

export type EmptyCardProps = (WithButton | WithLink | Regular) &
  HTMLAttributes<HTMLDivElement>;

export const EmptyCard: FC<EmptyCardProps> = ({
  label,
  button,
  link,
  ...rest
}) => {
  const { minDesktop } = useBreakpoints();
  return (
    <Card data-testid="empty-card" {...rest}>
      <Stack
        flow={minDesktop ? 'column' : 'row'}
        gap={1}
        justifyContent="center"
        textAlign={minDesktop ? 'start' : 'center'}
        justifyItems={minDesktop ? 'legacy' : 'center'}
      >
        <EmptyValue label={label} />

        {button && (
          <Button
            data-testid="empty-action-button"
            mode="secondary"
            size="small"
            children="Reset Filters"
            {...button}
          />
        )}

        {link && <Link data-testid="empty-action-link" {...link} />}
      </Stack>
    </Card>
  );
};
