import { useEffect } from 'react';
import type { NetworkPointDetail } from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';

export function useNetworkPointByUuid(uuid: string) {
  const request = useManualNetworkPointByUuid();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => request.execute(uuid), [uuid]);

  return request;
}

export function useManualNetworkPointByUuid() {
  return useAxios(
    (axios, baseConfig, uuid: string) =>
      axios.request<NetworkPointDetail>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/networkPoints/by-uuid/${uuid}`,
      }),
    { neededOnPageLoad: false }
  );
}
