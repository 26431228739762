import {
  faExclamationCircle,
  faInfoCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FC, HTMLAttributes, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { Stack } from 'src/components/layout/stack';
import { Heading } from 'src/components/text/heading';
import { Colors } from 'src/styles';

type AlertProps = {
  type: 'info' | 'warning' | 'error';
  title?: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const themes = {
  info: css`
    background-color: ${Colors.brandLight4};
    color: ${Colors.brand};
  `,
  warning: css`
    background-color: #fcf8e3;
    color: #8a6d3b;
  `,
  error: css`
    background-color: ${Colors.errorLighter};
    color: ${Colors.error};
  `,
};

const StyledAlert = styled.div<{ type: 'info' | 'warning' | 'error' }>`
  padding: 1.5rem;
  ${({ type }) => themes[type]};
`;

const label = {
  warning: 'Warning',
  error: 'Error',
  info: 'Info',
};

export const Alert: FC<AlertProps> = ({ type, children, title, ...rest }) => {
  return (
    <StyledAlert type={type} {...rest}>
      {title ? (
        <Stack gap={1}>
          <Heading mode="card">
            <FontAwesomeIcon
              icon={type === 'info' ? faInfoCircle : faExclamationCircle}
              aria-hidden
            />{' '}
            {title}
          </Heading>
          <div>{children}</div>
        </Stack>
      ) : (
        <>
          <FontAwesomeIcon
            icon={type === 'info' ? faInfoCircle : faExclamationCircle}
            aria-hidden
          />{' '}
          <strong>{label[type]}</strong> {children}
        </>
      )}
    </StyledAlert>
  );
};
