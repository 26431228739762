import { useAxios } from 'src/hooks/use-axios';

export function useWithdrawOrganisationalAssignment() {
  return useAxios(
    (axios, baseConfig, url: string) =>
      axios.request({
        ...baseConfig,
        method: 'DELETE',
        url,
      }),
    {
      neededOnPageLoad: false,
    }
  );
}

export type WithdrawOrganisationalAssignmentsRequest = ReturnType<
  typeof useWithdrawOrganisationalAssignment
>;
