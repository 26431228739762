import type { FC } from 'react';
import { lazy } from 'react';
import { Card } from 'src/components/data-display/card';
import { PageHeader } from 'src/components/layout/page-header';
import { Stack } from 'src/components/layout/stack';
import { PageSpinner } from 'src/components/spinner-container';
import type { Successful } from 'src/hooks/use-axios';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { useReferrer } from 'src/hooks/use-referrer';
import { useTitle } from 'src/hooks/use-title';
import { useAssignments } from 'src/pages/snam-bid-collector/bidding/use-assignments';
import type { LocationsRequest } from 'src/pages/snam-bid-collector/bidding/use-locations';
import { useLocations } from 'src/pages/snam-bid-collector/bidding/use-locations';
import type { BidCollectorRootRequest } from 'src/pages/snam-bid-collector/use-bid-collector';
import { useBidCollectorRoot } from 'src/pages/snam-bid-collector/use-bid-collector';

const Page = lazy(() => import('./page'));

export const SnamBidCollectorBiddingPage = () => {
  const bidCollectorRoot = useBidCollectorRoot();
  useTitle('Gela and Mazara (SNAM) Auctions');
  const referrer = useReferrer({
    label: 'Auctions',
    location: '/transport/auctions',
  });

  if (!bidCollectorRoot.response) return <PageSpinner />;

  if (
    !bidCollectorRoot.response.data.enabled ||
    !bidCollectorRoot.response.data.nonEmpty
  )
    return (
      <Stack gap={1}>
        <PageHeader
          title="Gela and Mazara (SNAM) Auctions"
          backLink={referrer.backLink}
        />
        <Card>
          <p>
            Bidding for Gela and Mazara (SNAM) Auctions is currently not
            available.
          </p>
        </Card>
      </Stack>
    );

  return <LoadLocations bidCollectorRoot={bidCollectorRoot} />;
};

type LoadLocationsProps = {
  bidCollectorRoot: Successful<BidCollectorRootRequest>;
};

const LoadLocations: FC<LoadLocationsProps> = ({ bidCollectorRoot }) => {
  const locations = useLocations(bidCollectorRoot);
  const monolithUser = useOptionalAuthenticatedMonolithUser();

  if (!locations.response) return <PageSpinner />;

  if (!monolithUser)
    return <Page bidCollectorRoot={bidCollectorRoot} locations={locations} />;

  return (
    <LoadAssignments
      bidCollectorRoot={bidCollectorRoot}
      locations={locations}
    />
  );
};

type LoadAssignmentsProps = {
  bidCollectorRoot: Successful<BidCollectorRootRequest>;
  locations: Successful<LocationsRequest>;
};

const LoadAssignments: FC<LoadAssignmentsProps> = ({
  bidCollectorRoot,
  locations,
}) => {
  const assignments = useAssignments(bidCollectorRoot);

  if (!assignments.response) return <PageSpinner />;
  return (
    <Page
      bidCollectorRoot={bidCollectorRoot}
      assignments={assignments}
      locations={locations}
    />
  );
};
