import type { FC } from 'react';
import { lazy } from 'react';
import { useParams } from 'react-router-dom';
import { useMemoOne } from 'use-memo-one';
import type { AffiliateTransactionsParams } from 'src/apis/capacity-management/types';
import type { CapacityManagementRootRequest } from 'src/apis/capacity-management/use-capacity-management-root';
import type { AuctionRequest } from 'src/apis/monolith/use-auction';
import { useAuction } from 'src/apis/monolith/use-auction';
import type { CompetitionsRequest } from 'src/apis/monolith/use-competition-list';
import { useCompetitions } from 'src/apis/monolith/use-competition-list';
import { useRemitReportingEntrypoint } from 'src/apis/remit-reporting/use-remit-reporting-entrypoint';
import { getPaginationSchema } from 'src/components/form/zod-schemas';
import { PageSpinner } from 'src/components/spinner-container';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import { useTitle } from 'src/hooks/use-title';
import type { AffiliateTransactionsRequest } from 'src/pages/reporting/auctions/details/use-affiliate-transactions';
import { useAffiliateTransactions } from 'src/pages/reporting/auctions/details/use-affiliate-transactions';
import { usePrimaryRemitReportHistory } from 'src/pages/reporting/remit-reports/overview/use-primary-remit-report-history';
import { CapacityManagement } from 'src/pages/transport/network-points/details/availability-and-price-data/overview/capacity-management';

const Page = lazy(() => import('./page'));

export const ReportingAuction: FC = () => {
  const { auctionId } = useParams<{ auctionId: string }>();
  useTitle(`Auction ID ${auctionId}`);
  const auction = useAuction({ auctionId });
  const competitions = useCompetitions({ auctionId });
  const monolithUser = useOptionalAuthenticatedMonolithUser();
  const remitReportingEntrypoint = useRemitReportingEntrypoint();

  if (!remitReportingEntrypoint.loaded) {
    return <PageSpinner />;
  }

  const loadRemitReportHistory = Boolean(
    remitReportingEntrypoint.response?.data._links?.['primary-report-history']
  );

  if (!monolithUser)
    return loadRemitReportHistory ? (
      <ReportingAuctionWithRemitHistory
        auction={auction}
        competitions={competitions}
      />
    ) : (
      <Page auction={auction} competitions={competitions} />
    );

  return (
    <CapacityManagement>
      {(capacityManagementRoot) =>
        capacityManagementRoot ? (
          <LoadCapacityManagementData
            auction={auction}
            competitions={competitions}
            capacityManagementRoot={capacityManagementRoot}
            loadRemitReportHistory={loadRemitReportHistory}
          />
        ) : loadRemitReportHistory ? (
          <ReportingAuctionWithRemitHistory
            auction={auction}
            competitions={competitions}
          />
        ) : (
          <Page auction={auction} competitions={competitions} />
        )
      }
    </CapacityManagement>
  );
};

type LoadCapacityManagementDataProps = {
  auction: AuctionRequest;
  competitions: CompetitionsRequest;
  capacityManagementRoot: CapacityManagementRootRequest;
  loadRemitReportHistory: boolean;
};

const LoadCapacityManagementData: FC<LoadCapacityManagementDataProps> = ({
  auction,
  competitions,
  capacityManagementRoot,
  loadRemitReportHistory,
}) => {
  const { auctionId } = useParams<{ auctionId: string }>();
  const paginationSchema = getPaginationSchema({ pageSize: 12 });
  const pageParams = usePageParamsFromSchema(paginationSchema);

  const params = useMemoOne<AffiliateTransactionsParams>(
    () => ({
      assignedAuctionId: auctionId,
      limit: pageParams.value.pageSize,
      offset: pageParams.value.start,
    }),
    [auctionId, pageParams.value]
  );
  const affiliateTransactions = useAffiliateTransactions({
    capacityManagementRoot,
    params,
  });

  if (!affiliateTransactions.loaded) {
    return <PageSpinner />;
  }

  const usesAffiliateTransactions = Boolean(
    capacityManagementRoot.response?.data._links?.affiliateTransactions
  );

  return loadRemitReportHistory ? (
    <ReportingAuctionWithRemitHistory
      auction={auction}
      competitions={competitions}
      affiliateTransactions={affiliateTransactions}
      usesAffiliateTransactions={usesAffiliateTransactions}
    />
  ) : (
    <Page
      auction={auction}
      competitions={competitions}
      affiliateTransactions={affiliateTransactions}
      usesAffiliateTransactions={usesAffiliateTransactions}
    />
  );
};

const ReportingAuctionWithRemitHistory: FC<{
  auction: AuctionRequest;
  competitions: CompetitionsRequest;
  affiliateTransactions?: AffiliateTransactionsRequest;
  usesAffiliateTransactions?: boolean;
}> = ({
  auction,
  competitions,
  affiliateTransactions,
  usesAffiliateTransactions,
}) => {
  const { auctionId } = useParams<{ auctionId: string }>();
  const remitHistory = usePrimaryRemitReportHistory(auctionId);

  if (!remitHistory.loaded) {
    return <PageSpinner />;
  }

  return (
    <Page
      auction={auction}
      competitions={competitions}
      affiliateTransactions={affiliateTransactions}
      usesAffiliateTransactions={usesAffiliateTransactions}
      remitHistory={remitHistory.response?.data}
    />
  );
};
