import type {
  TransactionStatus,
  TransactionType,
} from 'src/apis/capacity-management/types';
import { labelsToOptions } from 'src/utils/labels-to-options';
import { labelsToValues } from 'src/utils/labels-to-values';

export const transactionTypeLabels: Record<TransactionType, string> = {
  AUCTION: 'Auction',
  SURRENDER: 'Surrender',
  CONVERSION: 'Conversion',
  ASSIGNMENT_BUY: 'Assignment (Buy)',
  TRANSFER_OF_USE_BUY: 'Transfer of Use (Buy)',
  ASSIGNMENT_SELL: 'Assignment (Sell)',
  TRANSFER_OF_USE_SELL: 'Transfer of Use (Sell)',
  MULTI_YEAR_TRANSPORT_ALLOCATION: 'Multi-Year Transport Allocation',
  DIRECT_FCFS: 'FCFS',
  FCFS: 'FCFS',
};

export const transactionTypeValues = labelsToValues(transactionTypeLabels);

export const getTransactionTypeOptions = ({
  hasMultiYearTransactions,
  isV3,
}: {
  hasMultiYearTransactions: boolean;
  isV3: boolean;
}) =>
  labelsToOptions(transactionTypeLabels).filter((option) => {
    // merging 'DIRECT_FCFS', 'FCFS' into one filter, should only show one FCFS option
    if (!isV3 && option.value === 'FCFS') return false;
    if (isV3 && option.value === 'DIRECT_FCFS') return false;
    if (
      !hasMultiYearTransactions &&
      option.value === 'MULTI_YEAR_TRANSPORT_ALLOCATION'
    )
      return false;
    return true;
  });

export const transactionStatusLabels: Record<TransactionStatus, string> = {
  PENDING: 'Pending',
  SUCCESSFUL: 'Successful',
  UNSUCCESSFUL: 'Unsuccessful',
  INVALIDATED: 'Invalidated',
};

export const transactionStatusOptions = labelsToOptions(
  transactionStatusLabels
);

export const transactionStatusValues = labelsToValues(transactionStatusLabels);
