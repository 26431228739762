import type { FC, ReactNode } from 'react';
import { useMemo } from 'react';
import type { OrganisationRole } from 'src/apis/monolith/types';
import { useAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { Forbidden } from 'src/utils/errors';

/**
 * @deprecated This component was introduced many moons ago, because the old monolith APIs don't always return proper 403 error codes.
 * By now you should not use it anymore and rather handle the forbidden errors in the corresponding request hooks.
 */
export const RoleGateway: FC<{
  allowed: OrganisationRole[];
  children: ReactNode;
}> = ({ children, allowed }) => {
  const monolithUser = useAuthenticatedMonolithUser();
  const hasValidRole = useMemo(
    () =>
      allowed.some(
        (role) => role === monolithUser.currentlyActingWithOrganisationRole
      ),
    [allowed, monolithUser.currentlyActingWithOrganisationRole]
  );

  if (hasValidRole) {
    return <>{children}</>;
  } else {
    throw new Forbidden();
  }
};
