import type { FC } from 'react';
import { lazy } from 'react';
import { useMemoOne } from 'use-memo-one';
import type {
  TransactionType,
  TransactionsParams,
} from 'src/apis/capacity-management/types';
import { useCapacityCategories } from 'src/apis/capacity-management/use-capacity-categories';
import { useCapacityManagementRoot } from 'src/apis/capacity-management/use-capacity-management-root';
import { useMarketAreas } from 'src/apis/capacity-management/use-market-areas';
import { useMyTransactions } from 'src/apis/capacity-management/use-my-transactions';
import { useGetSurrenderActions } from 'src/apis/surrender-service/use-get-surrender-actions';
import { useSurrenderRoot } from 'src/apis/surrender-service/use-surrender-root';
import { PageHeader } from 'src/components/layout/page-header';
import { Stack } from 'src/components/layout/stack';
import { PageSpinner } from 'src/components/spinner-container';
import { useMyTransactionsPageParams } from 'src/pages/reporting/my-transactions/use-page-params';

const MyTransactionsPage = lazy(() => import('./page'));

export const ReportingTransactions: FC = () => {
  const capacityManagementRoot = useCapacityManagementRoot();
  const surrenderRoot = useSurrenderRoot();
  const capacityCategories = useCapacityCategories({ capacityManagementRoot });
  const marketAreas = useMarketAreas({ capacityManagementRoot });

  const pageParams = useMyTransactionsPageParams();

  // version 3 of api adds Regular FCFS to the transactions table
  const isV3 =
    capacityManagementRoot.response?.data._links?.myTransactionsV3?.href;

  const params = useMemoOne<TransactionsParams>(() => {
    const {
      start,
      pageSize,
      networkPointId,
      tsoId,
      transactionTypeSpecificId,
      transactionType,
      ...rest
    } = pageParams.value;

    // merging 'DIRECT_FCFS', 'FCFS' into one filter if v3
    const processedTransactionType: TransactionType[] = transactionType.flatMap(
      (type) =>
        Boolean(isV3) && (type === 'DIRECT_FCFS' || type === 'FCFS')
          ? ['DIRECT_FCFS', 'FCFS']
          : [type]
    );

    return {
      offset: start,
      limit: pageSize,
      networkPointId: networkPointId.map((id) => id.value),
      tsoId: tsoId.map((id) => id.value),
      transactionType: processedTransactionType,
      transactionTypeSpecificId: transactionTypeSpecificId
        ? [transactionTypeSpecificId]
        : [],
      ...rest,
    };
  }, [pageParams.value, isV3]);

  const myTransactions = useMyTransactions({
    capacityManagementRoot,
    params,
  });

  const surrenderActions = useGetSurrenderActions(
    myTransactions,
    surrenderRoot
  );

  if (!capacityManagementRoot.response) return <PageSpinner />;

  const hasMultiYearTransactions =
    capacityManagementRoot.response.data._links
      ?.multiYearTransactionTransportAllocation?.href;

  return (
    <Stack gap={2}>
      <PageHeader title="My Transactions" data-testid="title-my-transactions" />
      <MyTransactionsPage
        myTransactions={myTransactions}
        pageParams={pageParams}
        capacityCategories={capacityCategories}
        marketAreas={marketAreas}
        surrenderRoot={surrenderRoot}
        surrenderActions={surrenderActions}
        hasMultiYearTransactions={Boolean(hasMultiYearTransactions)}
        isV3={Boolean(isV3)}
      />
    </Stack>
  );
};
