import { createContext, useContext } from 'react';
import { useMemoOne } from 'use-memo-one';
import type {
  CapacityCategory,
  Currency,
  FlowUnit,
  NetworkPoint,
  Shipper,
  Tso,
} from 'src/apis/remit-reporting/types';
import { useLookup } from 'src/pages/reporting/secondary-tradings/external-trades/common/use-lookup';

export * from './use-exchange-rate';
export * from './use-lookup';
export * from './components';
export * from './searchable-single-lookup-select';

type RemitReportingLookupContextValue = {
  flowUnits: FlowUnit[];
  flowUnitMap: Map<string, FlowUnit>;
  capacityCategories: CapacityCategory[];
  capacityCategoryMap: Map<string, CapacityCategory>;
  currencies: Currency[];
  tsos: Tso[];
  counterparties: Shipper[];
  networkPoints: NetworkPoint[];
};

export function useRemitReportingLookups():
  | RemitReportingLookupContextValue
  | undefined {
  const tsos = useLookup<Tso>('tsos');
  const shippers = useLookup<Shipper>('shippers');
  const networkPoints = useLookup<NetworkPoint>('network-points');
  const flowUnits = useLookup<FlowUnit>('flow-units');
  const currencies = useLookup<Currency>('currencies');
  const capacityCategories = useLookup<CapacityCategory>('capacity-categories');

  const flowUnitsData = flowUnits.response?.data;
  const capacityCategoriesData = capacityCategories.response?.data;

  const flowUnitMap = useMemoOne(() => {
    if (!flowUnitsData) return;
    return new Map(flowUnitsData.map((f) => [f.id, f]));
  }, [flowUnitsData]);

  const capacityCategoryMap = useMemoOne(() => {
    if (!capacityCategoriesData) return;
    return new Map(capacityCategoriesData.map((c) => [c.id, c]));
  }, [capacityCategoriesData]);

  if (
    !(
      tsos.response &&
      shippers.response &&
      networkPoints.response &&
      currencies.response &&
      flowUnitsData &&
      flowUnitMap &&
      capacityCategoriesData &&
      capacityCategoryMap
    )
  )
    return;

  return {
    tsos: tsos.response.data,
    counterparties: shippers.response.data,
    networkPoints: networkPoints.response.data,
    currencies: currencies.response.data,
    flowUnits: flowUnitsData,
    flowUnitMap,
    capacityCategories: capacityCategoriesData,
    capacityCategoryMap,
  };
}

const RemitReportingLookupContext =
  createContext<RemitReportingLookupContextValue>({
    currencies: [],
    tsos: [],
    counterparties: [],
    networkPoints: [],
    flowUnits: [],
    flowUnitMap: new Map(),
    capacityCategories: [],
    capacityCategoryMap: new Map(),
  });

export function useRemitReportingLookupContext() {
  return useContext(RemitReportingLookupContext);
}

export const RemitReportingLookupContextProvider =
  RemitReportingLookupContext.Provider;

export function useFlowUnitLabel(flowUnitId: string | null) {
  const { flowUnits } = useRemitReportingLookupContext();
  return flowUnits.filter((u) => u.id === flowUnitId)[0]?.name;
}

export function useCurrencyAndFlowUnitLabel(
  currency: string | null,
  flowUnitId: string | null
) {
  const flowUnitLabel = useFlowUnitLabel(flowUnitId);
  const unit = `${currency || ''}${currency && flowUnitLabel ? '/' : ''}${
    flowUnitLabel || ''
  }`;
  return unit === '' ? '' : `${unit}/Runtime`;
}
