import type { FC } from 'react';
import { useEffect, useMemo } from 'react';
import type { BaseFormOption } from 'src/components/form/form-option';
import { SearchableSingleSelect } from 'src/components/form/select/searchable-single-select';
import { useAxios } from 'src/hooks/use-axios';
import { useSearch } from 'src/hooks/use-search';

type LookupFilterItem = {
  id: string;
  name: string;
};

type SearchFor =
  | 'external-trade-tso'
  | 'external-trade-networkpoint'
  | 'remit-report-tso'
  | 'remit-report-counterparty'
  | 'remit-report-networkpoint';
const searchForEndpoints: Record<SearchFor, string> = {
  'external-trade-tso': 'tsos-for-external-trade-filter',
  'external-trade-networkpoint': 'networkpoints-for-external-trades-filter',
  'remit-report-tso': 'tsos-for-secondary-remit-report-filter',
  'remit-report-counterparty': 'counterparties-for-remit-report-filter',
  'remit-report-networkpoint': 'networkpoints-for-remit-report-filter',
};

const useLookupSearch = (searchFor: SearchFor) =>
  useAxios(
    (axios, baseConfig) =>
      axios.request<LookupFilterItem[]>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.remitReporting}/api/platform/lookup/${searchForEndpoints[searchFor]}`,
      }),
    { neededOnPageLoad: false }
  );

export const SearchableSingleLookupSelect: FC<{
  name: string;
  label: string;
  searchFor: SearchFor;
}> = ({ name, label, searchFor }) => {
  const minLength = 1;
  const [searchValue, setSearchValue, searchQuery] = useSearch('', {
    minLength,
    delay: 0,
  });

  const search = useLookupSearch(searchFor);

  // on the very first search input we try to fetch the initial list
  useEffect(() => {
    if (!searchQuery || search.response || search.pending) return;
    search.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, search.response]);

  const searchResult = useMemo(() => {
    if (!search.response) return;

    const lowerSearchQuery = (searchQuery ?? '').toLowerCase();

    const filtered = searchQuery
      ? search.response.data.filter((item) =>
          item.name.toLowerCase().includes(lowerSearchQuery)
        )
      : search.response.data;

    const suggestions: BaseFormOption<string>[] = filtered.map((item) => ({
      value: item.id,
      label: item.name,
    }));

    return suggestions;
  }, [searchQuery, search.response]);

  return (
    <SearchableSingleSelect
      name={name}
      label={label}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      options={searchResult}
      searchValueForCurrentOptions={searchQuery}
      pending={search.pending}
      stacked
    />
  );
};
