import type { FC, ReactNode } from 'react';
import {
  Children,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  useId,
} from 'react';
import styled from 'styled-components';
import { FieldItem, FieldLayout } from 'src/components/form/field-layout';
import { FieldLabel } from 'src/components/form/label';
import { Stack } from 'src/components/layout/stack';
import { useDefaultStacked } from 'src/hooks/use-default-stacked';

const Fields = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1.5rem;
  align-items: start;
  grid-template-columns: 1fr 1fr;
`;

type FieldError = { name: string; showError: boolean };

type FieldGroupContextValue = {
  onError: (fieldError: FieldError) => void;
  id?: string;
} | null;

const FieldGroupContext = createContext<FieldGroupContextValue>(null);

export function useFieldGroup() {
  return useContext(FieldGroupContext);
}

type FieldGroupProps = {
  label: string;
  stacked?: boolean;
  stackedChildren?: boolean;
  inline?: boolean;
  id?: string;
  children: ReactNode;
  info?: ReactNode;
};

export const FieldGroup: FC<FieldGroupProps> = (props) => {
  const { isStacked, minTablet } = useDefaultStacked();
  const {
    children,
    label,
    inline = false,
    stacked = isStacked,
    stackedChildren = false,
    id: givenId,
    info,
  } = props;
  const generatedId = useId();
  const fieldId = givenId ?? generatedId;
  const [errors, setErrors] = useState<FieldError[]>([]);
  const onError = useCallback(
    (fieldError: FieldError) =>
      setErrors((errors) =>
        errors
          .filter((error) => error.name !== fieldError.name)
          .concat([fieldError])
      ),
    []
  );

  const firstContextValue = useMemo(
    () => ({ onError, fieldId }),
    [fieldId, onError]
  );
  const otherContextValues = useMemo(
    () => ({ onError, id: undefined }),
    [onError]
  );

  const fields = Children.map(children, (child, index) => (
    <FieldGroupContext.Provider
      value={index === 0 ? firstContextValue : otherContextValues}
    >
      {child}
    </FieldGroupContext.Provider>
  ));

  return minTablet ? (
    <FieldLayout stacked={stacked}>
      <FieldLabel
        displayLabel={label}
        fieldId={fieldId}
        info={info}
        showError={errors.some((error) => error.showError)}
      />

      {stackedChildren ? (
        <Stack gap={0.5}>{fields}</Stack>
      ) : inline ? (
        // Introduced inline property for a case if we want to have multiple fields equaly spaced between each other
        // and sticked to the top to prevent "buggy-jumping" of the fields when the error message appears
        <Stack
          justifyContent="space-between"
          alignItems="start"
          flow="column"
          gap={0.5}
        >
          {fields}
        </Stack>
      ) : (
        <FieldItem>
          <Fields>{fields}</Fields>
        </FieldItem>
      )}
    </FieldLayout>
  ) : (
    <Stack gap={1.5}>{fields}</Stack>
  );
};
