import type { FC } from 'react';
import { lazy, useMemo } from 'react';
import type { SurrendersParams } from 'src/apis/monolith/types';
import type { PlatformSettings } from 'src/apis/monolith/use-platform-settings';
import { usePlatformSettings } from 'src/apis/monolith/use-platform-settings';
import { useSurrenders } from 'src/apis/monolith/use-surrenders';
import { PageSpinner } from 'src/components/spinner-container';
import { useSurrenderPageParams } from 'src/pages/reporting/surrender/overview/use-page-params';

const Page = lazy(() => import('./page'));

export const SurrenderOverview: FC = () => {
  const platformSettings = usePlatformSettings();

  if (!platformSettings.loaded) return <PageSpinner />;

  return (
    <LoadData platformSettings={platformSettings.response?.data ?? null} />
  );
};

type PageProps = {
  platformSettings: PlatformSettings | null;
};

const LoadData: FC<PageProps> = ({ platformSettings }) => {
  const pageParams = useSurrenderPageParams({ platformSettings });

  const params = useMemo(
    () =>
      ({
        start: pageParams.value.start,
        pageSize: pageParams.value.pageSize,
        sortColumn: pageParams.value.sortColumn,
        sortAscending: pageParams.value.sortAscending,
        favoritesOnly: pageParams.value.favoritesOnly,
        dealIds: pageParams.value.dealId,
        runtimeFrom: pageParams.value.runtimeFrom,
        runtimeTo: pageParams.value.runtimeTo,
        direction: pageParams.value.direction,
        tso: pageParams.value.tso.map((tso) => tso.value),
        networkPointName: pageParams.value.networkPointName.map(
          (item) => item.value
        ),
        'bookedCapacity-min': pageParams.value['bookedCapacity-min'],
        'bookedCapacity-max': pageParams.value['bookedCapacity-max'],
        amountSetting: pageParams.value.amountSetting,
        costSetting: pageParams.value.costSetting,
      }) satisfies SurrendersParams,
    [pageParams.value]
  );
  const surrenders = useSurrenders({ params });

  if (!surrenders.response) return <PageSpinner />;

  return (
    <Page
      pageParams={pageParams}
      surrenders={surrenders}
      surrendersParams={params}
    />
  );
};
