import type { FC } from 'react';
import { useParams } from 'react-router-dom';
import { ThrowNotFound } from 'src/components/feedback/not-found';

/**
 * This is a small helper component for migrating from React Router v5 to v6.
 * This component will run a regex check on the param with the given name.
 * If the param is not present or does not match the regex, it will throw a 404.
 */
export const ParamsGuard: FC<{
  children: JSX.Element;
  name: string;
  regExp: RegExp;
}> = ({ children, name, regExp }) => {
  const param = useParams<Record<string, string | undefined>>()[name];
  if (!param) return <ThrowNotFound />;
  if (!param.match(regExp)) return <ThrowNotFound />;
  return <>{children}</>;
};
