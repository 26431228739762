import { useMonth } from '@datepicker-react/hooks';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { add, setMonth, setYear } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';
import type { FC } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { Button } from 'src/components/buttons-and-actions/button';
import { Day } from 'src/components/form/datepicker/day';
import { MonthSelector } from 'src/components/form/datepicker/month-selector';
import { YearSelector } from 'src/components/form/datepicker/year-selector';

const IconButton = styled(Button).attrs({ mode: 'icon', type: 'button' })`
  font-size: 3rem;
`;

const MonthContainer = styled.div``;

const Inner = styled.div`
  position: relative;
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: 3rem auto 3rem;
  justify-content: space-between;
  align-items: center;
  grid-column-gap: 1rem;
`;

const Weekdays = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-content: center;
  font-weight: 900;

  div {
    padding: 0.4rem;
    text-align: center;
  }
`;

const Days = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-content: center;
  grid-row-gap: 0.2rem;
`;

type MonthProps = {
  year: number;
  month: number;
  date: Date;
  monthOffset?: number;
  startDate: Date | null;
  endDate: Date | null;
  hoverDate: Date | null;
  hideNextMonthButton?: boolean;
  hidePrevMonthButton?: boolean;
  onChange: (date: Date) => void;
  onDateSelect: (date: Date) => void;
  onNextMonthClick: () => void;
  onPrevMonthClick: () => void;
  onDayHover: (date: Date | null) => void;
  isDateBlocked: (date: Date) => boolean;
};

export const Month: FC<MonthProps> = (props) => {
  const {
    year,
    month,
    date,
    monthOffset = 0,
    startDate,
    endDate,
    hoverDate,
    hideNextMonthButton = false,
    hidePrevMonthButton = false,
    onChange,
    onNextMonthClick,
    onPrevMonthClick,
    onDateSelect,
    onDayHover,
    isDateBlocked,
  } = props;

  const [showMonthSelector, setShowMonthSelector] = useState(false);
  const [showYearSelector, setShowYearSelector] = useState(false);

  const { days, monthLabel, weekdayLabels } = useMonth({
    year,
    month,
    monthLabelFormat(date) {
      return date.toLocaleDateString('en-GB', { month: 'long' });
    },
    weekdayLabelFormat(date) {
      return date.toLocaleDateString('en-GB', { weekday: 'short' });
    },
  });

  return (
    <MonthContainer>
      <Header>
        {hidePrevMonthButton ? (
          <span />
        ) : (
          <IconButton onClick={onPrevMonthClick}>
            <FontAwesomeIcon icon={faAngleLeft} aria-label="Prev month" />
          </IconButton>
        )}

        <div>
          <Button
            mode="link"
            isActive={showMonthSelector}
            onClick={() => {
              if (showYearSelector) {
                setShowYearSelector(false);
              }

              setShowMonthSelector(!showMonthSelector);
            }}
          >
            {monthLabel}
          </Button>{' '}
          <Button
            mode="link"
            isActive={showYearSelector}
            onClick={() => {
              if (showMonthSelector) {
                setShowMonthSelector(false);
              }

              setShowYearSelector(!showYearSelector);
            }}
          >
            {year}
          </Button>
        </div>

        {hideNextMonthButton ? (
          <span />
        ) : (
          <IconButton onClick={onNextMonthClick}>
            <FontAwesomeIcon icon={faAngleRight} aria-label="Next month" />
          </IconButton>
        )}
      </Header>

      <Inner>
        <Weekdays>
          {weekdayLabels.map((dayLabel) => (
            <div key={dayLabel}>{dayLabel}</div>
          ))}
        </Weekdays>

        <Days>
          {days.map((day, index) => {
            if (typeof day === 'object') {
              // the dates generated are always 00:00 local time, we need this to be 00:00 in Europe/Berlin (CET/CEST)
              // this is not just timezone "conversion", but we need to find the difference in hours and subtract them
              // for this we have:
              //  - day.date.getTimezoneOffset() => offset from browser TZ to UTC
              //  - date-fns-tz's getTimezoneOffset(timeZone: String, date: Date|Number) => can be used to get current offset of Europe/Berlin
              const midnightInUTC = add(day.date, {
                minutes: -1 * day.date.getTimezoneOffset(),
              });
              const midnightInBerlin = add(midnightInUTC, {
                // function yields milliseconds, duration only accepts seconds
                seconds:
                  getTimezoneOffset('Europe/Berlin', midnightInUTC) / -1000,
              });
              return (
                <Day
                  key={day.dayLabel}
                  date={midnightInBerlin}
                  dayLabel={day.dayLabel}
                  startDate={startDate}
                  endDate={endDate}
                  hoverDate={hoverDate}
                  onDateSelect={onDateSelect}
                  onDayHover={onDayHover}
                  isDateBlocked={isDateBlocked}
                />
              );
            }

            return <div key={index} />;
          })}
        </Days>

        {showMonthSelector && (
          <MonthSelector
            month={month}
            onSelect={(month) => onChange(setMonth(date, month - monthOffset))}
          />
        )}

        {showYearSelector && (
          <YearSelector
            year={year}
            onSelect={(year) => onChange(setYear(date, year))}
          />
        )}
      </Inner>
    </MonthContainer>
  );
};
