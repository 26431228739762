import styled from 'styled-components';
import { Colors } from 'src/styles';

type BoxProps = {
  color?: 'white' | 'brand';
  padding?: 'default' | 'small';
};

export const Box = styled.div<BoxProps>`
  padding: ${({ padding = 'default' }) => (padding === 'default' ? 1.5 : 1)}rem;
  background-color: ${({ color = 'white' }) =>
    color === 'white' ? 'white' : Colors.brand};
`;
