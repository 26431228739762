import type { FC } from 'react';
import { lazy } from 'react'; // import { useOurProfiler } from 'src/hooks/use-our-profiler';
import { useMemoOne } from 'use-memo-one';
import type {
  StorageAllocationState,
  StorageOffersSearchParams,
  StorageOfferState,
} from 'src/apis/monolith/types';
import { useStorageOffers } from 'src/apis/monolith/use-storage-offers';
import { PageSpinner } from 'src/components/spinner-container';
import type { QuickFilterValues } from 'src/pages/storage/offers/schema';
import { usePageParams } from 'src/pages/storage/offers/use-page-params';

const Page = lazy(() => import('./page'));

const offerStateMap: Record<QuickFilterValues, StorageOfferState[]> = {
  ALL: [],
  PENDING: [],
  DRAFT: ['DRAFT'],
  FINISHED: ['FINISHED'],
  OPEN_AND_PUBLISHED: ['OPEN', 'PUBLISHED'],
  WAITING_FOR_NEXT_ROUND: ['WAITING_FOR_NEXT_ROUND'],
};

const allocationStateMap: Record<QuickFilterValues, StorageAllocationState[]> =
  {
    ALL: [],
    PENDING: ['AWAITING_ALLOCATION', 'ALLOCATIONS_UNCONFIRMED'],
    DRAFT: [],
    FINISHED: [],
    OPEN_AND_PUBLISHED: [],
    WAITING_FOR_NEXT_ROUND: [],
  };

export const StorageOffers: FC = () => {
  const pageParams = usePageParams();

  const params = useMemoOne(() => {
    const params: StorageOffersSearchParams = {
      start: pageParams.value.start,
      pageSize: pageParams.value.pageSize,
      sortColumn: pageParams.value.sortColumn,
      sortAscending: pageParams.value.sortAscending,
      offerLabel: pageParams.value.offerLabel.map((item) => item.value),
      storageLocationName: pageParams.value.storageLocationName.map(
        (item) => item.value
      ),
      tsoShortName: pageParams.value.tsoShortName.map((item) => item.value),
      productType: pageParams.value.productType,
      runtimeFrom: pageParams.value.runtimeFrom,
      runtimeTo: pageParams.value.runtimeTo,
      offerState: offerStateMap[pageParams.value.quickFilter],
      allocationState: allocationStateMap[pageParams.value.quickFilter],
      id: pageParams.value.id,
    };

    return params;
  }, [pageParams.value]);

  const storageOffers = useStorageOffers(params);

  if (!storageOffers.response) {
    return <PageSpinner />;
  }

  return <Page storageOffers={storageOffers} pageParams={pageParams} />;
};
