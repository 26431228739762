import { useEffect } from 'react';
import type { ExternalSecondaryTradeDetails } from 'src/apis/remit-reporting/types';
import { useAxios } from 'src/hooks/use-axios';

export function useExternalTrade(externalTradeId: string) {
  const trade = useAxios(
    (axios, baseConfig, externalTradeId: string) =>
      axios.request<ExternalSecondaryTradeDetails>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.remitReporting}/api/platform/external-secondary-trades/${externalTradeId}`,
        method: 'GET',
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    trade.execute(externalTradeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalTradeId]);

  return trade;
}
