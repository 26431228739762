import { useEffect } from 'react';
import type { BidCollectorRootResponse } from 'src/apis/snam-bid-collector/types';
import { useAxios } from 'src/hooks/use-axios';

export function useBidCollectorRoot() {
  const bidCollectorRoot = useAxios(
    (axios, baseConfig) =>
      axios.request<BidCollectorRootResponse>({
        ...baseConfig,
        url: PRISMA_CONFIG.snamBidCollector,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    bidCollectorRoot.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return bidCollectorRoot;
}

export type BidCollectorRootRequest = ReturnType<typeof useBidCollectorRoot>;
