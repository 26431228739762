import { useEffect } from 'react';
import urlTemplate from 'url-template';
import type { LngOfferDetails } from 'src/apis/lng-marketing/types';
import type { LngOffersRequest } from 'src/apis/lng-marketing/use-lng-offers';
import type { Successful } from 'src/hooks/use-axios';
import { useAxios } from 'src/hooks/use-axios';

type HookOptions = {
  lngOffers?: LngOffersRequest;
  offerId: string;
};

type ExecuteOptions = {
  lngOffers: Successful<LngOffersRequest>;
  offerId: string;
};

export function useLngOfferDetails({ lngOffers, offerId }: HookOptions) {
  const lngOffer = useAxios(
    (axios, baseConfig, { lngOffers, offerId }: ExecuteOptions) => {
      const url = urlTemplate
        .parse(lngOffers.response.data._links.instance.href)
        .expand({ offerId });

      return axios.request<LngOfferDetails>({
        ...baseConfig,
        url,
      });
    },
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!lngOffers?.response) return;

    lngOffer.execute({
      lngOffers,
      offerId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lngOffers?.response, offerId]);

  // not available on stage
  if (!lngOffers) return;

  return lngOffer;
}

export type LngOfferDetailsRequest = ReturnType<typeof useLngOfferDetails>;
