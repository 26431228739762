import type { RefObject } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useMounted } from 'src/hooks/use-mounted';

function withinContainer(
  ref: RefObject<HTMLElement | undefined>,
  additionalRefs: RefObject<HTMLElement | undefined>[] | undefined,
  target: EventTarget | null
) {
  return !!(
    target instanceof Node &&
    (ref.current?.contains(target) ||
      additionalRefs?.some((ref) => ref.current?.contains(target)))
  );
}

type WhileActiveOptions = {
  initialActive?: boolean;
  /**
   * If there are additional elements that are outside of your original
   * container, but should still be considered as "keep container active",
   * add them here.
   */
  additionalRefs?: RefObject<HTMLElement | undefined>[];
};

export function useWhileActive(
  ref: RefObject<HTMLElement | undefined>,
  { initialActive = false, additionalRefs }: WhileActiveOptions = {}
) {
  const [active, setActive] = useState(initialActive);

  const [angularIframe] = useState(() => {
    const [iframe] = document.getElementsByName('embedded-angular');
    return iframe as HTMLIFrameElement | undefined;
  });

  const [mousedownInside, setMousedownInside] = useState(false);
  const handleMousedown = useCallback(
    (event: MouseEvent) => {
      if (!ref.current) return;
      if (withinContainer(ref, additionalRefs, event.target)) {
        setMousedownInside(true);
      } else {
        setMousedownInside(false);
        setActive(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleFocusout = useCallback(
    (event: FocusEvent) => {
      if (!ref.current) return;
      // ignore switching to something completely different (e.g. dev tools)
      if (!document.hasFocus()) return;
      // ignore focus switch within the container
      if (withinContainer(ref, additionalRefs, event.relatedTarget)) return;
      if (withinContainer(ref, additionalRefs, event.target)) return;
      // ignore focus loss, if clicked within the container
      if (!event.relatedTarget && mousedownInside) return;

      setActive(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mousedownInside]
  );

  const mounted = useMounted();

  // if we tab away, we'll set active to false
  // NOTE!!! why this complicated way? previously we used "focusin" to
  // check if the element which gets the focus next is within "ref",
  // but this sadly messed with scroll events
  const handleKeydown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Tab') {
        setTimeout(() => {
          if (!mounted.current) return;
          if (!ref.current?.contains(document.activeElement)) setActive(false);
        }, 1);
      }
    },
    [mounted, ref]
  );

  useEffect(() => {
    if (active) {
      document.addEventListener('focusout', handleFocusout);
      document.addEventListener('mousedown', handleMousedown);
      angularIframe?.contentDocument?.addEventListener(
        'mousedown',
        handleMousedown
      );
      document.addEventListener('keydown', handleKeydown, { capture: true });
      return () => {
        document.removeEventListener('focusout', handleFocusout);
        document.removeEventListener('mousedown', handleMousedown);
        angularIframe?.contentDocument?.removeEventListener(
          'mousedown',
          handleMousedown
        );
        document.removeEventListener('keydown', handleKeydown);
      };
    }
  }, [angularIframe, active, handleFocusout, handleMousedown, handleKeydown]);

  return [active, setActive] as const;
}
