import type { FC } from 'react';
import { lazy } from 'react';
import { useParams } from 'react-router-dom';
import { useCapacityManagementRoot } from 'src/apis/capacity-management/use-capacity-management-root';
import type { MultiYearAllocationRequest } from 'src/apis/capacity-management/use-multi-year-allocation';
import { useMultiYearAllocation } from 'src/apis/capacity-management/use-multi-year-allocation';
import { PageSpinner } from 'src/components/spinner-container';
import type { Successful } from 'src/hooks/use-axios';
import { useNetworkPointByUuid } from 'src/hooks/use-network-point-by-uuid';

const Page = lazy(() => import('./page'));

export const MultiYearTransactionDetail: FC = () => {
  const { multiYearAllocationId } = useParams<{
    multiYearAllocationId: string;
  }>();

  const capacityManagementRoot = useCapacityManagementRoot();
  const multiYearAllocation = useMultiYearAllocation({
    capacityManagementRoot,
    multiYearAllocationId,
  });

  if (!capacityManagementRoot.response || !multiYearAllocation.response)
    return <PageSpinner />;

  return <LoadNetworkPoint multiYearAllocation={multiYearAllocation} />;
};

type LoadNetworkPointProps = {
  multiYearAllocation: Successful<MultiYearAllocationRequest>;
};
const LoadNetworkPoint: FC<LoadNetworkPointProps> = ({
  multiYearAllocation,
}) => {
  const networkPointDetailRequest = useNetworkPointByUuid({
    uuid: multiYearAllocation.response.data.networkPointId,
  });

  return (
    <Page
      multiYearAllocation={multiYearAllocation}
      networkPointDetailRequest={networkPointDetailRequest}
    />
  );
};
