import { faDownload, faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ButtonHTMLAttributes } from 'react';
import { forwardRef } from 'react';
import type {
  StyledComponentPropsWithRef,
  StyledConfig,
} from 'styled-components';
import styled, { css } from 'styled-components';
import type { ActionMode } from 'src/components/action-mode';
import { useActionMode } from 'src/components/action-mode';
import type { ActionGroupMode } from 'src/components/buttons-and-actions/action-group';
import { useActionGroupMode } from 'src/components/buttons-and-actions/action-group';
import { Colors } from 'src/styles';

export type Size = 'default' | 'small';

export type ModeButtonProps =
  | {
      mode: 'inverse' | 'icon' | 'icon-inverse' | 'embedded' | 'dropdown';
    }
  | {
      mode?: 'link' | 'pill';
      isActive?: boolean;
    }
  | {
      mode: 'link-underlined';
      icon?: 'external' | 'download';
    }
  | {
      mode?: 'primary';
      size?: Size;
    }
  | {
      mode: 'secondary';
      size?: Size;
      isActive?: boolean;
    }
  | {
      mode: 'link-primary';
    };

const modeKeys = ['mode', 'isActive', 'icon', 'size'];

const styledConfig: StyledConfig<
  StyledComponentPropsWithRef<'button'> & ButtonProps
> = {
  shouldForwardProp(prop, defaultValidatorFn) {
    return !modeKeys.includes(prop) && defaultValidatorFn(prop);
  },
};

export type ButtonProps = {
  /**
   * Configure your button type.
   *
   * @default 'button'
   */
  type?: string;
} & ModeButtonProps &
  ButtonHTMLAttributes<HTMLButtonElement>;

export const buttonTransition = css`
  transition:
    color 0.35s,
    background-color 0.35s,
    border-color 0.35s;
`;

export const buttonBaseStyle = css<
  ButtonHTMLAttributes<HTMLButtonElement> & { isActive?: boolean }
>`
  cursor: pointer;
  user-select: none;
  ${buttonTransition};

  ${({ disabled, isActive = false }) =>
    disabled &&
    !isActive &&
    css`
      cursor: not-allowed;
      opacity: 0.65;
      box-shadow: none;
    `}

  ${({ disabled, isActive = false }) =>
    disabled &&
    isActive &&
    css`
      cursor: default;
    `}
`;

export const linkBaseStyle = css`
  text-decoration: none;
  border-bottom: 0.2rem solid transparent;
  ${buttonTransition};
`;

type ModeProps = { actionGroupMode: ActionGroupMode; size?: Size };

export function getActionGroupModeStyle({ actionGroupMode, size }: ModeProps) {
  const radius = size === 'small' ? '1.6rem' : '1.8rem';
  switch (actionGroupMode) {
    case 'left':
      return css`
        border-top-left-radius: ${radius};
        border-bottom-left-radius: ${radius};
      `;
    case 'right':
      return css`
        border-top-right-radius: ${radius};
        border-bottom-right-radius: ${radius};
      `;
    case 'middle':
      return;
    case 'single':
      return css`
        border-radius: ${radius};
      `;
  }
}

export const isSmall = (props: { size?: Size }) =>
  props.size === 'small'
    ? css`
        padding: 0.4rem 1rem;
        font-size: 1.2rem;
        line-height: 1;
      `
    : null;

const PrimaryButton = styled.button.withConfig(styledConfig)<{
  size?: Size;
  actionGroupMode: ActionGroupMode;
}>`
  ${buttonBaseStyle};

  color: white;
  background-color: ${Colors.brand};
  border: 0.2rem solid ${Colors.brand};
  padding: 0.6rem 2rem;
  font-weight: bold;

  ${getActionGroupModeStyle};
  ${({ actionGroupMode }) =>
    (actionGroupMode === 'left' || actionGroupMode === 'middle') &&
    css`
      margin-right: 0.1rem;
    `}

  ${isSmall};

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover,
      &:focus {
        color: white;
        background-color: ${Colors.brandSecondary};
        border-color: ${Colors.brandSecondary};
      }
    `}
  &:active {
    box-shadow: inset 0.1rem 0.1rem 0.4rem rgba(0, 0, 0, 0.25);
  }
`;

const SecondaryButton = styled.button.withConfig(styledConfig)<{
  size?: Size;
  actionGroupMode: ActionGroupMode;
  isActive?: boolean;
}>`
  ${buttonBaseStyle};

  color: ${Colors.brand};
  background-color: ${Colors.brandLight3};
  border: 0.2rem solid transparent;
  padding: 0.6rem 2rem;
  font-weight: bold;

  ${getActionGroupModeStyle};
  ${({ actionGroupMode }) =>
    (actionGroupMode === 'left' || actionGroupMode === 'middle') &&
    css`
      margin-right: 0.1rem;
    `}

  ${isSmall};

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover,
      &:focus {
        color: white;
        background-color: ${Colors.brandSecondary};
        border-color: ${Colors.brandSecondary};
      }
    `}
  ${({ isActive }) =>
    isActive &&
    css`
      box-shadow: inset 0.1rem 0.1rem 0.4rem rgba(0, 0, 0, 0.25);
    `}
  &:active {
    box-shadow: inset 0.1rem 0.1rem 0.4rem rgba(0, 0, 0, 0.25);
  }
`;

const InverseButton = styled(PrimaryButton)`
  color: ${Colors.brand};
  background-color: white;
  border: 0.2rem solid white;
`;

const EmbeddedButton = styled.button`
  ${buttonBaseStyle};

  color: ${Colors.brand};
  background: none;
  border: none;
  padding: 0;
  transition: opacity 0.2s ease;
  opacity: 0.4;

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover,
      &:focus {
        opacity: 0.8;
      }
    `}
`;

const IconButton = styled.button.withConfig(styledConfig)`
  ${buttonBaseStyle};

  color: ${Colors.brand};
  background: transparent;
  border: none;
  padding: 0;
  font-size: 1.6rem;

  ${({ disabled }) =>
    disabled
      ? css`
          color: ${Colors.inactive};
        `
      : css`
          &:hover,
          &:focus {
            color: ${Colors.brandSecondary};
          }
        `}
`;

const IconInverseButton = styled.button.withConfig(styledConfig)`
  ${buttonBaseStyle};

  color: white;
  background-color: ${Colors.brand};
  border: none;
  padding: 0;
  font-size: 1.6rem;

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover,
      &:focus {
        color: ${Colors.brandSecondary};
      }
    `}
`;

const LinkButton = styled.button.withConfig(styledConfig)<{
  isActive?: boolean;
  isUnderlined?: boolean;
}>`
  ${buttonBaseStyle};
  font-weight: inherit;

  color: ${Colors.brand};
  font-size: 1.5rem;
  line-height: inherit;
  border: none;
  background-color: transparent;
  text-align: left;
  padding: 0;
  border-bottom: 0.2rem solid transparent;

  ${({ isActive }) =>
    isActive &&
    css`
      border-bottom-color: ${Colors.brand};
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 1;
      color: ${Colors.inactive};
    `};

  ${({ isActive, disabled }) =>
    !isActive &&
    !disabled &&
    css`
      &:hover,
      &:focus {
        color: ${Colors.brandSecondary};
      }
    `};

  ${({ isUnderlined }) =>
    isUnderlined &&
    css`
      text-decoration: underline;
      text-decoration-color: ${Colors.brandLight2};
      border-bottom-width: 0;
    `}
`;

// note: "pill" was the name in the old frontend
const PillButton = styled.button.withConfig(styledConfig)<{
  isActive?: boolean;
}>`
  ${linkBaseStyle};
  border: none;
  font-weight: inherit;
  line-height: inherit;
  background-color: transparent;
  text-align: left;
  padding: 0;
  border-bottom: 0.2rem solid transparent;

  font-size: 1.6rem;
  color: ${Colors.brand};

  &:hover,
  &:focus {
    color: ${Colors.brand};
    border-bottom-color: ${Colors.brand};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${Colors.brand};
      border-bottom-color: ${Colors.brand};
    `};

  ${({ isActive, disabled }) =>
    !isActive &&
    disabled &&
    css`
      opacity: 1;
      color: ${Colors.inactive};
    `};
`;

const LinkPrimaryButton = styled.button.withConfig(styledConfig)`
  ${buttonBaseStyle};
  font-weight: inherit;

  color: white;
  font-size: 1.5rem;
  line-height: inherit;
  border: none;
  border-bottom: 0.2rem solid transparent;
  background-color: transparent;
  text-align: left;
  padding: 0;

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover,
      &:focus {
        border-bottom-color: white;
      }
    `}
`;

const DropdownButton = styled.button.withConfig(styledConfig)`
  ${buttonBaseStyle};

  border: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  background: none;
  padding: 0.2rem 2rem;
  color: #333;

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover,
      &:focus {
        color: #333;
        background-color: #f5f5f5;
      }
    `}
`;

/**
 * Your basic button component. Use this for `onClick` interactions.
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (baseProps, ref) => {
    const actionMode = useActionMode();
    const actionGroupMode = useActionGroupMode();

    const props: ButtonProps = {
      type: 'button',
      ...mapButtonActionMode(actionMode),
      ...baseProps,
    };

    switch (props.mode) {
      case 'link-underlined':
        const appendix = props.icon ? (
          <>
            {' '}
            <FontAwesomeIcon
              icon={props.icon === 'download' ? faDownload : faExternalLink}
            />
          </>
        ) : null;
        const link = <LinkButton isUnderlined {...props} ref={ref} />;
        return appendix ? (
          <span>
            {link}
            {appendix}
          </span>
        ) : (
          link
        );
      case 'link':
        return <LinkButton {...props} ref={ref} />;
      case 'pill':
        return (
          <PillButton
            {...props}
            ref={ref}
            disabled={props.disabled ?? props.isActive}
          />
        );
      case 'link-primary':
        return <LinkPrimaryButton {...props} ref={ref} />;
      case 'embedded':
        return <EmbeddedButton {...props} ref={ref} />;
      case 'icon':
        return <IconButton {...props} ref={ref} />;
      case 'icon-inverse':
        return <IconInverseButton {...props} ref={ref} />;
      case 'inverse':
        return (
          <InverseButton
            {...props}
            actionGroupMode={actionGroupMode}
            ref={ref}
          />
        );
      case 'dropdown':
        return <DropdownButton {...props} ref={ref} />;
      case 'secondary':
        return (
          <SecondaryButton
            {...props}
            actionGroupMode={actionGroupMode}
            ref={ref}
            disabled={props.disabled ?? props.isActive}
          />
        );
      case 'primary':
      case undefined:
        return (
          <PrimaryButton
            {...props}
            actionGroupMode={actionGroupMode}
            ref={ref}
          />
        );
    }
  }
);

export function mapButtonActionMode(
  actionMode: ActionMode | null
): ModeButtonProps | null {
  if (!actionMode) return null;
  if (actionMode.mode === 'button-primary') {
    return { ...actionMode, mode: 'primary' as const };
  }
  if (actionMode.mode === 'button-secondary') {
    return { ...actionMode, mode: 'secondary' as const };
  }
  return actionMode as ModeButtonProps;
}
