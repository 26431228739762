import type { FC } from 'react';
import { ExternalLink } from 'src/components/navigation/link';
import { Modal } from 'src/components/overlay/modal';

const DowngradePrismaApiModal: FC<{ onDismiss: () => void }> = ({
  onDismiss,
}) => {
  return (
    <Modal
      title="Are you sure you want to stop using the PRISMA API?"
      onDismiss={() => onDismiss()}
      footer={{
        confirmButton: { label: 'OK', onClick: () => onDismiss() },
        closeButton: null,
      }}
    >
      <p>
        We will assist you in the offboarding - for this, please reach out to
        our Customer Success team (
        <ExternalLink
          href="mailto:helpdesk@prisma-capacity.eu"
          mode="default-underlined"
        >
          helpdesk@prisma-capacity.eu
        </ExternalLink>
        ).
      </p>
    </Modal>
  );
};

export default DowngradePrismaApiModal;
