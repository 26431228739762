import type { FC } from 'react';
import { Route } from 'react-router-dom';
import { useAssignmentServicePublic } from 'src/apis/assignment-service/use-assignment-service-root';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { RootRoutes } from 'src/components/root-routes';
import { PageSpinner } from 'src/components/spinner-container';
import { EditAssignmentSettings } from 'src/pages/settings/assignment-settings/assignment-contact-point/edit';
import { EditDvgwConfiguration } from 'src/pages/settings/assignment-settings/dvgw-configuration/edit';
import { AssignmentSettingsRoot } from 'src/pages/settings/assignment-settings/page';
import { EditRequiredInformation } from 'src/pages/settings/assignment-settings/required-information/edit';

export const AssignmentSettings: FC = () => {
  const assignmentService = useAssignmentServicePublic();
  if (!assignmentService.response) return <PageSpinner />;

  return (
    <RootRoutes>
      <Route
        path="/settings/assignment-settings"
        element={
          <AssignmentSettingsRoot assignmentService={assignmentService} />
        }
      />

      <Route
        path="/settings/assignment-settings/edit"
        element={
          <EditAssignmentSettings assignmentService={assignmentService} />
        }
      />

      <Route
        path="/settings/assignment-settings/required-information/edit"
        element={
          <EditRequiredInformation assignmentService={assignmentService} />
        }
      />

      <Route
        path="/settings/assignment-settings/dvgw-configuration/edit"
        element={
          <EditDvgwConfiguration assignmentService={assignmentService} />
        }
      />

      <Route path="*" element={<ThrowNotFound />} />
    </RootRoutes>
  );
};
