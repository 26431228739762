import { useEffect } from 'react';
import type { ComfortBid } from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';

export function useDayAheadComfortBid(bidId: string) {
  const dayAheadComfortBid = useAxios(
    (axios, baseConfig, bidId: string) =>
      axios.request<ComfortBid>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/auctions/comfortBids/dayAhead/${bidId}`,
        method: 'GET',
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    dayAheadComfortBid.execute(bidId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bidId]);

  return dayAheadComfortBid;
}

export type DayAheadComfortBidRequest = ReturnType<
  typeof useDayAheadComfortBid
>;
