import type { FC } from 'react';
import { lazy } from 'react';
import { useParams } from 'react-router-dom';
import type { FinancialSecurityType } from 'src/apis/contract-management/types';
import { useContractManagement } from 'src/apis/contract-management/use-contract-management';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { PageSpinner } from 'src/components/spinner-container';

const Page = lazy(() => import('./page'));

export const FinancialSecurityAmendmentApproval: FC<{
  type: FinancialSecurityType;
}> = ({ type }) => {
  const { financialSecurityId } = useParams<{ financialSecurityId: string }>();
  const contractManagement = useContractManagement();

  if (!contractManagement.response) return <PageSpinner />;

  if (!contractManagement.response.data._links.uploadFile?.href) {
    return <ThrowNotFound />;
  }

  return (
    <Page
      uploadFileUrl={contractManagement.response.data._links.uploadFile.href}
      financialSecurityId={financialSecurityId}
      type={type}
      contractManagement={contractManagement.response.data}
    />
  );
};
